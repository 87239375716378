import React, { Component } from 'react';
import { Icon } from 'antd';

import Globals from '../../config/Globals';

export default class IconsTimeline extends Component {
  getIconCentral(state) {
    if (state == Globals.Event_State.READY || state == Globals.Event_State.IN_PROGRESS) {
      return (
        <Icon type="exclamation-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />
      );
    }

    if (state == Globals.Event_State.EXPIRED) {
      return (
        <Icon type="exclamation-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />
      );
    }

    if (state == Globals.Event_State.FAILED) {
      return (
        <Icon type="close-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />
      );
    }

    if (state == Globals.Event_State.COMPLETED) {
      return <Icon type="check-circle" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    }

    if (state == Globals.Event_State.CANCELLED) {
      return <Icon type="stop" style={{ color: `${Globals.COLOR_PALETTE.grey}` }} className="timelineIcon" />;
    }

    if (state == Globals.Event_State.LOCKED) {
      return <Icon type="clock-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }

    if (state == 'START') {
      return <Icon type="up-circle" style={{ color: `${Globals.COLOR_PALETTE.blue}` }} className="timelineIcon" />;
    }
    // if (state == 'END') {
    //   if (this.props.state == Globals.CertificationProcess_State.COMPLETED) {
    //     return <Icon type="safety-certificate" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    //   }
    // }

    return <Icon type="clock-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
  }

  getIconModal(event, state) {
    // ORANGE ELEMENTS
    if ((event == Globals.Event_Type.EXAM &&
          (state == Globals.Event_State.IN_PROGRESS ||
           state == Globals.Event_State.WAITING)) ||
        (event == Globals.Event_Type.PAYMENT && state == Globals.Event_State.IN_PROGRESS)) {
      return (
        <Icon type="exclamation-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />
      );
    }
    if (event == Globals.Event_Type.REQUIREMENT && state == Globals.Event_State.IN_PROGRESS) {
      return <Icon type="exclamation-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }
    if ((event == Globals.Event_Type.PAYMENT || event == Globals.Event_Type.RES_PAYMENT) && state == Globals.Event_State.READY) {
      return <Icon type="dollar" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }
    if (event == Globals.Event_Type.EXAM && state == Globals.Event_State.READY) {
      return <Icon type="calendar" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }
    if ((event == Globals.Event_Type.COOLDOWN ||
         event == Globals.Event_Type.PRINTCARD ||
         event == Globals.Event_Type.WAIVE) && state == Globals.Event_State.IN_PROGRESS) {
      return <Icon type="clock-circle" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }
    if (event == Globals.Event_Type.EXAM && state == Globals.Event_State.LOCKED) {
      return <Icon type="lock" style={{ color: `${Globals.COLOR_PALETTE.orange}` }} className="timelineIcon" />;
    }

    // GREEN ELEMENTS
    // if (event == Globals.Event_Type.EXAM && state == Globals.Event_State.COMPLETED) {
    //   return <Icon type="file-done" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    // }
    if (event == Globals.Event_Type.PAYMENT && state == Globals.Event_State.COMPLETED) {
      return <Icon type="check-circle" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    }
    if (event == Globals.Event_Type.EXAM && state == Globals.Event_State.COMPLETED) {
      return <Icon type="schedule" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    }
    if ((event == Globals.Event_Type.COOLDOWN ||
         event == Globals.Event_Type.REQUIREMENT ||
         event == Globals.Event_Type.PRINTCARD ||
         event == Globals.Event_Type.WAIVE) && state == Globals.Event_State.COMPLETED) {
      return <Icon type="check-circle" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    }
    if (event == Globals.Event_Type.CERTIFICATION && state == Globals.Event_State.COMPLETED) {
      return <Icon type="safety-certificate" style={{ color: `${Globals.COLOR_PALETTE.green}` }} className="timelineIcon" />;
    }

    // RED ELEMENTS
    // if (event == Globals.Event_Type.EXAM_INITIATED && state == Globals.Event_State.FAILED) {
    //   return <Icon type="exception" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />;
    // }
    if (event == Globals.Event_Type.PAYMENT && state == Globals.Event_State.FAILED) {
      return <Icon type="close-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />;
    }
    if (event == Globals.Event_Type.EXAM && state == Globals.Event_State.FAILED) {
      return (
        <Icon type="close-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />
      );
    }
    if (event == Globals.Event_Type.CERTIFICATION && state == Globals.Event_State.FAILED) {
      return (
        <Icon type="close-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />
      );
    }
    if (event == Globals.Event_Type.CERTIFICATION && state == Globals.Event_State.EXPIRED) {
      return (
        <Icon type="exclamation-circle" style={{ color: `${Globals.COLOR_PALETTE.main_red}` }} className="timelineIcon" />
      );
    }

    // GREY ELEMENTS
    if (state == Globals.Event_State.CANCELLED) {
      return <Icon type="stop" style={{ color: `${Globals.COLOR_PALETTE.grey}` }} className="timelineIcon" />;
    }


    return <Icon type="clock-circle" style={{ color: `${Globals.COLOR_PALETTE.blue}` }} className="timelineIcon" />;
  }

  render() {
    if (this.props.state) {
      return this.getIconCentral(this.props.eventState);
    }

    if (this.props.event && this.props.eventState) {
      return this.getIconModal(this.props.event, this.props.eventState);
    }

    // eslint-disable-next-line consistent-return
    return '';
  }
}
