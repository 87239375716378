import React from 'react';
import CommonCertificationCard from '../commonComponents/CommonCertificationCard';
import '../../assets/stylesheets/CommonCertificationProcess.scss';
import APIUtils from '../../components/API/APIUtils';

export default class CommonCertificationCollectionView extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <div className="wrapper">
        <div className="certification-container">
          {/* Receive all user certifications and render the cards */}
          {this.props.certifications.map(certification => {
            const certID = certification.certificationID || certification.id;
            return (<CommonCertificationCard
              app={this.props.app} customUser={this.props.customUser}
              handleStartCertification={this.props.handleStartCertification}
              key={certification.id}
              supersedeIDs={APIUtils.findSupersedingIDs(certID, this.props.certifications, this.props.app.sharedCache(), true)}
              certification={certification}
            />);
          })}
        </div>
      </div>
    );
  }
}
