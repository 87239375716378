import Utils from '../components/Utils';

export default class Globals {}

//UI
Globals.Table_PagingItemsPerPage = 25;
Globals.DefaultUIBirthDateFormat = 'YYYY-MM-DD';
Globals.DefaultUIDateTimeFormat = "YYYY-MM-DD HH:mm";
Globals.COLOR_PALETTE = {
  main_red: '#af3947',
  light_red: '#e56972',
  dark_red: '#7a0020',
  light_grey: '#f0f2f5',
  grey: '#babcbf',
  green: '#4bb543',
  blue: '#03a9f4',
  light_blue: '#89CFF0',
  orange: '#ffae42',
};
//Query
Globals.URLQueryParam_SearchTerm = 'search';
Globals.URLQueryParam_Page = 'page';
Globals.URLQueryParam_SortField = 'sort';
Globals.URLQueryParam_SortOrder = 'order';
//FE URL
Globals.URL_Path_ID_Placeholder = 'id';
Globals.URL_Path_ID2_Placeholder = 'id2';
Globals.URL_Path_FinancialTransactionID = 'fid';
Globals.URL_Path_FinancialRescheduleTransactionID = 'rfid';
Globals.URL_Path_FinancialTransaction_TokenID = 'token-id';
Globals.URL_Path_FinancialRescheduleExamDate = 'examDate';
Globals.URL_Path_FinancialRescheduleExamID = 'examID';
Globals.URL_NewIndentifier = 'new';
//Footer
Globals.EMAIL_CONTACT_US = "mailto:ccpo@bccsa.ca";
Globals.WEBSITE_LABEL = "bccsa.ca";
Globals.WEBSITE_LINK = "https://www.bccsa.ca/";
//UI Prefs and Labels
Globals.FLOAT_DECIMALS = 2;
Globals.ERROR_MESSAGE_DEFAULT = 'Unable to load user data, if this error happens again, please contact support.';
Globals.ERROR_MESSAGE_GET_WORK_HOURS_FILES = 'Unable to get work hours file, if this error happens again, please contact support.';
Globals.LABEL_NUMBER_OF_HOURS = 'Number of Hours';
Globals.LABEL_PERIOD = 'Period';
Globals.LABEL_ATTACHMENTS = 'Attachments';
Globals.LABEL_PUMP_CATEGORY = 'Pump Categories';
Globals.LABEL_CERTIFICATION_TYPE = 'Certification Type';
Globals.LABEL_CERTIFICATION_DESCRIPTION = 'Certification Description';
Globals.LABEL_EXAM = 'Exam Type';
Globals.LABEL_PAYMENT_TYPE = 'Payment Type';
Globals.LABEL_PAYMENT = 'Payment Information';
Globals.LABEL_AMOUNT = 'Amount';
Globals.LABEL_TRANSACTION_ID = 'Transaction ID';
Globals.LABEL_COMMENTS = 'Comments';
Globals.LABEL_TAX_AMOUNT = 'Tax Amount';
Globals.LABEL_TOTAL_AMOUNT = 'Total Amount';
Globals.LABEL_EXAM_ONLINE = 'On-line';
Globals.LABEL_EXAM_PRESENTIAL = 'Test-center';
Globals.LABEL_ADDRESS_SECTION = 'Address';
Globals.LABEL_SUITE = 'Suite';
Globals.LABEL_STREET = 'Street Address';
Globals.LABEL_STREET2 = 'Street Address 2';
Globals.LABEL_STREET_NUMBER = 'Street Number';
Globals.LABEL_CITY = 'City';
Globals.LABEL_PROVINCE = 'Province';
Globals.LABEL_POSTAL_CODE = 'Postal Code';
Globals.LABEL_COUNTRY = 'Country';
Globals.LABEL_MOBILE_PHONE = 'Mobile Number';
Globals.LABEL_OTHER_PHONE = 'Other Number';
Globals.LABEL_PERSONAL_SECTION = 'Personal Information';
Globals.LABEL_DRIVERS_LICENSE = 'Driver’s License';
Globals.LABEL_DRIVERS_LICENSE_EXP_DATE = 'DL Exp Date';
Globals.LABEL_DRIVERS_LICENSE_PICTURE = 'DL Picture';
Globals.LABEL_SPECIAL_NEEDS = 'Special Needs';
Globals.LABEL_EMPLOYER = 'Employer';
Globals.LABEL_BIRTH_DATE = 'Date of Birth';
Globals.LABEL_AGREED_ON_TERMS = "I agree to abide by BCCSA's code of ethics";
Globals.LABEL_READ_TERMS = 'Read BCCSA’s code of ethics';
Globals.LABEL_EXAM_DESCRIPTION = 'Exam Description';
Globals.LABEL_EXAM_TYPE = 'Exam Type';
Globals.LABEL_PAYMENT_DATE = 'Payment Date';
Globals.LABEL_PAYMENT_VALUE = 'Payment Amount';
Globals.LABEL_PAYMENT_INFO = 'Payment Information';
Globals.LABEL_REFUND_INFO = 'Refund Information';
Globals.LABEL_REFUND_VALUE = 'Refund Amount';
Globals.LABEL_COMPANY_LOCATION = 'Company Location';
Globals.LABEL_COMPANY_CONTACT_DISPATCHER = 'Company Contact/Dispatcher';
Globals.LABEL_COMPANY_CONTACT_DISPATCHER_PHONE = 'Contact/Dispatcher Phone & Email Address';
Globals.LABEL_PUMPS_CATEGORIES = [
  {
    pk: 'LINE_PUMPS',
    description: 'LINE PUMPS',
    isDisabled: true,
    Options: [
      { pk: 'LOW_PRESSURE', description: 'Low Pressure (1,233 psi & under)' },
      { pk: 'HIGH_PRESSURE', description: 'High Pressure (over 1,233 psi)' },
    ],
  },
  {
    pk: 'TRUCK_MOUNTED_BOOM_PUMPS',
    description: 'TRUCK MOUNTED BOOM PUMPS',
    isDisabled: true,
    Options: [
      { pk: '41_METRES_AND_UNDER', description: '41 metres & under' },
      { pk: 'OVER_41_METRES', description: 'Over 41 metres' },
    ],
  },
  {
    pk: 'TOWER_PLACING_BOOMS',
    description: 'TOWER PLACING BOOMS',
    isDisabled: false,
  },
];

//Import users definition
Globals.ImportUsers_CSV_COLUMNS = {
  FirstName: 'First Name',
  LastName: 'Last Name',
  Email: 'email',
  DriverLicense: 'driverLicense',
  MobileNumber: 'mobileNumber',
  EmployerWSID: 'employerWSID',
  CompanyLocation: 'companyLocation',
  CompanyContact: 'companyContact',
  CompanyContactPhone: 'companyContactPhone',
  DateOfBirth: 'date of birth',
  StreetAddress: 'address',
  City: 'city',
  PostalCode: 'postalCode',
  Province: 'province',
  Country: 'country',
  CertificationNumber: 'Certificate #',
  EffectiveDate: 'Effective Date',
  ExpiryDate: 'Expiry Date',
  CertificationID: 'Cert Line 1',
  //
  ImportStatus: '_impStatus',
  ValidationStatus: '_valStatus'
}
Globals.CSV_REQ_COLUMNS = [
    Globals.ImportUsers_CSV_COLUMNS.FirstName,
    Globals.ImportUsers_CSV_COLUMNS.LastName,
    Globals.ImportUsers_CSV_COLUMNS.Email,
    // Globals.ImportUsers_CSV_COLUMNS.EffectiveDate,
    // Globals.ImportUsers_CSV_COLUMNS.ExpiryDate,
    // Globals.ImportUsers_CSV_COLUMNS.CertificationID,
];

//API Definitions
Globals.API_MaxEpochDiscrepancy = 200000; //allow user to be X ms out of the server time
Globals.APIPath_User = '/user/';
Globals.APIPath_UserWorkHours = '/workHours/';
Globals.APIPath_File = '/file/';
Globals.APIPath_TenantConfig = '/tenant/config';
Globals.APIPath_TenantContactForm = '/contactUs';
Globals.APIPath_TenantUsers = '/tenant/users';
Globals.APIPath_TenantStats = '/tenant/stats/';
Globals.APIPath_TenantSearchUsers = '/tenant/users/search';
Globals.APIPath_TenantSearchUsersExport = '/tenant/users/search/export';
Globals.APIPath_TenantSearchUsersAutocomplete = '/tenant/users/autocomplete';
Globals.APIPath_TenantExamsQuestions = '/examsQuestions';
Globals.APIPath_Certification = '/certification/';
Globals.APIPath_CertificationPrint = 'printCard';
Globals.APIPath_CertificationPrintInfo = 'printCardInfo';
Globals.APIPath_Certifications = '/certifications/';
Globals.APIPath_CertificationRenew = '/renew';
Globals.APIPath_AddExamResult = '/addResult/';
Globals.APIPath_AddExamPracticalResult = '/addResultPratical/';
Globals.APIPath_ApprovePracticalExam = '/approveResultPratical/';
Globals.APIPath_CheckWrittenExamResult = '/checkWrittenResult/';
Globals.APIPath_ScheduleExam = '/schedule/';
Globals.APIPath_EndCooldown = '/endCooldown/';
Globals.APIPath_Reschedule = '/reschedule/';
Globals.APIPath_ReschedulePractical = '/practical';
Globals.APIPath_Payment = '/payment/';
Globals.APIPath_ApplyVoucher = '/applyVoucher/';
Globals.APIPath_PaymentBegin = '/beginPayment/';
Globals.APIPath_PaymentComplete = '/completePayment/';
Globals.APIPath_PaymentCancellation = '/cancelPayment/';
Globals.APIPath_PaymentRefund = '/refundPayment/';
Globals.APIPath_AuditCertEvents = '/auditing/cert/';
Globals.APIPath_AdvisorDashboard = '/advisor/dashboard';
Globals.APIPath_Advisor_ExamsReadyToSchedule = '/advisor/toSchedule';
Globals.APIPath_Advisor_ExamsScheduled = '/advisor/scheduled';
Globals.APIPath_WaiveRequest = '/waiveRequest';
Globals.APIPath_WaiveApprove = '/approve';
Globals.APIPath_WaiveReject = '/reject';
Globals.APIPath_WaiveRequests = '/waiveRequests';
Globals.APIPath_Report = '/report';
//
Globals.API_NewIDKeyword = 'new';
Globals.API_FileUpload_CardPictureDescription = 'ccpo card picture';
Globals.API_FileUpload_WorkHoursDescription = 'work hours file';
//Financial Transaction Types
Globals.API_FinancialTransactionType_NewCert = 'NEW_CERT';
Globals.API_FinancialTransactionType_Renew = 'RENEW_CERT';
Globals.API_FinancialTransactionType_Reschedule = 'RESCHEDULE';
Globals.API_FinancialTransactionType_Cancellation = 'CANCELLATION';
Globals.API_FinancialTransactionType_Refund = 'REFUND';
//Upload
Globals.API_MaxAllowedFileSize = 2621440; //2.5MB
//Certification states
//Here we have a incomplete version of process states.. API has the complete version on IKCollegerGlobals.js
Globals.CertificationProcess_State = {
  //Please dont change this states before reflecting it on API!
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CANCEL_REFUND_PROCESSING:'CANCEL_REFUND_PROCESSING',
  CANCEL_REFUND_COMPLETED:'CANCEL_REFUND_COMPLETED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  WAIVE_REQUESTED: 'WAIVE_REQUESTED',
  PENDING_RESULT_APPROVAL: 'PENDING_RESULT_APPROVAL',
  RESCHEDULE_PAYMENT_PENDING: 'RESCHEDULE_PAYMENT_PENDING',
  RESCHEDULE_PAYMENT_PROCESSING: 'RESCHEDULE_PAYMENT_PROCESSING',
  RESCHEDULE_PAYMENT_COMPLETED: 'RESCHEDULE_PAYMENT_COMPLETED',
  RESCHEDULE_PAYMENT_FAILED: 'RESCHEDULE_PAYMENT_FAILED',
  COOLDOWN: 'COOLDOWN',
  READY_SCHEDULE: 'READY_TO_SCHEDULE',
  SCHEDULED: 'SCHEDULED',
  EXPIRED_REQUIREMENT: 'EXPIRED_REQUIREMENT',
  EXPIRED: 'EXPIRED'
};
Globals.Exam_State = {
  PENDING: 'PENDING',
  PASS: 'PASSED',
  FAIL: 'FAILED',
  CANCELLED: 'CANCELLED',
  PENDING_RES_APPROVAL: 'PENDING_RES_APPROVAL',
};
Globals.FinancialTransaction_State = {
  INITIATED: 'INITIATED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
};

//Timeline
Globals.Event_Type = {
  PAYMENT: 'PAYMENT',
  RES_PAYMENT: 'RES_PAYMENT',
  EXAM: 'EXAM',
  WAIVE: 'WAIVE',
  REQUIREMENT: 'REQUIREMENT',
  COOLDOWN: 'COOLDOWN',
  CERTIFICATION: 'CERTIFICATION',
  PRINTCARD: 'PRINTCARD'
};
Globals.Event_State = {
  IN_PROGRESS: 0,
  READY: 1,
  COMPLETED: 2,
  FAILED: 3,
  CANCELLED: 4,
  WAITING: 5,
  LOCKED: 6,
  EXPIRED: 7,
};
// Timeline Action Buttons Label
Globals.Timeline_label = {
  PAY_NOW: 'Pay now',
  RELEASE_EXAM: 'Release exam',
  LAUNCH_EXAM: 'Launch Exam',
  CHECK_RESULTS: 'Check exam results',
  SCHEDULE_EXAM: 'Update exam schedule',
  RESCHEDULE_EXAM: 'Reschedule exam',
  CANCEL_EXAM: 'Cancel exam',
  ADD_EXAM_RESULT: 'Add exam result',
  CANCEL: 'Cancel',
  CANCEL_COOLDOWN: 'End Cooldown',
  OPTIONS: 'Options',
  PRINT: 'Download Card',
  APPROVE_EXAM: 'Review',
  RENEWAL: 'Renew',
};
//Card
Globals.getCertificationDisplayState = function (state, supersedeIDs) {
  const states = Globals.CertificationProcess_State;
  if (supersedeIDs && supersedeIDs.length > 0)
    return 'Certification Superseded ✅';
  else if (state) {
    switch (state) {
      case states.PAYMENT_PENDING:
        return 'Payment Pending';
      case states.PAYMENT_PROCESSING:
        return 'Payment Processing';
      case states.PAYMENT_COMPLETED:
        return 'Payment Completed';
      case states.PAYMENT_FAILED:
        return 'Payment Failed';
      case states.CANCEL_REFUND_PROCESSING:
        return 'Refund Processing';
      case states.CANCEL_REFUND_COMPLETED:
        return 'Refund Completed';
      case states.COOLDOWN:
        return 'Exam Cooldown';
      case states.RESCHEDULE_PAYMENT_PENDING:
        return 'Pending Reschedule Payment';
      case states.RESCHEDULE_PAYMENT_FAILED:
        return 'Reschedule Failed';
      case states.RESCHEDULE_PAYMENT_PROCESSING:
        return 'Reschedule Processing';
      case states.RESCHEDULE_PAYMENT_COMPLETED:
        return 'Reschedule Completed';
      case states.READY_SCHEDULE:
        return 'Exam Ready for Schedule';
      case states.SCHEDULED:
        return 'Exam Scheduled';
      case states.COMPLETED:
        return 'Certification Completed';
      case states.FAILED:
        return 'Certification Failed';
      case states.EXPIRED_REQUIREMENT:
        return 'Requirement Expired';
      case states.PENDING_RESULT_APPROVAL:
        return 'Pending Result Approval';
      case states.WAIVE_REQUESTED:
        return 'Waive Practical Requested';
      case states.EXPIRED:
        return 'Certification Expired';
      default:
        return state;
    }
  } else return '';
};
Globals.getCertificationAction = function(state) {
  const states = Globals.CertificationProcess_State;
  if (state) {
    switch (state) {
      case states.PAYMENT_PENDING:
      case states.PAYMENT_FAILED:
        return 'Payment Required';
      case states.READY_SCHEDULE:
        return 'Exam Ready to be Scheduled';
      case states.EXPIRED_REQUIREMENT:
        return 'Restart Required';
      case states.EXPIRED:
        return 'Renewal Required';
      default:
        return '';
    }
  } return '';
};

//Local Cache
Globals.Cookies_ExamsQuestionsIndex = 'examsQuestionsIndex';
Globals.Cookies_ExamsQuestionsPrefix = 'CCPOExamQuestion_';

//Graph stuff
Globals.DefaultGraphDefinitions = [
  { id: 'dots', type: 'patternDots', background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)', size: 4, padding: 1, stagger: true
  },
  { id: 'lines', type: 'patternLines', background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)', rotation: -45, lineWidth: 6, spacing: 10
  }];
Globals.DefaultGraphMonetaryProps = {
  //monetary
  labelFormat: (v) => { return "$" + Utils.toCurrencyFormat(v) },
  sliceLabel: (v) => { return "$" + Utils.toCurrencyFormat(v.value) },
  tooltipFormat: (v) => { return "$" + Utils.toCurrencyFormat(v) }
};
//Pie chart
Globals.DefaultGraphPieChartLegend = [{
  dataFrom: 'keys',
  anchor: 'right', direction: 'column', itemDirection: 'left-to-right',
  translateX: 90, itemWidth: 100, itemHeight: 14, itemsSpacing: 25,
  symbolSize: 14, itemTextColor: '#999',  symbolShape: 'square',
  effects: [{ on: 'hover', style: { itemTextColor: '#000' }}]
}];
Globals.DefaultGraphPieChartProps = {
  margin:{ top: 40, right: 230, bottom: 80, left: 10 },
  innerRadius:0.5, padAngle:1, cornerRadius:6,
  colors:{ scheme: 'nivo' }, borderWidth:1,
  borderColor:{ from: 'color', modifiers: [ [ 'darker', 0.2 ]]},
  enableRadialLabels: false, radialLabel: 'label',
  slicesLabelsSkipAngle:30,
  slicesLabelsTextColor:"#333333", animate:true,
  motionStiffness:90, motionDamping:15,
  defs: Globals.DefaultGraphDefinitions, legends: Globals.DefaultGraphPieChartLegend,
  theme:{ legends: { text: { fontSize: 16 } }, labels: { text: { fontSize: 16 } } },
};
//horizontal
Globals.DefaultGraphHorizontalBarGraphProps = {
  keys:['achieved','active','inProgress'], indexBy: "id",
  margin: { top: 40, right: 40, bottom: 80, left: 195 },
  pixelRatio:1, padding:0.15, innerPadding: 0, layout:"horizontal",
  colors: { scheme: 'orange_red' }, colorBy: "id", borderWidth: 0, borderRadius:3,
  borderColor: { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] },
  axisTop: null, axisRight: null, axisBottom: null,
  theme:{
    legends: { text: { fontSize: 16 } },
    axis: { ticks: { text: { fontSize: 14 } } },
    labels: { text: { fontSize: 16 } }
  },
  axisLeft: {
    tickSize: 0, tickPadding: 20, tickRotation: 0
  },
  enableGridY: false, enableLabel: true, labelSkipWidth: 12, labelSkipHeight: 12,
  labelTextColor: { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] },
  isInteractive: true
}
//Vertical
Globals.DefaultGraphVerticalBarChartLegend = [{
  dataFrom: 'keys',
  anchor: 'right', direction: 'column', itemDirection: 'left-to-right',
  translateX: 80, itemWidth: 60, itemHeight: 14, itemsSpacing: 25,
  symbolSize: 14, itemTextColor: '#999',  symbolShape: 'square',
  effects: [{ on: 'hover', style: { itemTextColor: '#000' }}],
}];
Globals.DefaultGraphVerticalBarGraphProps = {
  indexBy: "id", margin: { top: 40, right: 300, bottom: 80, left: 110 },
  pixelRatio:1, padding:0.15, innerPadding: 0, layout:"vertical",
  colors: { scheme: 'nivo' }, colorBy: "id", borderWidth: 0, borderRadius:3,
  borderColor: { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] },
  axisTop: null, axisRight: null, axisBottom: null, axisLeft: null,
  enableGridY: false, enableLabel: true, labelSkipWidth: 12, labelSkipHeight: 12,
  labelTextColor: { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] },
  isInteractive: true, legends: Globals.DefaultGraphVerticalBarChartLegend,
  theme:{ legends: { text: { fontSize: 16 } }, labels: { text: { fontSize: 16 } } },
}
//
Globals.UserPartitionFilesIDs = {
  DRIVERLICENSE: 'DRIVERLICENSE',
};
//Vaulting
Globals.Cookies_NonceKey = 'lms_nonce_v1';
Globals.Cookies_NonceCachingPeriod = 24 * 60 * 60 * 1000; //1 day
