import { saveAs } from 'file-saver';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
import mime from 'mime-types';
//
import Globals from '../config/Globals';
//
const csv = require('fast-csv');
//
export default class Utils {}
//Data handling helpers
Utils.setNestedObject = function(obj, prop, value) {
  let reference = obj;
  const a = prop.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let key = a[i];
    if (i + 1 != a.length) {
      //check if is not last object
      //safe check for sub.object
      if (reference[key] == null || reference[key] == undefined) {
        if (key == 0 || key == 1) reference = [];
        else reference = reference[key] = {};
      } else reference = reference[key];
    } else {
      reference[key] = value;
    }
  }
  return obj;
};
Utils.getNestedObject = function(obj, props) {
  let a = props.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    if (obj == null || obj == undefined) return undefined;
    let key = a[i];
    if (key in obj) {
      obj = obj[key];
    } else {
      return undefined;
    }
  }
  return obj;
};
Utils.safelyGetNumericNestedObject = function(obj, props) {
  return Utils.safeNumber(Utils.getNestedObject(obj, props));
};
Utils.safelySumNumericNestedValues = function(objs, props) {
  let retValue = 0;
  for (let obj of objs) {
    retValue += Utils.safelyGetNumericNestedObject(obj, props);
  }
  return retValue;
};
Utils.safeNumber = function(value) {
  //safe check for booleans
  if (value === true) return 1;
  //safe check for strings
  let returnValue = parseFloat(value);
  if (isNaN(returnValue)) return 0;
  return returnValue;
};
Utils.caseInsensitiveObjectForKey = function(obj, key) {
  if (!obj) return null;
  const insensitiveKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
  if (insensitiveKey && insensitiveKey != '') return obj[insensitiveKey];
  return null;
};
Utils.toDoubleDigit = function(str) {
  return String('0' + str).slice(-2);
};
Utils.toDateFormat = function(str) {
  if (str != undefined) {
    return str.split('-').join('/');
  }
  return '';
};
Utils.businessDaysFromDate = function(date, businessDays) {
  let counter = 0; // set to 1 to count from next business day
  let retDate = null;
  while (businessDays > 0) {
    retDate= new Date();
    retDate.setDate(date.getDate() + counter++);
    switch(tmp.getDay()) {
      case 0: case 6: break;// sunday & saturday
      default:
        businessDays--;
    };
  } return retDate;
}
Utils.toCurrencyFormat = function(str) {
  if (str !== undefined) {
    str = parseFloat(str);
    if (isNaN(str)) return '0.00';
    return str.toLocaleString('en', { minimumFractionDigits: '2', maximumFractionDigits: 2 });
  }
  return '0.00';
};
//Currency manipulation
Utils.safelyFixCurrency = function(value, toFix) {
  if (!toFix) toFix = 2;
  let rountTo = Math.pow(10, toFix);
  return parseFloat(parseFloat(Math.round(value * rountTo) / rountTo).toFixed(toFix));
};
Utils.capitalizeString = function(str) {
  return str.split(' ').map(w=> w.replace(/./, m=> m.toUpperCase())).join(' ');
}

//FORM HELPER
Utils.defaultFormChangeHandler = function(event, object) {
  const targetID = event.target.id;
  let targetValue;
  //Choose where to get value
  if (event.target.type == 'checkbox') {
    targetValue = event.target.checked;
  } else if (event.target.type == 'select') {
    targetValue = event.target.value;
  } else {
    targetValue = event.target.value;
  }
  //set state
  object.state = Utils.setNestedObject(object.state, targetID, targetValue);
  object.setState({ ...object.state });
};

//DATES
Utils.getMonthDescription = function(month) {
  const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ];
  return monthNames[month - 1];
};
Utils.getQuarterMonths = function(quarter) {
  const base = (quarter - 1) * 3;
  return [base + 1, base + 2, base + 3];
}
Utils.getMonthName = function(month) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (month > monthNames.length) return null;
  return monthNames[month - 1];
};
Utils.setReportDateRange = function(obj) {
  obj.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  //
  let date = new Date();

  if (obj.selectedYear) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const month = Number(obj.selectedYear) < currentYear ? 12 : currentMonth;

    date = new Date(Date.UTC(obj.selectedYear, month, 1, 0, 0, 0));
  }

  delete obj.selectedYear;
  obj.years = [];
  for (let i = -1; i < 2; i++) {
    obj.years.push(date.getFullYear() + i);
  }
  //Filter current month + previous & quarters
  obj.validMonths = [];
  obj.validQuarters = [];
  for (let i = 0; i < (date.getMonth() + 1); i++) {
    obj.validMonths.push(i + 1);
    if (i == 0 || i % 3 == 0) obj.validQuarters.push(obj.validQuarters.length + 1);
  }
  //default for filter
  obj.currentYear = obj.years[1];
  obj.currentMonth = date.getMonth() + 1; //dont leave as index 0-11
  obj.currentQuarter = Math.ceil(Math.min((date.getMonth()/3)+1, 4));
};
Utils.getCurrentDateOnUIFormat = function(excludeDay) {
  return Utils.getDateOnUIFormat(new Date(), excludeDay);
}
Utils.getDateOnUIFormat = function(date, excludeDay) {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  let day = (excludeDay ? "" : ("-" + Utils.toDoubleDigit(date.getDate())));
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  return ("" + date.getFullYear() + "-" + month + day);
}
Utils.getDateOnUIFormatByTimestamp = function(timestamp, excludeDay) {
  if (!timestamp || timestamp < 1) return '-';
  return Utils.getDateOnUIFormat(new Date(timestamp), excludeDay);
}
  //date calculation
Utils.timestampAfterDays = function(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.getTime();
}
Utils.timestampBeforeYearsFromDate = function(years, date) {
  date.setYear(date.getFullYear() - years);
  return date.getTime();
}
Utils.timestampAfterYears = function(years) {
  const date = new Date();
  date.setYear(date.getFullYear() + years);
  return date.getTime();
}
Utils.timestampAfterYearsFromDate = function(years, date) {
  date.setYear(date.getFullYear() + years);
  return date.getTime();
}
  //
Utils.getDateOnCardFormatByTimestamp = function(timestamp) {
  return Utils.getDateOnCardFormat(new Date(timestamp));
}
Utils.getDateOnCardFormat = function(date) {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  let day = Utils.toDoubleDigit(date.getDate());
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  return ("" + day + '/' + month + '/' + date.getFullYear());
}
Utils.getDateOnMomentFormatByTimestamp = function(timestamp) {
  return Utils.getDateOnMomentFormat(new Date(timestamp));
}
Utils.getDateOnMomentFormat = function (date) {
  return moment(date,Globals.DefaultUIDateTimeFormat);
}
Utils.getTimestampFromMoment = function (mDate) {
  return moment(mDate).toDate().getTime();
}
  //Date and time
Utils.getCurrentDateAndTimeOnUIFormat = function() {
  return Utils.getDateAndTimeOnUIFormat(new Date());
}
Utils.getDateAndTimeOnUIFormatByTimestamp = function(timestamp) {
  return Utils.getDateAndTimeOnUIFormat(new Date(timestamp));
}
Utils.getDateAndTimeOnUIFormat = function(d) {
  if (!d || isNaN(d.getTime())) return '-';
  return [Utils.toDoubleDigit(d.getMonth()+1), Utils.toDoubleDigit(d.getDate()), d.getFullYear()].join('/') +' ' +
          [Utils.toDoubleDigit(d.getHours()), Utils.toDoubleDigit(d.getMinutes())].join(':');
}


//BROWSER stuff
Utils.dataURItoBlob = function(dataURI) {
  const parsed = new URL(dataURI);
  const match = /^[^/]+\/[^,;]+(?:[^,]*?)(;base64)?,([\s\S]*)$/.exec(parsed.pathname);
  const { 1: base64, 2: body } = match;
  return Buffer.from(decodeURIComponent(body), base64 ? 'base64' : 'utf8');
}
Utils.downloadArrayBuffer = function(data, fileName, fileType) {
  var byteArray = new Uint8Array(data);
  const blob = new Blob([byteArray]);
  saveAs(blob, `${fileName}.${fileType}`);
}
Utils.downloadBlob = function(blob) {
  const test = reader.readAsArrayBuffer(blob);
  let blobURL = URL.createObjectURL(blob);
  let tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', 'Receipt');

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);

};
Utils.downloadBlobWithFileSaver = function (blob, fileName, fileType) {
  saveAs(blob, `${fileName}.${fileType}`);
};
Utils.getFileExtensionFromMimeType = function (mimeType) {
  return mime.extension(mimeType);
};
Utils.isFirefox = function() {
  // Firefox 1.0+ - more here https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
  return typeof InstallTrigger !== 'undefined';
}

//REACT stuff
//propagate ref child to get referece
Utils.propagateRef = function(parent, props) {
  return {
    ref: _ref => Utils.setNestedObject(parent, props, _ref),
  };
};

//Date and time
Utils.getCurrentDateAndTimeOnUIFormat = function() {
  return Utils.getDateAndTimeOnUIFormat(new Date());
};
Utils.getDateAndTimeOnUIFormatByTimestamp = function(timestamp) {
  return Utils.getDateAndTimeOnUIFormat(new Date(timestamp));
};
Utils.getDateAndTimeOnUIFormat = function(d) {
  if (!d || isNaN(d.getTime())) return '-';
  return (
    [Utils.toDoubleDigit(d.getMonth() + 1), Utils.toDoubleDigit(d.getDate()), d.getFullYear()].join('/') +
    ' ' +
    [Utils.toDoubleDigit(d.getHours()), Utils.toDoubleDigit(d.getMinutes())].join(':')
  );
};
Utils.getDateAndTimeOnPrintFormatByTimestamp = function(timestamp) {
  return Utils.getDateAndTimeOnPrintFormat(new Date(timestamp));
}
Utils.getDateAndTimeOnPrintFormat = function(d) {
  if (!d || isNaN(d.getTime())) return '-';
  return `${Utils.getMonthName(d.getMonth()+1)} ${Utils.toDoubleDigit(d.getDate())}, ${d.getFullYear()}`;
}

//Ant design form helpers
Utils.defaultCurrenyInputFormatter = function(value) {
  return (
    `$ ${value || parseFloat(0).toFixed(2)}`.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1,'
    )
  );
}
Utils.defaultCurrentInputParser = function (value) {
  return value.replace(/\$\s?|(,*)/g, '');
}

//Thread helper
Utils.shortRandSleep = async function() {
  const delay = (Math.random() * (100 - 50)) + 50;
  return new Promise(resolve => setTimeout(resolve, delay));
}
Utils.sleep = async function(delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

//CSV
Utils.parseCSV = async function(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = async readerResp => {
      const data = readerResp.currentTarget.result;
      const dataArray = [];
      const stream = csv.parseString(data, { headers: true })
        .on('data', data => dataArray.push(data))
        .on('end', () => {
          stream.end();
          resolve(dataArray);
        })
        .on('error', (err) => {
          reject(err)
        });
    };
    fileReader.readAsText(file);
  });
}

//Connection helper
Utils.execRequests = async (reqs, failureCallback, respCallback, failIfAnyFailure) => {
  //cleanup
  for (let reqIdx of reqs) {
    if (!reqs[reqIdx]) reqs[reqIdx] = new Promise((res) => res({statusCode: 200}));
  }
  //Make calls concurrency
  const resps = await Promise.all(reqs);
  const failure = resps.find(resp => resp && resp.statusCode != 200);
  if (failure) {
    if (failureCallback) await failureCallback(failure);
    if (failIfAnyFailure) return false;
  }
  //
  for (let respIdx in resps) await respCallback(resps[respIdx], respIdx);
  return true;
};

Utils.generateXLSX = async function({ name, columns, rows }) {
  // Starts XLSX
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(name);

  // Add XLSX header and rows
  ws.addRow(columns);
  rows.forEach((row) => ws.addRow(row));

  // Generate XLSX
  const buffer = await wb.xlsx.writeBuffer();

  Utils.downloadArrayBuffer(buffer, name, 'xlsx');
}
