import React from 'react';
import autoBind from 'react-autobind';
import { ResponsiveBar } from '@nivo/bar';
import { Divider, Row, Col, Typography, Spin, Icon } from 'antd';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import AdminDashboardGraph_Achieved from './AdminDashboardGraph_Achieved';
//
export default class AdminDashboardGraph_ExamRevenue extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      graphData: null
    };
  }
  //API
  async loadData(stats) {
    if (!stats) this.setState({ graphData: null });
    //Sum all achieved
    let written = 0;
    let practical = 0;
    let total = 0;
    for (let stat of stats) {
      if (stat.revenue && this.props.months.indexOf(parseInt(stat.sk.slice(4))) != -1) {
        practical += stat.revenue.practical || 0;
        written += stat.revenue.written || 0;
      }
    } total = written + practical;
    //Check if any value is above 0
    if (total > 0 || written > 0 || practical > 0) this.setState({ graphData: { total, written, practical } });
    else this.setState({ graphData: null });
  }
  //UI
  render() {
    return (
      <Col className='graphColumn'>
        <Row className='graphTitleRow' type="flex" justify="space-between">
          <Typography.Text className='graphTitle'>Exam Revenue</Typography.Text>
        </Row>
        <Row className='graphSubtitleRow'>
          {this.state.graphData &&
            <Typography.Text className='graphSubtitle'>
              <Typography.Text className='graphSubtitleNumber'>${Utils.toCurrencyFormat(this.state.graphData.total || 0)}</Typography.Text>
            </Typography.Text>
          }
        </Row><Row type="flex" align="middle" align='middle'>
          <Col className='graph'>
            { this.props.isLoading ?
              AdminDashboardGraph_Achieved.DefaultGraphLoading() :
              this._renderGraph()
            }
          </Col>
        </Row>
      </Col>
    )
  }
  //Graphs
  _renderGraph() {
    if (!this.state.graphData) return AdminDashboardGraph_Achieved.DefaultEmptyGraph();
    const filteredData = this.state.graphData;
    const data = [
      { id: 'Revenue', label: 'Revenue', Written: filteredData.written, Practical: filteredData.practical},
    ];
    return (<ResponsiveBar data={data} {... Globals.DefaultGraphVerticalBarGraphProps}
                           keys={['Written','Practical']} {... Globals.DefaultGraphMonetaryProps}
                           fill={[
          { match: { id: 'Certification Active' }, id: 'dots' },
          { match: { id: 'Certification Achieved' }, id: 'lines' },
          { match: { id: 'Certification in Progress' }, id: 'lines' },
        ]}/>);
  }
}
