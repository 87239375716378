import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout, PageHeader, Row, Drawer, Button, Icon } from 'antd';
//import Utils from '../../components/Utils';
import CustomComponent from '../../components/CustomComponent';
import APIUtils from '../../components/API/APIUtils';
//
import CommonUserHeader from  '../commonComponents/CommonUserHeader';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonCertificationProcess from '../commonComponents/CommonCertificationProcess';
import AdminAuditingDrawer from '../commonComponents/AdminAuditingDrawer';
//
import config from '../../config/config';
import Utils from '../../components/Utils';
//
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonDashboardStyles.scss';
import '../../assets/stylesheets/CommonCertificationProcess.scss';
//
const md5 = require('md5');

export default class AdminUserCertificationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { personalPart: null, customUser: null, certification: null, isLoading: false, firstLoad: true, certSpecs: null, certificationEvents: [] };
  }

  componentDidMount() {
    super.componentDidMount();
    this.certID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    this.userID = this.props.match.params[Globals.URL_Path_ID2_Placeholder];
    if (this.state.firstLoad) this.fetchData();
  }

  handleUpdate = async () => { this.fetchData(); };

  async fetchData() {
    this.startLoading();
    //Get CCPO user
    const ccpoUser = await this.props.app.api.user.getByID(this.userID);
    if (ccpoUser.statusCode != 200 || !ccpoUser.body) {
      this.setState({ certification: null, isLoading: false, firstLoad: false, personalPart: null, certificationEvents: null, customUser: null, certSpecs: null });
      this.props.app.alertController.showAPIErrorAlert(null, ccpoUser);
      return;
    }
    //get cert
    const userCertResp = await this.props.app.api.userCertification.getByUserIDAndCertID(this.userID, this.certID);
    if (userCertResp.statusCode != 200 || !userCertResp.body) {
      this.setState({ certification: null, isLoading: false, firstLoad: false, personalPart: null, certificationEvents: null, customUser: null, certSpecs: null });
      this.props.app.alertController.showAPIErrorAlert(null, userCertResp);
      return;
    }
    //Get personal part
    const wantedParts = [config.IDMClientOptions.partitions.PERSONAL];
    const parts = await this.props.app.idm.api.userPartition.readSome(wantedParts, this.userID);
    const personalPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.PERSONAL);
    if (!personalPart) {
      this.setState({ certification: null, isLoading: false, firstLoad: false, personalPart: null, certificationEvents: null, customUser: null, certSpecs: null });
      this.props.app.alertController.showErrorAlert(null, Globals.ERROR_MESSAGE_DEFAULT);
      return;
    }
    //Generate certification events
    let certificationEvents = APIUtils.parseCertificationProcessToEvents(userCertResp.body, this.props.app);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(userCertResp.body.certificationID);
    this.setState({ certification: userCertResp.body, isLoading: false, firstLoad: false,
                    personalPart, certificationEvents, customUser: ccpoUser.body, certSpecs: certSpecs });
  }
  //Actions
  handlePaymentCancellation(event) {
    const object = event.metadata.object;
    this._cancelFinancialPayment(object.id, object.examID, object.type);
  }
  handleCheckExamResults(event) {
      this._checkExamResults(this.state.customUser.id, this.state.certification.id, event.metadata.object.id);
  }
  handleCertificationRenewal(event) {
    this._renewCertification(this.state.customUser.id, this.state.certification.id);
  }
  handleCertificationWaive() {
    this._waiveCertification(this.state.customUser.id, this.state.certification.id);
  }
  handleReceiptDownload(event) {
    this._downloadReceipt(this.state.customUser.id, event.metadata.object.id);
  }
  handleOnBack() {
    const queryParams = new URLSearchParams(window.location.search);
    const from = queryParams.get('from');
    const filter = queryParams.get('filter');
    if (from) {
      this.props.app.pushPage(config.ApplicationRoutes.statusReport, { filter })
    } else {
      this.props.app.pushPage(config.ApplicationRoutes.userDashboard, null, this.state.customUser.id)
    }
  }
  //
  render() {
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader className="pageHeaderNoHeight" title=" "
                    onBack={this.handleOnBack}/>
         <Layout.Content>
          {(this.state.certification && this.state.certificationEvents && this.state.personalPart) && (
            <div>
              <Layout>
                <Layout>
                  <Row type="flex" justify="end">
                    <CommonUserHeader app={this.props.app} ccpoUser={this.state.customUser}
                                      personalPart={this.state.personalPart} justCard
                                      isLoading={this.state.isLoading} datasource={this}/>
                  </Row>
                  <Layout.Content id='userDashboardTimeline'>
                    <div className="header-container">
                      <div className="header-certification">
                        Certification Progress: {this.state.certSpecs.description}
                      </div>
                    </div>
                    <CommonCertificationProcess
                      handlePaymentCancellation={this.handlePaymentCancellation}
                      handleUpdate={this.handleUpdate}
                      handleCheckExamResults={this.handleCheckExamResults}
                      handleReceiptDownload={this.handleReceiptDownload}
                      handleCertificationRenewal={this.handleCertificationRenewal}
                      handleCertificationWaive={this.handleCertificationWaive}
                      ccpoUser={this.state.customUser}
                      specialNeeds={this.state.personalPart.value.specialNeeds}
                      app={this.props.app} isLoading={this.state.isLoading}
                      events={this.state.certificationEvents}
                      certification={this.state.certification}/>
                    <AdminAuditingDrawer app={this.props.app} {... Utils.propagateRef(this, 'auditDrawer')} certProcID={this.state.certification.id}/>
                  </Layout.Content>
                </Layout>
              </Layout>
            </div>
          )}

        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private */
  async _cancelFinancialPayment(ftID, examID, ftType) {
    this.startLoading();
    const paymentResp = (ftType == Globals.API_FinancialTransactionType_Reschedule ?
                        await this.props.app.api.userExam.cancelReschedule(this.userID,
                              this.state.certification.id, examID, { financialTransactionID: ftID })
                      : await this.props.app.api.userCertification.cancelPayment(this.userID,
                              this.state.certification.id, examID, { financialTransactionID: ftID }));
    if (!this._isMounted) return;
    console.log('Transaction cancellation resp: ', paymentResp, ftID, examID);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.warning('Transaction Cancelled!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
      this.stopLoading();
    }
  }
  async _checkExamResults(userID, certID, examID) {
    this.startLoading();
    const resp = await this.props.app.api.userExam.checkWrittenResult(userID, certID, examID);
    if (resp.statusCode == 200) {
      message.success('Result retrieved with success!');
      this.handleUpdate();
    } else {
      message.warning('Result is not available yet!');
      this.stopLoading();
    }
  }
  async _renewCertification(userID, certID) {
    this.startLoading();
    const renewalResp = await this.props.app.api.userCertification.renewCertificationByUserIDAndCertID(userID, certID);
    if (!this._isMounted) return;
    console.log('Certification renewal resp: ', renewalResp);
    if (renewalResp.statusCode == 200 && renewalResp.body) {
      message.warning('Certification renewal started!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, renewalResp);
      this.stopLoading();
    }
  }
  async _waiveCertification(userID, certID) {
    this.startLoading();
    const waiveResp = await this.props.app.api.userCertification.requestWaive(userID, certID);
    if (!this._isMounted) return;
    console.log('Certification waive resp: ', waiveResp);
    if (waiveResp.statusCode == 200 && waiveResp.body) {
      message.success('Waive requested with success!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, waiveResp);
      this.stopLoading();
    }
  }
  async _downloadReceipt(userID, txID) {
    this.startLoading();
    const resp = await this.props.app.api.financialTransaction.getTransactionReceipt(userID, txID);
    if (!this._isMounted) return;
    console.log('Receipt resp: ', resp);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${md5(txID)}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.stopLoading();
  }
}
