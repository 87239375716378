import Globals from '../../../config/Globals';
//
export default class APIAudit {
  constructor(API) {
    this.api = API;
  }
  async getEventsByCertID(certProcID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_AuditCertEvents + certProcID;
    return await req.exec();
  }
}
