import React from 'react';
import autoBind from 'react-autobind';
import { ResponsivePie } from '@nivo/pie'
import { Divider, Row, Col, Typography, Spin, Icon, Empty } from 'antd';
//
import Globals from '../../config/Globals';
//
export default class AdminDashboardGraph_Achieved extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      graphData: null
    };
  }
  //API
  async loadData(stats) {
    if (!stats) this.setState({ graphData: null });
    //Sum all achieved
    let lowPressure = 0;
    let highPressure = 0;
    let truckMountUnder41 = 0;
    let truckMountOver41 = 0;
    let tpBooms = 0;
    let total = 0;
    for (let stat of stats) {
      if (this.props.months.indexOf(parseInt(stat.sk.slice(4))) != -1) {
        lowPressure += stat.practicallowPressure || 0;
        highPressure += stat.practicalhighPressure || 0;
        truckMountUnder41 += stat.practicaltruckMountUnder41 || 0;
        truckMountOver41 += stat.practicaltruckMountOver41 || 0;
        tpBooms += stat.practicaltpBooms || 0;

        total += stat.practicalTotal || 0;
      }
    }
    //Check if any value is above 0
    if (total > 0 || lowPressure > 0 || highPressure > 0 || truckMountUnder41 > 0 || truckMountOver41 > 0 || tpBooms > 0) {
      this.setState({ graphData: { lowPressure, highPressure, truckMountUnder41, truckMountOver41, tpBooms, total } });
    }else {
      this.setState({ graphData: null });
    }

  }
  //UI
  render() {
    return (
      <Col className='graphColumn'>
        <Row className='graphTitleRow'>
          <Typography.Text className='graphTitle'>Assessments Conducted</Typography.Text>
        </Row>
        <Row className='graphSubtitleRow'>
          {this.state.graphData &&
            <Typography.Text className='graphSubtitle'>
              <Typography.Text className='graphSubtitleNumber'>{this.state.graphData.total || 0}</Typography.Text>
              <Typography.Text className='graphSubtitlePostfix'>Total</Typography.Text>

            </Typography.Text>
          }
        </Row><Row type="flex" align="middle" align='middle'>
          <Col className='graph'>
            { this.props.isLoading ?
              AdminDashboardGraph_Achieved.DefaultGraphLoading() :
              this._renderGraph()
            }
          </Col>
        </Row>
      </Col>
    )
  }
  //Graphs
  _renderGraph() {
    if (!this.state.graphData) return AdminDashboardGraph_Achieved.DefaultEmptyGraph();
    const filteredData = this.state.graphData;
    const data = [
      { id: 'Low Pressure Line', label: 'Low Pressure Line', value: filteredData.lowPressure || 0 },
      { id: 'High Pressure Line', label: 'High Pressure Line', value: filteredData.highPressure || 0 },
      { id: 'Truck Mount Over 41m', label: 'Truck Mount Over 41m', value: filteredData.truckMountOver41 || 0 },
      { id: 'Truck Mount 41m & under', label: 'Truck Mount 41m & under', value: filteredData.truckMountUnder41 || 0 },
      { id: 'Tower Placing Boom', label: 'Tower Placing Boom', value: filteredData.tpBooms || 0 },
    ];
    return (
      <ResponsivePie data={data} {... Globals.DefaultGraphPieChartProps} fill={[
          { match: { id: 'Tower Placing Boom' }, id: 'dots' },
          { match: { id: 'Truck Mount 41m & under' }, id: 'lines' },
          { match: { id: 'Truck Mount Over 41m' }, id: 'lines' },
        ]}/>
    );
  }
}

AdminDashboardGraph_Achieved.DefaultGraphLoading = function() {
    return (
      <Spin className='graphLoad' indicator={ <Icon type="loading" style={{ fontSize: 40 }} spin />}/>
    );
}
AdminDashboardGraph_Achieved.DefaultEmptyGraph = function() {
  return (
    <Empty className='graphEmpty' description='Not enough data to build this chart.'/>
  );
}
