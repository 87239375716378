import Globals from '../../../config/Globals';
//
import APIRequest from '../APIRequest';
//
export default class APIAdvisor {
  constructor(API) {
    this.api = API;
  }
  async getDashboard() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_AdvisorDashboard;
    return await req.exec();
  }
  async getToBeScheduledExams() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Advisor_ExamsReadyToSchedule;
    return await req.exec();
  }
  async getScheduledExams() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Advisor_ExamsScheduled;
    return await req.exec();
  }
}
