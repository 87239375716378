import Globals from '../../../config/Globals';
//
import APIRequest from '../APIRequest';
//
export default class APICertification {
  constructor(API) {
    this.api = API;
  }

  //Certs
  async getByUserIDAndCertID(userID, certID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID;
    return await req.exec();
  }
  async getByUserID(userID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certifications;
    return await req.exec();
  }
  async createByUserIDAndCertSpecsID(userID, certSpecsID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certSpecsID;
    return await req.exec();
  }
  async renewCertificationByUserIDAndCertID(userID, certID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_CertificationRenew;
    return await req.exec();
  }

  //Certification card
  async getPrintingCard(userID) {
    const request = await this.api.newBaseRequest('GET');
    request.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + Globals.APIPath_CertificationPrint;
    request.bodyType = 'BINARY';
    return await request.exec();
	}
  async getPrintingCardInfo(userID) {
    const request = await this.api.newBaseRequest('GET');
    request.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + Globals.APIPath_CertificationPrintInfo;
    return await request.exec();
	}

  /* payment */
    //Voucher
  async applyVoucher(userID, certID, examID, code) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_ApplyVoucher;
    req.body = { code: code, examSpecsID: examID };
    return await req.exec();
  }
    //Normal Payment
  async beginPayment(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_PaymentBegin;
    req.body = { ...content, examSpecsID: examID };
    return await req.exec();
  }
  async completePayment(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_PaymentComplete;
    req.body = { ...content, examSpecsID: examID };
    return await req.exec();
  }
  async cancelPayment(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_PaymentCancellation;
    req.body = { ...content };
    return await req.exec();
  }
    //Refund
  async refundPayment(userID, certID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_PaymentRefund;
    req.body = { ...content };
    return await req.exec();
  }

  /* waive */
  async listWaives() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_WaiveRequests;
    return await req.exec();
  }
  async requestWaive(userID, certID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_WaiveRequest;
    return await req.exec();
  }
  async approveWaiveRequest(userID, certID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_WaiveRequest + Globals.APIPath_WaiveApprove;
    return await req.exec();
  }
  async rejectWaiveRequest(userID, certID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_WaiveRequest + Globals.APIPath_WaiveReject;
    return await req.exec();
  }
}
