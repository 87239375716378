import React from 'react';
import autoBind from 'react-autobind';
//
import { Button, Col, Row, Avatar, Typography, Skeleton, Popover, Icon } from 'antd';
//
import Utils from '../../components/Utils';
//
import config from '../../config/config';
//
const { Text, Paragraph } = Typography;
//
import '../../assets/stylesheets/CommonUserHeader.scss';
//
export default class CommonUserHeader extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleEditProfile() {
    if (this.props.app.isAdmin()) {
      this.props.app.pushPage(config.ApplicationRoutes.userProfile, {}, this.props.ccpoUser.id);
    } else {
      this.props.app.pushPage(config.ApplicationRoutes.profile);
    }
  }

  handleOpenBillingInfo() {
    if (this.props.app.isAdmin()) {
      this.props.app.pushPage(config.ApplicationRoutes.adminBillingView, null, this.props.ccpoUser.id);
    } else {
      this.props.app.pushPage(config.ApplicationRoutes.simpleBillingView);
    }
  }

  handleOpenWorkHours() {
    if (this.props.app.isAdmin()) {
      this.props.app.pushPage(config.ApplicationRoutes.userWorkHours, null, this.props.ccpoUser.id);
    } else {
      this.props.app.pushPage(config.ApplicationRoutes.workHours);
    }
  }

  render() {
    if(this.props.ccpoUser) {
      return (
        <div className={this.props.justCard ? "userHeaderJustCard" : "userHeader"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {!this.props.justCard &&
            <Typography.Text>
              {(!this.props.app.isAdmin() || this.props.isAdminView) && 'Welcome '}
              {this.props.ccpoUser.firstName} {this.props.ccpoUser.lastName}
            </Typography.Text>}
          {!this.props.isAdminView &&
            <div style={{ display: 'flex', alignItems: 'center', jutifyContent: 'flex-end', flex: 1 }}>
              <Popover placement="bottomLeft" content={this._renderUserCard()} className='userInfoPopover'>
                <Icon type="exclamation-circle" className='userHeaderIcon'/>
              </Popover>

              <Button type="link" className="dashboardAdditionalButton" style={{ marginLeft: '10px' }}
                      onClick={this.handleOpenBillingInfo}><Icon type="credit-card" style={{ marginTop: '3px' }} /></Button>
              <Button type="link" className="dashboardAdditionalButton" style={{ marginLeft: '10px' }}
                      onClick={this.handleOpenWorkHours}><Icon type="clock-circle" style={{ marginTop: '3px' }} /></Button>
            </div>
          }
        </div>
      );
    } else return (<Skeleton className="userHeader" paragraph={{ rows: 0 }} active/>);
  }

  /* private */
  _renderUserCard() {
    const imageData = this.props.datasource.state.profileImageData;
    return (
      <Row type="flex" align="top" className='userInfoPopoverContent'>
        {!this.props.justCard &&
          <Col> <Avatar size={64} icon={(this.props.datasource.state.isLoading || imageData == 'loading') ?
                                        'loading' : 'user'} className='avatarIcon' src={imageData} /> </Col>}
        <Col span={16} offset={1}>
          <Row type="flex" align="middle">
            <Col span={3} offset={19}>
              <Button type="link" className="dashboardProfileHeaderEditButton"
                      icon="edit" onClick={this.handleEditProfile}> See More </Button>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={20}>{this._renderMultiItems(['firstName', 'lastName'], 'Name', this.props.ccpoUser)}</Col>

          </Row>
          <Row type="flex" align="middle">
            <Col span={22}>{this._renderItem('email', 'Email', this.props.ccpoUser)}</Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={22}>{this._renderItem('value.dateOfBirth', 'Date of Birth', this.props.personalPart)}</Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={22}>{this._renderItem('value.driverLicense', 'Drivers License', this.props.personalPart)}</Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={22}>{this._renderItem('agreedOnTerms', 'Terms agreed on', this.props.ccpoUser, 'date')}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
  _renderItem(propKey, title, source, type) {
    const value = Utils.getNestedObject(source, propKey);
    let formatter = type == 'date' ? Utils.getDateOnUIFormatByTimestamp : val => val;
    return (
      <div style={{ fontSize: 14, lineHeight: '22px', marginBottom: 7, color: 'rgba(0,0,0,0.65)' }}>
        <Paragraph className="dashboardProfileHeaderItemTitle">{title}:</Paragraph>
        <Text className="dashboardProfileHeaderItemContent">{formatter(value || '-')}</Text>
      </div>
    );
  }
  _renderMultiItems(propKeys, title, source) {
    const setValue = propKeys.map((key, index) => {
      const value = Utils.getNestedObject(source, key);
      if (value)
        return Utils.getNestedObject(source, key) + (propKeys.length > 1 && index != propKeys.length - 1 ? ' ' : '');
      return index == 0 ? '-' : '';
    });
    return (
      <div style={{ fontSize: 14, lineHeight: '22px', marginBottom: 7, color: 'rgba(0,0,0,0.65)' }}>
        <Paragraph className="dashboardProfileHeaderItemTitle">{title}:</Paragraph>
        <Text className="dashboardProfileHeaderItemContent">{setValue}</Text>
      </div>
    );
  }
}
