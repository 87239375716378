import React from 'react';
import { Upload, Icon, Popover } from 'antd';
import autoBind from 'react-autobind';
//
// import config from '../../config/config';
import Globals from '../../config/Globals';
//
class UserUploadPictureStep extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    if (this.props.delegate) this.props.delegate.pictureStep = this;
    this.state = {
      tenantConfig: null,
      isLoading: false,
      imageURL: null,
      firstLoad: true,
      photoHash: null,
      oldHashes: [],
      isDisabled: props.isDisabled ? props.isDisabled : false,
    };
  }

  componentDidMount = async () => {
    const tenantConfig = await this.props.app.sharedCache().getTenantConfig();

    this.setState({ tenantConfig });
    if (this.state.firstLoad) {
      //check if first time, if so, load image
      if (this.props.ccpoPart && this.props.ccpoPart.value && this.props.ccpoPart.value.photo) {
        //load existing photo
        this.loadImage(this.props.ccpoPart.value.photo);
      } else this.setState({ firstLoad: false });
    }
  };
  async loadImage(hashedFile) {
    if (hashedFile) {
      //load existing photo
      this.setState({ isLoading: true, photoHash: hashedFile }); //set hash and start loading
      //load Photo
      // const userID = this.props.app.idm.session.authorization.getUserID();
      const pictureURL = await this.props.app.api.userFile.signedGetURL(this.props.ccpoPart.userID, hashedFile);

      //End loading and set image data
      if (pictureURL.statusCode == 200) {
        this.setState({ isLoading: false, imageURL: pictureURL.body.fileURL, firstLoad: false });
      } else this.setState({ isLoading: false, firstLoad: false });
    } else this.setState({ firstLoad: false });
  }
  //Actions
  handleUpload(event) {
    if (this.state.photoHash) this.state.oldHashes.push(this.state.photoHash);
    this._uploadImage(event);
  }

  //UI
  render() {
    const customSize = this.props.size ? this.props.size : 200;

    const uploadButton = (
      <div>
        <Icon type={this.state.isLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text" style={{ fontSize: 20, minWidth: customSize }}>
          {this.state.isLoading ? 'Uploading Picture' : 'Upload Picture'}
        </div>
      </div>
    );
    return (
      <div
        style={{ margin: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        {...(this.props.className ? { className: this.props.className } : {})}
      >
        <Upload
          disabled={this.state.isDisabled}
          style={{ fontSize: 40, minWidth: customSize }}
          name="picture"
          accept="image/*"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={this.handleUpload}
        >
          {this.state.imageURL && !this.state.isLoading ? (
            <img
              src={this.state.imageURL}
              alt="avatar"
              style={{ width: '100%' }}
              height={customSize}
              width={customSize}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        {this.state.tenantConfig && (
          <Popover
            placement="right"
            content={
              <div style={{ width: 220 }}>
                <p style={{ textAlign: 'left', fontSize: 13 }}>{this.state.tenantConfig.organizationPhotoReq}</p>
              </div>
            }
            title="Photo Requirements"
          >
            <Icon type="info-circle" />
          </Popover>
        )}
      </div>
    );
  }

  /* private */
  _uploadImage(event) {
    this.setState({ isLoading: true });
    event.onProgress(1);
    //Read file
    const fileReader = new FileReader();
    fileReader.onloadend = async readerResp => {
      //Prepare to upload
      const userID = this.props.ccpoPart.userID;
      const hashedFile = this.props.app.api.userFile.encodedFileName(userID, event.file.name);
      event.file.file = readerResp.currentTarget.result;
      //Upload
      const resp = await this.props.app.api.userFile.uploadImage(
        event.file,
        hashedFile,
        userID,
        Globals.API_FileUpload_CardPictureDescription
      );
      if (resp.statusCode == 200) {
        this.setState({ isLoading: false, photoHash: hashedFile });
        await this.loadImage(hashedFile);
      } else {
        this.props.app.alertController.showErrorAlert(
          'Error',
          'Could not upload profile picture - ' + JSON.stringify(resp.body)
        );
        this.setState({ isLoading: false });
      }
    };
    fileReader.readAsDataURL(event.file);
  }
  _loadBlob(blobData) {
    return Buffer.to(blobData, 'base64');
  }
}

export default UserUploadPictureStep;
