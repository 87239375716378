import React, { Component } from "react";
import autoBind from 'react-autobind';
import { Avatar, Spin } from 'antd';
//zoom support
import Viewer from 'react-viewer';
//
export default class AsyncImage extends Component {
  constructor(props) {
    super(props);
    this.state = {isLoading: true, hashedFileName: this.props.fileName, signedURL: null, zoomImage: false};
  }
  componentDidMount() { this.startLoading(); }
  //
  startLoading() {
    this._getImageURL(this.state.hashedFileName, urlObject => {
      if (urlObject == null) return;
      this.loadImage(urlObject);
    })
  }
  //
  loadImage(urlObject){
    this.setState({...this.state, signedURL: urlObject});
  }
  stopLoading() {
    this.setState({...this.state, isLoading: false});
  }
  //actions
  handleImageZoom() {
    if (this.state.signedURL != null && !this.state.isLoading && !this.state.zoomImage) {
      this.setState({...this.state, zoomImage : true});
    }
  }
  handleDownload(e) { window.open(this.state.signedURL); }
  //UI
  render() {
    return(
      <div key={this.state.hashedFileName} onClick={this.handleImageZoom.bind(this)}>
          {this.state.signedURL &&
            <Avatar shape="square" size={this.props.size}
                    src={this.state.signedURL} onLoad={this.stopLoading.bind(this)}/>}
          {this.state.isLoading &&
            <Spin spinning/>
          }
          { this.state.signedURL && !this.state.isLoading &&
            <Viewer visible={this.state.zoomImage} noImgDetails={true} noFooter={false} changeable={false} noNavbar={true}
                    onClose={() => { this.setState({ zoomImage: false }); } } images={[{src: this.state.signedURL}]}
                    customToolbar={(toolbars) => {
                        return toolbars.concat([{
                            key: 'download-self', render: <div className="react-viewer-icon react-viewer-icon-download"></div>,
                            onClick: this.handleDownload.bind(this)
                          }]);
                        }}
             />
          }
      </div>
    );
  }


  //private
  async _getImageURL(imageID, callback) {
    try {
      const urlObject = await this.props.app.api.userFile.signedGetURL(this.props.userID, imageID);
      if (urlObject.statusCode == 200 && urlObject.body.fileURL) {
        callback(urlObject.body.fileURL);
      } else callback(null);
    } catch (error) {
      console.log(error);
      callback(null);
    }
  }
}
