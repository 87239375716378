import Globals from '../config/Globals';
//
import config from '../config/config';
//
const COLS = Globals.ImportUsers_CSV_COLUMNS;
//
export default class ImportUserOperation {}
ImportUserOperation.importUser = async function(entry, app) {
  //IDM
  const userObj = await this._createUserIfRequired(entry, app);
  await this._createPartitionsData(userObj, entry, app);
  //Attempt to retrieve organization
  const orgObj = await this._getOrganization(entry, app);
  //Register into CCPO
  await this._registerIntoCCPO(userObj, entry, orgObj, app);
  if (orgObj) await this._setEmployer(userObj, orgObj, app);
  //Try to initiate this user specified certification
  if (entry[COLS.CertificationID]) {
    const cert = await this._startCertification(userObj, entry, app);
    await this._completeMissingRequirements(userObj, cert, entry, app);
  }
}
//IDM
ImportUserOperation._createUserIfRequired = async function(entry, app) {
  //Start by checking the user on IDM
  const user = await app.idm.api.user.readByEmail(entry[COLS.Email]);
  if (user && user.statusCode == 200) {
    console.debug(`User ${entry[COLS.Email]} found on IDM!`);
    return user.body;
  }
  console.debug('User not found on IDM, creating a new one, ', entry[COLS.Email]);
  //Else, we need to create it
  const userCreate = await app.idm.api.registration.register({
    firstName: entry[COLS.FirstName],
    lastName: entry[COLS.LastName],
    email: entry[COLS.Email],
    bypassConfirmation: true,
    linkingRoles: [
      config.IDMClientOptions.roles.USER,
      config.IDMClientOptions.roles.IDM_USER,
      config.IDMClientOptions.roles.SHARED_MODULES ]
  });
  if (userCreate && userCreate.statusCode == 200) {
    console.debug('User created with success, retrieving its ID.');
    const user2 = await app.idm.api.user.readByEmail(entry[COLS.Email]);
    if (user2 && user2.statusCode == 200) return user2.body;
  } else {
    console.error('Could not create user', userCreate);
    throw userCreate.body;
  }
}
ImportUserOperation._createPartitionsData = async function(userObj, entry, app) {
  const ccpo = await app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.CCPO, true);
  const personal = await app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
  const partitionsValues = {
    [config.IDMClientOptions.partitions.CCPO]: {
      value: {
        ...(ccpo ? ccpo.value : {}),
        agreedOnTerms: Date.now(),
      },
    },
    [config.IDMClientOptions.partitions.PERSONAL]: {
      value: {
        //spread previous content
        ...(personal ? personal.value : {}),
        address: {
          street: [ entry[COLS.StreetAddress] ],
          country: entry[COLS.Country],
          province: entry[COLS.Province],
          city: entry[COLS.City],
          postalCode: entry[COLS.PostalCode],
          phone: entry[COLS.MobileNumber],
        },
        dateOfBirth: entry[COLS.DateOfBirth],
        driverLicense: entry[COLS.DriverLicense],
        mobilePhone: entry[COLS.MobileNumber],
      },
    },
    [config.IDMClientOptions.partitions.PROFESSIONAL]: {
      value: {
        employer: {
          location: entry[COLS.CompanyLocation],
          contact: entry[COLS.CompanyContact],
          contactPhone: entry[COLS.CompanyContactPhone],
        },
      },
    },
  };
  // Send API request to IDM, setting paritition for user X on partition Y with value Z
  const resp = await app.idm.api.userPartition.setMultiple(
    { parts: partitionsValues },
    /* userID */ userObj.id
  );
  //Invalidate old partition values
  await app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.CCPO);
  await app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL);
  await app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PERSONAL);

  // Simple 200 status means we got a update success
  if (resp.statusCode == 200) {
    console.debug('Created/Updated user partitions with success');
    return;
  } else {
    // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
    // Other status codes are not expected, 500 means de role API is down and it should be
    // handled as a unknown error for it rarity
    console.log('ERROR while updating user partitions', resp);
    throw resp.body;
  }
}
//CCPO User
ImportUserOperation._registerIntoCCPO = async function(userObj, entry, orgObj, app) {
  //Check the user on CCPO
  const user = await app.api.user.getByID(userObj.id);
  if (user && user.statusCode == 200) {
    console.debug(`User ${entry[COLS.Email]} found on CCPO, tenant ${config.ApplicationTenantID}!`);
    return;
  }
  console.debug('User not found on CCPO, registering, ', entry[COLS.Email]);
  //Continue registration
  const registration = await app.api.user.create({
    'agreedWithCertTerms': true,
    'agreedOnTerms': Date.now(),
    'firstName': entry[COLS.FirstName],
    'lastName': entry[COLS.LastName],
    'phoneNumber': entry[COLS.MobileNumber],
    'email': entry[COLS.Email],
    'orgID': (orgObj ? orgObj.id : ''),
    'id': userObj.id,
    'tenantID': config.ApplicationTenantID,
    'certNumber': entry[COLS.CertificationNumber],
    'companyLocation': entry[COLS.CompanyLocation],
    'companyContact': entry[COLS.CompanyContact],
    'companyContactPhone': entry[COLS.CompanyContactPhone],
  });
  if (registration && registration.statusCode == 200) return;
  else {
    console.error('Could not register user into CCPO', registration);
    throw registration.body;
  }
}
//CCPO Cert
ImportUserOperation._startCertification = async function(userObj, entry, app) {
  const certID = entry[COLS.CertificationID];
  //Check fi user is already enrolled into the specified cert
  const getCert = await app.api.userCertification.getByUserID(userObj.id, certID);
  if (getCert && getCert.statusCode == 200) {
    const cert = getCert.body.procs.find( (cert) => cert.certificationID == certID);
    if (cert) {
      console.debug(`Certification ${certID} for user ${userObj.email} is already created!`);
      return cert;
    }
  }
  //cert create
  console.debug(`Creating certification ${certID} for user ${userObj.email}`);
  const certCreate = await app.api.userCertification.createByUserIDAndCertSpecsID(userObj.id, certID);
  if (certCreate && certCreate.statusCode == 200) {
    //get recently created certs
    const getCert2 = await app.api.userCertification.getByUserID(userObj.id, certID);
    if (getCert2 && getCert2.statusCode == 200) {
      const cert = getCert2.body.procs.find( (cert) => cert.certificationID == certID);
      if (cert) return cert;
    }
    console.error(`Error while fetching recently created certification ${certID} for user ${userObj.email}`, getCert2);
    throw getCert2.body;
  }
  console.error(`Error while creating certification ${certID} for user ${userObj.email}`, certCreate);
  throw certCreate.body;
}
ImportUserOperation._completeMissingRequirements = async function(userObj, certObj, entry, app) {
  const certID = entry[COLS.CertificationID];
  //Get specs
  const specs = app.sharedCache().getCertificationByID(certID);
  //Get updated expanded cert
  const getCert = await app.api.userCertification.getByUserIDAndCertID(userObj.id, certObj.id);
  if (!getCert || getCert.statusCode != 200) {
    console.error(`Error while getting certification ${certID} for user ${userObj.email}`, getCert);
    throw getCert.body;
  }
  //Check for requirements X specs
  for (let req of specs.requirements) {
    req = (Array.isArray(req) ? req[0] : req);
    const reqID = (Array.isArray(req) ? req[0].id : req.id);
    const fullfiledExam = this._isRequirementFullfiled(req, getCert.body.exams, app);
    if (!fullfiledExam) {
      console.debug(`Certification ${certID} for user ${userObj.email} needs requirement ${reqID}!`);
      await this._completeRequirement(userObj, getCert.body, req, entry, app);
    } else {
      console.debug(`Certification ${certID} for user ${userObj.email} has requirement ${reqID} already fullfilled!`);
    }
  }
}
//Organization integration
ImportUserOperation._getOrganization = async function(entry, app) {
  if (entry[COLS.EmployerWSID]?.length > 0) {
    const resp = await app.organization.organization.getOrganizationByHashedWSID(entry[COLS.EmployerWSID]);
    if (resp.statusCode == 200 && resp.body) return resp.body;
    else {
      console.error('Unable to find organization with specified WSID!', entry, resp.body);
      throw resp.body;
    }
  } return null;
}
ImportUserOperation._setEmployer = async function(userObj, orgObj, app) {
  const resp = await app.organization.employee.upsertEmployee(orgObj.id, userObj.id, {
    hashedWorksafeID: orgObj.hashedWorksafeID, email: userObj.email,
    firstName: userObj.firstName, lastName: userObj.lastName,
  });
  if ((resp.statusCode == 200 || resp.statusCode == 204)) return;
  else {
    console.error('Unable to set employer!', userObj, orgObj);
    throw resp.body;
  }
}


/* super privates */
ImportUserOperation._completeRequirement = async function(userObj, certObj, requirement, entry, app) {
  //Skip payment if needed
  await this._skipPayment(userObj, certObj, requirement, entry, app);
  //Add grade if needed
  await this._addExamResult(userObj, certObj, requirement, entry, app);
  //Approve practical if needed
  await this._approveExamResult(userObj, certObj, requirement, entry, app);
}
ImportUserOperation._skipPayment = async function(userObj, certObj, req, entry, app) {
  const certID = entry[COLS.CertificationID];
  const currentExamID = req.id;
  console.debug(`Skipping payment for ${certID} user ${userObj.email} on requirement ${currentExamID}`);
  //Begin transaction
  const beginPaymentResp = await app.api.userCertification.beginPayment(userObj.id, certObj.id, currentExamID, {
    'amount': 0, 'taxValue': 0, 'totalValue': 0,
    'originalValue': req.policy.fee, 'comments': 'Skipped by import tool!',
  });
  if (beginPaymentResp.statusCode == 200 && beginPaymentResp.body && beginPaymentResp.body.fID) {
    const transactionID = beginPaymentResp.body.fID;
    //Continue transaction!
    const paymentResp = await app.api.userCertification.completePayment(userObj.id, certObj.id, currentExamID, { 'tokenID': '', 'financialTransactionID': transactionID });
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      console.debug(`Payment completed for ${certID} user ${userObj.email} on requirement ${currentExamID}`);
    } else {
      console.error(`Payment failed for ${certID} user ${userObj.email} on requirement ${currentExamID}`, paymentResp);
      throw paymentResp.body;
    }
  } else {
    console.debug(`Error while skipping payment for ${certID} user ${userObj.email} on requirement ${currentExamID} -- This is an allowed error, will try to continue.`, beginPaymentResp);
    //throw beginPaymentResp.body;
  }
}
ImportUserOperation._addExamResult = async function(userObj, certObj, req, entry, app) {
  const certID = entry[COLS.CertificationID];
  //Get updated expanded cert
  const getCert = await app.api.userCertification.getByUserIDAndCertID(userObj.id, certObj.id);
  if (!getCert || getCert.statusCode != 200) {
    console.error(`Error while getting certification ${certID} for user ${userObj.email}`, getCert);
    throw getCert.body;
  }
  //Find pending exam that is pending
  const examObj = getCert.body.exams.find( (exam) => {
    return (exam.examID == req.id && req.type == exam.type && exam.state == Globals.Exam_State.PENDING);
  });
  if (!examObj) {
    const msg = `Could not find pending exam for ${certObj.certificationID} user ${userObj.email} on requirement ${req.id} -- Maybe the exam is ready to be approved?`;
    console.debug(msg);
    return; //will not continue :)
  }

  if (req.type == 'Written') {
    const addResult = await app.api.userExam.addResult(userObj.id, certObj.id, examObj.id, {
      grade: 100, passed: true, noShow: false, comments: 'Results added by import tool!'
    });
    if (addResult && addResult.statusCode == 200) {
      console.debug(`Exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID} added with success!`);
      return;
    }
    console.debug(`Error while adding exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID}`, addResult);
    throw addResult.body;
  } else {
    const questions = app.sharedCache().getExamQuestionsByID(examObj.examID);
    const userObj = await app.idm.session.data.getUserObject();
    const addResult = await app.api.userExam.addPracticalResult(userObj.id, certObj.id, examObj.id, {
      noShow: false, suspended: false, stopped: false, comments: 'Results added by import tool!',
      questions: this._getQuestionAnswers(questions),
      otherInfo: {
        assessorID: app.idm.session.authorization.getUserID(),
        assessorName: userObj.firstName,
        examID: examObj.examID,
        examVersion: questions.examVersion,
      },
    });
    if (addResult && addResult.statusCode == 200) {
      console.debug(`Practical exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID} added with success!`);
      return;
    }
    console.debug(`Error while adding practical exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID}`, addResult);
    throw addResult.body;
  }
}
ImportUserOperation._approveExamResult = async function(userObj, certObj, req, entry, app) {
  const certID = entry[COLS.CertificationID];
  //Get updated expanded cert
  const getCert = await app.api.userCertification.getByUserIDAndCertID(userObj.id, certObj.id);
  if (!getCert || getCert.statusCode != 200) {
    console.error(`Error while getting certification ${certID} for user ${userObj.email}`, getCert);
    throw getCert.body;
  }
  //Find pending exam that is PENDING_RES_APPROVAL
  const examObj = getCert.body.exams.find( (exam) => {
    return (exam.examID == req.id && req.type == exam.type && exam.state == Globals.Exam_State.PENDING_RES_APPROVAL);
  });
  if (!examObj) {
    const msg = `Could not find pending approval exam for ${certObj.certificationID} user ${userObj.email} on requirement ${req.id} -- Maybe the exam is ready to be approved?`;
    console.debug(msg);
    return; //will not continue :)
  }

  if (req.type == 'Practical') {
    const questions = app.sharedCache().getExamQuestionsByID(examObj.examID);
    //Get optional date
    let expDate = ( entry[COLS.ExpiryDate] ? (new Date(entry[COLS.ExpiryDate])) : null );
    if (expDate) {
      const currDate = new Date();
      currDate.setDate(expDate.getDate());
      currDate.setMonth(expDate.getMonth());
      currDate.setYear(expDate.getFullYear());
      currDate.setUTCHours(currDate.getHours());
      expDate = currDate.getTime();
    }
    //
    const approveResult = await app.api.userExam.approveResult(userObj.id, certObj.id, examObj.id, expDate);
    if (approveResult && approveResult.statusCode == 200) {
      console.debug(`Practical exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID} approved with success!`, approveResult);
      return;
    }
    console.debug(`Error while approving practical exam results for certification ${certID}, user ${userObj.email} and exam ${examObj.id}/${examObj.examID}`, approveResult);
    throw addResult.body;
  }
}
//Helpers
ImportUserOperation._isRequirementFullfiled = function(requirement, userExams, app) {
    if (Array.isArray(requirement)) {
      for (let subReq of requirement) {
        let validPassedExam = this._isRequirementFullfiled(subReq, userExams, app);
        if (validPassedExam) return validPassedExam;
      } return false;
    } else {
      return userExams.find((exam) => {
        const specs = app.sharedCache().getExamByID(exam.examID);
        return ((specs && specs.type ? specs.type : null) == requirement.type && requirement.type == 'Written' && //same type and need to be written
                !exam.invalidated &&
                (exam.certExpiryDate && exam.certExpiryDate != -1 ?
                  /*use cert expiry date*/ Date.now() < exam.certExpiryDate :
                  /*use requirement expiry date*/ Date.now() < exam.reqExpiryDate
                ));
      });
    }
  }
ImportUserOperation._getQuestionAnswers = function(questions) {
  let answers = [];
  for (let part of questions.parts) {
    for (let section of part.sections) {
      for (let subsection of section.subsections) {
        for (let item of subsection.items) {
          for (let question of item.answersGroup) {
            const correctLabel = this._getCorrectAnswer(questions, question).label;
            answers.push({id: question.id, grade: correctLabel})
          }
        }
      }
    }
  } return answers;
}
ImportUserOperation._getCorrectAnswer = function(questions, question) {
  return questions.answersType[question.answersId].find( (answer) => answer.isCorrect );
}
