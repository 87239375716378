import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, TreeSelect, Row, Col, DatePicker } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
import config from '../../config/config';
//
import CommonOrganizationSelectionInput from "../commonComponents/CommonOrganizationSelectionInput";

const { TreeNode } = TreeSelect;

class UserProfessionalStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    if (this.props.delegate) this.props.delegate.professionalStep = this;
    this.state = {
      isFormValidProfessional: false
    };
  }

  componentDidMount = async () => {
    const personal = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    const ccpo = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.CCPO, true);
    const professional = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL, true);

    // console.log('Personal data - Professional Step', personal);
    // console.log('CCPO data - Professional Step', ccpo);
    // console.log('Professional data - Professional Step', professional);
    try {
      if (personal) {
        // setting initial values
        this.props.form.setFieldsValue({
          driverLicense: Utils.getNestedObject(personal, 'value.driverLicense'),
          specialNeeds: Utils.getNestedObject(personal, 'value.specialNeeds'),
        });
      }

      if (ccpo) {
        // setting initial values
        this.props.form.setFieldsValue({
          pumpCategories: Utils.getNestedObject(ccpo, 'value.pumpCategories'),
        });
      }

      // I called the validation to show explicitly to user the fields that they need to fill
      await this.props.form.validateFields();
      this.props.onChange({ isFormValidProfessional: true });
    } catch (err) {
      console.log(err);
      this.props.onChange({ isFormValidProfessional: false });
    }
  };

  handleValidation = async event => {
    try {
      await this.props.form.setFieldsValue({ [event.target.name]: event.target.value });
      const resp = await this.props.form.validateFields();
      //console.log('response - Professional Step', resp);
      this.props.onChange({ isFormValidProfessional: true });
    } catch (err) {
      //console.log('error - Professional Step', err);
      this.props.onChange({ isFormValidProfessional: false });
    }
  };

  handleSubmit = async () => {
    let resp = '';
    try {
      resp = await this.props.form.validateFields();
      if (resp.driverLicenseExpDate) resp.driverLicenseExpDate.format(Globals.DefaultUIBirthDateFormat);
    } catch (err) {
      resp = err;
      console.log(err);
    } return resp;
  };

  handleOrgChange(orgObj) { this.props.onChange({ userOrg: orgObj }); }

  render() {
    return (
      <>
        <Row>
          <Col span={8}>
          <CommonOrganizationSelectionInput app={this.props.app} input={{ id: this.props.delegate.state?.userOrg?.id, label: Globals.LABEL_EMPLOYER, required: true }} isEdit={true}
              selectedOrg={this.props.delegate.state?.userOrg} isAdmin={this.props.app.isAdmin()} onSelection={this.handleOrgChange}/>
          </Col>
          <Col span={5} style={{ marginLeft: 10 }}>
            <Form.Item label={`${Globals.LABEL_DRIVERS_LICENSE}`}>
              {this.props.form.getFieldDecorator('driverLicense')(<Input allowClear name="driverLicense" onChange={this.handleValidation} />)}
            </Form.Item>
          </Col>
          <Col span={4} style={{ marginLeft: 10 }}>
            <Form.Item label={`${Globals.LABEL_DRIVERS_LICENSE_EXP_DATE}`}>
              {this.props.form.getFieldDecorator('driverLicenseExpDate')(
                <DatePicker onChange={(value) => this.handleValidation({ target: { name: 'driverLicenseExpDate', value } })} format={Globals.DefaultUIBirthDateFormat} />
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginLeft: 10 }}>
            <Form.Item label={`${Globals.LABEL_SPECIAL_NEEDS}`}>
              {this.props.form.getFieldDecorator('specialNeeds', {})(
                <Input allowClear name="specialNeeds" onChange={this.handleValidation} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={`${Globals.LABEL_COMPANY_LOCATION}`}>
              {this.props.form.getFieldDecorator('companyLocation', {
                rules: [{ required: true, message: 'Please enter the city that you primarily work in', whitespace: true }],
              })(<Input allowClear name="companyLocation" onChange={this.handleValidation} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={`${Globals.LABEL_COMPANY_CONTACT_DISPATCHER}`}>
              {this.props.form.getFieldDecorator('companyContact', {
                rules: [{ required: true, message: 'BCCSA will contact this person to schedule your practical assessment', whitespace: true }],
              })(<Input allowClear name="companyContact" onChange={this.handleValidation} />)}
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: 10 }}>
            <Form.Item label={`${Globals.LABEL_COMPANY_CONTACT_DISPATCHER_PHONE}`}>
              {this.props.form.getFieldDecorator('companyContactPhone', {
                rules: [{ required: true, message: 'Please include an extension number if applicable', whitespace: true }],
              })(<Input allowClear name="companyContactPhone" onChange={this.handleValidation} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 14, maxWidth: 660 }}>
          <Col span={24}>
            <span style={{ color: '#333' }}>Category(ies) of concrete pump(s) you currently operate:</span>

            {this.props.form.getFieldDecorator('pumpCategories', {})(
              <TreeSelect
                showSearch
                style={{ width: '100%', marginTop: 10 }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                multiple
                treeDefaultExpandAll
              >
                {Globals.LABEL_PUMPS_CATEGORIES.map(category => (
                  <TreeNode
                    className='topTreeNode'
                    disabled={category.isDisabled}
                    value={`${category.description}`}
                    title={`${category.description}`}
                    key={`${category.pk}`}
                  >
                    {category.Options &&
                      category.Options.map(option => (
                        <TreeNode
                          value={`${option.description}`}
                          title={`${option.description}`}
                          key={`${option.pk}`}
                          onChange={this.handleValidation}
                        />
                      ))}
                  </TreeNode>
                ))}
              </TreeSelect>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default Form.create()(UserProfessionalStep);
