import React, { Fragment } from 'react';
import { Form, Input, TimePicker, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message, Modal, Checkbox, Popover, Icon } from 'antd';
//
import Utils from '../../components/Utils';
//
export default class CommonExamPracticalFormExtension {}
//Information
CommonExamPracticalFormExtension.renderInformationByType = function(type, fieldDecorator, readMode) {
  type = type.toLowerCase();
  const capType = Utils.capitalizeString(type);
  return (
    <Fragment>
      <Divider orientation="left" dashed>{capType}</Divider>
      <Row type="flex" justify="center" className='shortTimeInput'>
        <Col span={6}>
          <Form.Item label='Type'>
            {fieldDecorator(`${type}Type`)(<Input id={`${type}Type`} name={`${type}Type`} disabled={readMode}/>)}
          </Form.Item>
        </Col>
        <Col span={5} offset={1}>
          <Form.Item label={'Date'}>
            {fieldDecorator(`${type}Date`)(<DatePicker id={`${type}Date`} name={`${type}Date`} disabled={readMode}/>)}
          </Form.Item>
        </Col>
        <Col span={4} offset={1}>
          <Form.Item label={'Start Time'}>
            {fieldDecorator(`${type}StartTime`)(<TimePicker format={'HH:mm'} id={`${type}StartTime`} name={`${type}StartTime`} disabled={readMode}/>)}
          </Form.Item>
        </Col>
        <Col span={4} offset={1}>
          <Form.Item label={'End Time'}>
            {fieldDecorator(`${type}EndTime`)(<TimePicker format={'HH:mm'} id={`${type}EndTime`} name={`${type}EndTime`} disabled={readMode}/>)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={22}>
          <Form.Item label='Location'>
            {fieldDecorator(`${type}Location`, {rules: [{ required: false }]})(<Input.TextArea disabled={readMode} rows={2} />)}
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
//Questions
CommonExamPracticalFormExtension.renderQuestions = function(questions, answers, fieldDecorator, readMode, parentState) {
  //
  let grandTotal = 0;
  let grandTotalMax = 0;
  const readState = (answers ? answers : {});
  const answerTypes = (questions ? questions.answersType : []);
  //
  return (
    <>
    {questions && questions.parts.map( (part, index) => {
      let sectionTotal = 0;
      return (
        <Row key={`A${index}`}>
          {/*Header*/ index != 0 && <Divider dashed/>}
          <div className='questionsForm-sectionTitle'>{part.title}</div>
          {/*Sections*/ part.sections.map( (section, _index) => {
            let subsectionTotal = 0;
            grandTotalMax += section.maxScore;
            return (
              <Fragment key={`B${_index}`}>
                <Row>
                  {/*Header*/ section.title != part.title && <div className='questionsForm-subsectionTitle'>
                    {section.title}
                    {this._renderTotalInfo(section.maxScore, section.minPassScore, this._getSectionTotal(section, readState, answerTypes))}
                  </div>}
                  {/*Subsections*/ section.subsections && section.subsections.map( (subsection, __index) => {
                    const subVal = this._getSubsectionTotal(subsection, readState, answerTypes);
                    subsectionTotal += subVal;
                    return (
                      <Col key={`C${__index}`} span={10} offset={1}>
                        {/*Header*/ section.title != subsection.title && <div className='questionsForm-questionTitle'>{subsection.title}</div>}
                        {/*Questions*/ this._renderQuestionInput(
                          subsection.id,
                          (!subsection.maxScore ? section.maxScore : subsection.maxScore),
                          (!subsection.minPassScore ? section.minPassScore : subsection.minPassScore),
                          (subsection.isRequired || section.isRequired),
                          subVal, fieldDecorator, readMode)}
                      </Col>
                    );
                  })}
                </Row>
                {((()=>{
                  /*Acc. totals*/
                  sectionTotal += subsectionTotal;
                  grandTotal += subsectionTotal;
                })())}
              </Fragment>
            );
          })}
        </Row>
      );
    })}
      <Row type="flex" justify="end">
        <div className='questionsForm-grandTotal'>Total: {grandTotal} of {grandTotalMax}</div>
      </Row>
    </>
  )
}

CommonExamPracticalFormExtension._renderTotalInfo = function(max, min, current, includeStatus) {
  let content = <div className='questionsForm-totalPopoverContent'>
    <ul>
      <li>Maximum score: {max}</li>
      <li>Minimum passing score: {min}</li>
      <li>Current score: {current}</li>
    </ul>
  </div>;
  let className = (current >= min ? 'pass' : 'fail');
  return (
    <Popover content={content} className='questionsForm-totalPopover'>
      {!includeStatus && <Icon type="exclamation-circle" className='questionsForm-totalPopoverIcon'/>}
      {includeStatus && className != '' && <Icon type={(className == 'pass' ? 'check-circle' : 'close-circle')} className={'questionsForm-totalPopoverFeedbackIcon ' + className}/>}
    </Popover>
  );
}

CommonExamPracticalFormExtension._renderQuestionInput = function(id, maxScore, minPassScore, requiredQuestion, value, fieldDecorator, readMode) {
  let opts = { rules: [{ required: true, message: 'Please, enter a grade!' }]};
  //set initial value
  opts.initialValue = value;
  //
  return (
    <Form.Item label=''>
      <div className="questionsForm-inputWrapper ant-input-group-wrapper">
      <div className="ant-input-wrapper ant-input-group">
        {fieldDecorator(id, opts)(<InputNumber className='questionsForm-gradeInput' id={id} name={id}
        disabled={readMode} allowClear min={0} max={maxScore}/>)}
        <span className="postfix">
          of {maxScore}{(requiredQuestion && ' ‡')}
          {this._renderTotalInfo(maxScore, minPassScore, value, true)}
        </span>
      </div></div>
  </Form.Item>);
}

CommonExamPracticalFormExtension._getSectionTotal = function(section, readState, answerTypes) {
  if (section.subsections) {
    let val = 0;
    for (let subsection of section.subsections) {
      for (let item of subsection.items) {
        for (let question of item.answersGroup) {
          const answerType = question.answersId;
          const answerValue = Utils.getNestedObject(readState, question.id)
          if (this._isQuestionCorrect(answerValue, answerType, answerTypes)) val += 1;
        }
      }
    } return val;
  } else return 0;
}
CommonExamPracticalFormExtension._getSubsectionTotal = function(subsection, readState, answerTypes) {
  if (subsection.items) {
    let val = 0;
    for (let item of subsection.items) {
      for (let question of item.answersGroup) {
        const answerType = question.answersId;
        const answerValue = Utils.getNestedObject(readState, question.id)
        if (this._isQuestionCorrect(answerValue, answerType, answerTypes)) val += 1;
      }
    } return val;
  } else return 0;
}
CommonExamPracticalFormExtension._isQuestionCorrect = function(answer, answerType, answerTypes) {
  const answerSpecs = answerTypes[answerType];
  for (let option of answerSpecs) {
    if (answer && option.label == answer && option.isCorrect) return true;
  } return false;
}
