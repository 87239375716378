import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Button, message, Modal, Typography } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CardTemplateImage from '../../assets/images/card_template.png';
//
import Utils from '../../components/Utils';
// import Globals from '../../config/Globals';
//
//import config from '../../config/config';
import '../../assets/stylesheets/CommonPrintCardModal.scss';
//
const { TextArea } = Input;
//props handleSubmissionComplete, handleCancel
class CommonPrintCardModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, ccpoUser: null, cardInfo: {
      certLines: [],
    } };
  }
  async loadModalInfo() {
    const res = await this.props.app.api.userCertification.getPrintingCardInfo(this.props.ccpoUser.id);

    if (res.statusCode == 200) {
      this.setState({ cardInfo: res.body });
    }
  }
  //Actions
  handleCancel() { this.props.handleCancel(); }
  async handleSubmit() { this._downloadCardFile(); }
  //UI
  render() {
    return (
      <Modal maskClosable={false} closable={false} title="Card Preview" footer={null}
             className="printCardModal" visible={this.props.isVisible} confirmLoading={this.state.isLoading}>
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subcomponents */
  _renderForm() {
    const { getFieldDecorator } = this.props.form;
    const certSpecs = this.props.app.sharedCache().getCertificationByID(this.props.certification.certificationID);
    return (
      <Form key="printCard" className="printCardForm">
        <Row type="flex" justify="center">
          <img src={CardTemplateImage} className='printCardFormBackground'/>
            <Typography.Text className='printCardElementName'>{this.props.ccpoUser.firstName + ' ' + this.props.ccpoUser.lastName}</Typography.Text>
            <div className='printCardElementCertName'>
              <ul>
                {this.state.cardInfo.certLines.map((item) => (
                  <li key={item.title}>{item.title} <span>{item.expiration}</span></li>
                ))}
              </ul>
            </div>

            <Typography.Text className='printCardElementCertNumber'>{this.state.cardInfo.certNumber}</Typography.Text>
            <Typography.Text className='printCardElementEffectiveDate'>{Utils.getDateAndTimeOnPrintFormatByTimestamp(this.props.certification.completionDate)}</Typography.Text>
            <Typography.Text className='printCardElementExpiryDate'>{Utils.getDateAndTimeOnPrintFormatByTimestamp(this.state.cardInfo.expiryDate)}</Typography.Text>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary"
                loading={this.state.isLoading} onClick={this.handleSubmit} disabled={this.state.isLoading}
                className="printCardSubmitButton"> Download Card </Button>
      </Row>
    );
  }
  /* private methods */
  async _downloadCardFile() {
    this.setState({ isLoading: true });
    const resp = await this.props.app.api.userCertification.getPrintingCard(this.props.ccpoUser.id);
    if (!this._isMounted) return;
    console.debug('Card Print resp:', resp);
    if (resp.statusCode == 200) {
      this.setState({ isLoading: false });
      message.success('Download Initiated!');
      Utils.downloadArrayBuffer(resp.body.data, 'file', 'zip');
      this.props.handleCancel();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}

export default Form.create({})(CommonPrintCardModal);
