import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, Layout, message, Row, Col, Icon, Button } from 'antd';

import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonUserHeader from  '../commonComponents/CommonUserHeader';
import CommonCertificationCollectionView from '../commonSubviews/CommonCertificationCollectionView';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
import DataUtils from '../../components/DataUtils';
//
import '../../assets/stylesheets/AdminUserDashboardView.scss';
//

export default class AdminUserDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      ccpoUser: null, personalPart: null, ccpoPart: null, certifications: [],
      isLoading: false, firstLoad: true, profileImageData: null, selectingCertSpecsID: null
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.userID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    if (this.state.firstLoad && this.userID) this.fetchData();
  }

  //API
  async fetchData() {
    this.startLoading();
    const userID = this.userID;
    const wantedParts = [config.IDMClientOptions.partitions.CCPO, config.IDMClientOptions.partitions.PERSONAL];
    const parts = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
    const ccpoUser = await this.props.app.api.user.getByID(userID);
    const userCertsResp = await this.props.app.api.userCertification.getByUserID(userID);
    this.loadData(parts, ccpoUser, userCertsResp);
  }
  async loadData(parts, user, userCerts) {
    if (parts.statusCode == 200 && parts.body.parts &&
        user.statusCode == 200 && user.body &&
        userCerts.statusCode == 200 && userCerts.body && userCerts.body.procs) {
          //Process certifications
      const tenantConfig = this.props.app.sharedCache().getTenantConfig();
      let certifications = DataUtils.processUserCertifications(userCerts.body.procs, tenantConfig.certifications);
      //Find parts
      const ccpoPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.CCPO);
      const personalPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.PERSONAL);
      const ccpoUser = user.body;
      //Auto Select certification
      if (this.state.selectingCertSpecsID) {
        const cert = certifications.filter(item => item.certificationID == this.state.selectingCertSpecsID);
        if (cert) this.props.app.pushPage(config.ApplicationRoutes.userCertification, {}, cert[0].id, ccpoUser.id);
      } else {
        //Set start
        this.setState({ ccpoPart, personalPart, ccpoUser, isLoading: false, firstLoad: false, selectingCertSpecsID: null,
                        certifications, profileImageData: 'loading' /*flag to indicate loading*/ }, () => {
          this.loadImage(ccpoPart, ccpoUser); //load image after loading state
        });
      }
    } else {
      console.debug(parts, user, userCerts);
      this.props.app.alertController.showAPIErrorAlert(null, parts, (user.statusCode == 200 ? userCerts : user));
      this.setState({ firstLoad: false, isLoading: false, ccpoPart: null, personalPart: null, selectingCertSpecsID: null,
                      ccpoUser: null, profileImageData: null, certifications: [] })
    }
  }
  async loadImage(ccpoPart, ccpoUser) {
    let profileImageData = null;
    if (ccpoPart && ccpoPart.value.photo) {
      profileImageData = await this.props.app.api.userFile.getImage(ccpoPart.value.photo, ccpoUser.id);
      if (profileImageData && profileImageData.statusCode == 200 && profileImageData.body) { profileImageData = profileImageData.body; }
      else { profileImageData = null; }
    } this.setState({ profileImageData });
  }
  //Actions
  async handleStartCertification(certSpecsID) {
    if (!this.state.ccpoUser) return;
    this.startLoading();
    //Start cert
    const certStarted = await this.props.app.api.userCertification.createByUserIDAndCertSpecsID(this.state.ccpoUser.id, certSpecsID);
    if (!this._isMounted) return;
    //Check answer
    if (certStarted.statusCode == 200 && certStarted.body) {
      message.success('Certification initiated.');
      //Flag cert id to be selected
      this.setState({ selectingCertSpecsID: certSpecsID }, () => {
        this.fetchData();
      });
    } else {
      //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, certStarted);
      this.stopLoading();
    }
  }
  handleOpenWorkHours() { this.props.app.pushPage(config.ApplicationRoutes.userWorkHours, null, this.userID); }
  //UI
  render() {
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeaderNoHeight" title=" "
                      onBack={(e) => { this.props.app.pushPage(config.ApplicationRoutes.userSearch) }}/>
          <Layout.Content>
            <Row type="flex" justify="space-between">
              <Col>
                <CommonUserHeader app={this.props.app} ccpoUser={this.state.ccpoUser}
                                  personalPart={this.state.personalPart}
                                  isLoading={this.state.isLoading} datasource={this}/>
              </Col>
            </Row>
            {this.state.certifications.length > 0 && (
              <CommonCertificationCollectionView certifications={this.state.certifications} app={this.props.app}
                                                 customUser={this.state.ccpoUser} handleStartCertification={this.handleStartCertification}/>
            )}
          </Layout.Content>
      </Layout.Content>
    );
  }
}
