import Globals from '../../../config/Globals';
//
export default class APITenant {
  constructor(API) {
    this.api = API;
  }
  //Get tenant user file signed URLs
  async getConfiguration() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_TenantConfig;
    return await req.exec();
  }
  async getExamsQuestions(currentVersions) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TenantExamsQuestions;
    req.body = { query: currentVersions };
    return await req.exec();
  }
  async getStatsByYear(year) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_TenantStats + year;
    return await req.exec();
  }
  async getStatsByRange(from, to) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_TenantStats + from + "?to=" + to;
    return await req.exec();
  }
  async searchUsers(searchTerm, filter) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TenantSearchUsers;
    req.body = { searchTerm, filter };
    return await req.exec();
  }
  async searchUsersAutocomplete(value) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TenantSearchUsersAutocomplete;
    req.body = { value };
    return await req.exec();
  }
  async searchUsersReport(searchTerm, filter) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TenantSearchUsersExport;
    req.bodyType = 'JSON/BLOB';
    req.body = { searchTerm, filter };
    return await req.exec();
  }
  async sendContactForm(formValue) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TenantContactForm;
    req.body = { ...formValue };
    return await req.exec();
  }
}
