import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, InputNumber, Button, message, Steps, Spin, Modal, Result, Popover, Icon, Select } from 'antd';
import moment from 'moment';
//
import CustomComponent from '../../components/CustomComponent';
//
import APIUtils from '../../components/API/APIUtils';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonPaymentModal.css'; //inherits
import '../../assets/stylesheets/CommonPracticalExamRescheduleModal.scss';
//
const INITIAL_STATE = {
   currentExam: null, rule: null, currentExamSpecs: null,
   certSpecs: null, isLoading: false,
   //dynamic
   userObj: {},
   transactionValue: 0, originalValue: 0, taxAmount: 0, reason: 0, requestDate: moment(new Date(), Globals.DefaultUIDateTimeFormat), examDate: moment(new Date(), Globals.DefaultUIDateTimeFormat)
 };
//
class CommonExamRescheduleRequestModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = INITIAL_STATE;
  }
  //
  componentDidMount() {}
  loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    let examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same object
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    this.setState({ userObj: event.metadata.object.userObj || this.props.ccpoUser }, this._fecthUserOrg);
    //set values
    this._recalculateValues(examSpecs, examObj, certSpecs);
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleAfterClose() {
    this.setState(INITIAL_STATE);
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields();
    if (resp) this._reschedule(resp)
  };
    //form change
  handleChange(value, id) {
    this.setState({[id]: value}, () => {
      if (id == 'amount') this._recalculateValues(null, null, null, value);
      else this._recalculateValues();
    });
  };
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = examType + ' Exam Reschedule';
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false} footer={null} className='practicalExamReschedule'>
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subforms */
  _renderForm() {
    const { getFieldDecorator } = this.props.form;
    const orgName = !this.state.userObj.orgID ? 'Unknown Employer' : (!this.state.org ? 'Loading...' : this.state.org.name);
    return (
      <Form key='productReschedule'>
        <Divider orientation="left">{Globals.LABEL_PAYMENT}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`, {
                rules: [{ required: true, message: 'Please, type the certification description!' }],
              })(<Input disabled id="certificationDescription" name="certificationDescription" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={Globals.LABEL_EMPLOYER}>
              <Input disabled id="employer" name="employer" value={orgName} />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: 10 }}>
            <Form.Item label={Globals.LABEL_COMPANY_LOCATION}>
              <Input disabled id="companyLocation" name="companyLocation" value={this.state.userObj.companyLocation} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={Globals.LABEL_COMPANY_CONTACT_DISPATCHER}>
              <Input disabled id="companyContact" name="companyContact" value={this.state.userObj.companyContact} />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: 10 }}>
            <Form.Item label={Globals.LABEL_COMPANY_CONTACT_DISPATCHER_PHONE}>
              <Input disabled id="companyContactPhone" name="companyContactPhone" value={this.state.userObj.companyContactPhone} />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{'Reschedule Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={6}>
            <Form.Item label={'Request date'}>
              {this.props.form.getFieldDecorator(`requestDate`, { rules:[{required: true, message: 'Please, inform rescheduling request date!'}] })(
                <DatePicker allowClear={false} showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat} onChange={ (val) => this.handleChange(val, 'requestDate') }/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={4}>
            <Form.Item label={'New scheduled date'}>
              {this.props.form.getFieldDecorator(`examDate`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker allowClear={false} showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat}  onChange={ (val) => this.handleChange(val, 'examDate') }/>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={'Reschedule reason'}>
              {this.props.form.getFieldDecorator(`reason`, {
                initialValue: this.state.reason
              })(
                <Select onChange={ (val) => this.handleChange(val, 'reason') }>
                  <Select.Option value={0} key={0}>Assessor request</Select.Option>
                  <Select.Option value={1} key={1}>Candidate request</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        {this.state.reason != 0 &&
          <Row type="flex" justify="center">
            <Col span={7}>
              <Form.Item label={`${Globals.LABEL_AMOUNT}`}>
                {this.props.form.getFieldDecorator('amount', {
                  rules: [{ required: true, message: 'Please, inform the amount!' }],
                })(
                  <InputNumber precision={2} decimalSeparator="." formatter={Utils.defaultCurrenyInputFormatter}
                               disabled={!this.props.app.isAdmin()} min={0}
                               parser={Utils.defaultCurrentInputParser} onChange={ (val) => this.handleChange(val, 'amount') }/>
                )}
              </Form.Item>
            </Col>
            <Col span={1} offset={1}> {this._renderRescheduleRulePopover()} </Col>
            <Col span={5} offset={1}>
              <Form.Item label={`${Globals.LABEL_TAX_AMOUNT}`}>
                {this.props.form.getFieldDecorator('taxAmount', {
                  rules: [{ required: true, message: 'Please, inform the tax amount!' }],
                })(
                  <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                               parser={Utils.defaultCurrentInputParser}/>
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={`${Globals.LABEL_TOTAL_AMOUNT}`}>
                {this.props.form.getFieldDecorator('totalAmount', {
                  rules: [{ required: true, message: 'Please, inform the total amount!' }],
                })(
                  <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                               parser={Utils.defaultCurrentInputParser}/>
                )}
              </Form.Item>
            </Col>
          </Row>
        }
        {this.state.originalValue != this.state.transactionValue && (
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: true, message: 'Please, justify why the amount has changed!' }],
                })(<Input.TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit"
                type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
                Submit </Button>
      </Row>
    )
  }
  _renderRescheduleRulePopover() {
    if (!this.state.currentExamSpecs) return (<></>);
    return (
        <Popover placement="right" title="Reschedule Rules" content={
            <ul style={{ width: 250 }}>
              {this.state.currentExamSpecs.policy.reschedulePolicy.rules.sort((a,b) => a.daysAbove < b.daysAbove)
                .map( (rule, index) => (
                  <li {...(this.state.rule == rule ? {className:'selectedRescheduleRule'} : {})}
                      key={index} style={{ textAlign: 'left', fontSize: 13 }}>
                    If <b>{rule.daysAbove}</b> days or more before scheduled date
                  {(()=>{
                      if (rule.percentageCharged != 0 || rule.amountCharged == 0) return (
                        <> <b>{rule.percentageCharged} percent</b> of the transaction will be charged.</>
                      );
                      else return (
                        <> <b>${Utils.toCurrencyFormat(rule.amountCharged)}</b> will be charged.</>
                      );
                    })()}
                  </li>
                ))}
            </ul>
          }>
          <Icon className='rescheduleRulesIcon' type="info-circle"/>
        </Popover>
    );
  }

  /* private methods */
  _calculateProposedRescheduleValue(rule, examObj) {
    //Check if is using percentage or amount
    if (rule && rule.percentageCharged != 0) return examObj.transaction.transactionValue * (rule.percentageCharged/100.0);
    if (rule && rule.amountCharged != 0) return rule.amountCharged;
    return 0; //no feed :/
  }
  _calculateProposedRescheduleRule(examSpecs, scheduledDate, requestDate) {
    const reschedulePolicy = examSpecs.policy.reschedulePolicy;
    let rule = null;
    //Find rule
    for (let ruleObj of reschedulePolicy.rules) {
      let time = new Date(scheduledDate);
      time.setDate(time.getDate() - ruleObj.daysAbove);
      if (reschedulePolicy.isBusinessDays) time = Utils.businessDaysFromDate(new Date(requestDate), ruleObj.daysAbove);
      if (new Date(requestDate) <= time.getTime() && (!rule || rule.daysAbove < ruleObj.daysAbove)) rule = ruleObj;
    }
    return rule;
  }
  _recalculateValues(examSpecs, examObj, certSpecs, amount) {
    examSpecs = (examSpecs || this.state.currentExamSpecs);
    examObj = (examObj || this.state.currentExam);
    certSpecs = (certSpecs || this.state.certSpecs);

    //Get proper reschedule rule and value
    const scheduledDate = (examObj.examDate && examObj.examDate != -1 ? examObj.examDate : examObj.updatedOn);
    const rule = (amount || this._calculateProposedRescheduleRule(examSpecs, scheduledDate, Utils.getTimestampFromMoment(this.state.requestDate)));
    const feeValue = this._calculateProposedRescheduleValue(rule, examObj);

    //Calculate transaction values
    let taxAmount = feeValue * (examSpecs.taxPercentage / 100.0);
    if (isNaN(taxAmount)) taxAmount = 0;
    let totalAmount = Utils.safelyFixCurrency(feeValue + taxAmount);
    if (isNaN(totalAmount)) totalAmount = 0;

    //
    this.setState({currentExamSpecs:examSpecs, currentExam:examObj, originalValue: feeValue, certSpecs:certSpecs, taxAmount:taxAmount, transactionValue:feeValue, rule:rule }, () => {
      // set dynamic initial values
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        examDescription: examSpecs.displayName,
        examDate: this.state.examDate,
        reason: this.state.reason,
        requestDate: this.state.requestDate,
        ...(this.state.reason != 0 ? {taxAmount, totalAmount, ...(amount ? {} : {amount: feeValue})} : {})
      });
    });
  }

  /* private API methods */
  async _reschedule(data) {
    this.startLoading();
    //
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExam.id;
    const resp = await this.props.app.api.userExam.reschedule(this.state.userObj.id, currentCert.id, currentExamID, {
      'requestDate': Utils.getTimestampFromMoment(data.requestDate),
      'rescheduleDate': Utils.getTimestampFromMoment(data.examDate),
      'amount': data.amount,
      'reason': data.reason,
      'comments': data.comments
    });
    if (!this._isMounted) return;
    console.debug('Reschedule resp: ', resp, data);
    if (resp.statusCode == 200 && resp.body) {
      message.success('Exam rescheduled!');
      this.props.onChange(); //ask to hide modal
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  async _fecthUserOrg() {
    if (!this.state.userObj?.orgID) return;
    const orgResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: [this.state.userObj?.orgID] });
    if (orgResp.statusCode == 200 && orgResp.body?.orgs) this.setState({ org: orgResp.body.orgs[0] });
    else this.props.app.alertController.showAPIErrorAlert(null, orgResp);
  }
}

export default Form.create({})(CommonExamRescheduleRequestModal);
