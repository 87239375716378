import React from "react";
import { Row, Col, Menu, Dropdown, Icon } from 'antd';
import autoBind from 'react-autobind';
//
import '../../assets/stylesheets/CommonAuditFilter.scss';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
export default class CommonAuditFilter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.buildFilter();
  }
  //helper
  buildFilter() {
    this.state = { selectedFilter: 'iso' };
  }
  handleChange(value, id) {
    //handle special cases
    if (value.key == 'all') this.props.filterChange(0);
    else if (value.key == 'iso') this.props.filterChange(1);
    else if (value.key == 'ccpo') this.props.filterChange(2);
    else if (value.key == 'debug') this.props.filterChange(3);
    this.setState({selectedFilter: value.key});
  }
  //UI
  render() {
    //render
    return (
      <Col>
        <Dropdown overlay={this._renderMenu()} trigger={['click']}>
            <a className="filterAuditLabel">
              Event Level: {this._getTitleByFilterType(this.state.selectedFilter)} <Icon type="filter" />
            </a>
          </Dropdown>
      </Col>
     );
  }
_renderMenu() {
  return (
     <Menu className='filterMenuAudit' placement="bottomCenter" onClick={this.handleChange}>
       <Menu.Item key={`all`}>All</Menu.Item>
       <Menu.Item key={`iso`}><Icon type="security-scan" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.light_red}/> Certification </Menu.Item>
       <Menu.Item key={`ccpo`}><Icon type="lock" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.orange}/> Detailed </Menu.Item>
     </Menu>
    );
    //<Menu.Item key={`debug`}><Icon type="info-circle" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.grey}/> Debug</Menu.Item>
  }

  _getTitleByFilterType(value) {
    if (value == 'all') return 'All';
    else if (value == 'iso') return 'Certification';
    else if (value == 'ccpo') return 'Detailed';
    return 'Debug';
  }
}
