import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message, Modal, Checkbox } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonExamResultModal.scss';
//
const { Option } = Select;
const { TextArea } = Input;
//
class CommonExamWrittenResultModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, currentExamSpecs: null, certSpecs: null, currentExam: null, noShow: false /*keep elements disabled*/};
  }
  //
  loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    this.setState({currentExamSpecs: examSpecs, noShow: false, currentExam: examObj, isLoading: false, certSpecs: certSpecs }, () => {
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        examDescription: examSpecs.description,
        examDate: Utils.getDateAndTimeOnUIFormatByTimestamp((examObj.examDate && examObj.examDate != -1 ? examObj.examDate : examObj.updatedOn)),
        grade: 0, pass: false, noShow: false,
      });
    });
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleAfterClose() {
    this.setState({ isLoading: false, noShow: false, currentExamSpecs: null, currentExam: null, certSpecs: null});
    this.props.form.resetFields();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields(['grade', 'pass', 'comments', 'noShow']);
    if (resp) this._addExamResult(resp)
  };
  //form change
  handleChange(event, id) {
    const value = event.target.checked;
    //Check for the amount to be set
    if (id == 'noShow') {
      if (value) this.props.form.resetFields(['grade','pass']);
      this.setState({noShow: value});
    }
  };
  //UI
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = (this.state.certSpecs ? this.state.certSpecs.description + ' - ' + examType + ' Exam Result' : '');
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null} className='examResultModal'>
        {this._renderResultForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderResultForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key='result' className='examResultModalForm'>
        <Divider orientation="left">{'Exam Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`)(<Input disabled id="certificationDescription" name="certificationDescription" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label={'Scheduled Date'}>
              {this.props.form.getFieldDecorator(`examDate`)(<Input disabled id="examDate" name="examDate" />)}
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">{'Result'}</Divider>
        <Row type="flex" justify="center">
          <Col span={4}>
            <Form.Item label={'No Show'}>
              {this.props.form.getFieldDecorator(`noShow`, { rules:[{required: true}], initialValue: false, valuePropName: 'checked'  })
              (<Checkbox className='fixedInputCheckbox' onChange={ (val) => this.handleChange(val, 'noShow')}/>)}
            </Form.Item>
          </Col><Col span={4} offset={4}>
            <Form.Item label={'Approved'}>
              {this.props.form.getFieldDecorator(`pass`, { rules:[{required: true}], initialValue: false, valuePropName: 'checked' })
              (<Checkbox disabled={this.state.noShow} className='fixedInputCheckbox'/>)}
            </Form.Item>
          </Col>
          <Col span={6} offset={4}>
            <Form.Item label={'Grade'}>
              {this.props.form.getFieldDecorator(`grade`, { rules:[{required: true, message: 'Please, inform the exam grade!'}], initialValue: 0 })
              (<InputNumber id="grade" name="grade" disabled={this.state.noShow} allowClear min={0}/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
              {getFieldDecorator('comments', {
                rules: [{ required: false }],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
                Submit </Button>
      </Row>
    )
  }

  /* private methods */
  async _addExamResult(data) {
    this.setState({ isLoading: true });
    const currentCert = this.props.certification;
    const currentExam = this.state.currentExam;
    const resultResp = await this.props.app.api.userExam.addResult(this.props.ccpoUser.id, currentCert.id, currentExam.id, {
      'grade': data.grade,
      'passed': (data.pass ? true : false),
      'noShow': (data.noShow ? true : false),
      ...(data.comments ? {'comments': data.comments} : {})
    });
    if (!this._isMounted) return;
    console.debug('Written exam result resp:', resultResp, data);
    if (resultResp.statusCode == 200 && resultResp.body) {
      message.success('Written exam result added!');
      this.props.onChange();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, resultResp);
    }
  }
}

export default Form.create({})(CommonExamWrittenResultModal);
