import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Form, Input, Typography, Button, Divider, message } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
//
const NEW_STATE = { isLoading: false, isVisible: false };
//props are: app, selectedOrg, onValidationCancelled, onValidationCompleted
class CommonOrganizationSelectionConfirmationDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = NEW_STATE;
  }
  //Public
  show() { this.setState({ ...NEW_STATE, isVisible: true }); }
  //Actions
  async handleConfirm() {
    const data = await this.props.form.validateFields();
    if (data) this._validateWorksafeID(data);
  }
  handleCancel() {
    if (this.props.onValidationCancelled) this.props.onValidationCancelled();
    this._close();
  }

  //UI
  render() {
    const selectedOrg = this.props.selectedOrg;
    return(
      <>
      <div className='abcd' />
      <Drawer title={`Employeement company confirmation`} placement="top" closable={false} maskClosable={false}
          visible={this.state.isVisible} getContainer={() => document.querySelector('.abcd')} style={{ position: 'absolute' }} height={450}>
        <Form type='flex' layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={12} offset={2}>
              <Form.Item label="Company Name"> <Input value={selectedOrg?.name} disabled/> </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item label="Company City"> <Input value={selectedOrg?.address?.city || 'N/A'} disabled/> </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={20} offset={2}>
              <Divider/>
              <Typography.Title level={4}>Please, confirm the company <strong>"{selectedOrg?.name}"</strong> worksafe number in order to confirm your employement with it.</Typography.Title>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={20} offset={2}>
              <Form.Item extra="If you don't know this number, please, contact your employeer!">
                {this.props.form.getFieldDecorator(`worksafeID`, {
                  rules: [{ required: true, message: 'Please, type the company\'s WorksafeBC number!' },
                  { minLength: 4, message: 'WorksafeBC number is between 4 and 255 characters' },
                  { maxLength: 4, message: 'WorksafeBC number is between 4 and 255 characters' },]
                })(<Input id="worksafeID" name="worksafeID" placeholder="WorksafeBC #"/>)}
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' align='bottom'>
            <Col span={4} offset={1}>
              <Button type='secondary' onClick={this.handleCancel} disabled={this.state.isLoading}>Cancel</Button>
            </Col>
            <Col span={4}>
              <Button type='primary' loading={this.state.isLoading} disabled={this.state.isLoading} onClick={this.handleConfirm}>Confirm</Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
      </>
    );
  }
  /* private - helper */
  _close() {
    this.setState(NEW_STATE, () => {
      if (this.form) this.form.resetFields();
    });
  }
  /* private - API */
  async _validateWorksafeID(data) {
    if (!this._isMounted) return;
    this.startLoading();
    //request
    const resp = await this.props.app.organization.employee.validateWorksafeID(this.props.selectedOrg?.id, data.worksafeID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.info('WorksafeBC # validated with success. Save your profile to have this change saved!');
      if (this.props.onValidationCompleted) this.props.onValidationCompleted();
      this._close();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}

export default Form.create({})(CommonOrganizationSelectionConfirmationDrawer);
