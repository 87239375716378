import React from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon, Row, Col, Typography } from 'antd';
//
import CustomComponent from './components/CustomComponent';
import AppRoutes from './AppRoutes';
//
import Logo from './views/commonComponents/Logo';
import IDMUserBadge from './views/commonComponents/IDMUserBadge';
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
//
import CommonContactModal from './views/commonSubviews/CommonContactModal';
//
import config from './config/config';
import Globals from './config/Globals';
//
import './assets/stylesheets/AppViewController.scss';
//
const { Content, Sider, Header } = Layout;

export default class AuthorizedViewController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    // reset history state if restarting app
    this.props.app.props.history.location.state = undefined;
    this.handleLogout = this.handleLogout.bind(this);
    document.title = 'CCPO - Certified Concrete Pump Operator';
    this.state = {
      collapsed: true,
      isLoading: false,
      contactFormVisible: false,
    };
  }
  async componentDidMount() {
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObj });
  }
  // Actions
  async handleLogout() {
    this.setState({isLoading: true}, async () => {
      await this.props.app.idm.authenticator.logout();
      this.props.app.sharedCache().clearCache();
      this.props.app.pushPage(config.ApplicationRoutes.login);
    });
  }
  changePassword() {
    this.props.app.idm.user.changePassword();
  }
  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  handleContactShow() {
    if (this.contactModal) this.contactModal.loadAuthorizedInfo();
    this.setState({ contactFormVisible: true });
  }
  handleContactClose() {
    this.setState({ contactFormVisible: false });
  }

  //Helpers
  checkForValidAuthorization() {
    //Rare case where user has two tabs open, no CCPO access but logs in on IDM
    //CCPO gets notified with valid session, however, no app access - handle logout
    if (!(this.props.app.isAdmin() || this.props.app.isUser() || this.props.app.isAdvisor())) {
      this.props.app.idm.authenticator.logout();
      this.props.app.alertController.showWarningAlert(
        'Login error',
        "Your session is not valid for this application! Please, click on 'Log in' to use an existing account or click on 'Create account' to create a new account."
      );
      this.props.app.pushPage('/');
      return true;
    }
    return false;
  }
  // UI
  render() {
    //this check will redirect user out of application, if authorization is
    //not valid for some reason
    if (this.checkForValidAuthorization()) return <></>;
    return this._renderPage();
  }

  /* private methods */
  _renderPage() {
    return (
      <Layout className="container-layout">
        {this._renderHeader()}
        <Layout>
          {this._renderUserNavigationBar()}
          <Layout>
            <Content className="main-content">
              <CommonLoadingView isLoading={this.state.isLoading} />
              <AppRoutes appRef={this} />
            </Content>
            {this._renderFooter()}
            {this._renderContactModal()}
          </Layout>
        </Layout>
      </Layout>
    );
  }
  _renderHeader() {
    return (
      <Header className="appHeader">
        <Row type="flex" align="middle" justify="space-between">
          <Col className="menu-icon">
            <Icon className="trigger" type={this.state.collapsed ? 'menu' : 'close'} onClick={this.onCollapse} />
          </Col>
          <Col>
            {' '}
            <Logo isHeading authorized />{' '}
          </Col>
          <Col>
            {this.props.app.idm.isLogged() && (
              <IDMUserBadge user={this.state.userObj} changePassword={this.changePassword} logoutHandler={this.handleLogout}/>
            )}
          </Col>
        </Row>
      </Header>
    );
  }
  _renderUserNavigationBar() {
    // this should not happen, but it take our precautions :p
    if (!this.props.app.idm.isLogged()) return <></>;
    return this._renderPrivilegedNavigation();
  }
  _renderPrivilegedNavigation() {
    const pathName = this.props.app.props.history.location.pathname;
    let waiveRequests = this.props.app.sharedCache().getWaiveRequests();
    waiveRequests = waiveRequests ? waiveRequests.length : 0;

    return (
      <Sider theme="light" width={225} collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
        <Menu mode="inline" className="sideNavigation" selectedKeys={[pathName]} style={{ height: '100%' }}>
          <Menu.Item key={config.ApplicationRoutes.homepage}>
            <Icon type="pie-chart" />
            <span>Dashboard</span>
            <Link to={config.ApplicationRoutes.homepage} />
          </Menu.Item>
          {this.props.app.isUser() && (
            <Menu.Item key={config.ApplicationRoutes.workHours}>
              <Icon type="schedule" />
              <span>Work Hours</span>
              <Link to={config.ApplicationRoutes.workHours} />
            </Menu.Item>
          )}
          {this.props.app.isAdmin() && (
            <Menu.Item key={config.ApplicationRoutes.userSearch}>
              <Icon type="search" />
              <span>User Search</span>
              <Link to={config.ApplicationRoutes.userSearch} />
            </Menu.Item>
          )}
          {this.props.app.isAdmin() && (
            <Menu.Item key={config.ApplicationRoutes.pendingWaiveRequests}>
              {waiveRequests > 0 && (
                <div className="waivesBadge">
                  {waiveRequests > 99 ? '99+' : waiveRequests}
                </div>
              )}
              <Icon type="inbox" />
              <span>Pending Waive Requests</span>
              <Link to={config.ApplicationRoutes.pendingWaiveRequests} />
            </Menu.Item>
          )}
          {this.props.app.isAdmin() && (
            <Menu.Item key={config.ApplicationRoutes.usersImport}>
              <Icon type="import" />
              <span>Import users</span>
              <Link to={config.ApplicationRoutes.usersImport} />
            </Menu.Item>
          )}
          {this.props.app.isAdmin() && (
            <Menu.Item key={config.ApplicationRoutes.statusReport}>
              <Icon type="snippets" />
              <span>Status Report</span>
              <Link to={config.ApplicationRoutes.statusReport} />
            </Menu.Item>
          )}
          {/*
            If new menu items are needed please add them above.
            I am using last-child option to make these items fixed at the end of the page
            if you want to change look for ul.sideNavigation li:last-child
          */}
          {this.props.app.isUser() && (
            <Menu.Item key={config.ApplicationRoutes.profile}>
              <Icon type="setting" />
              <span>Profile</span>
              <Link to={config.ApplicationRoutes.profile} />
            </Menu.Item>
          )}
          {this.props.app.isAdmin() && (
            <Menu.Item key={config.ApplicationRoutes.settings}>
              <Icon type="setting" />
              <span>Settings</span>
              <Link to={config.ApplicationRoutes.settings} />
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    );
  }
  _renderFooter() {
    return (
      <Row type="flex" justify="end" align="middle" className="appFooter">
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" href={Globals.WEBSITE_LINK} target="_blank"> {Globals.WEBSITE_LABEL} </a>
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" key="contactUs" onClick={this.handleContactShow}> Contact Us </a>
          </Typography.Text>
        </Col>
        <Col> <Logo isFooter/> </Col>
      </Row>
    );
  }
  _renderContactModal() {
    return (
      <CommonContactModal
        isVisible={this.state.contactFormVisible}
        wrappedComponentRef={ref => {
          this.contactModal = ref;
        }}
        app={this.props.app}
        handleCancel={this.handleContactClose}
        handleSubmissionComplete={this.handleContactClose}
      />
    );
  }
}
