import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button } from 'antd';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//props are: app, isLoading, total, currentPage, sortedInfo, users, orgs,
//onPageChange, onFilterChange, onUserEdit, onRowAction
export default class CommonUsersTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  render() {
    let { sortedInfo } = this.props;
    sortedInfo = sortedInfo || {};
    const columns = [{
      title: 'Name', key: 'name.keyword',  width: '20%', render: d => (d.firstName ? d.firstName + ' ' + d.lastName : '-'),
      // title: 'Name',  dataIndex: 'name', key: 'name.keyword',  width: '20%',
      sortOrder: sortedInfo.columnKey === 'name.keyword' && sortedInfo.order, sorter: true,
     },
     {
      title: 'Email', dataIndex: 'email', key: 'email.keyword', width: '20%',
      sortOrder: sortedInfo.columnKey === 'email.keyword' && sortedInfo.order, sorter: true,
     },
     {
      title: 'Company Name', dataIndex: 'orgID', key: 'orgID.keyword', width: '15%',
      sortOrder: sortedInfo.columnKey === 'orgID.keyword' && sortedInfo.order,  sorter: true,
      render: (orgID) => !orgID ? 'Unknown Employer' : (!this.props.orgs ? 'Loading...' : this.props.orgs.find((o) => o.id == orgID)?.name)
     },
     {
       title: 'Certificate Numbers', dataIndex: 'certNumbers', key: 'certNumbers.keyword', width: '20%',
       sortOrder: sortedInfo.columnKey === 'certNumbers.keyword' && sortedInfo.order,  sorter: true,
       render: props => props ? props.join(', ') : ''
      },
      {
        title: 'Agreed on Terms', key: 'agreedOnTerms', width: '15%',
        render: props => props.agreedOnTerms ? Utils.getDateOnUIFormatByTimestamp(props.agreedOnTerms) : 'N/A',
        sortOrder: sortedInfo.columnKey === 'agreedOnTerms' && sortedInfo.order,  sorter: true,
     },
     { title: 'Actions', width: '10%', key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Set password'>
                      <Button variant="none" icon="tool" shape="circle" onClick={this.props.onRowAction.bind(this, props, 'setPass')}/>
                    </Tooltip>
                  </span>);
        }
      }];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.props.onFilterChange,
                    locale: {emptyText: 'No users found!'},
                    pagination: {
                      pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: false, position: 'bottom', size: "small",
                      total: this.props.total, onChange: this.props.onPageChange, current: this.props.currentPage,
                      showSizeChanger: false, showTotal: (total, range) => <div style={{marginRight: 20}}>{`${range[0]}-${range[1]} of ${total} users`}</div>
                    }};
    return (<Table className="adminSearchUsersTable" onRow={this._onRow} columns={columns} dataSource={this.props.users} {...props}/>);
  }
  /* private */
  _onRow(record) {
    return {
      onClick: (e) => {
        if (e.target.className.indexOf('ant-btn') != -1) return;
        this.props.onUserEdit(record);
      }, // click row
      onDoubleClick: () => { this.props.onUserEdit(record) }, // double click row
    };
  };
}