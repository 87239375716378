import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message, Modal, Checkbox } from 'antd';
import moment from 'moment';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonExamScheduleModal.scss';
//
const { Option } = Select;
const { TextArea } = Input;
//
class CommonExamScheduleModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, currentExamSpecs: null, currentExam: null, currentCertSpecs: null, userObj: {} };
  }
  //
  // const currentCert = this.props.certification;
  async loadModalInfo(event) {
    //Get all interesting ivars
    const certification = this.props.certification;
    const examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(certification.certificationID);
    const userObj = await this.props.app.api.user.getByID(event.metadata.object.userID);
    this.setState({currentCertSpecs: certSpecs, currentExamSpecs: examSpecs, currentExam: examObj, isLoading: false, userObj: userObj.body }, () => {
      this._fecthUserOrg();
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        examDescription: examSpecs.description,
        //examDate: moment(new Date(), Globals.DefaultUIDateTimeFormat)
      });
    });
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleAfterClose() {
    this.setState({ isLoading: false, currentExamSpecs: null, currentExam: null});
    this.props.form.resetFields();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields(['examDate', 'location']);
    if (resp) this._addExamSchedule(resp)
  };
  //UI
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = (this.state.currentCertSpecs ? this.state.currentCertSpecs.description : '') + ' - ' + examType + ' Exam Schedule';
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null} className='examScheduleModal'>
        {this._renderResultForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderResultForm() {
    const { getFieldDecorator } = this.props.form;
    const isPractical = (this.state.currentExam ? this.state.currentExam.type.includes('Practical') : false);
    const orgName = !this.state.userObj?.orgID ? 'Unknown Employer' : (!this.state.org ? 'Loading...' : this.state.org.name);
    return (
      <Form key='result' className='examScheduleModalForm'>
        <Divider orientation="left">{'Exam Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`)(<Input disabled id="certificationDescription" name="certificationDescription" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={Globals.LABEL_EMPLOYER}>
              <Input disabled id="employer" name="employer" value={orgName} />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: 10 }}>
            <Form.Item label={Globals.LABEL_COMPANY_LOCATION}>
              <Input disabled id="companyLocation" name="companyLocation" value={(this.state.userObj ? this.state.userObj.companyLocation : '')} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={Globals.LABEL_COMPANY_CONTACT_DISPATCHER}>
              <Input disabled id="companyContact" name="companyContact" value={(this.state.userObj && this.state.userObj.companyContact ? this.state.userObj.companyContact : '')} />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: 10 }}>
            <Form.Item label={Globals.LABEL_COMPANY_CONTACT_DISPATCHER_PHONE}>
              <Input disabled id="companyContactPhone" name="companyContactPhone" value={(this.state.userObj ? this.state.userObj.companyContactPhone : '')} />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{'Schedule Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={6}>
            <Form.Item label={'Scheduled date'}>
              {this.props.form.getFieldDecorator(`examDate`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker allowClear={false} showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat}/>
              )}
            </Form.Item>
          </Col><Col span={16}></Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label='Location and Instructions'>
              {getFieldDecorator('location', {
                rules: [{ required: isPractical, message: 'Please, enter a valid location and instructions!' }, { min: 10, whitespace: true, message: 'Minimum of 10 characters without white space is required!' }],
              })(<TextArea rows={2} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
                Submit </Button>
      </Row>
    )
  }

  /* private methods */
  async _addExamSchedule(data) {
    this.setState({ isLoading: true });
    const currentCert = this.props.certification;
    const currentExam = this.state.currentExam;
    const resultResp = await this.props.app.api.userExam.schedule(this.props.ccpoUser.id, currentCert.id, currentExam.id, {
      'examDate': moment(data.examDate).toDate().getTime(),
      ...(data.location ? { location: data.location } : {})
    });
    if (!this._isMounted) return;
    console.debug('Exam schedule resp:', resultResp, data);
    if (resultResp.statusCode == 200 && resultResp.body) {
      message.success('Exam scheduled!');
      this.props.onChange();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, resultResp);
    }
  }
  async _fecthUserOrg() {
    if (!this.state.userObj?.orgID) return;
    const orgResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: [this.state.userObj?.orgID] });
    if (orgResp.statusCode == 200 && orgResp.body?.orgs) this.setState({ org: orgResp.body.orgs[0] });
    else this.props.app.alertController.showAPIErrorAlert(null, orgResp);
  }
}

export default Form.create({})(CommonExamScheduleModal);
