import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Popover, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheelchair } from '@fortawesome/free-solid-svg-icons';

import IconsTimeline from './IconsTimeline';
import CommonCertificationTimelineEvent from './CommonCertificationTimelineEvent';
//admin
import CommonPaymentView from '../commonSubviews/CommonPaymentView';
import CommonCertificationContactModal from '../commonSubviews/CommonCertificationContactModal';
import CommonExamCancellationModal from '../commonSubviews/CommonExamCancellationModal';
import CommonExamWrittenResultModal from '../commonSubviews/CommonExamWrittenResultModal';
import CommonExamScheduleModal from '../commonSubviews/CommonExamScheduleModal';
import CommonExamReschedulePaymentModal from '../commonSubviews/CommonExamReschedulePaymentModal';
import CommonExamRescheduleRequestModal from '../commonSubviews/CommonExamRescheduleRequestModal';
import CommonCooldownEndModal from '../commonSubviews/CommonCooldownEndModal';
import CommonPrintCardModal from '../commonSubviews/CommonPrintCardModal';
import CommonExamPracticalResultModal from '../commonSubviews/CommonExamPracticalResultModal';
//
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonCertificationProcess.scss';
import '../../assets/stylesheets/CertificationTimeline.scss';
//
export default class CommonCertificationProcess extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isVisiblePayment: false,          isVisibleNextSteps: false,
      isVisibleExamCancellation: false, isVisibleWrittenExamResult: false,
      isVisibleExamSchedule: false,     isVisibleExamPaymentReschedule: false,
      isVisibleCooldownCancel: false,   isVisiblePracticalExamResult: false,
      isVisiblePrintCard: false,        isVisibleExamRescheduleRequest: false,
    };
  }
  //Actions
    //Show modals
  handleShowModalPayment() {
    if (!this.state.isVisiblePayment) this.paymentModal.loadModalInfo();
    this.setState({ isVisiblePayment: !this.state.isVisiblePayment }, () => {
      if (!this.state.isVisiblePayment) this.props.handleUpdate();
    });
  };
  handleShowExamCancellation(event) {
    if (!this.state.isVisibleExamCancellation) this.cancellationModal.loadModalInfo(event);
    this.setState({ isVisibleExamCancellation: !this.state.isVisibleExamCancellation}, () => {
      if (!this.state.isVisibleExamCancellation) this.props.handleUpdate();
    });
  }
  handleShowWrittenExamResult(event) {
    if (!this.state.isVisibleWrittenExamResult) this.writtenResultModal.loadModalInfo(event);
    this.setState({ isVisibleWrittenExamResult: !this.state.isVisibleWrittenExamResult}, () => {
      if (!this.state.isVisibleWrittenExamResult) this.props.handleUpdate();
    });
  }
  handleShowPracticalExamResult(event) {
    if (!this.state.isVisiblePracticalExamResult) this.practicalResultModal.loadModalInfo(event);
    this.setState({ isVisiblePracticalExamResult: !this.state.isVisiblePracticalExamResult}, () => {
      if (!this.state.isVisiblePracticalExamResult) this.props.handleUpdate();
    });
  }
  handleShowExamSchedule(event) {
    if (!this.state.isVisibleExamSchedule) this.scheduleModal.loadModalInfo(event);
    this.setState({ isVisibleExamSchedule: !this.state.isVisibleExamSchedule}, () => {
      if (!this.state.isVisibleExamSchedule) this.props.handleUpdate();
    });
  }
  handleShowExamPaymentReschedule(event) {
    if (!this.state.isVisibleExamPaymentReschedule) this.writtenRescheduleModal.loadModalInfo(event);
    this.setState({ isVisibleExamPaymentReschedule: !this.state.isVisibleExamPaymentReschedule}, () => {
      if (!this.state.isVisibleExamPaymentReschedule) this.props.handleUpdate();
    });
  }
  handleShowExamRescheduleRequest(event) {
    if (!this.state.isVisibleExamRescheduleRequest) this.practicalRescheduleModal.loadModalInfo(event);
    this.setState({ isVisibleExamRescheduleRequest: !this.state.isVisibleExamRescheduleRequest}, () => {
      if (!this.state.isVisibleExamRescheduleRequest) this.props.handleUpdate();
    });
  }
  handleShowCooldownEnd(event) {
    if (!this.state.isVisibleCooldownCancel) this.cooldownEndModal.loadModalInfo(event);
    this.setState({ isVisibleCooldownCancel: !this.state.isVisibleCooldownCancel}, () => {
      if (!this.state.isVisibleCooldownCancel) this.props.handleUpdate();
    });
  }
  handleShowModalContact = () => { this.setState({ isVisibleNextSteps: !this.state.isVisibleNextSteps }); };
  handleShowPrintCard() {
    if (!this.state.isVisiblePrintCard) this.commonPrintCardModal.loadModalInfo(event);
    this.setState({ isVisiblePrintCard: !this.state.isVisiblePrintCard}, () => {
      if (!this.state.isVisiblePrintCard) this.props.handleUpdate();
    });
  }
    //hide modal
  handleHideExamCancellation() { this.setState({ isVisibleExamCancellation: false }); }
  handleHideWrittenExamResult() { this.setState({ isVisibleWrittenExamResult: false }); }
  handleHidePracticalExamResult() { this.setState({ isVisiblePracticalExamResult: false }); }
  handleHideExamSchedule() { this.setState({ isVisibleExamSchedule: false }); }
  handleHideCooldownEnd() { this.setState({ isVisibleExamSchedule: false }); }
  handleHideRescheduleRequest() { this.setState({ isVisibleExamRescheduleRequest: false }); }
    //API Actions
  handleCertificationRenewal(event) { this.props.handleCertificationRenewal(event); }
  handlePaymentCancellation(event) { this.props.handlePaymentCancellation(event); };
  handleReceiptDownload(event) { this.props.handleReceiptDownload(event); }
    //Other actions
  handleExamLaunch(event) {
    const url = event.metadata ? event.metadata.url : '';
    if (url && event.type == Globals.Event_Type.EXAM) window.open(url);
  };
  handleCheckExamResults(event) { this.props.handleCheckExamResults(event); }
  handleCertificationWaive(event) {
    this.props.handleCertificationWaive(event);
  }
  //UI
  render() {
    const requiresTopEvent = ((this.props.certification.state != Globals.CertificationProcess_State.COMPLETED && this.props.certification.state != Globals.CertificationProcess_State.EXPIRED) || !this.props.certification.printedOn)
    return (
      <>
        {this._renderPaymentModal()}
        {this._renderContactModal()}
        {this._renderExamCancelModal()}
        {this._renderExamWrittenResultModal()}
        {this._renderExamPracticalResultModal()}
        {this._renderExamScheduleModal()}
        {this._renderExamPaymentRescheduleModal()}
        {this._renderExamRescheduleRequestModal()}
        {this._renderEndCooldownModal()}
        {this._renderPrintCardModal()}
        <div id="timelineVertical">
          <VerticalTimeline>
            {requiresTopEvent &&
              <VerticalTimelineElement iconStyle={{ background: '#f0f2f5', textAlign: 'center' }}
                                       icon={<IconsTimeline state='END' eventState='END' />}/>}
            {this.props.events.map((event, index) => {
              //dont show event in case on payment in progress AND visible payment modal
              if (event.type == Globals.Event_Type.PAYMENT && event.state == Globals.Event_State.IN_PROGRESS) {
                if (!this.state.isVisiblePayment && !this.props.isLoading) return this._renderEvent(event, index);
                else return <React.Fragment key={index}></React.Fragment>;
              } else return this._renderEvent(event, index);
            })}
            <VerticalTimelineElement iconStyle={{ background: '#f0f2f5', textAlign: 'center' }} icon={<IconsTimeline state="START" eventState="START" />}/>
          </VerticalTimeline>
        </div>
        {this.props.specialNeeds && this._renderSpecialNeedsView()}
      </>
    );
  }
/* private */
  _renderSpecialNeedsView() {
    const content = (
      <div>
        <p>{this.props.specialNeeds ? <>{this.props.specialNeeds}</> : ''}</p>
      </div>
    );
    return (
      <Popover content={content} title="Special Needs">
        <button className="special-needs-fixed" type="button">
          <FontAwesomeIcon icon={faWheelchair} />
        </button>
      </Popover>
    );
  }
  _renderEvent(event, index) {
    return (
      <CommonCertificationTimelineEvent key={index} app={this.props.app} event={event} index={index}
                     certification={this.props.certification} onNextSteps={this.handleShowModalContact}
                     onExamWrittenResult={this.handleShowWrittenExamResult} onReceiptDownload={this.handleReceiptDownload}
                     onExamPracticalResult={this.handleShowPracticalExamResult} onExamCancel={this.handleShowExamCancellation}
                     onExamSchedule={this.handleShowExamSchedule} onExamReschedulePayment={this.handleShowExamPaymentReschedule}
                     onExamRescheduleRequest={this.handleShowExamRescheduleRequest}
                     onExamLaunch={this.handleExamLaunch}
                     onCheckResults={this.handleCheckExamResults}
                     onPayment={this.handleShowModalPayment} onPaymentCancel={this.handlePaymentCancellation}
                     onCooldownEnd={this.handleShowCooldownEnd}
                     onCardPrint={this.handleShowPrintCard}
                     onRenewal={this.handleCertificationRenewal}
                     onWaive={this.handleCertificationWaive}/>
    )
  }
  //Modals
  _renderPaymentModal() {
    return (
      <CommonPaymentView ccpoUser={this.props.ccpoUser}
                         certification={this.props.certification} isVisible={this.state.isVisiblePayment}
                         wrappedComponentRef={ref => { this.paymentModal = ref; }}
                         onCancel={this.handleShowModalPayment} app={this.props.app}/>
    );
  }
  _renderContactModal() {
    return (
      <CommonCertificationContactModal app={this.props.app}
                                       isVisible={this.state.isVisibleNextSteps} handleCancel={this.handleShowModalContact}/>
                                   );
  }
  _renderExamCancelModal() {
    return (
      <CommonExamCancellationModal ccpoUser={this.props.ccpoUser}
                                   certification={this.props.certification} isVisible={this.state.isVisibleExamCancellation}
                                   wrappedComponentRef={ref => { this.cancellationModal = ref; }}
                                   onChange={this.handleShowExamCancellation} app={this.props.app}
                                   onCancel={this.handleHideExamCancellation}/>
    );
  }
  _renderExamWrittenResultModal() {
    return (
      <CommonExamWrittenResultModal ccpoUser={this.props.ccpoUser}
                             certification={this.props.certification} isVisible={this.state.isVisibleWrittenExamResult}
                             wrappedComponentRef={ref => { this.writtenResultModal = ref; }}
                             onChange={this.handleShowWrittenExamResult} app={this.props.app}
                             onCancel={this.handleHideWrittenExamResult}/>
    );
  }
  _renderExamPracticalResultModal() {
    return (
      <CommonExamPracticalResultModal ccpoUser={this.props.ccpoUser}
                             certification={this.props.certification} isVisible={this.state.isVisiblePracticalExamResult}
                             wrappedComponentRef={ref => { this.practicalResultModal = ref; }}
                             onChange={this.handleShowPracticalExamResult} app={this.props.app}
                             onCancel={this.handleHidePracticalExamResult}/>
    );
  }
  _renderExamPaymentRescheduleModal() {
    return (
      <CommonExamReschedulePaymentModal ccpoUser={this.props.ccpoUser}
          certification={this.props.certification} isVisible={this.state.isVisibleExamPaymentReschedule}
          wrappedComponentRef={ref => { this.writtenRescheduleModal = ref; }}
          onCancel={this.handleShowExamPaymentReschedule} app={this.props.app}/>
    );
  }
  _renderExamRescheduleRequestModal() {
    return (
      <CommonExamRescheduleRequestModal ccpoUser={this.props.ccpoUser}
          certification={this.props.certification} isVisible={this.state.isVisibleExamRescheduleRequest}
          wrappedComponentRef={ref => { this.practicalRescheduleModal = ref; }}
          onChange={this.handleShowExamRescheduleRequest} app={this.props.app}
          onCancel={this.handleHideRescheduleRequest}/>
    );
  }
  _renderExamScheduleModal() {
    return (
      <CommonExamScheduleModal ccpoUser={this.props.ccpoUser}
                               certification={this.props.certification}
                               isVisible={this.state.isVisibleExamSchedule}
                               wrappedComponentRef={ref => { this.scheduleModal = ref; }}
                               onChange={this.handleShowExamSchedule} app={this.props.app}
                               onCancel={this.handleHideExamSchedule}/>
    );
  }
  _renderEndCooldownModal() {
    return (
      <CommonCooldownEndModal ccpoUser={this.props.ccpoUser}
                              certification={this.props.certification} isVisible={this.state.isVisibleCooldownCancel}
                              wrappedComponentRef={ref => { this.cooldownEndModal = ref; }}
                              onChange={this.handleShowCooldownEnd} app={this.props.app}
                              onCancel={this.handleHideCooldownEnd}/>
    );
  }
  _renderPrintCardModal() {
    return (
      <CommonPrintCardModal app={this.props.app}
                            wrappedComponentRef={ref => { this.commonPrintCardModal = ref; }}
                            isVisible={this.state.isVisiblePrintCard} handleCancel={this.handleShowPrintCard}
                            ccpoUser={this.props.ccpoUser} certification={this.props.certification}/>
    );
  }
}
