import React from 'react';
import autoBind from 'react-autobind';

import Globals from '../../config/Globals';
import CustomComponent from '../../components/CustomComponent';
import { Form, Checkbox, Collapse, Row, Col } from 'antd';

import '../../assets/stylesheets/CommonRegistration.css';

const { Panel } = Collapse;

class UserAgreementStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    if (this.props.delegate) this.props.delegate.agreementStep = this;
    this.state = {
      isFormValidAgreement: false,
      agreement: '',
      isRead: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount = async () => {
    // Send API request to CCPO
    const resp = await this.props.app.sharedCache().getTenantConfig();
    console.log('Tenant config', resp);
    if (resp && resp.agreement) {
      this.setState({ agreement: resp.agreement });
    } else {
      this.props.app.alertController.showErrorAlert('Error!', 'Unable to load Code of Ethics.');
    }
  };

  handleScroll(event) {
    let scroll = event.target;
    const GAP = 10;
    const contentHeight = this.agreementContainer.clientHeight - GAP;
    let total = scroll.scrollTop + scroll.clientHeight;
    console.log(Math.trunc(total), Math.trunc(contentHeight))
    if (Math.trunc(total) >= (Math.trunc(contentHeight))) {
      this.setState({ isRead: true });
    }
  }

  handleSubmit = async () => {
    let resp = '';
    try { resp = await this.props.form.validateFields(); }
    catch (err) {
      resp = err;
      console.log(err);
    } return resp;
  };

  handleValidation = async event => {
    if (event.target.checked) {
      this.props.onChange({ isFormValidAgreement: true });
    } else {
      this.props.onChange({ isFormValidAgreement: false });
    }
  };

  render() {
    return (
      <>
        <Collapse bordered={false} style={{ marginTop: 15, marginBottom: 15 }}>
          <Panel header={Globals.LABEL_READ_TERMS} key="1">
            <div
              id="scroll"
              style={{ minHeight: 300, maxHeight: 300, minWidth: '100%', maxWidth: 628, overflow: 'auto' }}
              onScroll={this.handleScroll}
            >
              <div
                id="agreementContainer"
                // eslint-disable-next-line no-return-assign
                ref={agreementContainer => (this.agreementContainer = agreementContainer)}
                style={{ height: 'auto' }}
              >
                <span style={{ whiteSpace: 'pre-line', lineHeight: 1.6, textAlign: 'justify', fontSize: 11 }}>
                  {this.state.agreement.split('\t').map((item, key) => {
                    return (
                      <span key={key} style={{ marginLeft: 25 }}>
                        {item}
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
          </Panel>
        </Collapse>
        <>
          <Form id="agreementItemForm">
            <Form.Item>
              <Row>
                <Col>
                  {this.props.form.getFieldDecorator('agreedOnTerms', {
                    valuePropName: 'checked',
                    initialValue: false,
                    rules: [
                      {
                        required: true,
                        transform: value => value || undefined, // Those two lines
                        type: 'boolean', // Do the magic
                        message: 'Please agree the terms and conditions.',
                      },
                    ],
                  })(
                    <Checkbox disabled={!this.state.isRead} onChange={this.handleValidation}>
                      {Globals.LABEL_AGREED_ON_TERMS}
                    </Checkbox>
                  )}
                </Col>
                <Col>
                  <span style={{ color: '#af3947', fontSize: 12 }}>
                    * Please note, you must read the code of ethics in full before you can agree
                  </span>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </>
      </>
    );
  }
}

export default Form.create()(UserAgreementStep);
