import Globals from '../config/Globals';
import Utils from './Utils';
//
export default class Cache {
  constructor(auth) {
    this.auth = auth;
    this.tenantConfig = null;
    this.tenantUsers = null;
    this.userObj = null;
    this.isLoading = false;
    this.examsQuestions = {};
    this.waiveRequests = null;
  }

  async loadCache() {
    //avoid double cache loading
    if (this.isLoading) return false;
    this.isLoading = true;
    //execute all
    const respAll = await Utils.execRequests([
      (this.auth.api.tenant.getConfiguration()),
      (!(this.auth.isAdvisor() || this.auth.isAdmin()) ? this.reloadUserObj() : null),
      this.loadSavedExamsQuestions(),
      (this.auth.isAdmin() ? this.loadWaives() : null),
    ], null, (resp, respIdx) => {
      console.log('Resp', respIdx, resp);
      if (resp && respIdx == 0) this.tenantConfig = resp.body;
    }, true);
    //
    this.isLoading = false;
    return respAll;
  }
  clearCache() {
    this.tenantConfig = null;
    this.tenantUsers = null;
    this.userObj = null;
    this.examsQuestions = {};
    this.waiveRequests = null;
  }

  getTenantConfig() { return this.tenantConfig; }
  getCertificationConfig() { return this.tenantConfig ? this.tenantConfig.certificationObj : null; }
  getCertificationByID(certID) {
    if (!this.tenantConfig || ! this.tenantConfig.certifications) return null;
    //For all certifications
    for (let certificationObj of this.tenantConfig.certifications) {
      if (certificationObj.id == certID) return certificationObj;
    } return null;
  }
  getExamByID(examID) {
    if (!this.tenantConfig || ! this.tenantConfig.certifications) return null;
    //For all certifications
    for (let certificationObj of this.tenantConfig.certifications) {
      //For all exams of tenant config
      for (let exam of certificationObj.requirements) {
        if (Array.isArray(exam)) {
          for (let subExam of exam) if (subExam.id == examID) return subExam;
        } else if (exam.id == examID) return exam;
      }
    } return null;
  }
  getExamQuestionsByID(examID) {
    examID = `${Globals.Cookies_ExamsQuestionsPrefix}_${examID}`;
    return (this.examsQuestions[examID] ? this.examsQuestions[examID] : null);
  }
  getCCPOUser() { return this.userObj; }
  getWaiveRequests() { return this.waiveRequests; }
  //Tenant users
  setTenantUsers(users) { this.tenantUsers = users; }
  getTenantUsers() { return this.tenantUsers; }
  //Wrappers
  async reloadUserObj() {
    // Get current logged user ID from session authorization
    const userID = this.auth.idm.session.authorization.getUserID();
    //Load CCPO user
    const userResp = await this.auth.api.user.getByID(userID);
    if (userResp.statusCode == 200) this.userObj = userResp.body;
    //Load CCPO user -- for now we really dont care if the user was loaded
    // or not because we deal not found user as income registrations
    // This will change on the future when we will required to handle
    // tenant enrollments and multiple tenants login into different tenants.
    return {statusCode: 200};
  }
  async loadSavedExamsQuestions() {
    //Check newer exams questions
    const query = await this._buildExamsQuestionsQuery();
    const examsQResp = await this.auth.api.tenant.getExamsQuestions(query);
    if (examsQResp.statusCode == 200 && examsQResp.body.exams) {
      await this._appendExamsQuestions(examsQResp.body.exams);
    } return examsQResp;
  }
  async loadWaives() {
    const resp = await this.auth.api.userCertification.listWaives();
    if (resp.statusCode == 200 && resp.body) {
      this.waiveRequests = resp.body.certs;
    } return resp;
  }
  //Cachable getter
  async getVaultPaymentNonceByUserID(userID, additionalInfo) {
    //**IMPORTANT**// - This cookie key logic avoids issue when user logs out on another app and login with a different user ID
    const cookieKey = Globals.Cookies_NonceKey + (userID || this.auth.getAuthorizedUserID());
    console.log(cookieKey)
    //Attempt to get on cache
    let nonce = await this.auth.idm.session.data.storage.getValue(cookieKey);
    if (nonce) {
      console.debug('Using cached nonce.');
      return nonce;
    }
    //Continue to request if cache not available
    const resp = await this.auth.api.vault.getVaultNonce(userID, additionalInfo);
    if (resp.statusCode == 200 && resp.body && resp.body.nonce) {
      //Set plans and cache
      nonce = resp.body.nonce;
      await this.auth.idm.session.data.storage.setValue(cookieKey, nonce, Globals.Cookies_NonceCachingPeriod);
      return nonce;
    } else this.auth.alertController.showAPIErrorAlert(null, resp);
    console.error('Error while getting nonce', resp) ;
    return null;
  }
  //Exam cache management
  async _appendExamsQuestions(examsQuestions) {
    //Save all new exams
    for (let exam of (examsQuestions ? examsQuestions : [])) {
      const examID = `${Globals.Cookies_ExamsQuestionsPrefix}_${exam.id}`;
      localStorage.setItem(examID, JSON.stringify(exam)); //set to io cache
      this.examsQuestions[examID] = exam;//set to inmem cache
      await this._appendExamsQuestionsIndex(examID);
    }
  }
  async _appendExamsQuestionsIndex(examID) {
    let examsQuestionsIndex = await this.auth.idm.session.data.storage.getValue(Globals.Cookies_ExamsQuestionsIndex);
    if (examsQuestionsIndex) {
      examsQuestionsIndex = JSON.parse(examsQuestionsIndex);
      if (examsQuestionsIndex.indexOf(examID) == -1) examsQuestionsIndex.push(examID);
    } else if (!examsQuestionsIndex) examsQuestionsIndex = [examID];
    //
    await this.auth.idm.session.data.storage.setLongLiveValue(Globals.Cookies_ExamsQuestionsIndex, JSON.stringify(examsQuestionsIndex));
  }
  async _buildExamsQuestionsQuery() {
    let examsQuestionsQuery = [];
    try {
      //Build query upon saved questions
      const raw = await this.auth.idm.session.data.storage.getValue(Globals.Cookies_ExamsQuestionsIndex);
      const examsQuestionsIndex = (raw ? JSON.parse(raw) : []);
      for (let examIndex of (examsQuestionsIndex ? examsQuestionsIndex : [])) {
        const savedExam = JSON.parse(localStorage.getItem(examIndex));
        if (savedExam && savedExam.examVersion) {
          examsQuestionsQuery.push({examID: savedExam.id, examVersion: savedExam.examVersion});
          this.examsQuestions[examIndex] = savedExam;
        }
      }
    } catch (e) { console.log(e); }
    console.debug('Query exams questions', examsQuestionsQuery)
    return examsQuestionsQuery;
  }
}
