import Globals from '../../../config/Globals';
//
export default class APIUser {
  constructor(API) {
    this.api = API;
  }
  //Get tenant user object
  async getByID(userID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID;
    return await req.exec();
  }
  //Create tenant user
  async create(userObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userObj.id;
    req.body = { ...userObj };
    return await req.exec();
  }
  //Update tenant user
  async update(userObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + userObj.id;
    req.body = { ...userObj };
    return await req.exec();
  }
}
