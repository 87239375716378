import React from 'react';
import autoBind from 'react-autobind';
import scrollIntoView from 'scroll-into-view';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonUserSearchBar from '../commonComponents/CommonUserSearchBar';
import CommonUsersTable from '../commonComponents/CommonUsersTable';
import CommonSetPasswordModal from '../commonComponents/CommonSetPasswordModal';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/AdminSearchUsersView.scss';
import Utils from '../../components/Utils';
import PersistentSearch from '../../components/PersistentSearch';
//
export default class AdminSearchUsersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.persistentSearch = new PersistentSearch();
    this.state = {
      isLoading: false, isExporting: false, users: [], usersTotal: 0,
      isSetPassModalVisible: false, orgs: null,
      ...this._getInitialState()
    };
  }

  //Live cycle
  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }
  
  //Actions
  handleSearch(term) { this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, this.fetchData); }
    //Table user actions
  handleUsersEdit(val) {
    this.props.app.pushPage(config.ApplicationRoutes.userDashboard, {}, val.id);
  }
  handleUserAction(userObj, type) {
    if (type != 'setPass') return;
    this.setState({isSetPassModalVisible: true}, () => {
      this.setPassmodal.loadInfo(userObj.id);
    });
   }
  async handleExportXLSX() {
    this.setState({ isLoading: true, isExporting: true });
    //Make request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.api.tenant.searchUsersReport(searchTerm, this._getSearchFilter());
    console.log(resp);
    if (resp.statusCode == 200) {
      Utils.downloadBlobWithFileSaver(resp.body, `Users`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false, isExporting: false });
  }
   //Modal
  handleSetPassClose() { this.setState({isSetPassModalVisible: false}); }
    //Table internal actions
  handleTablePageChange(currentPage) { this.setState({ currentPage }, this.fetchData); }
  handleTableChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }); }
  //UI
  render() {
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
          {this._renderSetPasswordModal()}
          <PageHeader className="pageHeader" title="Search Users"
                      onBack={() => this.props.app.pushPage(config.ApplicationRoutes.homepage)}/>
          <Layout.Content>
            <Row>
              <Col>
                <CommonUserSearchBar persistentSearch={this.persistentSearch} isLoading={this.state.isLoading}
                  onSearch={this.handleSearch} {...Utils.propagateRef(this, 'searchBar')} app={this.props.app}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  icon="download"
                  type="primary"
                  style={{ marginBottom: 8, float: "right" }}
                  disabled={this.state.users.length < 1}
                  onClick={this.handleExportXLSX}
                  loading={this.state.isExporting}
                >
                  {this.state.isExporting ? 'Generating file...' : 'Export to Excel'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <CommonUsersTable app={this.props.app} isLoading={this.state.isLoading} total={this.state.usersTotal}
                    currentPage={this.state.currentPage} sortedInfo={this.state.sortedInfo}
                    users={this.state.users} orgs={this.state.orgs}
                    onPageChange={this.handleTablePageChange} onFilterChange={this.handleTableChange}
                    onUserEdit={this.handleUsersEdit} onRowAction={this.handleUserAction}/>
              </Col>
            </Row>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* UI privates */
  _renderSetPasswordModal() {
    return (
      <CommonSetPasswordModal isVisible={this.state.isSetPassModalVisible} 
        handleCancel={this.handleSetPassClose} app={this.props.app}
        handleSubmissionComplete={this.handleSetPassClose} wrappedComponentRef={(r)=> this.setPassmodal = r}/>);
  }
  /* private */
  _getInitialState() {
    const currentPage = parseInt(this.persistentSearch.getParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.persistentSearch.getParam(Globals.URLQueryParam_SortField) || 'createdOn';
    const order = this.persistentSearch.getParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return { currentPage, sortedInfo: { columnKey, order } };
  }
  _reloadURLParams() {
    this.persistentSearch.setParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.persistentSearch.setParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.persistentSearch.setParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }
  _getSearchFilter() {
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc') };
  }
  /* remote data */
  async fetchData() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.api.tenant.searchUsers(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = resp.body.users;
      this.setState({ users, usersTotal: resp.body.total, isLoading: false }, this._fetchCompanies);
      scrollIntoView(document.getElementById('root'), { debug: true, time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }
  async _fetchCompanies() {
    const orgIDs = this.state.users.map(o => o.orgID).filter(o => !!o);
    if (!orgIDs || orgIDs.length == 0) return;
    const orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) this.setState({ orgs: orgsResp.body?.orgs });
    else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
}
