import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//
import { Layout, Button, PageHeader, Card, Row, Col, Typography, Form, Skeleton } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonUserProfileForm from './CommonUserProfileForm';
import UserUploadPictureStep from '../commonComponents/UserUploadPictureStep';
//resources
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import config from '../../config/config';
//
import '../../assets/stylesheets/CommonUserProfileView.css';
//
const { Text, Paragraph } = Typography;
const { Content } = Layout;
const UserProfileForm = Form.create()(CommonUserProfileForm);
//
export default class CommonUserProfileView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.customUser = null;
    this.state = { firstLoad: true, isLoading: false, personalPart: null,
                   professionalPart: null, ccpoPart: null, ccpoUser: null, userOrg: null };
  }
  async componentDidMount() {
    super.componentDidMount();
    //Check if custom user parameter set (admin function)
    this.customUser = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    if (this.state.firstLoad) this.fetchData();
  }

  //API
  async fetchData() {
    let ccpoPart = null;
    let personalPart = null;
    let professionalPart = null;
    let ccpoUser = null;
    let userOrg = null;
    let orgIDfromIDM = null;
    this.startLoading();
    if (!this._isMounted) return;

    //Get user data
    if (this.customUser) {
      const wantedParts = [ config.IDMClientOptions.partitions.CCPO, config.IDMClientOptions.partitions.PERSONAL,
                            config.IDMClientOptions.partitions.PROFESSIONAL ];
      const parts = await this.props.app.idm.api.userPartition.readSome(wantedParts, this.customUser);
      console.log(this.customUser, parts)
      if (parts.statusCode == 200) {
        //Get parts
        ccpoPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.CCPO);
        personalPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.PERSONAL);
        professionalPart = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.PROFESSIONAL);
        console.debug(' parts -->>', parts);
        //Get CCPO user
        ccpoUser = await this.props.app.api.user.getByID(this.customUser);
        console.debug(' ccpoUser -->>', ccpoUser);
        if (ccpoUser.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, ccpoUser);
        else ccpoUser = ccpoUser.body;
        orgIDfromIDM = professionalPart?.value?.orgModuleOrgID; // grab the ID from IDM to make sure we get the latest, previously we used ccpoUser.orgID
        // if (ccpoUser.orgID) {
        if (orgIDfromIDM) {
          userOrg = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: [orgIDfromIDM] });
          if (userOrg.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, userOrg.body.orgs[0]);
          else userOrg = userOrg.body.orgs[0];
        }
      } else this.props.app.alertController.showAPIErrorAlert(null, parts);
    } else {
      // Check if should reload
      let shouldReload = false;
      if (!this.customUser && !this.state.firstLoad) {
        //Invalidate old partition values
        await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.CCPO);
        await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PERSONAL);
        await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL);
        shouldReload = true;
      }
      //Fetch current user stuff
      personalPart = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
      professionalPart = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL, true);
      ccpoPart = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.CCPO, true);
      if (shouldReload) await this.props.app.sharedCache().loadCache(); //reload user object
      ccpoUser = this.props.app.sharedCache().getCCPOUser();
      if (ccpoUser.orgID) {
        userOrg = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: [ccpoUser.orgID] });
        if (userOrg.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, userOrg.body.orgs[0]);
          else userOrg = userOrg.body.orgs[0];
      }
    }
    //
    this.setState({ isLoading: false, firstLoad: false,
                    ccpoUser, ccpoPart, personalPart, professionalPart, userOrg });
  }

  loadResponse(resp) {
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      //valid data state
    } else {
      //non-valid data state
      let err = resp.body.err || (resp.error || resp.error.message);
      this.props.app.alertController.showErrorAlert('Error!', err);
    }
    //Check for id
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  //Actions handler
  handleEditProfile() {
    this.props.app.openExternalPage(config.IDMClientOptions.externalAuthDomain + config.IDMClientOptions.externalProfilePath);
  }
  async handleSave() {
    const values = await this.form.validateFields();
    //Set state and save user!
    this.setState((prevState) => ({
      personalPart: { value: values.personalPart?.value },
      professionalPart: { value: values.professionalPart?.value },
      ccpoUser: { ...prevState.ccpoUser, ...values.ccpoUser },
      userOrg: values.selectedOrg
    }), () => { this._saveUser() });
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Content className="pageContent">
        <CommonLoadingView isLoading={isLoading} isFixed />
        <PageHeader className="pageHeader" onBack={() => window.history.back()}
                    title="Profile" extra={[ <Button key="1" onClick={this.handleSave}> Save </Button> ]}/>
        <Content>
          {this.state.ccpoUser ? this._renderIDMProfileCard() : <Skeleton avatar paragraph={{ rows: 2 }} active />}
          <UserProfileForm app={this.props.app} datasource={this}
                           wrappedComponentRef={ref => { this.form = ref }}/>
        </Content>
      </Content>
    );
  }

  //Private UIs
  _renderIDMProfileCard() {
    // <Col span={1} offset={3}>
    //   <Button className='editProfileButton' icon='edit' onClick={this.handleEditProfile}>Edit User</Button>
    // </Col>
    return (
      <Card className="userProfileIDMContainer">
        <Row type="flex" align="middle">
          <Col>
            <UserUploadPictureStep ccpoPart={this.state.ccpoPart} size={80}
                                   app={this.props.app} className="userProfileAvatar"
                                   delegate={this}/>
          </Col>
          <Col span={19} offset={1}>
            <Row>
              <Col span={8}>{this._renderMultiItems(['firstName', 'lastName'], 'Name', this.state.ccpoUser)}</Col>
              <Col span={10}>{this._renderItem('email', 'Email', this.state.ccpoUser)}</Col>
              <Col span={4}></Col>
            </Row>
            <Row>
              <Col span={8}>{this._renderItem('certificateNumber', 'Certification Number', this.state.ccpoUser)}</Col>
              <Col span={10}>
                {this._renderItem('value.pumpCategories', 'Pump Categories', this.state.ccpoPart, 'array')}
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                {this._renderItem('value.agreedOnTerms', 'Terms agreed on', this.state.ccpoPart, 'date')}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
  _renderItem(propKey, title, source, type) {
    const value = Utils.getNestedObject(source, propKey);
    let formatter = type == 'date' ? Utils.getDateAndTimeOnUIFormatByTimestamp : val => val;
    if (type == 'array') formatter = val => val.toString().replace(',', ', ');
    return (
      <div style={{ fontSize: 14, lineHeight: '22px', marginBottom: 7, color: 'rgba(0,0,0,0.65)' }}>
        <Paragraph className="dashboardProfileHeaderItemTitle">{title}:</Paragraph>
        <Text className="dashboardProfileHeaderItemContent">{formatter(value || '-')}</Text>
      </div>
    );
  }
  _renderMultiItems(propKeys, title, source) {
    const setValue = propKeys.map((key, index) => {
      const value = Utils.getNestedObject(source, key);
      if (value)
        return Utils.getNestedObject(source, key) + (propKeys.length > 1 && index != propKeys.length - 1 ? ' ' : '');
      return index == 0 ? '-' : '';
    });
    return (
      <div style={{ fontSize: 14, lineHeight: '22px', marginBottom: 7, color: 'rgba(0,0,0,0.65)' }}>
        <Paragraph className="dashboardProfileHeaderItemTitle">{title}:</Paragraph>
        <Text className="dashboardProfileHeaderItemContent">{setValue}</Text>
      </div>
    );
  }

  //API Calls
  async _saveUser() {
    this.startLoading();
    let resp = await this._updateUserPartitions();
    if (resp) resp = await this._updateCCPOUser();
    if (resp && this.state.userOrg) resp = await this.props.app.organization.employee.upsertEmployee( this.state.userOrg.id,
                                                                                                      this.state.ccpoUser.id,
                                                                                                      {
                                                                                                        email: this.state.ccpoUser.email,
                                                                                                        firstName: this.state.ccpoUser.firstName,
                                                                                                        lastName: this.state.ccpoUser.lastName,
                                                                                                        hashedWorksafeID: this.state.userOrg.hashedWorksafeID
                                                                                                      });
    if (resp) {
      await this.clearOldImages();
      this.fetchData();
      this.props.app.alertController.showSuccessAlert('', 'Profile saved!');
    } else this.stopLoading(); //fetch data stop loading for us on success ops
  }
  async _updateUserPartitions() {
    /* present form - aka. CCPO registration */
    const partitionsValues = {
      [config.IDMClientOptions.partitions.CCPO]: this.state.ccpoPart,
      [config.IDMClientOptions.partitions.PERSONAL]: this.state.personalPart,
      //[config.IDMClientOptions.partitions.PROFESSIONAL]: this.state.professionalPart,
    };
    partitionsValues[config.IDMClientOptions.partitions.CCPO].value.photo = this.pictureStep.state.photoHash;
    console.log('Saving', partitionsValues);
    //Save partition on IDM
    const userID = this.props.match.params[Globals.URL_Path_ID_Placeholder]
      ? this.props.match.params[Globals.URL_Path_ID_Placeholder]
      : this.props.app.idm.session.authorization.getUserID();
    const resp = await this.props.app.idm.api.userPartition.setMultiple(
      { parts: partitionsValues },
      /* userID */ userID
    );
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      //Invalidate old partition values
      await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.CCPO);
      await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PERSONAL);
      console.log('updated user partitions with success');
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 500 means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while updating user partitions');
      this.props.app.alertController.showErrorAlert('Error', JSON.stringify(resp));
      return false;
    }
    return true;
  }
  async _updateCCPOUser() {
    //Phone number logic
    let phoneNumber = null;
    if (this.state.personalPart && this.state.personalPart.value.mobilePhone) phoneNumber = this.state.personalPart.value.mobilePhone;
    //if (this.state.professionalPart?.value?.address?.phone) phoneNumber = this.state.professionalPart.value.address.phone;
    //Build ccpo user
    const ccpoUser = {
      ...this.state.ccpoUser,
      firstName: this.state.ccpoUser.firstName,
      lastName: this.state.ccpoUser.lastName,
      email: this.state.ccpoUser.email,
      photo: this.pictureStep.state.photoHash,
      phoneNumber: phoneNumber,
      orgID: this.state.userOrg?.id,
      companyLocation: this.state.ccpoUser.companyLocation,
      companyContact: this.state.ccpoUser.companyContact,
      companyContactPhone: this.state.ccpoUser.companyContactPhone,
    };
    delete ccpoUser.currentCertification;
    //Save partition on IDM
    const resp = await this.props.app.api.user.update(ccpoUser);
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('updated user with success');
    } else {
      console.log('ERROR while updating user');
      this.props.app.alertController.showErrorAlert('Error', JSON.stringify(resp));
      return false;
    }
    return true;
  }
  async clearOldImages() {
    for (let oldHash of this.pictureStep.state.oldHashes) {
      await this.props.app.api.userFile.deleteImage(oldHash, this.state.ccpoUser.id);
      console.log('deleting file', oldHash);
    }
  }
}
