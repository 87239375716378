import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { format } from 'date-fns';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Divider, Button, Badge, Popover, Descriptions, Dropdown, Menu, Icon } from 'antd';
//
import IconsTimeline from './IconsTimeline';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';

export default class CommonCertificationTimelineEventPopover extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  render() {
    const event = this.props.event;
    if (this._needsPopover(event)) {
      return (
        <Popover content={this._renderPopoverContent(event)} placement={this.props.index % 2 ? 'right' : 'left'}
                 className='timelineInformationalPopover' arrowPointAtCenter
                 title={this._renderPopoverTitle(event)}>
          {this.props.children}
        </Popover>
      );
    } else return this.props.children;
  }

  /* popover support */
  _needsPopover(event) {
    if (event.type == Globals.Event_Type.PAYMENT &&
        event.metadata.object && event.metadata.object.examID) {
      return true;
    } else if (event.type == Globals.Event_Type.EXAM && event.metadata.object &&
              (event.state == Globals.Event_State.FAILED || event.state == Globals.Event_State.COMPLETED || event.state == Globals.Event_State.WAITING)) {
      return true;
    } return false;
  }
  _renderPopoverTitle(event) {
    if (event.type == Globals.Event_Type.PAYMENT &&
        event.metadata.object && event.metadata.object.examID) {
      let type = (event.metadata.object.type == Globals.API_FinancialTransactionType_Refund ? 'Refund' : 'Payment');
      if (event.metadata.object.type == Globals.API_FinancialTransactionType_Reschedule) type = 'Reschedule';
      return `${type} information`;
    } else if (event.type == Globals.Event_Type.EXAM && event.metadata.object &&
              (event.state == Globals.Event_State.FAILED || event.state == Globals.Event_State.COMPLETED)) {
      return 'Exam Result';
    } else if (event.type == Globals.Event_Type.EXAM && event.metadata.object && event.state == Globals.Event_State.WAITING) {
      return 'Partial Exam Result';
    } return '';
  }
  _renderPopoverContent(event) {
    if (event.type == Globals.Event_Type.PAYMENT &&
        event.metadata.object && event.metadata.object.examID) { //make sure it's a valid exam object
      const object = event.metadata.object;
      let badgeStatus = 'processing';
      let type = (object.type == Globals.API_FinancialTransactionType_Refund ? 'Refund' : 'Payment');
      if (object.type == Globals.API_FinancialTransactionType_Reschedule) type = 'Reschedule';
      //
      if (event.state == Globals.Event_State.FAILED || event.state == Globals.Event_State.CANCELLED) badgeStatus = 'error';
      else if (event.state == Globals.Event_State.READY || event.state == Globals.Event_State.COMPLETED) badgeStatus = 'success';
      //
      return (
        <div className='timelineInformationalPopoverContent'>
          {this._renderPopoverAction(event)}
          <Descriptions size='small' className='timelineInformationalPopoverContent' column={4}>
            <Descriptions.Item label="State" span={2}>
              <Badge status={badgeStatus} text={Utils.capitalizeString(object.state.toLowerCase())}/>
            </Descriptions.Item>
            <Descriptions.Item label="Transaction Identifier" span={2}>{object.financialTransactionID || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label={`${type} Value`} span={2}>${Utils.toCurrencyFormat(object.transactionValue)}</Descriptions.Item>
            <Descriptions.Item label={`${type} Tax`} span={2}>${Utils.toCurrencyFormat(object.taxValue)}</Descriptions.Item>
            <Descriptions.Item label="Total" span={2}>${Utils.toCurrencyFormat(object.totalValue)}</Descriptions.Item>
            <Descriptions.Item label="Provider Identifier" span={2}>{(object.financialTransactionID ? object.providerID : 'N/A')}</Descriptions.Item>
            <Descriptions.Item label="Created On" span={2}>{Utils.getDateAndTimeOnUIFormatByTimestamp(object.createdOn)}</Descriptions.Item>
            <Descriptions.Item label="Updated On" span={2}>{Utils.getDateAndTimeOnUIFormatByTimestamp(object.updatedOn)}</Descriptions.Item>
          </Descriptions>
        </div>
      );
    } else if (event.type == Globals.Event_Type.EXAM && event.metadata.object &&
              (event.state == Globals.Event_State.FAILED || event.state == Globals.Event_State.COMPLETED ||
              event.state == Globals.Event_State.WAITING)) {
      const object = event.metadata.object;
      let badgeStatus = 'success';
      let readState = object.state;
      if (event.state == Globals.Event_State.FAILED) badgeStatus = 'error';
      else if (event.state == Globals.Event_State.WAITING) {
        badgeStatus = 'warning';
        readState = 'Waiting Approval';
      }
      //
      const isPractical = (event.metadata.object.examSpecs.id.includes('Practical'));
      const examSpecs = this.props.app.sharedCache().getExamByID(object.examID);
      //
      return (
        <div className='timelineInformationalPopoverContent'>
          {this._renderPopoverAction(event)}
          <Descriptions size='small' column={4}>
            <Descriptions.Item label="State" span={2}>
              <Badge status={badgeStatus} text={Utils.capitalizeString(readState.toLowerCase())}/>
            </Descriptions.Item>
            <Descriptions.Item label="Exam" span={2}>{examSpecs.description}</Descriptions.Item>
            <Descriptions.Item label={`Grade`} span={2}>{object.grade + (isPractical ? '%' : '')}</Descriptions.Item>
            <Descriptions.Item label={`No show`} span={2}>{object.noShow ? 'Yes' : 'No'}</Descriptions.Item>
            {isPractical && <Descriptions.Item label={`Suspended`} span={2}>{object && object.examReport && object.examReport.suspended ? 'Yes' : 'No'}</Descriptions.Item>}
            {isPractical && <Descriptions.Item label={`Stopped`} span={2}>{object && object.examReport && object.examReport.stopped ? 'Yes' : 'No'}</Descriptions.Item>}
            <Descriptions.Item label="Type" span={2}>{Utils.capitalizeString(object.type.toLowerCase())}</Descriptions.Item>
            <Descriptions.Item label="Scheduled To" span={2}>{Utils.getDateAndTimeOnUIFormatByTimestamp(object.examDate)}</Descriptions.Item>
            <Descriptions.Item label="Created On" span={2}>{Utils.getDateAndTimeOnUIFormatByTimestamp(object.createdOn)}</Descriptions.Item>
            <Descriptions.Item label="Updated On" span={2}>{Utils.getDateAndTimeOnUIFormatByTimestamp(object.updatedOn)}</Descriptions.Item>
            {object.overwriteJustification && <Descriptions.Item label="Result Comments" span={2}>{object.overwriteJustification}</Descriptions.Item>}
          </Descriptions>
        </div>
      );
      //missing fullDay, examID, virtual, attachments
    } return <></>;
  }
  _renderPopoverAction(event) {
    if (event.type == Globals.Event_Type.EXAM && event.metadata.object && 
        (event.state == Globals.Event_State.WAITING || event.state == Globals.Event_State.FAILED || event.state == Globals.Event_State.COMPLETED)) {
        const isPractical = (event.metadata.object.examSpecs.id.includes('Practical'));
        if (isPractical) {
          return (
            <div className='timelineInformationalPopoverContentAction'>
              <Button type="link" onClick={this.props.onExamPracticalResult.bind(this, event)} ghost>See more</Button>
            </div>
          );
        }
    } else if ((event.type == Globals.Event_Type.PAYMENT || event.type == Globals.Event_Type.RES_PAYMENT) && 
                event.metadata.object && event.state == Globals.Event_State.COMPLETED && 
                (event.metadata.object.state == Globals.FinancialTransaction_State.COMPLETED || event.metadata.object.state == Globals.FinancialTransaction_State.DECLINED) &&
                event.metadata.object.totalValue != 0) {
        return (
          <div className='timelineInformationalPopoverContentAction'>
            <Button type="link" onClick={this.props.onReceiptDownload.bind(this, event)} ghost> <Icon type='download'/> Download Receipt</Button>
          </div>
        );
    } return <></>;
  }
}
