import APIRequest from './APIRequest';
// API Compoenents
import APIAdvisor from './operations/APIAdvisor';
import APIAudit from './operations/APIAudit';
import APICertification from './operations/APICertification';
import APIExam from './operations/APIExam';
import APIFinancialTransaction from './operations/APIFinancialTransaction';
import APIStatusReport from './operations/APIStatusReport';
import APITenant from './operations/APITenant';
import APIUser from './operations/APIUser';
import APIUserFile from './operations/APIUserFile';
import APIUserWorkHours from './operations/APIUserWorkHours';
import APIVault from './operations/APIVault';
//
import config from '../../config/config';
//
export default class API {
  constructor(idm) {
    this.idm = idm;
    //api operations
    this.advisor = new APIAdvisor(this);
    this.audit = new APIAudit(this);
    this.financialTransaction = new APIFinancialTransaction(this);
    this.statusReport = new APIStatusReport(this);
    this.tenant = new APITenant(this);
    this.user = new APIUser(this);
    this.userCertification = new APICertification(this);
    this.userExam = new APIExam(this);
    this.userFile = new APIUserFile(this);
    this.userWorkHours = new APIUserWorkHours(this);
    this.vault = new APIVault(this);
  }
  async newBaseRequest(method) {
    const req = new APIRequest(method);
    //Check for authorization token
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      req.appendHeader('Authorization', `Bearer ${token}`);
    }
    req.appendHeader('TenantID', config.ApplicationTenantID);
    //Base url
    req.baseURL = config.ApplicationAPIEndpoint;
    return req;
  }
}
