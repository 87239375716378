import React from 'react';
import autoBind from 'react-autobind';
//
import CustomComponent from '../../components/CustomComponent';
//
// import config from '../../config/config';
// import Globals from '../../config/Globals';
//
export default class AdminSettingsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //
    // console.log(this.props.app)
    // console.log(this.props.app.alertController.showErrorAlert('Error','Description!'));
    //
    // this.xpto = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    // console.log()
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  render() {
    return <>ADMIN-SETTINGS</>;
  }
}
