import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout, PageHeader, Row } from 'antd';
//import Utils from '../../components/Utils';
import CustomComponent from '../../components/CustomComponent';
import APIUtils from '../../components/API/APIUtils';
//
import CommonUserHeader from '../commonComponents/CommonUserHeader';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonCertificationProcess from '../commonComponents/CommonCertificationProcess';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/CommonDashboardStyles.scss';
import '../../assets/stylesheets/CommonCertificationProcess.scss';
//
const md5 = require('md5');

export default class UserCertificationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      personalPart: null,
      isLoading: false,
      firstLoad: true,
      certification: null,
      certSpecs: null,
      certificationEvents: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.certID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    //console.log(this.props.match.params)
    if (this.state.firstLoad) this.fetchData();
  }

  //Actions
  handleCertificationRenewal(event) {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    this._renewCertification(ccpoUser.id, this.state.certification.id);
  }
  handlePaymentCancellation(event) {
    const object = event.metadata.object;
    this._cancelFinancialPayment(object.id, object.examID, object.type);
  }
  handleUpdate() {
    console.log('Update requested');
    this.fetchData();
  }
  handleCheckExamResults(event) {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    this._checkExamResults(ccpoUser.id, this.state.certification.id, event.metadata.object.id);
  }
  handleCertificationWaive() {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    this._waiveCertification(ccpoUser.id, this.state.certification.id);
  }
  handleReceiptDownload(event) {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    this._downloadReceipt(ccpoUser.id, event.metadata.object.id);
  }

  async fetchData() {
    this.startLoading();
    const ccpoUser = this.props.app.sharedCache().getCCPOUser(); //user must come to logged users, no need to check it
    //Get personal part
    const personalPart = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    if (!personalPart) {
      this.setState({ certification: null, isLoading: false, firstLoad: false, personalPart: null, certificationEvents: null, certSpecs: null });
      this.props.app.alertController.showErrorAlert(null, Globals.ERROR_MESSAGE_DEFAULT);
      return;
    }
    //get cert
    const userCertResp = await this.props.app.api.userCertification.getByUserIDAndCertID(ccpoUser.id, this.certID);
    if (userCertResp.statusCode != 200 || !userCertResp.body) {
      this.setState({ certification: null, isLoading: false, firstLoad: false, personalPart: null, certificationEvents: null, certSpecs: null });
      this.props.app.alertController.showAPIErrorAlert(null, userCertResp);
      return;
    }
    //Generate certification events
    let certificationEvents = APIUtils.parseCertificationProcessToEvents(userCertResp.body, this.props.app);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(userCertResp.body.certificationID);
    this.setState({ certification: userCertResp.body, certSpecs: certSpecs, isLoading: false, firstLoad: false, personalPart, certificationEvents });
  }
  //
  render() {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser(); //user must come to logged users, no need to check it
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading}/>
          <PageHeader className="pageHeaderNoHeight" title=" "
                      onBack={() => this.props.app.pushPage(config.ApplicationRoutes.homepage)}/>
          <Layout.Content>
            {(this.state.certification && this.state.certificationEvents && this.state.personalPart) && (
              <div>
                <Layout>
                  <Layout>
                    <Row type="flex" justify="end">
                      <CommonUserHeader app={this.props.app} ccpoUser={ccpoUser}
                                        personalPart={this.state.personalPart} justCard
                                        isLoading={this.state.isLoading} datasource={this}/>
                    </Row>
                    <Layout.Content id="certificationContent">
                      <div className="header-container">
                        <div className="header-certification">
                          Certification Progress: {this.state.certSpecs.description}
                        </div>
                      </div>
                      <CommonCertificationProcess
                        handlePaymentCancellation={this.handlePaymentCancellation}
                        handleUpdate={this.handleUpdate}
                        handleReceiptDownload={this.handleReceiptDownload}
                        handleCheckExamResults={this.handleCheckExamResults}
                        handleCertificationRenewal={this.handleCertificationRenewal}
                        handleCertificationWaive={this.handleCertificationWaive}
                        ccpoUser={ccpoUser}
                        specialNeeds={(this.state.personalPart.value ? this.state.personalPart.value.specialNeeds : null)}
                        app={this.props.app} isLoading={this.state.isLoading}
                        events={this.state.certificationEvents}
                        certification={this.state.certification}/>
                    </Layout.Content>
                  </Layout>
                </Layout>
              </div>
            )}
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private */
  async _cancelFinancialPayment(ftID, examID, ftType) {
    this.startLoading();
    const ccpoUser = this.props.app.sharedCache().getCCPOUser(); //user must come to logged users, no need to check it
    const paymentResp = (ftType == Globals.API_FinancialTransactionType_Reschedule ?
                        await this.props.app.api.userExam.cancelReschedule(ccpoUser.id,
                              this.state.certification.id, examID, { financialTransactionID: ftID })
                      : await this.props.app.api.userCertification.cancelPayment(ccpoUser.id,
                              this.state.certification.id, examID, { financialTransactionID: ftID }));
    if (!this._isMounted) return;
    console.log('Transaction cancellation resp: ', paymentResp, ftID, examID);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.warning('Transaction Cancelled!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
      this.stopLoading();
    }
  }
  async _checkExamResults(userID, certID, examID) {
    this.startLoading();
    const resp = await this.props.app.api.userExam.checkWrittenResult(userID, certID, examID);
    if (resp.statusCode == 200) {
      message.success('Result retrieved with success!');
      this.handleUpdate();
    } else message.warning('Result is not available yet!');
    this.stopLoading();
  }
  async _renewCertification(userID, certID) {
    this.startLoading();
    const renewalResp = await this.props.app.api.userCertification.renewCertificationByUserIDAndCertID(userID, certID);
    if (!this._isMounted) return;
    console.log('Certification renewal resp: ', renewalResp);
    if (renewalResp.statusCode == 200 && renewalResp.body) {
      message.warning('Certification renewal started!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, renewalResp);
      this.stopLoading();
    }
  }
  async _waiveCertification(userID, certID) {
    this.startLoading();
    const waiveResp = await this.props.app.api.userCertification.requestWaive(userID, certID);
    if (!this._isMounted) return;
    console.log('Certification waive resp: ', waiveResp);
    if (waiveResp.statusCode == 200 && waiveResp.body) {
      message.success('Waive requested with success!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, waiveResp);
      this.stopLoading();
    }
  }
  async _downloadReceipt(userID, txID) {
    this.startLoading();
    const resp = await this.props.app.api.financialTransaction.getTransactionReceipt(userID, txID);
    if (!this._isMounted) return;
    console.log('Receipt resp: ', resp);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${md5(txID)}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.stopLoading();
  }
}
