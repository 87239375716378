import Globals from '../../../config/Globals';
import APIRequest from '../APIRequest';
import Utils from '../../Utils';
//
const md5 = require('md5');
//
export default class APIUserFile {
  constructor(API) {
    this.api = API;
  }
  async getImage(hashedFile, userID) {
		//request signed url
    const signedURLResp = await this.signedGetURL(userID, hashedFile);
    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) return signedURLResp;
    const signedURL = signedURLResp.body.fileURL;
    //resp content
    return await this._getRemoteFile(signedURL);
	}
  async uploadImage(file, hashedFile, userID, fileDescription) {
    fileDescription = encodeURIComponent(fileDescription);
    //check for file max size
    if (file.size > Globals.API_MaxAllowedFileSize) return {error: 'File is too big! Max allowed size is 2.5mb.', statusCode: -1};
    console.debug("encoded file name " + hashedFile);
    //request signed url
    const signedURLResp = await this._signedPutURL(userID, hashedFile, fileDescription);
    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) return signedURLResp;
    const signedURL = signedURLResp.body.fileURL;
    //upload content
    return await this._uploadRemoteFile(file, userID, fileDescription, signedURL);
  }
  async deleteImage(hashedFile, userID) {
    //request signed url
    const signedURLResp = await this._signedDeleteURL(userID, hashedFile);
    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) return signedURLResp;
    const signedURL = signedURLResp.body.fileURL;
    //resp content
    return await this._deleteRemoteFile(signedURL);
  }
/** private **/
  /** URL Signing **/
  async signedGetURL(userID, fileName) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_File + fileName;
    return await req.exec();
  }
  async _signedPutURL(userID, fileName, fileDescription) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_File + fileName;
    req.body = { description: fileDescription }
    return await req.exec();
  }
  async _signedDeleteURL(userID, fileName) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_File + fileName;
    return await req.exec();
  }
  /** S3 ops **/
	async _getRemoteFile(url) {
    const request = new APIRequest('GET');
    request.baseURL = url;
    request.bodyType = 'BINARY';
    return await request.exec();
	}
	async _deleteRemoteFile(url) {
    const request = new APIRequest('DELETE');
    request.baseURL = url;
    return await request.exec();
	}
	async _uploadRemoteFile(file, userID, fileDescription, url) {
    const request = new APIRequest('PUT');
    request.baseURL = url;
    request.appendHeader('Content-type', file.type);
    request.appendHeader('Content-length', file.file.length);
    request.appendHeader('x-amz-tagging', `ik-userid=${userID}&ik-description=${fileDescription}`);
    //
    // console.log(Utils.dataURItoBlob(file.file))
    request.body = Utils.dataURItoBlob(file.file);
    request.bodyType = 'BINARY';
    return await request.exec();
	}
  //Utils
  encodedFileName(userID, filename) {
    return md5(userID + filename + Date.now());
  }
}
