import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message, Modal, Checkbox, Popover, Icon } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonExamPracticalFormExtension from './CommonExamPracticalFormExtension';
import AsyncImage from '../commonComponents/AsyncImage';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonExamResultModal.scss';
import html2pdf from 'html2pdf.js';
//
const { Option } = Select;
const { TextArea } = Input;
//
class CommonExamPracticalResultModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      isLoading: false, currentExamSpecs: null, currentExam: null, noShow: false /*keep elements disabled*/, 
      questionsAnswers: null, currentExamQuestions: null, certSpecs: null,
      overridePass: null, overrideReason: null
    };
  }
  //
  async loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const examQuestions = this.props.app.sharedCache().getExamQuestionsByID(examObj.examID);
    const fieldsValue = await this._getInitialFieldsValue(certSpecs, examSpecs, examObj);
    const questionsAnswers = this._getInitialAnswers(examObj);
    //Set interesting ivars
    this.setState({
      currentExamSpecs: examSpecs, currentExam: examObj, currentExamQuestions: examQuestions, questionsAnswers: questionsAnswers, certSpecs: certSpecs,
      isLoading: false, noShow: examObj.noShow,
      stopped: (examObj && examObj.examReport ? examObj.examReport.stopped : false),
      overridePass: (examObj.examReport && examObj.examReport.overriden ? examObj.examReport.pass : null),
      overrideReason: null,
      suspended: (examObj && examObj.examReport ? examObj.examReport.suspended : false)}, () => {
      this.props.form.setFieldsValue(fieldsValue);
      //Scroll to top!
      setTimeout(()=>{
        document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 0);
      }, 100);
    });
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleSaveAsPDF() {
    const element = document.getElementsByClassName('examResultModalForm')[0];
    const opt = {
      margin: 5,
      filename: 'result.pdf',
      pagebreak: { mode: 'avoid-all' },
    };
    html2pdf().set(opt).from(element).save();
  }
  handleAfterClose() {
    this.setState({ isLoading: false, noShow: false, currentExamSpecs: null, currentExam: null, certSpecs: null, overridePass: null, overrideReason: null});
    this.props.form.resetFields();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields();
    if (resp) this._approveExamResult(resp);
  };
  //form change
  handleChange(event, id) {
    const value = event.target.checked;
    //Check for the amount to be set
    if (id == 'noShow') {
      if (value) this.props.form.resetFields(['pass']);
    } this.setState({[id]: value});
  };
  handleChangeSelect(value, id) {
    this.setState({[id]: value});
  };
  //UI
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = (this.state.certSpecs ? this.state.certSpecs.description + ' - ' + examType + ' Exam Result' : '');
    const readMode = true; //always read mode

    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null} className='examResultModal'>
        {this._renderResultForm(readMode)}
        {this._renderButtonsFooter(readMode)}
      </Modal>
    );
  }
  /* subforms */
  _renderResultForm(readMode) {
    const { getFieldDecorator } = this.props.form;
    const attachments = this._getAttachments(this.state.currentExam, this.state.currentExamQuestions);
    const approved = (readMode && this.state.currentExam && this.state.currentExam.state != Globals.Exam_State.PENDING_RES_APPROVAL);
    return (
      <Form key='result' className='examResultModalForm'>
        <Divider orientation="left">{'Exam Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {getFieldDecorator(`certificationDescription`)(<Input disabled id="certificationDescription" name="certificationDescription" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label={'Scheduled Date'}>
              {getFieldDecorator(`examDate`)(<Input disabled id="examDate" name="examDate" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={14} offset={1}>
            <Form.Item label={'Assessor Name'}>
              {getFieldDecorator(`assessorName`, { rules:[{required: (!readMode), message: 'Assessor name is required!'}]})(<Input id="assessorName" disabled={readMode} name="assessorName" />)}
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">{'Candidate Information'}</Divider>
        <Row type="flex" justify="center">
          <Col span={8}>
            <Form.Item label='Make Model'>
              {getFieldDecorator(`makeModel`, { rules:[{required: false, message: 'Make model is required!'}]})(<Input id="makeModel" name="makeModel" disabled={readMode}/>)}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={'Boom length'}>
              {getFieldDecorator(`boomLength`, { rules:[{required: false, message: 'Boom length is required!'}]})(<Input id="boomLength" name="boomLength" disabled={readMode}/>)}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={'Type Number'}>
              {getFieldDecorator(`typeNumber`, { rules:[{required: false, message: 'Type Number is required!'}]})(<Input id="typeNumber" name="typeNumber" disabled={readMode}/>)}
            </Form.Item>
          </Col>
        </Row>
        {CommonExamPracticalFormExtension.renderInformationByType('inspection', getFieldDecorator, readMode)}
        {CommonExamPracticalFormExtension.renderInformationByType('operations', getFieldDecorator, readMode)}
        {CommonExamPracticalFormExtension.renderInformationByType('washout', getFieldDecorator, readMode)}
        <Divider orientation="left">{'Exam'}</Divider>
        {CommonExamPracticalFormExtension.renderQuestions(this.state.currentExamQuestions, this.state.questionsAnswers, getFieldDecorator, readMode, this.state)}
        <Divider orientation="left">{'Attachments'}</Divider>
        <Row type="flex">
          {attachments.map( (file, index) => (
            <Col offset={!index ? 2 : 1} key={index}>
              <AsyncImage fileName={file} size={60} app={this.props.app} userID={this.state.currentExam.userID}/>
            </Col>
          ))}
          {(!attachments || attachments.length == 0) && <Col offset={10}>No attachments submitted!</Col> }
        </Row>
        <Divider orientation="left">{'Result'}</Divider>
        <Row type="flex" justify="center">
          <Col span={4}>
            <Form.Item label={'No Show'}>
              {getFieldDecorator(`noShow`, { initialValue: false, valuePropName: 'checked'  })
              (<Checkbox disabled={readMode} className='fixedInputCheckbox' onChange={ (val) => this.handleChange(val, 'noShow')}/>)}
            </Form.Item>
          </Col><Col span={4} offset={4}>
            <Form.Item label={'Stopped'}>
              {getFieldDecorator(`stopped`, { initialValue: false, valuePropName: 'checked' })
              (<Checkbox disabled={this.state.noShow || readMode} className='fixedInputCheckbox' onChange={ (val) => this.handleChange(val, 'stopped')}/>)}
            </Form.Item>
          </Col><Col span={4} offset={4}>
            <Form.Item label={'Suspended'}>
              {getFieldDecorator(`suspended`, { initialValue: false, valuePropName: 'checked' })
              (<Checkbox disabled={this.state.noShow || readMode} className='fixedInputCheckbox' onChange={ (val) => this.handleChange(val, 'suspended')}/>)}
            </Form.Item>
          </Col>
        </Row>
        {(this.state.suspended || this.state.stopped) &&
          <Row>
            <Col span={10} offset={2}>
              <Form.Item label={(this.state.suspended ? 'Suspension Reason' : 'Stop Reason')}>
                {getFieldDecorator(`reason`, { rules:[{required: (!readMode), message: 'Reason is required when stopping or suspending an exam!'}]})(<Input  disabled={readMode} id="reason" name="reason" />)}
              </Form.Item>
            </Col>
          </Row>}
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: false }],
                })(<TextArea disabled={readMode} rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">{'Override'}</Divider>
          <Row>
            <Col span={8} offset={1}>
              <Form.Item label={`Override exam result to:`}>
                {getFieldDecorator('overridePass', { initialValue: this.state.overridePass })(
                  <Select onChange={ (val) => this.handleChangeSelect(val, 'overridePass')} disabled={approved}>
                    <Select.Option value={null}>No override</Select.Option>
                    <Select.Option value={true}>Pass</Select.Option>
                    <Select.Option value={false}>Fail</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            {(this.state.overridePass) && <Col span={12} offset={2}>
              <Form.Item label={'Override Reason'}>
                {getFieldDecorator(`overrideReason`, { rules:[{required: (!approved), message: 'Reason is required when overriding an exam result!'}]})(<TextArea disabled={approved} id="overrideReason" name="overrideReason" rows={2}/>)}
              </Form.Item>
            </Col>}
          </Row>

      </Form>
    );
  }
  _renderButtonsFooter(readMode) {
    if (!this.props.isVisible) return <></>;
    const approved = (readMode && this.state.currentExam && this.state.currentExam.state != Globals.Exam_State.PENDING_RES_APPROVAL);
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading}
                onClick={this.handleSaveAsPDF}
                className='saveAsPDFButton'> Save as PDF </Button>
        <Button disabled={this.state.isLoading}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        {readMode && this.props.app.isAdmin() && !approved && <Button
          style={{ background: '#af3947', border: 0 }}
          key="submit" type="primary" loading={this.state.isLoading}
          onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
          Approve Result </Button>}
      </Row>
    )
  }

  /* private methods */
  async _approveExamResult(data) {
    this.setState({ isLoading: true });
    const currentCert = this.props.certification;
    const currentExam = this.state.currentExam;
    const resultResp = await this.props.app.api.userExam.approveResult(this.props.ccpoUser.id, currentCert.id, currentExam.id, null, {
      overridePass: this.state.overridePass, comments: data.overrideReason
    });
    if (!this._isMounted) return;
    console.debug('Practical exam approval resp:', resultResp, data);
    if (resultResp.statusCode == 200 && resultResp.body) {
      message.success('Practical exam approved!');
      this.props.onChange();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, resultResp);
    }
  }
  async _getInitialFieldsValue(certSpecs, examSpecs, examObj) {
    let user = await this.props.app.idm.session.data.getUserObject();
    let returnValue = {
      certificationDescription: certSpecs.description,
      examDescription: examSpecs.description,
      assessorName: `${user.firstName} ${user.lastName}`,
      examDate: Utils.getDateAndTimeOnUIFormatByTimestamp((examObj.examDate && examObj.examDate != -1 ? examObj.examDate : examObj.updatedOn))
    };
    if (examObj.examReport) {
      const r = examObj.examReport;
      const o = r.otherInfo;
      returnValue.comments = r.comments;
      returnValue.noShow = r.noShow;
      returnValue.assessorName = o.assessorName;
      returnValue.suspended = r.suspended;
      returnValue.stopped = r.stopped;
      returnValue.reason = o.reason;
      returnValue.makeModel = r.otherInfo.makeModel;
      returnValue.boomLength = o.boomLength;
      returnValue.typeNumber = o.typeNumber;
      //inspection
      const oi = o.inspection;
      if (oi) {
        returnValue.inspectionType = oi.type;
        if (oi.date) returnValue.inspectionDate = Utils.getDateOnMomentFormatByTimestamp(oi.date);
        if (oi.startTime) returnValue.inspectionStartTime = Utils.getDateOnMomentFormatByTimestamp(oi.startTime);
        if (oi.endTime) returnValue.inspectionEndTime = Utils.getDateOnMomentFormatByTimestamp(oi.endTime);
        returnValue.inspectionLocation = oi.location;
      }
      //operations
      const oo = o.operations;
      if (oo) {
        returnValue.operationsType = oo.type;
        if (oo.date) returnValue.operationsDate = Utils.getDateOnMomentFormatByTimestamp(oo.date);
        if (oo.startTime) returnValue.operationsStartTime = Utils.getDateOnMomentFormatByTimestamp(oo.startTime);
        if (oo.endTime) returnValue.operationsEndTime = Utils.getDateOnMomentFormatByTimestamp(oo.endTime);
        returnValue.operationsLocation = oo.location;
      }
      //Washout
      const ow = o.washout;
      if (ow) {
        returnValue.washoutType = ow.type;
        if (ow.date) returnValue.washoutDate = Utils.getDateOnMomentFormatByTimestamp(ow.date);
        if (ow.startTime) returnValue.washoutStartTime = Utils.getDateOnMomentFormatByTimestamp(ow.startTime);
        if (ow.endTime) returnValue.washoutEndTime = Utils.getDateOnMomentFormatByTimestamp(ow.endTime);
        returnValue.washoutLocation = ow.location;
      }
    } return returnValue;
  }
  _getInitialAnswers(examObj) {
    if (examObj && examObj.examReport && examObj.examReport.questions) {
      let returnValue = {};
      for (let question of examObj.examReport.questions) returnValue[question.id] = question.grade;
      return returnValue;
    } return {};
  }
  _getAttachments(examObj, examQuestions) {
    if (examObj && examQuestions && examObj.examReport && examObj.examReport.otherInfo) {
      let returnValue = [];
      const key = `savedFiles`;
      const sectionFiles = examObj.examReport.otherInfo[key];
      if (sectionFiles) { returnValue = returnValue.concat(sectionFiles); }
      return returnValue;
    } return [];
  }
}

export default Form.create({})(CommonExamPracticalResultModal);
