import React from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from './components/CustomRoute';

// Common
// import ErrorView from './views/ErrorView';
import CommonUserProfileForm from './views/commonSubviews/CommonUserProfileView';
import CommonBillingView from './views/commonSubviews/CommonBillingView';
import CommonWorkHoursView from './views/commonSubviews/CommonWorkHoursView';
// User
import UserDashboardView from './views/userSubviews/UserDashboardView';
import UserCertificationView from './views/userSubviews/UserCertificationView';
// Advisor
import AdvisorDashboardView from './views/advisorSubviews/AdvisorDashboardView';
// Admin
import AdminDashboardView from './views/adminSubviews/AdminDashboardView';
import AdminSettingsView from './views/adminSubviews/AdminSettingsView';
import AdminReportsView from './views/adminSubviews/AdminReportsView';
import AdminUserDashboardView from './views/adminSubviews/AdminUserDashboardView';
import AdminSearchUsersView from './views/adminSubviews/AdminSearchUsersView';
import AdminPendingWaiveRequestsView from './views/adminSubviews/AdminPendingWaiveRequestsView';
import AdminUserCertificationView from './views/adminSubviews/AdminUserCertificationView';
import AdminUsersImportView from './views/adminSubviews/AdminUsersImportView';
import AdminStatusReportView from './views/adminSubviews/AdminStatusReportView';
// Auth
import CommonLoginView from './views/authSubviews/CommonLoginView';
//
import config from './config/config';

export default class AppRoutes extends React.Component {
  _renderPrivilegedRoutes() {
    // not logged? - default landing page
    if (!this.props.appRef.props.app.idm.isLogged() || this.props.appRef.props.app.isAuthenticating) {
      // Login page will eventually be here
      return (
        <Switch>
          <CustomRoute component={CommonLoginView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    if (this.props.appRef.props.app.isAdmin()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact
                       component={AdminDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.usersImport} exact
                       component={AdminUsersImportView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.userSearch} exact
                       component={AdminSearchUsersView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.pendingWaiveRequests} exact
                       component={AdminPendingWaiveRequestsView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.userCertification} exact
                       component={AdminUserCertificationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.userDashboard} exact
                       component={AdminUserDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact
                       component={CommonUserProfileForm} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.userProfile} exact
                       component={CommonUserProfileForm} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.userWorkHours} exact
                       component={CommonWorkHoursView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.reports} exact
                       component={AdminReportsView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.settings} exact
                       component={AdminSettingsView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.statusReport} exact
                       component={AdminStatusReportView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.adminBillingView} exact 
                       component={CommonBillingView} appRef={this.props.appRef}/>
          <CustomRoute component={AdminDashboardView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    //
    if (this.props.appRef.props.app.isAdvisor()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact
                       component={AdvisorDashboardView} appRef={this.props.appRef}/>
          <CustomRoute component={AdvisorDashboardView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    if (this.props.appRef.props.app.isUser()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact
                       component={UserDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.certification}
                       component={UserCertificationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact
                       component={CommonUserProfileForm} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.workHours} exact
                       component={CommonWorkHoursView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.simpleBillingView} exact 
                       component={CommonBillingView} appRef={this.props.appRef}/>
          <CustomRoute component={UserDashboardView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    return <Switch />;
  }

  render() {
    return this._renderPrivilegedRoutes();
  }
}
