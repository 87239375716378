import React from 'react';
import { GridLoader } from 'react-spinners';
import '../../assets/stylesheets/CommonLoadingView.css';
import ReactDOM from 'react-dom';
//

export default function CommonLoadingView({ isLoading, isFixed }) {
  const loadingClass = `${isLoading ? '' : 'hide '}loading${isFixed != undefined ? ' loadingLogin' : ''}`;
  return (
    <div className={loadingClass}>
      <div className='loadingWheel'>
        <GridLoader sizeUnit="px" size={20} color='#af3947'
                    loading={isLoading}/>
      </div>
    </div>
  );
}
