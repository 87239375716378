//Polyfills
import "./polyfills";
// Log rocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// Compoenents
import App from './app/App';
import config from "./app/config/config";
//
import './app/assets/stylesheets/index.scss';
import 'antd/dist/antd.less';

if (!process.env.REACT_APP_OFFLINE) {
  //Log rocket
  LogRocket.init(config.LogRocketOptions.applicationId);
  setupLogRocketReact(LogRocket);
}
// Render router with App
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
