import Globals from '../../../config/Globals';
//
import APIRequest from '../APIRequest';
//
export default class APIExam {
  constructor(API) {
    this.api = API;
  }
  // Results
  async addResult(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_AddExamResult + examID;
    req.body = { ...content };
    return await req.exec();
  }
  async checkWrittenResult(userID, certID, examID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_CheckWrittenExamResult + examID;
    return await req.exec();
  }
  async addPracticalResult(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_AddExamPracticalResult + examID;
    req.body = { ...content };
    return await req.exec();
  }
  async approveResult(userID, certID, examID, optionalExpDate, optionalOverride) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_ApprovePracticalExam + examID;
    if (optionalExpDate) req.appendQueryParam('oExpDate',optionalExpDate);
    req.body = optionalOverride || {};
    return await req.exec();
  }
  // Cooldown
  async forceEndCooldown(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_EndCooldown + examID;
    req.body = { ...content };
    return await req.exec();
  }
  // Schedule
  async schedule(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_ScheduleExam + examID;
    req.body = { ...content };
    return await req.exec();
  }
  // Reschedule Practical
  async reschedule(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_Reschedule + examID + Globals.APIPath_ReschedulePractical;
    req.body = { ...content };
    return await req.exec();
  }
  // Reschedule - payment
  async beginReschedule(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_Reschedule + examID + Globals.APIPath_PaymentBegin;
    req.body = { ...content };
    return await req.exec();
  }
  async completeReschedule(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_Reschedule + examID + Globals.APIPath_PaymentComplete;
    req.body = { ...content };
    return await req.exec();
  }
  async cancelReschedule(userID, certID, examID, content) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_Certification + certID + Globals.APIPath_Reschedule + examID + Globals.APIPath_PaymentCancellation;
    req.body = { ...content };
    return await req.exec();
  }
}
