import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message, Modal, Checkbox } from 'antd';
import moment from 'moment';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonCooldownEndModal.scss';
//
const { Option } = Select;
const { TextArea } = Input;
//
class CommonCooldownEndModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, currentExamSpecs: null, currentExam: null, certSpecs: null };
  }
  //
  loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    const cooldownEndDate = new Date(examObj.examResultDate);
    cooldownEndDate.setDate(cooldownEndDate.getDate() + examSpecs.coolDownPeriod);
    //
    this.setState({currentExamSpecs: examSpecs, currentExam: examObj, isLoading: false, certSpecs: certSpecs }, () => {
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        examDescription: examSpecs.description,
        scheduledTo: moment(new Date(examObj.examDate), Globals.DefaultUIDateTimeFormat),
        resultDate: moment(new Date(examObj.examResultDate), Globals.DefaultUIDateTimeFormat),
        cooldownEndDate: moment(new Date(cooldownEndDate), Globals.DefaultUIDateTimeFormat),
        comments: ''
      });
    });
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleAfterClose() {
    this.setState({ isLoading: false, currentExamSpecs: null, currentExam: null, certSpecs: null});
    this.props.form.resetFields();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields(['comments']);
    if (resp) this._forceEndCooldown(resp)
  };
  //UI
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = (this.state.certSpecs ? this.state.certSpecs.description + ' - ' + examType + ' End Cooldown' : '');
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null} className='examEndCooldownModal'>
        {this._renderEndCooldownForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderEndCooldownForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key='result' className='examEndCooldownForm'>
        <Divider orientation="left">{'Exam Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`)(<Input disabled id="certificationDescription" name="certificationDescription" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Divider orientation="left">{'Schedule Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={6}>
            <Form.Item label={'Scheduled date'}>
              {this.props.form.getFieldDecorator(`scheduledTo`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker disabled showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label={'Result date'}>
              {this.props.form.getFieldDecorator(`resultDate`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker disabled showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label={'Cooldown end date'}>
              {this.props.form.getFieldDecorator(`cooldownEndDate`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker disabled showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat}/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
              {getFieldDecorator('comments', {
                rules: [{ required: true, message: 'Please, justify why the you are overwritting the system cooldown.' }],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
                Submit </Button>
      </Row>
    )
  }

  /* private methods */
  async _forceEndCooldown(data) {
    this.setState({ isLoading: true });
    const currentCert = this.props.certification;
    const currentExam = this.state.currentExam;
    const cooldownResp = await this.props.app.api.userExam.forceEndCooldown(this.props.ccpoUser.id, currentCert.id, currentExam.id, {
      'comments': data.comments
    });
    if (!this._isMounted) return;
    console.debug('Exam end cooldown resp:', cooldownResp, data);
    if (cooldownResp.statusCode == 200 && cooldownResp.body) {
      message.success('Exam cooldown ended!');
      this.props.onChange();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, cooldownResp);
    }
  }
}

export default Form.create({})(CommonCooldownEndModal);
