import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Button, Checkbox, message, Modal } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
// import Utils from '../../components/Utils';
// import Globals from '../../config/Globals';
//
//import config from '../../config/config';
import '../../assets/stylesheets/CommonContactModal.scss';
//props handleSubmissionComplete, handleCancel
class CommonSetPasswordModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, userID: null };
  }
  loadInfo(userID) { this.setState({userID}); }
  //Actions
  handleCancel() {
    this.props.handleCancel();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields();
    if (resp) {
      if (resp.confirmationPassword != resp.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!","Passwords don't match!");
        return;
      }
      this._submitSetPassword(resp);
    }
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} closable={true}
        title="Set new password" footer={null}
        className="contactUsModal"
        visible={this.props.isVisible}
        confirmLoading={this.state.isLoading}>
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subcomponents */
  _renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key="contactUs" className="contactUsForm">
        <Row type="flex" justify="center">
          <Col span={20}>
            <Form.Item label="New password">
              {this.props.form.getFieldDecorator(`newPassword`, {
                rules: [{ required: true, message: 'Please, type a new password!' }],
              })(<Input.Password id="newPassword" name="newPassword" allowClear data-dd-privacy="mask" />)}
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item label="Permanent">
              {this.props.form.getFieldDecorator(`isPermanent`, {})(<Checkbox id="isPermanent" name="isPermanent" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start">
        <Col span={22}>
          <Form.Item label="Confirm new password">
            {this.props.form.getFieldDecorator(`confirmationPassword`, {
              rules: [{ required: true, message: 'Please, type a new password again!' }],
            })(<Input.Password id="confirmationPassword" name="confirmationPassword" allowClear data-dd-privacy="mask" />)}
          </Form.Item>
        </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary"
          loading={this.state.isLoading} onClick={this.handleSubmit} disabled={this.state.isLoading} className="contactUsSubmitButton"> Set password </Button>
      </Row>
    );
  }
  /* private methods */
  async _submitSetPassword(data) {
    this.setState({ isLoading: true });
    const changeResp = await this.props.app.idm.api.password.update({
      newPassword: data.newPassword, isPermanent: !!data.isPermanent
    }, this.state.userID);
    if (!this._isMounted) return;
    console.debug('Set new pass resp:', changeResp, data);
    if (changeResp.statusCode == 200) {
      this.setState({ isLoading: false });
      message.success('New password was set with success!');
      this.props.form.resetFields();
      this.props.handleSubmissionComplete();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, changeResp);
    }
  }
}

export default Form.create({})(CommonSetPasswordModal);
