import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//Components
import { Form, Input, Card, Row, Col, Divider, Select, DatePicker, Button, Upload, Icon, message } from 'antd';
//
import Globals from '../../config/Globals';
import config from '../../config/config';
import Utils from '../../components/Utils';
//resources
import '../../assets/stylesheets/CommonUserProfileView.css';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView'
import CommonOrganizationSelectionInput from "../commonComponents/CommonOrganizationSelectionInput";
//
export default class CommonUserProfileForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, hasCheckedForDriverLicensePicture: false, hasUploadedDriverLicense: false, selectedOrg: null };
  }

  async componentDidUpdate() {
    if (!this.state.hasCheckedForDriverLicensePicture && this.props.datasource.state?.ccpoPart?.userID) {
      if (!this.state.isLoading) this.setState({ isLoading: true });

      const state = { hasCheckedForDriverLicensePicture: true, hasUploadedDriverLicense: false, isLoading: false, selectedOrg: this.state.selectedOrg || this.props.datasource.state?.userOrg };

      const resp = await this._downloadDriverLicenseFile();
      if (resp.statusCode == 200) {
        state.hasUploadedDriverLicense = true;
      }

      this.setState(state);
    }
  }

  async validateFields() {
    return this.props.form.validateFields((err, values) => {
      if (err) return;
      console.log('values from form profile', values, this.state.selectedOrg)
      //convert required values!
      values.personalPart.value.dateOfBirth = moment(values.personalPart.value.dateOfBirth).format(
        Globals.DefaultUIBirthDateFormat
      );
      values.personalPart.value.driverLicenseExpDate = moment(values.personalPart.value.driverLicenseExpDate).format(
        Globals.DefaultUIBirthDateFormat
      );
      values.selectedOrg = this.state.selectedOrg;
      return values;
    });
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleUploadDriverLicense({ file }) {
    const allowedTypes = ['image/'];
    if (!allowedTypes.find(fileType => file.type?.includes(fileType))) {
      message.error('This type of file is not accepted.');
      return;
    }

    this._uploadFile(file);
  }

  async handlePreviewDriverLicense() {
    this.setState({ isLoading: true });

    const fileUrl = await this.props.app.idm.api.userPartitionFile.getDownloadURL(
      this.props.datasource.state?.ccpoPart?.userID,
      config.IDMClientOptions.partitions.PERSONAL,
      Globals.UserPartitionFilesIDs.DRIVERLICENSE,
    );
    if (fileUrl) {
      window.open(fileUrl);
    }

    this.setState({ isLoading: false });
  }

  async handleDownloadDriverLicense() {
    this.setState({ isLoading: true });

    const resp = await this._downloadDriverLicenseFile();
    if (resp.body) {
      Utils.downloadBlobWithFileSaver(resp.body, 'driver-license', Utils.getFileExtensionFromMimeType(resp.body.type));
    }

    this.setState({ isLoading: false });
  }

  handleOrgChange(orgObj) { this.setState({ selectedOrg: orgObj }); }

  //UI
  render() {
    const defaultInputOptions = {};
    return (
      <Card className="userProfileFormContainer">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />

        <Form onSubmit={this.handleSubmit} className="userProfileForm">
          {this.props.app.isAdmin() && (
            <Form.Item label="Admin notes">
              {this._renderInput('ccpoUser.adminNote', {
                ...defaultInputOptions,
                rules: [],
              })(
                <Input.TextArea />
              )}
            </Form.Item>
          )}
          {this._renderPersonalSection(defaultInputOptions)}
          {this._renderAddressSection(defaultInputOptions)}
        </Form>
      </Card>
    );
  }

  // API Requests
  _downloadDriverLicenseFile() {
    return this.props.app.idm.api.userPartitionFile.download(
      this.props.datasource.state?.ccpoPart?.userID,
      config.IDMClientOptions.partitions.PERSONAL,
      Globals.UserPartitionFilesIDs.DRIVERLICENSE,
    );
  }

  async _uploadFile(file) {
    this.setState({ isLoading: true });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = async (readerResp) => {
      file.file = readerResp.currentTarget.result;

      const resp = await this.props.app.idm.api.userPartitionFile.upload(
        this.props.datasource.state?.ccpoPart?.userID,
        config.IDMClientOptions.partitions.PERSONAL,
        file,
        Globals.UserPartitionFilesIDs.DRIVERLICENSE,
      );

      if (resp.statusCode == 200) {
        this.setState({ isLoading: false, hasUploadedDriverLicense: true });
        return;
      }

      this.setState({ isLoading: true });
    };
  }

  //Prefix selector phones
  _getPrefixSelector() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator('prefix', { initialValue: '+1' })(
      <Select style={{ width: 70 }}>
        <Select.Option value="+1">+1</Select.Option>
      </Select>
    );

    return prefixSelector;
  }

  //Sections
  _renderPersonalSection(defaultInputOptions) {
    const prefixSelector = this._getPrefixSelector();
    return (
      <>
        <Divider orientation="left">{Globals.LABEL_PERSONAL_SECTION}</Divider>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item label={`${Globals.LABEL_MOBILE_PHONE}`}>
              {this._renderInput('personalPart.value.mobilePhone', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your mobile phone number!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  addonBefore={prefixSelector}
                  allowClear
                  placeholder="e.g. 778-323-9090"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={`${Globals.LABEL_BIRTH_DATE}`}>
              {this._renderInput(
                'personalPart.value.dateOfBirth',
                { ...defaultInputOptions, rules: [{ required: true, message: 'Please, insert your birthdate!' }] },
                'date'
              )(
                <DatePicker
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  placeholder="1991/11/03"
                  format={Globals.DefaultUIBirthDateFormat}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Driver License</Divider>

        <Row gutter={32}>
          <Col span={4}>
            <Form.Item label={`${Globals.LABEL_DRIVERS_LICENSE}`}>
              {this._renderInput('personalPart.value.driverLicense', { ...defaultInputOptions })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  placeholder="e.g. 3297502"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={`${Globals.LABEL_DRIVERS_LICENSE_EXP_DATE}`}>
              {this._renderInput('personalPart.value.driverLicenseExpDate', { ...defaultInputOptions }, 'date')(
                <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: '100%' }} />
              )}
            </Form.Item>
          </Col>
          <Col>
              <Form.Item label={Globals.LABEL_DRIVERS_LICENSE_PICTURE}>
                <Upload beforeUpload={() => false} onChange={this.handleUploadDriverLicense} multiple={false} showUploadList={false} disabled={this.state.isLoading}>
                  <Button type="primary" disabled={this.state.isLoading}>
                    <Icon type="upload" />
                    Click to select{this.state.hasUploadedDriverLicense ? ' a new' : ''} picture
                  </Button>
                </Upload>
                {this.state.hasUploadedDriverLicense && (
                  <>
                    <Button disabled={this.state.isLoading} onClick={this.handlePreviewDriverLicense} style={{ marginLeft: 16 }}>
                      <Icon type="eye" />
                      Preview
                    </Button>
                    {' '}
                    <Button disabled={this.state.isLoading} onClick={this.handleDownloadDriverLicense}>
                      <Icon type="download" />
                      Download
                    </Button>
                  </>
                )}
              </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Employer information</Divider>

        <Row gutter={32}>
          <Col span={8}>
            <CommonOrganizationSelectionInput app={this.props.app} input={{ id: this.state.selectedOrg?.id, label: Globals.LABEL_EMPLOYER, required: true }} isEdit={true}
              selectedOrg={this.state.selectedOrg} isAdmin={this.props.app.isAdmin()} onSelection={this.handleOrgChange}/>
          </Col>
          <Col span={8}>
            <Form.Item label={`${Globals.LABEL_SPECIAL_NEEDS}`}>
              {this._renderInput('personalPart.value.specialNeeds', { ...defaultInputOptions })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. broken leg"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item label={`${Globals.LABEL_COMPANY_LOCATION}`}>
              {this._renderInput('ccpoUser.companyLocation', { ...defaultInputOptions, rules: [{ required: true, message: 'Please, insert the company location!' }] })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label={`${Globals.LABEL_COMPANY_CONTACT_DISPATCHER}`}>
              {this._renderInput('ccpoUser.companyContact', { ...defaultInputOptions, rules: [{ required: true, message: 'Please, insert the company dispatcher!' }] })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label={`${Globals.LABEL_COMPANY_CONTACT_DISPATCHER_PHONE}`}>
              {this._renderInput('ccpoUser.companyContactPhone', { ...defaultInputOptions, rules: [{ required: true, message: 'Please, insert the company phone!' }] })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderAddressSection(defaultInputOptions) {
    const prefixSelector = this._getPrefixSelector();
    return (
      <>
        <Divider orientation="left">{Globals.LABEL_ADDRESS_SECTION}</Divider>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label={Globals.LABEL_STREET}>
              {this._renderInput('personalPart.value.address.street.0', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your address street!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  placeholder="e.g. 10 Main St."
                />
              )}
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label={Globals.LABEL_STREET2}>
              {this._renderInput('personalPart.value.address.street.1', {
                rules: [{ required: false }],
                ...defaultInputOptions,
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. Unit #722"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item label={Globals.LABEL_CITY}>
              {this._renderInput('personalPart.value.address.city', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your address city!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. Vancouver"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label={Globals.LABEL_POSTAL_CODE}>
              {this._renderInput('personalPart.value.address.postalCode', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your address postal code!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. V7E-3G7"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label={Globals.LABEL_PROVINCE}>
              {this._renderInput('personalPart.value.address.province', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your address province!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. BC"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item label={Globals.LABEL_COUNTRY}>
              {this._renderInput('personalPart.value.address.country', {
                ...defaultInputOptions,
                rules: [{ required: true, message: 'Please, insert your address country!' }],
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  allowClear
                  placeholder="e.g. Canada"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={Globals.LABEL_OTHER_PHONE}>
              {this._renderInput('personalPart.value.address.phone', {
                ...defaultInputOptions,
              })(
                <Input
                  // disabled={this.props.app.props.location.pathname !== config.ApplicationRoutes.profile}
                  addonBefore={prefixSelector}
                  allowClear
                  placeholder="e.g. 778-323-9090"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  //Helper
  _renderInput(val, opts, type) {
    const { getFieldDecorator } = this.props.form;
    const readState = this.props.datasource.state ? this.props.datasource.state : {};
    opts.initialValue = Utils.getNestedObject(readState, val);
    if (type == 'date' && opts.initialValue)
      opts.initialValue = moment(opts.initialValue, Globals.DefaultUIBirthDateFormat);
    return getFieldDecorator(val, opts);
  }
}
