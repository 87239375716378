import React from 'react';
import autoBind from 'react-autobind';
import { ResponsivePie } from '@nivo/pie'
import { Divider, Row, Col, Typography, Spin, Icon } from 'antd';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import AdminDashboardGraph_Achieved from './AdminDashboardGraph_Achieved';
//
export default class AdminDashboardGraph_CertRevenue extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      graphData: null
    };
  }
  //API
  async loadData(stats) {
    if (!stats) this.setState({ graphData: null });
    //Sum all achieved
    let lowPressure = 0;
    let highPressure = 0;
    let truckMountUnder41 = 0;
    let truckMountOver41 = 0;
    let tpBooms = 0;
    let total = 0;
    for (let stat of stats) {
      if (stat.revenue && this.props.months.indexOf(parseInt(stat.sk.slice(4))) != -1) {
        lowPressure += stat.revenue.lowPressure || 0;
        highPressure += stat.revenue.highPressure || 0;
        truckMountUnder41 += stat.revenue.truckMountUnder41 || 0;
        truckMountOver41 += stat.revenue.truckMountOver41 || 0;
        tpBooms += stat.revenue.tpBooms || 0;
      }
    } total = tpBooms + lowPressure + highPressure + truckMountUnder41 + truckMountOver41;
    //Check if any value is above 0
    if (lowPressure > 0 || highPressure > 0 || truckMountUnder41 > 0 || truckMountOver41 > 0 || tpBooms > 0) {
      this.setState({ graphData: { lowPressure, highPressure, truckMountUnder41, truckMountOver41, tpBooms, total } });
    }else this.setState({ graphData: null });
  }
  //UI
  render() {
    return (
      <Col className='graphColumn'>
        <Row className='graphTitleRow'>
          <Typography.Text className='graphTitle'>Revenue p/ Certification</Typography.Text>
        </Row>
        <Row className='graphSubtitleRow'>
          {this.state.graphData &&
            <Typography.Text className='graphSubtitle'>
              <Typography.Text className='graphSubtitleNumber'>${Utils.toCurrencyFormat(this.state.graphData.total || 0)}</Typography.Text>
            </Typography.Text>
          }
        </Row><Row type="flex" align="middle" align='middle'>
          <Col className='graph'>
            { this.props.isLoading ?
              AdminDashboardGraph_Achieved.DefaultGraphLoading() :
              this._renderGraph()
            }
          </Col>
        </Row>
      </Col>
    )
  }
  //Graphs
  _renderGraph() {
    if (!this.state.graphData) return AdminDashboardGraph_Achieved.DefaultEmptyGraph();
    const filteredData = this.state.graphData;
    const data = [
      { id: 'Low Pressure Line', label: 'Low Pressure Line', value: filteredData.lowPressure || 0 },
      { id: 'High Pressure Line', label: 'High Pressure Line', value: filteredData.highPressure || 0 },
      { id: 'Truck Mount Over 41m', label: 'Truck Mount Over 41m', value: filteredData.truckMountOver41 || 0 },
      { id: 'Truck Mount 41m & under', label: 'Truck Mount 41m & under', value: filteredData.truckMountUnder41 || 0 },
      { id: 'Tower Placing Boom', label: 'Tower Placing Boom', value: filteredData.tpBooms || 0 },
    ];
    return (
      <ResponsivePie data={data} {... Globals.DefaultGraphMonetaryProps}
        {... Globals.DefaultGraphPieChartProps} fill={[
          { match: { id: 'Tower Placing Boom' }, id: 'dots' },
          { match: { id: 'Truck Mount 41m & under' }, id: 'lines' },
          { match: { id: 'Truck Mount Over 41m' }, id: 'lines' },
        ]}/>
    );
  }
}
