import React from 'react';
import autoBind from 'react-autobind';
import { Button, Form } from 'antd';

// import Globals from '../../config/Globals';
import config from '../../config/config';
import CommonModal from '../commonComponents/CommonModal';

import UserAgreementStep from '../commonComponents/UserAgreementStep';
import UserProfileStep from '../commonComponents/UserProfileStep';
import UserProfessionalStep from '../commonComponents/UserProfessionalStep';

class UserRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.props.delegate.registrationModal = this;
    this.state = {
      isLoading: false,
      isVisible: false,
      registrationSteps: [],
      isFormValidProfile: false,
      isFormValidProfessional: false,
      isFormValidAgreement: false,
      userOrg: null
    };
  }

  componentDidMount = async () => {
    const userID = this.props.app.idm.session.authorization.getUserID();
    const userOrg = await this.props.app.organization.employee.getEmployeeByEmployeeID(userID);
    this.setState({
      userOrg: userOrg.body?.org || null,
      registrationSteps: [
        {
          title: 'Personal Information',
          content: (
            <UserProfileStep
              delegate={this}
              onChange={this.handleValidation}
              app={this.props.app}
            />
          ),
        },
        {
          title: 'CCPO Information',
          content: (
            <UserProfessionalStep
              delegate={this}
              onChange={this.handleValidation}
              app={this.props.app}
            />
          ),
        },
        {
          title: 'Agreement',
          content: (
            <UserAgreementStep
              delegate={this}
              onChange={this.handleValidation}
              app={this.props.app}
            />
          ),
        },
      ],
    });
    const personal = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    console.log('Personal data - Registration View', personal);
  };

  updateUserPartitions = async data => {
    const ccpo = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.CCPO, true);
    const personal = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    /* present form - aka. CCPO registration */
    const partitionsValues = {
      [config.IDMClientOptions.partitions.CCPO]: {
        value: {
          //spread previous content
          ...(ccpo ? ccpo.value : {}),
          pumpCategories: data.pumpCategories,
          agreedOnTerms: data.agreedOnTerms ? Date.now() : null,
        },
      },
      [config.IDMClientOptions.partitions.PERSONAL]: {
        value: {
          //spread previous content
          ...(personal ? personal.value : {}),

          address: {
            street: data.street,
            country: data.country,
            province: data.province,
            city: data.city,
            postalCode: data.postalCode,
            phone: data.phone,
          },
          driverLicense: data.driverLicense,
          driverLicenseExpDate: data.driverLicenseExpDate,
          mobilePhone: data.mobilePhone,
          specialNeeds: data.specialNeeds,
        },
      },
      [config.IDMClientOptions.partitions.PROFESSIONAL]: {
        value: {
          // Here will go my CCPO partition info - can be anything, object, string, binary, number, array :)
          // ...this.state.data
          employer: {
            name: data.employerName,
          },
        },
      },
    };
    // Get current logged user ID from session authorization
    const userID = this.props.app.idm.session.authorization.getUserID();
    // Send API request to IDM, setting paritition for user X on partition Y with value Z
    const resp = await this.props.app.idm.api.userPartition.setMultiple(
      { parts: partitionsValues },
      /* userID */ userID
    );

    //Invalidate old partition values
    await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.CCPO);
    await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL);
    await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PERSONAL);

    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('update user partitions with success');
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 500 means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while updating user partitions');
      this.props.app.alertController.showErrorAlert('Error', JSON.stringify(resp));
      return false;
    }
    return true;
  };

  upsertEmployee = async () => {
    if (this.state.userOrg) {
      const userID = this.props.app.idm.session.authorization.getUserID();
      const userObj = await this.props.app.idm.session.data.getUserObject();
      const resp = await this.props.app.organization.employee.upsertEmployee( this.state.userOrg.id,
                                                                              userID,
                                                                              {
                                                                                email: userObj.email,
                                                                                firstName: userObj.firstName,
                                                                                lastName: userObj.lastName,
                                                                                hashedWorksafeID: this.state.userOrg.hashedWorksafeID
                                                                              });
      if (resp.statusCode == 200 || resp.statusCode == 204) return true;
      else return false;
    } else return true;
  };

  createCCPOUser = async data => {
    // Get current logged user ID from session authorization
    const userID = this.props.app.idm.session.authorization.getUserID();
    const userObj = await this.props.app.idm.session.data.getUserObject();
    const userValue = {
      id: userID,
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      email: userObj.email,
      companyName: data.employerName,
      agreedWithCertTerms: data.agreedOnTerms,
      agreedOnTerms: data.agreedOnTerms ? Date.now() : null,
      phoneNumber: (data.mobilePhone || data.phone),
      companyLocation: data.companyLocation,
      companyContact: data.companyContact,
      companyContactPhone: data.companyContactPhone,
    };

    // Send API request to CCPO
    const resp = await this.props.app.api.user.create(userValue);
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('created ccpo user with success');
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 50x means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while creating ccpo user', resp);
      this.props.app.alertController.showErrorAlert('Error', JSON.stringify(resp));
      return false;
    }
    return true;
  };

  completeUserRegistration = async () => {
    this.setState({ isLoading: true });
    let resp = '';
    let data = {};
    const respProfile = await this.profileStep.handleSubmit();
    if (!respProfile.errors) {
      const respProfessional = await this.professionalStep.handleSubmit();
      if (!respProfessional.errors) {
        const respAgreement = await this.agreementStep.handleSubmit();

        data = { ...respProfile, ...respProfessional, ...respAgreement };
        if (!respAgreement.errors) {
          resp = await this.updateUserPartitions(data);
          if (resp) resp = await this.createCCPOUser(data); //only continue to save if saved previous with success
          if (resp) {
            resp = await this.upsertEmployee();
            if (resp) {
              await this.props.app.sharedCache().loadCache(); //reload user object
              this.props.app.alertController.showSuccessAlert('', 'Registration completed!');
              this.setState({ isLoading: false, isVisible: false });
              if (this.props.completionHandler) this.props.completionHandler();
            } else {
              console.log('error upserting employee on organization');
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
          }
        } else {
          console.log('error agreement');
          this.setState({ isLoading: false });
        }
      } else {
        console.log('error professional');
        this.setState({ isLoading: false });
      }
    } else {
      console.log('error profile');
      this.setState({ isLoading: false });
    }
  };

  handleShow() {
    this.setState({ isLoading: false, isVisible: true });
  }

  //Used to validate when should the submit button be enabled
  handleValidation(data) {
    this.setState({ ...data });
  }

  render() {
    return (
      <>
        {this.state.isVisible && (
          <CommonModal
            title="Complete CCPO Registration"
            isVisible={this.state.isVisible}
            isLoading={this.state.isLoading}
            steps={this.state.registrationSteps}
            modalButtons={null}
            submitButton={<Button
              className="primary solid"
              key="submit"
              type="primary"
              disabled={
                !this.state.isFormValidProfile ||
                !this.state.isFormValidProfessional ||
                !this.state.isFormValidAgreement
              }
              loading={this.state.isLoading}
              onClick={() => this.completeUserRegistration()}
            >
              Submit
            </Button>}
          />
        )}
      </>
    );
  }
}

export default Form.create()(UserRegistrationModal);
