import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Button, message } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import '../../assets/stylesheets/WhiteBox.scss';
//
// import config from '../../config/config';
import Globals from '../../config/Globals';
//
const styleHidden = {style: { display: 'none' }};
// This view can be called in 2 different ways:
//    - /billing/:userID
//    - /billing
//    - Same param {userID} to embeed this view somewhere else
//props are: userID, app
export default class CommonBillingView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      isLoading: true, 
      userID: this.props.match?.params?.[Globals.URL_Path_ID_Placeholder] || this.props.userID || null,
      newPaymentMethodAvailable: false
    };
  }

  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    await this._loadBraintreeSDK();
    this.stopLoading();
  }
  componentWillUnmount() { 
    this._releaseDropInListeners();
    this.paymentProcessorInstance.teardown();
  }

  //Actions
  async handleAddPaymentAction() {
    const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
  }

  //UI
  render() {
    const isEmbeeded = !!this.props.userID;
    const { isLoading } = this.state;
    return (
      <>
        <Layout.Content className="pageContent">  
          <CommonLoadingView isLoading={isLoading} />
          {!isEmbeeded && <PageHeader className="pageHeader" onBack={() => window.history.back()} title="Billing"/>}
          <Layout.Content type='flex' align='center' className='whiteBox'>
            <Row type='flex' justify='center' style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto'}}>
                <div {...(isLoading ? styleHidden : { style: { opacity: 1, width: '100%' }})} id="paymentProcessorContainer"></div>
              </Row>
              <Row type='flex' justify='end' style={{marginTop: 20}}>
                <Col>
                  <Button type="primary" loading={isLoading} disabled={isLoading || !this.state.newPaymentMethodAvailable} onClick={this.handleAddPaymentAction}>Add Payment method</Button>
                </Col>
              </Row>
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  // Utils
  async _getScopedUserInformation() {
    if (this.props.app.isAdmin()) { //get user info
      const user = await this.props.app.api.user.getByID(this.state.userID);
      if (user.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, user);
      else {
        const { lastName, firstName, email } = user.body;
        return { firstName, lastName, email };
      }
    }
    const { lastName, firstName, email } = this.props.app.sharedCache().getCCPOUser();
    return { firstName, lastName, email };
  }

  /* Private Braintree */
  async _loadBraintreeSDK() {
    const info = (await this._getScopedUserInformation());
    console.debug(info);
    const nonce = await this.props.app.sharedCache().getVaultPaymentNonceByUserID(this.state.userID, info);
    if (nonce) {
      this.paymentProcessorInstance = await this.props.app.paymentManager.initializePaymentFlowSDKOnContainer('#paymentProcessorContainer', nonce);
      this._setupDropInListeners();
    }
  }
  /* API operations */
  async _setCurrentPaymentMethodAsDefault(paymentMethodAlreadyExists) {
    try {
      //Ask for payment method nonce
      //this operation will add the payment method if it's on the 'ADD' form
      const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
      //If already existing payment method, we need to set as default via API
      if (paymentMethodAlreadyExists) {
        const resp = await this.props.app.api.vault.setVaultDefaultPaymentMethod(nonce.nonce, this.state.userID);
        if (resp.statusCode != 200) {
          this.paymentProcessorInstance.clearSelectedPaymentMethod();
          message.error('Error while setting up default payment method. Please, try again!');
        }
      } else message.success({ content: 'New payment method added with success!' });
    } catch (e) {
      console.error(e);
      message.error({ content: e.message });
    }
  }
    //Listeners
  _setupDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.on('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.on('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _releaseDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.off('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.off('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  async _onPaymentMethodRequestable(event) { 
    this.setState({ newPaymentMethodAvailable: !event.paymentMethodIsSelected });
    if (event.paymentMethodIsSelected) await this._setCurrentPaymentMethodAsDefault(true);
  }
  _onNoPaymentMethodRequestable() {
    this.setState({ newPaymentMethodAvailable: false });
  } 
}
