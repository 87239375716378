import Globals from '../../config/Globals';
import Utils from '../Utils';
//
export default class APIUtils {
  //constructor() {}
}

APIUtils.parseCertificationProcessToEvents = function(certProcObj, app) {
  if (!certProcObj) return null;
  const isAdmin = app.isAdmin();
  let events = [];
  //Header
  this._procedureHeader(certProcObj, events, app.sharedCache(), isAdmin);
  //Procedural Exams event
  this._procedureExams(certProcObj, events, app.sharedCache(), isAdmin);
  //Procedural Orphan Payments event
  this._procedureOrphanPayments(certProcObj, events, app.sharedCache(), isAdmin);
  //Footer
  this._procedureFooter(certProcObj, events, app.sharedCache(), isAdmin);
  //Sort events by date
  events.sort((a, b) => ((a.eventDate < b.eventDate) ? 1 : -1));
  console.debug('Events are ', events, certProcObj);
  return events;
}
APIUtils._procedureExams = function(certProcObj, events, cache, isAdmin) {
  //For each exam
  let specsSectionedExamsDate = {};
  let specsSectionedExams = {};
  let latestPracticalExpired = null;
  for (let exam of certProcObj.exams) {
    const examSpecs = cache.getExamByID(exam.examID);
    const isPractical = (examSpecs.id.includes('Practical'));
    let earlierExamActionDate = exam.createdOn;
    //For each transaction related to this exam
    for (let transaction of certProcObj.payments) {
      //Add payments from same exam id and that are from new certs
      if (transaction.examID == exam.id) {
        const paymentEvent = this._examPaymentEvent(exam, examSpecs, transaction);
        if (paymentEvent) events.push(paymentEvent);
        //If payment completed we should use it as the transaction of the exam
        if (transaction.type == Globals.API_FinancialTransactionType_NewCert &&
            transaction.state == Globals.FinancialTransaction_State.COMPLETED) exam.transaction = transaction;
      }
      if (transaction.examSpecsID == exam.examID) {
        if (transaction.createdOn < earlierExamActionDate) earlierExamActionDate = transaction.createdOn;
      }
    }
    //Add exam event
    events.push(this._examEvent(exam, examSpecs, certProcObj, isAdmin));
    //calculate the earlier date with action for this exam specs
    if (!specsSectionedExamsDate[examSpecs.id] || earlierExamActionDate < specsSectionedExamsDate[examSpecs.id]) {
      specsSectionedExamsDate[examSpecs.id] = earlierExamActionDate;
      specsSectionedExams[examSpecs.id] = exam;
    }
    //If exam is failed we should render a cooldown event
    if (exam.state == Globals.Exam_State.FAIL && !isPractical) {
      events.push(this._examCooldownEvent(exam, examSpecs, certProcObj, isAdmin));
    }
    //Check if is practical and expired but not on the following states
    //display expiration history
    if (isPractical && exam.reqExpiryDate < Date.now() && (
      certProcObj.state != Globals.CertificationProcess_State.COMPLETED &&
      certProcObj.state != Globals.CertificationProcess_State.EXPIRED
    ) && (!latestPracticalExpired || latestPracticalExpired.reqExpiryDate < exam.reqExpiryDate)) {
      latestPracticalExpired = exam;
    }

    //If waived, display history
    if (exam.waivedBy) {
      events.push(this._waiveRequestEvent(certProcObj, exam, examSpecs, isAdmin));
    }
  }
  //Expired practical
  if (latestPracticalExpired) {
    const certSpecs = cache.getCertificationByID(certProcObj.certificationID);
    events.push(this._certificationExpiredEvent(certProcObj, certSpecs, latestPracticalExpired.examResultDate));
  }
  //Process requirements
  for (let requirementID of Object.keys(specsSectionedExamsDate)) {
    const examSpecs = cache.getExamByID(requirementID);
    events.push(this._examExamRequirementEvent(examSpecs, specsSectionedExams[requirementID], specsSectionedExamsDate[requirementID], certProcObj));
  }
}
APIUtils._procedureOrphanPayments = function(certProcObj, events, cache, isAdmin) {
  let specsSectionedExamsDate = {};
  //For each transaction not related with any exam created
  for (let transaction of certProcObj.payments) {
    //no exam related to it...
    if (!certProcObj.exams.find( exam => transaction.examID == exam.id)) {
      const paymentEvent = this._examPaymentEvent(null, null, transaction);
      if (paymentEvent) events.push(paymentEvent);
      //calculate the earlier date with action for this exam specs
      if (!specsSectionedExamsDate[transaction.examSpecsID] || transaction.createdOn < specsSectionedExamsDate[transaction.examSpecsID]) {
        specsSectionedExamsDate[transaction.examSpecsID] = transaction.createdOn;
      }
    }
  }

  //Process requirements of orphan transactions
  for (let requirementID of Object.keys(specsSectionedExamsDate)) {
    //If no exam with this specs id
    if (!certProcObj.exams.find( exam => exam.examID == requirementID)) {
      const examSpecs = cache.getExamByID(requirementID);
      if (examSpecs) events.push(this._examExamRequirementEvent(examSpecs, null, specsSectionedExamsDate[requirementID], certProcObj));
    }
  }
}
APIUtils._procedureHeader = function(certProcObj, events, cache, isAdmin) {}
APIUtils._procedureFooter = function(certProcObj, events, cache, isAdmin) {
  const certSpecs = cache.getCertificationByID(certProcObj.certificationID);
  //Certification completion/failure/expiry
  if (certProcObj.state == Globals.CertificationProcess_State.COMPLETED ||
      certProcObj.state == Globals.CertificationProcess_State.FAILED ||
      certProcObj.state == Globals.CertificationProcess_State.EXPIRED ) {
    events.push(this._certificationCompletionEvent(certProcObj, certSpecs));
  }
  //Completed display print card
  if (certProcObj.state == Globals.CertificationProcess_State.COMPLETED) {
    events.push(this._certificationPrintEvent(certProcObj, certSpecs, isAdmin));
  }
  //Certification has payment pending/failed and exam is pending.. Probably need payment on this exam
  if ((certProcObj.state == Globals.CertificationProcess_State.PAYMENT_FAILED ||
      certProcObj.state == Globals.CertificationProcess_State.PAYMENT_PENDING ||
      certProcObj.state == Globals.CertificationProcess_State.CANCEL_REFUND_COMPLETED ||
      certProcObj.state == Globals.CertificationProcess_State.FAILED ||
      certProcObj.state == Globals.CertificationProcess_State.EXPIRED_REQUIREMENT) &&
      !certProcObj.exams.find( exam => exam.state == Globals.Exam_State.PENDING)) {
    const examSpecs = APIUtils.findNextRequirementsForCertification(certProcObj, cache, cache.getTenantConfig())[0];
    if (examSpecs) {
      const isPractical = (examSpecs.id.includes('Practical'));
      //payment request for this exam
      events.push(this._examNewPaymentEvent(examSpecs, certProcObj));
      //Valid exam must be on the same id, not expired if practical
      const validExam = certProcObj.exams.find( exam => {
        return exam.examID == examSpecs.id && ((isPractical && exam.reqExpiryDate < Date.now()) || !isPractical);
      });
      //Search for valid payment
      const validPayment = !certProcObj.payments.find( payment => payment.examSpecsID == examSpecs.id);
      //Add requirement with no payments and no exams
      if (!validExam && !validPayment) {
        events.push(this._examExamRequirementEvent(examSpecs, null, Date.now()-100, certProcObj));
      }
    }
  } else if (certProcObj.state == Globals.CertificationProcess_State.RESCHEDULE_PAYMENT_PENDING) {
    const lastExam = certProcObj.exams.find( exam => exam.state == Globals.Exam_State.PENDING);
    //payment request for this exam
    events.push(this._examNewResPaymentEvent(cache.getExamByID(lastExam.examID), lastExam, certProcObj));
  } else if (certProcObj.state == Globals.CertificationProcess_State.WAIVE_REQUESTED) {
    const examSpecs = APIUtils.findNextRequirementsForCertification(certProcObj, cache, cache.getTenantConfig())[0];
    //waive in progress
    if (examSpecs) events.push(this._waiveRequestEvent(certProcObj, null, examSpecs, isAdmin));
  }
}
//Events
  //Exam events
APIUtils._examExamRequirementEvent = function(examSpecs, exam, date, certProcObj) {
  const isPractical = (examSpecs.id.includes('Practical'));
  //Check if the exam is really valid, practical must not be expired or cert.states expired or completed we wait till the user rewew it to display requirement invalidation
  const validPass = (isPractical && exam && (exam.reqExpiryDate > Date.now() || certProcObj.state == Globals.CertificationProcess_State.EXPIRED || certProcObj.state == Globals.CertificationProcess_State.COMPLETED)) || !isPractical;
  //
  let description = `Exam is required`;
  let title = `${(examSpecs ? examSpecs.description : 'Exam')} is required`;
  let badge = 'Requirement';
  let state = Globals.Event_State.IN_PROGRESS;
  let eventDate = (validPass || !exam ? date-1 : exam.updatedOn - 1);
  //
  if (exam && exam.state == Globals.Exam_State.PASS && validPass) {
    description = `Requirement completed`;
    title = `Requirement completed`;
    badge = `Requirement completed`;
    state = Globals.Event_State.COMPLETED;
    eventDate = exam.createdOn;
  }
  return {
    type: Globals.Event_Type.REQUIREMENT,
    state: state,
    eventDate: eventDate,
    metadata: {
      title: title,
      badge: badge,
      description: description,
      object: {...(exam ? exam : {}), examSpecs},
      url: '',
    },
  };
};
APIUtils._examEvent = function(exam, examSpecs, certProcObj, isAdmin) {
  let description = '';
  let title = '';
  let badge = '';
  let state ;
  let eventDate = Date.now();
  const isPractical = (examSpecs.id.includes('Practical'));
  //
  if (exam.invalidated) {
    description = `The exam ${examSpecs.description} is expired and need to be retaken.`;
    title = `Exam expired`;
    badge = `Exam invalidated`;
    state = Globals.Event_State.CANCELLED;
    eventDate = exam.examResultDate; //need to be updated on so schedule and reschedule are on top of payments
  } else if (exam.state == Globals.Exam_State.PENDING_RES_APPROVAL) {
    description = `The exam was completed but still pending result approval.`;
    title = `Exam Result Pending Approval`;
    badge = `Pending Approval`;
    state = Globals.Event_State.WAITING;
    eventDate = exam.updatedOn; //need to be updated on so schedule and reschedule are on top of payments
  } else if (exam.state == Globals.Exam_State.CANCELLED) {
    description = `The exam was cancelled and can be initiated again upon payment.`;
    title = `Exam cancelled`;
    badge = `Exam cancelled`;
    state = Globals.Event_State.CANCELLED;
    eventDate = exam.updatedOn; //need to be updated on so schedule and reschedule are on top of payments
  } else if (exam.state == Globals.Exam_State.FAIL) {
    description = `Failed on exam ${examSpecs.description}`;
    title = `Exam failed `
    badge = `Exam failed`;
    state = Globals.Event_State.FAILED;
    eventDate = (exam.examResultDate ? exam.examResultDate : exam.createdOn); //do not use updated on because invalidation changes it -- Do not user created on so if the exam get completed after the creation date it's reflected
  } else if (exam.state == Globals.Exam_State.PASS) {
    if (exam.waivedBy) {
      description = `${examSpecs.description} was waived!`;
      title = `Exam waived`;
      badge = `Exam waived`;
      state = Globals.Event_State.COMPLETED;
      eventDate = (exam.examResultDate ? exam.examResultDate : exam.createdOn); //do not use updated on because invalidation changes it -- Do not user created on so if the exam get completed after the creation date it's reflected
    } else {
      description = `Passed on ${examSpecs.description} with grade ${exam.grade}`;
      title = `Exam completed`;
      badge = `Exam completed`;
      state = Globals.Event_State.COMPLETED;
      eventDate = (exam.examResultDate ? exam.examResultDate : exam.createdOn); //do not use updated on because invalidation changes it -- Do not user created on so if the exam get completed after the creation date it's reflected
    }
  } else if (exam.state == Globals.Exam_State.PENDING) {
    if (!exam.isReleased) {
      description = `${examSpecs.description} is locked and needs to be released by admin`;
      title = `Locked Exam`;
      badge = `Locked`;
      state = Globals.Event_State.LOCKED;
      eventDate = exam.updatedOn; //need to be updated on so schedule and reschedule are on top of payments
    } else if (exam.examDate && exam.examDate != -1) {
      if (certProcObj.state == Globals.CertificationProcess_State.RESCHEDULE_PAYMENT_PENDING) {
        description = `${examSpecs.description} reschedule is requested to ${Utils.getDateAndTimeOnUIFormatByTimestamp(exam.examDate)}.`;
        title = `Exam reschedule requested`;
        badge = `Reschedule requested`;
        state = Globals.Event_State.IN_PROGRESS;
        eventDate = exam.updatedOn; //need to be updated on so schedule and reschedule are on top of payments
      } else {
        description = (examSpecs.isOnline ?
            `${examSpecs.description} was made available on ${Utils.getDateAndTimeOnUIFormatByTimestamp(exam.examDate)}` :
            `${examSpecs.description} is scheduled to ${Utils.getDateAndTimeOnUIFormatByTimestamp(exam.examDate)}`);
        title = `Scheduled Exam`;
        badge = `Scheduled`;
        state = Globals.Event_State.IN_PROGRESS;
        eventDate = exam.updatedOn; //need to be updated on so schedule and reschedule are on top of payments
      }
    } else {
      if (isPractical) {
        description = `Practical Exam is waiting to be scheduled`;
        title = `Schedule Exam`;
        badge = `Waiting for Schedule`;
        state = Globals.Event_State.READY;
        eventDate = exam.createdOn;
      } else {
        description = `${examSpecs.description} is ready to be scheduled`;
        title = `Schedule Exam`;
        badge = `Ready for Schedule`;
        state = Globals.Event_State.READY;
        eventDate = exam.createdOn;
      }
    }
  }
  return {
    type: Globals.Event_Type.EXAM,
    state: state,
    eventDate: eventDate,
    metadata: {
      title: title,
      description: description,
      badge: badge,
      url: (exam.ssoURL ? exam.ssoURL : ''),
      object: {...exam, examSpecs },
    },
  };
};
APIUtils._examPaymentEvent = function(exam, examSpecs, payment) {
  //Check params to be used
  let state = Globals.Event_State.IN_PROGRESS;
  let title = '';
  let badge = '';
  let description = '';
  let type = (payment.type == Globals.API_FinancialTransactionType_Refund ? 'Refund' : 'Payment');
  if (payment.type == Globals.API_FinancialTransactionType_Reschedule) type = 'Reschedule';
  //
  if (payment.state == Globals.FinancialTransaction_State.INITIATED) {
    title = `${type} Incomplete`;
    badge = `${type} In Progress`;
    description = `Due to interruption on ${type.toLowerCase()} process, please, request the cancellation of this transaction`;
    state = Globals.Event_State.IN_PROGRESS;
  } else if (payment.state == Globals.FinancialTransaction_State.COMPLETED) {
    const actionType = payment.totalValue == 0 ? 'Skipped' : 'Completed';
    description = `${type} for the ${examSpecs.description} ${actionType.toLowerCase()}`;
    title = `${type} ${actionType}`;
    badge = `${type} ${actionType}`;
    state = Globals.Event_State.COMPLETED;
  } else if (payment.state == Globals.FinancialTransaction_State.FAILED) {
    title = `${type} Failed`;
    badge = `${type} Failed`;
    description = `${type} attempt failed`;
    state = Globals.Event_State.FAILED;
  } else if (payment.state == Globals.FinancialTransaction_State.DECLINED) {
    title = `${type} Declined`;
    badge = `${type} Declined`;
    description = `${type} attempt declined`;
    state = Globals.Event_State.FAILED;
  } else if (payment.state == Globals.FinancialTransaction_State.CANCELLED) {
    return null;
  }
  //
  return {
    type: Globals.Event_Type.PAYMENT,
    state: state,
    eventDate: payment.createdOn,
    metadata: {
      title: title,
      badge: badge,
      description: description || `Payment is required to book exam`,
      url: '',
      object: payment,
    },
  };
};
APIUtils._examNewPaymentEvent = function(examSpecs, certProcObj) {
  return {
    type: Globals.Event_Type.PAYMENT,
    state: Globals.Event_State.READY,
    eventDate: Date.now(),
    metadata: {
      title: `Payment Required`,
      badge: `Ready to pay`,
      description: `Payment is required to book ${examSpecs.description}`,
      object: {...examSpecs, cert: certProcObj},
      url: '',
    },
  };
};
APIUtils._examNewResPaymentEvent = function(examSpecs, examObj, certObj) {
  return {
    type: Globals.Event_Type.RES_PAYMENT,
    state: Globals.Event_State.READY,
    eventDate: Date.now(),
    metadata: {
      title: `Payment Requested`,
      badge: `Ready to pay`,
      description: `Payment is requested to reschedule ${examSpecs.description} on date ${Utils.getDateAndTimeOnUIFormatByTimestamp(examObj.examDate)} and payment of $${certObj.pendingPaymentValue} is required.`,
      object: {
        pendingPaymentValue: certObj.pendingPaymentValue,
        ...examObj,
        examSpecs: examSpecs
      },
      url: '',
    },
  };
};
APIUtils._examCooldownEvent = function(exam, examSpecs, certProcObj, isAdmin) {
  const time = new Date(exam.examResultDate);
  time.setDate(time.getDate() + examSpecs.coolDownPeriod);
  const hasCooldownFinished = ((exam.cooldownOverwrittenOn > exam.examResultDate) ||
    (time.getTime() <= Date.now() && certProcObj.state != Globals.CertificationProcess_State.COOLDOWN));
  //
  return {
    type: Globals.Event_Type.COOLDOWN,
    state: (hasCooldownFinished ? Globals.Event_State.COMPLETED : Globals.Event_State.IN_PROGRESS),
    eventDate: exam.updatedOn,
    metadata: {
      title: (hasCooldownFinished ? `Cooldown Completed` : `Cooldown in Progress`),
      badge: (hasCooldownFinished ? `Cooldown period ended` : `Cooldown In Progress`),
      description: (hasCooldownFinished ? `Cooldown period ended on ${Utils.getDateOnUIFormat(time)}. You may schedule a new exam` :`Cooldown period is in progress, you must wait until ${Utils.getDateOnUIFormat(time)} to retake the exam`),
      object: exam,
      url: '',
    },
  };
};
  //Other events
APIUtils._waiveRequestEvent = function(certProcObj, examObj, examSpecs, isAdmin) {
  const waived = !!(examObj && examObj.waivedBy);
  return {
    type: Globals.Event_Type.WAIVE,
    state: (waived ? Globals.Event_State.COMPLETED : Globals.Event_State.IN_PROGRESS),
    eventDate: (examObj && examObj.examResultDate ? examObj.examResultDate : Date.now()) - 1,
    metadata: {
      title: (waived ? `Exam waived` : `Waive request in progress`),
      badge: (waived ? `Waive Completed` : `Waive requested`),
      description: (waived ? `Exam waived on ${Utils.getDateOnUIFormatByTimestamp(examObj.waivedOn)}.` :`${examSpecs.description} waive requested, waiting the admin to make a decision.`),
      object: examObj,
      url: '',
    },
  };
}
  //Cert events
APIUtils._certificationPrintEvent = function(certProcObj, certSpecs, isAdmin) {
  let state = Globals.Event_State.IN_PROGRESS;
  let title = (isAdmin ? `Print Card` : `Printing Card`);
  let badge = (isAdmin ? `Print Card` : `Printing Card`);
  let description = (isAdmin ? `Print Card` : `Printing Card`);
  let eventDate = certProcObj.completionDate + 100;
  if (certProcObj.printedOn && certProcObj.printedOn != -1) {
    state = Globals.Event_State.COMPLETED;
    title = `Card Printed`;
    badge = `Card Printed`;
    description = `Certification ${certSpecs.description} card printed on ${Utils.getDateOnUIFormatByTimestamp(certProcObj.printedOn)}.`;
    eventDate = certProcObj.printedOn;
  }
  return {
    type: Globals.Event_Type.PRINTCARD,
    state: state,
    eventDate: eventDate,
    metadata: {
      title: title,
      badge: badge,
      description: description,
      object: certSpecs,
      url: '',
    },
  };
}
APIUtils._certificationCompletionEvent = function(certProcObj, certSpecs) {
  let state = Globals.Event_State.COMPLETED;
  let title = `Certification Completed`;
  let badge = `Certification Completed`;
  let description = `Certification ${certSpecs.description} is completed.`;
  if (certProcObj.state == Globals.CertificationProcess_State.FAILED) {
    state = Globals.Event_State.FAILED;
    title = `Certification Failed`;
    badge = `Certification Failed`;
    description = `Certification ${certSpecs.description} is failed. You are required to start the process again.`;
  } else if (certProcObj.state == Globals.CertificationProcess_State.EXPIRED) {
    state = Globals.Event_State.EXPIRED;
    title = `Certification Expired`;
    badge = `Certification Expired`;
    description = `Certification ${certSpecs.description} has expired on ${Utils.getDateOnUIFormatByTimestamp(certProcObj.expiryDate)}. You are required to renew it to continue certificated.`;
  }
  return {
    type: Globals.Event_Type.CERTIFICATION,
    state: state,
    eventDate: (certProcObj.completionDate ? certProcObj.completionDate : certProcObj.updatedOn),
    metadata: {
      title: title,
      badge: badge,
      description: description,
      object: { certSpecs, ...certProcObj },
      url: '',
    },
  };
}
APIUtils._certificationExpiredEvent = function(certProcObj, certSpecs, date) {
  let state = Globals.Event_State.EXPIRED;
  let title = `Certification Expired`;
  let badge = `Certification Expired`;
  let description = `Certification ${certSpecs.description} has expired on ${Utils.getDateOnUIFormatByTimestamp(certProcObj.expiryDate)}.`;
  return {
    type: Globals.Event_Type.CERTIFICATION,
    state: state,
    eventDate: date + 1,
    metadata: {
      title: title,
      badge: badge,
      description: description,
      object: { certSpecs, ...certProcObj, noAction: true },
      url: '',
    },
  };
}


//Helpers
APIUtils.findNextRequirementsForCertification = function(currentCert, cache, tenantConfig) {
  function _isNotFilled(exam) {
    if (!currentCert.exams.length) return true;
    let allow = true;
    currentCert.exams.forEach(req => {
      const examSpecs = cache.getExamByID(req.examID);
      if (!req.invalidated && exam.type == examSpecs.type &&
          (req.state == Globals.Exam_State.PASS || req.state == Globals.Exam_State.PENDING) && req.reqExpiryDate > Date.now()) allow = false;
    });
    return allow;
  }
  const currentCertSpecs = tenantConfig.certifications.find( cert => currentCert.certificationID == cert.id);
  let examsSpecs = currentCertSpecs.requirements.find( _examSpecs => {
    if (Array.isArray(_examSpecs)) return _examSpecs.find( _examSpecs2 => {
      return _isNotFilled(_examSpecs2);
    });
    return _isNotFilled(_examSpecs);
  });
  if (!Array.isArray(examsSpecs)) examsSpecs = [examsSpecs];
  return examsSpecs;
}
APIUtils.findSupersedingIDs = function(certSpecsID, certifications, cache, skipCompleted = false) {
  //Check if certSpecsID is not on certifications, if it's is, it's not superseeded
  if (certifications.find((c) => c.certificationID == certSpecsID) && !skipCompleted) return null;
  //Now certifications that supersedes this certSpecsIDs
  const IDs = certifications.filter((cert) => {
    const certSpecs = cache.getCertificationByID(cert.certificationID);
    return (certSpecs && certSpecs.supersedeIDs.indexOf(certSpecsID) != -1 && cert.state == Globals.CertificationProcess_State.COMPLETED);
  }).map((cert)=>cert.certificationID);
  return (IDs.length > 0 ? IDs : null);
}
