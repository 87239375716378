import React from 'react';
import autoBind from 'react-autobind';
import { ResponsiveBar } from '@nivo/bar';
import { Divider, Row, Col, Typography, Spin, Icon } from 'antd';
//
import Globals from '../../config/Globals';
import AdminDashboardGraph_Achieved from './AdminDashboardGraph_Achieved';
//
export default class AdminDashboardGraph_Certifications extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      graphData: null
    };
  }
  //API
  async loadData(stats) {
    if (!stats) this.setState({ graphData: null });
    //Sum all achieved
    let writtenPassed = 0;
    let writtenFailed = 0;
    let practicalPassed = 0;
    let practicalFailed = 0;
    let achievedCertified = 0;
    let achievedExpired = 0;
    let usersCount = 0;
    for (let stat of stats) {
      if (this.props.months.indexOf(parseInt(stat.sk.slice(4))) != -1) {
        if (stat.writtenPassed) {
          writtenPassed += stat.writtenPassed;
        }
        if (stat.writtenFailed) {
          writtenFailed += stat.writtenFailed;
        }

        if (stat.practicalPassed) {
          practicalPassed += stat.practicalPassed;
        }
        if (stat.practicalFailed) {
          practicalFailed += stat.practicalFailed;
        }

        if (stat.numCertified) {
          achievedCertified += stat.numCertified;
        }
        if (stat.numExpiredCertified) {
          achievedExpired += stat.numExpiredCertified;
        }

        usersCount += stat.numRegisteredUsers || 0;
      }
    }
    //Check if any value is above 0
    if (
      usersCount > 0 || writtenPassed > 0 || writtenFailed > 0 || practicalPassed > 0
      || practicalFailed > 0 || achievedCertified > 0 || achievedExpired > 0
    ) {
      this.setState({
        graphData: {
          usersCount, writtenPassed, writtenFailed, practicalPassed,
          practicalFailed, achievedCertified, achievedExpired,
        },
      });
    }else {
      this.setState({ graphData: null });
    }

  }
  //UI
  render() {
    return (
      <Col className='graphColumn'>
        <Row className='graphTitleRow'>
          <Typography.Text className='graphTitle'>Certification Summary</Typography.Text>
        </Row>
        <Row className='graphSubtitleRow'>
          {this.state.graphData &&
            <Typography.Text className='graphSubtitle'>
              <Typography.Text className='graphSubtitleNumber'>{this.state.graphData.usersCount || 0}</Typography.Text>
              <Typography.Text className='graphSubtitlePostfix'>Registered Users</Typography.Text>
            </Typography.Text>
          }
        </Row><Row type="flex" align="middle" align='middle'>
          <Col className='graph'>
            { this.props.isLoading ?
              AdminDashboardGraph_Achieved.DefaultGraphLoading() :
              this._renderGraph()
            }
          </Col>
        </Row>
      </Col>
    )
  }
  //Graphs 
  _renderGraph() {
    if (!this.state.graphData) return AdminDashboardGraph_Achieved.DefaultEmptyGraph();
    const filteredData = this.state.graphData;
    const data = [
      {
        id: 'Active CCPOs', label: 'Active CCPOs',
        Certified: filteredData.achievedCertified || 0, Expired: filteredData.achievedExpired || 0
      },
      {
        id: 'Practical Assessments', label: 'Practical Assessments',
        Passed: filteredData.practicalPassed || 0, Failed: filteredData.practicalFailed || 0
      },
      {
        id: 'Written Exams', label: 'Written Exams',
        Passed: filteredData.writtenPassed || 0, Failed: filteredData.writtenFailed || 0,
      },
    ];
    return (<ResponsiveBar data={data} {... Globals.DefaultGraphHorizontalBarGraphProps}
        keys={['Passed', 'Failed', 'Certified', 'Expired']} fill={[
          { match: { id: 'Active CCPOs' }, id: 'dots' },
          { match: { id: 'Practical Assessments' }, id: 'lines' },
          { match: { id: 'Written Exams' }, id: 'lines' },
        ]}/>);
  }
}
