import qs from 'qs';
//
import Globals from '../config/Globals';
//
export default class PersistentSearch {
  getAll() {
    const query = window.location.search?.substr(1); // remove ?
    return qs.parse(query);
  }

  hasPersistedSearch() {
    const allParams = this.getAll();
    return !!(allParams[Globals.URLQueryParam_SearchTerm]);
  }

  getParam(paramName) {
    const params = this.getAll();
    return params[paramName];
  }

  setParam(key, value) {
    //smart removal
    if (!value) {
      this._removeParams([key]);
      return;
    }
    //
    const currentParams = this.getAll();
    this._setQueryParams({ ...currentParams, [key]: value });
    return this;
  }
  
  setParams(object) {
    const currentParams = this.getAll();

    this._setQueryParams({ ...currentParams, ...object });

    return this;
  }

  setSearchTerm(searchValue, otherValues) {
    this._cleanParams()
    if (searchValue) this.setParams({ [Globals.URLQueryParam_SearchTerm]: searchValue })
    if (otherValues) this.setParams(otherValues);
  }

  // Private
  _setQueryParams(params) {
    const stringifiedParams = qs.stringify(params);

    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    if (stringifiedParams && stringifiedParams.length > 0) window.history.replaceState({}, "", `${baseUrl}?${stringifiedParams}`);
    else window.history.replaceState({}, "", `${baseUrl}`);
  }

  _removeParams(paramsToDelete) {
    const params = this.getAll();

    paramsToDelete.forEach(paramName => {
      delete params[paramName];
    });

    this._setQueryParams(params);

    return this;
  }

  _cleanParams() {
    this._removeParams([
      Globals.URLQueryParam_SearchTerm, Globals.URLQueryParam_Page,
      Globals.URLQueryParam_SortField, Globals.URLQueryParam_SortOrder,
    ]);
    return this;
  }
}
