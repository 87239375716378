import Globals from '../../../config/Globals';
//
export default class APIUserWorkHours {
  constructor(API) {
    this.api = API;
  }
  //Get user work hours object
  async getByID(userID, wkID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_UserWorkHours + wkID;
    return await req.exec();
  }
  //Get user work hours objects
  async getByUserID(userID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_UserWorkHours;
    return await req.exec();
  }
  //Create user work hours
  async create(workHoursObj, userID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_UserWorkHours + Globals.API_NewIDKeyword;
    req.body = { ...workHoursObj };
    return await req.exec();
  }
  //Update user work hours
  async update(workHoursObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_User + workHoursObj.userID + Globals.APIPath_UserWorkHours + workHoursObj.id;
    req.body = { ...workHoursObj };
    return await req.exec();
  }
  //Delete user work hours
  async delete(userID, workHoursID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_User + userID + Globals.APIPath_UserWorkHours + workHoursID;
    return await req.exec();
  }
}
