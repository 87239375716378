import Globals from '../../../config/Globals';
//
export default class APIFinancialTransaction {
  constructor(API) {
    this.api = API;
  }
  async getTransactionReceipt(userID, txID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = `/user/${userID}/receipt/${txID}`;
    req.bodyType = 'BINARY';
    return await req.exec();
  }
}
