import React from 'react';
import autoBind from 'react-autobind';
import { Alert, PageHeader, Layout, List, message, Row, Col, Icon, Button, Typography, Descriptions, Popover, Spin } from 'antd';
import { FilePicker } from 'react-file-picker'
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomComponent from '../../components/CustomComponent';
//
import config from '../../config/config';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import ImportUserOperation from '../../components/ImportUserOperation';
//
import '../../assets/stylesheets/AdminUsersImportView.scss';
//
const ImportingState = { INITIAL: 0, IMPORTING: 1, SUCCEEDED: 2, FAILED: 3, SKIPPED: 4 };

export default class AdminUsersImportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, importingData: [],
      importingState: ImportingState.INITIAL, importingIndex: 0 };

  }

  //Actions
  async handleFileSelect(file) {
    let csv = await Utils.parseCSV(file);
    csv = this._validateEntries(csv);
    this.setState({ importingData: csv, importingState: ImportingState.INITIAL, importingIndex: 0 });
  }
  handleFileSelectError(err) {
    this.props.app.alertController.showErrorAlert('Error!', err);
  }
  handleStartImport() {
    this.setState({ importingState: ImportingState.IMPORTING, importingIndex: 0 }, () => {
      this.importAtRow(0);
    });
  }
  handleResetImport() {
    this.setState({ importingData: [], importingState: ImportingState.INITIAL });
  }

  //UI
  render() {
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader className="pageHeader" title="Import users"
                    onBack={() => this.props.app.pushPage(config.ApplicationRoutes.homepage, null)}/>
         <Layout.Content>
          {this._renderSelectFile()}
          {this._renderSelectedFileContent()}
        </Layout.Content>
      </Layout.Content>
    );
  }
  /* private renders */
  _renderSelectFile() {
    if (this.state.importingData.length > 0) return (<></>);
    return (<div>
        <Row type="flex" justify="center" align="middle">
          <Alert
            message="Import information"
          description={(<>
            <Typography.Title level={4}>Please, use the following certification IDs when importing.</Typography.Title>
            <Typography.Paragraph className='importImportInfoParagraph'>• Certification: High Pressure Concrete Line Pumps (over 1,233 psi) - Use ID: PumpOperatorLineHigh</Typography.Paragraph>
            <Typography.Paragraph className='importImportInfoParagraph'>• Certification: Low Pressure Concrete Line Pumps (1,233 psi & under) - Use ID: PumpOperatorLineLow </Typography.Paragraph>
            <Typography.Paragraph className='importImportInfoParagraph'>• Tower Placing Booms - Use ID: PumpOperatorTower</Typography.Paragraph>
            <Typography.Paragraph className='importImportInfoParagraph'>• Truck Mounted Boom Pumps - over 41 metres - Use ID: PumpOperatorTruckOver</Typography.Paragraph>
            <Typography.Paragraph className='importImportInfoParagraph'>• Truck Mounted Boom Pumps - 41 metres & under - Use ID: PumpOperatorTruckUnder</Typography.Paragraph>
          </>)}
            type="info" showIcon
          />
        </Row>
        <Row type="flex" justify="center" align="middle" className="importRow">
          <Typography.Title level={3}>No CSV selected!</Typography.Title>
        </Row><Row type="flex" justify="center" align="middle">
          <FilePicker extensions={['csv']}
                      onChange={this.handleFileSelect}
                      onError={this.handleFileSelectError}>
                      <Button> Select a file </Button>
          </FilePicker>
        </Row>
      </div>);
  }
  _renderSelectedFileContent() {
    if (this.state.importingData.length <= 0) return (<></>);
    return (<div>
        <Row type="flex" justify="space-between" align="middle">
          <Col> <Typography.Title level={3}> {this._renderEntriesTitle()} </Typography.Title> </Col>
          <Col>
            <Button type='primary' onClick={this.handleStartImport} disabled={this.state.importingState != ImportingState.INITIAL}> Start Import </Button>
            <Button onClick={this.handleResetImport} type='secondary' style={{marginLeft: '20px'}}> Clear </Button>
          </Col>
        </Row>
        {this._renderEntriesTable()}
     </div>);
  }
  /* entries renders */
  _renderEntriesTitle() {
    if (this.state.importingState == ImportingState.INITIAL) {
      const failed = this.state.importingData.filter( (entry) => {
        return (entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].valid == false);
      });
      return (
        <span>
          {this.state.importingData.length} Entries waiting to be processed!
          {failed.length > 0 && <Typography.Text> - {failed.length} Items will be skipped due validation errors</Typography.Text>}
        </span>);
    } else if (this.state.importingState == ImportingState.IMPORTING) {
      return (<span> Importing {this.state.importingIndex+1}/{this.state.importingData.length}.. <Spin spinning/></span>);
    } else if (this.state.importingState == ImportingState.SUCCEEDED) {
      return (<span> Import finished!</span>);
    } else if (this.state.importingState == ImportingState.FAILED) {
      return (<span> Import failed!</span>);
    }
    return <></>;
  }
  _renderEntriesTable() {
    const succeeded = this.state.importingData.filter( (entry) => {
      return (entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.SUCCEEDED);
    });
    const failed = this.state.importingData.filter( (entry) => {
      return (entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.FAILED);
    });
    return (
      <List itemLayout="vertical" size="large" dataSource={this.state.importingData}
            renderItem={this._renderRow} header={
          <div>
            <b>{succeeded.length}</b> Items imported successfully!
            <br/>
            <b>{failed.length}</b> Items imported with error!
          </div>}
        />
    );
  }
  /* table row renders */
  _renderRow(item, index) {
    return (
      <div className='importUsersTableRow'>
        <Descriptions bordered title={`Entry #${index+1}`} size='small'>
          <Descriptions.Item label="First Name">{item[Globals.ImportUsers_CSV_COLUMNS.FirstName]}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{item[Globals.ImportUsers_CSV_COLUMNS.LastName]}</Descriptions.Item>
          <Descriptions.Item label="Email">{item[Globals.ImportUsers_CSV_COLUMNS.Email]}</Descriptions.Item>
          <Descriptions.Item label="Driver License">{item[Globals.ImportUsers_CSV_COLUMNS.DriverLicense]}</Descriptions.Item>
          <Descriptions.Item label="Mobile Number">{item[Globals.ImportUsers_CSV_COLUMNS.MobileNumber]}</Descriptions.Item>
          <Descriptions.Item label="Employer WSID">{item[Globals.ImportUsers_CSV_COLUMNS.EmployerWSID]}</Descriptions.Item>
          <Descriptions.Item label="Company Location">{item[Globals.ImportUsers_CSV_COLUMNS.CompanyLocation]}</Descriptions.Item>
          <Descriptions.Item label="Company Contact">{item[Globals.ImportUsers_CSV_COLUMNS.CompanyContact]}</Descriptions.Item>
          <Descriptions.Item label="Company Contact Phone">{item[Globals.ImportUsers_CSV_COLUMNS.CompanyContactPhone]}</Descriptions.Item>
          <Descriptions.Item label="Date of Birth">{item[Globals.ImportUsers_CSV_COLUMNS.DateOfBirth]}</Descriptions.Item>
          <Descriptions.Item label="Street address">{item[Globals.ImportUsers_CSV_COLUMNS.StreetAddress]}</Descriptions.Item>
          <Descriptions.Item label="City">{item[Globals.ImportUsers_CSV_COLUMNS.City]}</Descriptions.Item>
          <Descriptions.Item label="Postal Code">{item[Globals.ImportUsers_CSV_COLUMNS.PostalCode]}</Descriptions.Item>
          <Descriptions.Item label="Province">{item[Globals.ImportUsers_CSV_COLUMNS.Province]}</Descriptions.Item>
          <Descriptions.Item label="Country">{item[Globals.ImportUsers_CSV_COLUMNS.Country]}</Descriptions.Item>
          <Descriptions.Item label="Certification number">{item[Globals.ImportUsers_CSV_COLUMNS.CertificationNumber]}</Descriptions.Item>
          <Descriptions.Item label="Certification effective date">{item[Globals.ImportUsers_CSV_COLUMNS.EffectiveDate]}</Descriptions.Item>
          <Descriptions.Item label="Certification expiry date">{item[Globals.ImportUsers_CSV_COLUMNS.ExpiryDate]}</Descriptions.Item>
          <Descriptions.Item label="Certification ID">{item[Globals.ImportUsers_CSV_COLUMNS.CertificationID]}</Descriptions.Item>
          <Descriptions.Item label="Validation status">{this._renderValidationStatus(item, index)}</Descriptions.Item>
          <Descriptions.Item label="Import status">{this._renderImportStatus(item, index)}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
  _renderValidationStatus(item, index) {
    if (item[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].valid) {
      return <Icon className='passValIcon' type='check-circle'/> ;
    }
    let content = (<div className=''>
      <ul>
        {item[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].columns.map( (col, index) => {
          return <li key={index}>{col} Is invalid or missing!</li>
        })}
      </ul>
    </div>);
    return (
      <Popover content={content}><Icon className='failValIcon' type='close-circle'/> </Popover>
    );
  }
  _renderImportStatus(item, index) {
    if (item[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.IMPORTING) {
      return <Icon className='importWIPIcon' type='loading'/> ;
    } else if (item[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.SKIPPED) {
      return <Icon className='importSkipIcon' type='redo'/> ;
    } else if (item[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.SUCCEEDED) {
      return <Icon className='importCheckIcon' type='check-circle'/> ;
    } else if (item[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.FAILED) {
      return <Icon className='importFailIcon' type='close-circle'/> ;
    } return <></>;

  }
  /* private helpers */
  _validateEntries(csv) {
    for (let entry of csv) {
      for(let reqColumns of Globals.CSV_REQ_COLUMNS) {
        if (!entry[reqColumns] || entry[reqColumns].length <= 0) {
          if (entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus]) {
            console.log(entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].columns)
            entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus] = {
              valid: false,
              columns: entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].columns.concat([reqColumns])
            };
          } else {
            entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus] = {
              valid: false, columns: [reqColumns]
            };
          }
        }
      }
      //
      if (!entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus]) {
        entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus] = { valid: true };
      }
    } return csv;
  }

  /* import */
  async importAtRow(index) {
    const csv = this.state.importingData;
    const entry = csv[index];
    if (entry[Globals.ImportUsers_CSV_COLUMNS.ValidationStatus].valid) {
      //Display message and update state
      const msg = message.loading(`Creating user ${entry[Globals.ImportUsers_CSV_COLUMNS.Email]}`, 0);
      entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] = ImportingState.IMPORTING;
      this.setState({importingData: csv}, async () => {
        try {
          await ImportUserOperation.importUser(entry, this.props.app); //Import
          entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] = ImportingState.SUCCEEDED; //Update state to success
          message.destroy(); //remove message
          this.setState({importingData: csv}); //update state
          this._goToNext(index); //go to next if available
        } catch (e) {
          console.log(e)
          //Display error message
          this.props.app.alertController.showErrorAlert(`Could not import user ${entry[Globals.ImportUsers_CSV_COLUMNS.Email]}`, (e?.message || e?.err || e));
          entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] = ImportingState.FAILED;
          message.destroy(); //Destroy loading msg
          this.setState({importingData: csv}); //Update state
          this._goToNext(index); //go to next if available
        }
      });
    } else {
      //Display message
      message.warning(`Skipping user ${entry[Globals.ImportUsers_CSV_COLUMNS.Email]}`);
      entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] = ImportingState.SKIPPED;
      this.setState({importingData: csv});
      //display error
      this.props.app.alertController.showErrorAlert(`Could not import user ${entry[Globals.ImportUsers_CSV_COLUMNS.Email]}`, 'User is invalid!');
      this._goToNext(index); //go to next if available
    }
  }
  _goToNext(index) {
    //Has more to go, go to next
    if (this.state.importingData.length > index+1) {
      this.setState({importingIndex: index+1}, () => this.importAtRow(index+1));
    } else { //Finished
      //Check if all succeeded
      const succeeded = (this.state.importingData.filter( (entry) => {
        return (entry[Globals.ImportUsers_CSV_COLUMNS.ImportStatus] == ImportingState.SUCCEEDED);
      }).length > 0);
      //Set state appropriately and display message
      this.setState({ importingState: (succeeded ? ImportingState.SUCCEEDED : ImportingState.FAILED) });
      if (succeeded) message.success('Import users succeeded!');
      else message.error('Import users failed, check each entry import status!');
    }
  }
}
