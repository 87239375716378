import Globals from '../../../config/Globals';
//
export default class APIVault {
  constructor(API) {
    this.api = API;
  }
  async getVaultNonce(userID, userInfo) {
    const req = await this.api.newBaseRequest('POST');
    req.path = '/vault/nonce/' + (userID || '');
    req.body = { ...userInfo };
    return await req.exec();
  }
  async setVaultDefaultPaymentMethod(paymentMethodNonce, userID) {
    const req = await this.api.newBaseRequest('POST');
    if (userID) req.path = '/vault/defaultPayment/' + (userID || '');
    else req.path = '/vault/defaultPayment';
    req.body = { paymentMethodNonce };
    return req.exec();
  }
}
