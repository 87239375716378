import Globals from '../config/Globals';
import Utils from '../components/Utils';
//
export default class DataUtils {}
DataUtils.processUserCertifications = function(userCerts, tenantCerts) {
  let certs = tenantCerts;
  if (userCerts.length > 0) {
    for (let i = 0; i < userCerts.length; i++) { //remove from specs if already have the cert
      certs = certs.filter(cert => cert.id !== userCerts[i].certificationID);
      certs.push(userCerts[i]);
    }
  }

  let DOWN = ()=> { return (Utils.isFirefox() ? -1 : 1); };
  let UP = () => { return (Utils.isFirefox() ? 1 : -1); };
  let STAY = ()=> { return 0; };
  //Sort
  // 1. inprogress  2. completed  3. new
  certs.sort( (a,b) => {
    if (a.state != Globals.CertificationProcess_State.COMPLETED &&
        a.state != Globals.CertificationProcess_State.FAILED &&
        a.state != Globals.CertificationProcess_State.CANCELLED) {
      if (!b.state) return UP(); //no proc on B, go UP()
      if (a.state == b.state) return (a.updatedOn - b.updatedOn ? DOWN() : UP());
      return UP();
    } else if (a.state == Globals.CertificationProcess_State.COMPLETED) {
      if (!b.state) return UP();
      if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
      if (b.state == Globals.CertificationProcess_State.FAILED || b.state == Globals.CertificationProcess_State.CANCELLED) return UP();
      return DOWN();
    } else if (a.state == Globals.CertificationProcess_State.FAILED) {
      if (!b.state) return UP();
      if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
      if (b.state == Globals.CertificationProcess_State.CANCELLED) return UP();
      return DOWN();
    } else if (a.state == Globals.CertificationProcess_State.CANCELLED) {
      if (!b.state) return UP();
      if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
      return DOWN();
    }  else {
      if (a.state && b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
      else if (!a.state) return UP();
      else if (!b.state) return DOWN();
    } return STAY();
  });
  //
  return certs;
};
