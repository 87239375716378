import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row } from 'antd';

import CustomComponent from '../../components/CustomComponent';
import Globals from '../../config/Globals';
import config from '../../config/config';

class UserProfileStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    if (this.props.delegate) this.props.delegate.profileStep = this;
    this.state = {
      isLoading: false,
      isVisible: false,
      isFormValidProfile: false,
    };
  }

  componentDidMount = async () => {
    const personal = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    try {
      if (personal && personal.value) {
        // setting initial values
        this.props.form.setFieldsValue({
          ...personal.value.address,
          mobilePhone: personal.value.mobilePhone,
        });
      }

      // I called the validation to show explicitly to user the fields that they need to fill
      this.props.form.validateFields();
      this.props.onChange({ isFormValidProfile: true });
    } catch (err) {
      console.log(err);
      this.props.onChange({ isFormValidProfile: false });
    }
  };

  handleValidation = async event => {
    try {
      await this.props.form.setFieldsValue({ [event.target.name]: event.target.value });
      const resp = await this.props.form.validateFields();
      this.props.onChange({ isFormValidProfile: true });
    } catch (err) {
      console.log('error ', err);
      this.props.onChange({ isFormValidProfile: false });
    }
  };

  handleSubmit = async () => {
    let resp = '';
    try { resp = await this.props.form.validateFields(); }
    catch (err) {
      resp = err;
      console.log(err);
    } return resp;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <Form>
          <Divider orientation="left">{Globals.LABEL_ADDRESS_SECTION}</Divider>
          <Row>
            <Col span={12}>
              <Form.Item label={`${Globals.LABEL_STREET}`}>
                {this.props.form.getFieldDecorator(`street[0]`, {
                  rules: [{ required: true, message: 'Please, type your street name!', whitespace: true }],
                })(<Input id="0" name="street" allowClear onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
            <Col span={11} style={{ marginLeft: 5 }}>
              <Form.Item label="Street Address 2">
                {this.props.form.getFieldDecorator(`street[1]`, {})(
                  <Input id="1" name="street2" allowClear onChange={this.handleValidation} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label={`${Globals.LABEL_CITY}`}>
                {this.props.form.getFieldDecorator('city', {
                  rules: [{ required: true, message: 'Please, type your city name!', whitespace: true }],
                })(<Input id="0" name="city" allowClear onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
            <Col span={8} style={{ marginLeft: 5 }}>
              <Form.Item label={`${Globals.LABEL_COUNTRY}`}>
                {this.props.form.getFieldDecorator('country', {
                  rules: [{ required: true, message: 'Please, type your country name!', whitespace: true }],
                })(<Input id="0" name="country" allowClear onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
            <Col span={7} style={{ marginLeft: 5 }}>
              <Form.Item label={`${Globals.LABEL_POSTAL_CODE}`}>
                {this.props.form.getFieldDecorator('postalCode', {
                  rules: [{ required: true, message: 'Please, type your postal code!', whitespace: true }],
                })(<Input id="0" name="postalCode" allowClear onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item label={`${Globals.LABEL_PROVINCE}`}>
                {this.props.form.getFieldDecorator('province', {
                  rules: [{ required: true, message: 'Please, type your province name!', whitespace: true }],
                })(<Input id="0" name="province" allowClear onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
            <Col span={8} style={{ marginLeft: 5 }}>
              <Form.Item label={`${Globals.LABEL_MOBILE_PHONE}`}>
                {getFieldDecorator('mobilePhone', {
                  rules: [{ required: true, message: 'Please input your mobile phone number!', whitespace: true }],
                })(<Input style={{ width: '100%' }} onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
            <Col span={7} style={{ marginLeft: 5 }}>
              <Form.Item label={`${Globals.LABEL_OTHER_PHONE}`}>
                {getFieldDecorator('phone', {})(<Input style={{ width: '100%' }} onChange={this.handleValidation} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default Form.create({})(UserProfileStep);
