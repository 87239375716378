import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, Tooltip, Modal, Icon } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonUserSearchBar from '../commonComponents/CommonUserSearchBar';
//
import config from '../../config/config';
//
import '../../assets/stylesheets/AdminSearchUsersView.scss';
//
export default class AdminPendingWaiveRequestsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false, sortedInfo: {},
      data: [],
    };
  }

  componentDidMount() {
    this.fetchData(true);
  }

  async fetchData(updateCache = false) {
    if (updateCache) {
      await this.props.app.sharedCache().loadWaives();
    }

    const waiveRequests = this.props.app.sharedCache().getWaiveRequests();
    this.setState({ data: waiveRequests });
  }

  // Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  handleApproveWaiveRequest(record) {
    Modal.confirm({
      title: 'Confirm',
      icon: <Icon type="exclamation-circle" />,
      content: `Are you sure that you want to Approve ${record.userObj.firstName} ${record.userObj.lastName}'s Waive Request?`,
      onOk: () => {
        this._approve(record.userID, record.id);
      },
      onCancel() {},
    });
  }

  handleRejectWaiveRequest(record) {
    Modal.confirm({
      title: 'Confirm',
      icon: <Icon type="exclamation-circle" />,
      content: `Are you sure that you want to Reject ${record.userObj.firstName} ${record.userObj.lastName}'s Waive Request?`,
      onOk: () => {
        this._reject(record.userID, record.id);
      },
      onCancel() {},
    });
  }

  handleOpenWorkHours(record) {
    this.props.app.pushPage(config.ApplicationRoutes.userWorkHours, null, record.userID);
  }

  // UI
  render() {
    const { sortedInfo } = this.state;
    const columns = [
      {
        title: 'User', dataIndex: 'userObj', key: 'user', width: '15%',
        sorter: (a, b) => (a.firstName + ' ' + a.lastName).localeCompare((b.firstName + ' ' + b.lastName)),
        sortOrder: sortedInfo.columnKey === 'user' && sortedInfo.order,
        render: (user) => `${user.firstName} ${user.lastName}`,
      },
      {
        title: 'Date', dataIndex: 'updatedOn', key: 'updatedOn', width: '15%',
        sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn),
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
        render: (date) => Utils.getDateAndTimeOnUIFormatByTimestamp(date),
      },
      {
        title: 'Certification', dataIndex: 'certificationDescription', key: 'certificationDescription', width: '15%',
        sorter: (a, b) => a.certificationDescription.localeCompare(b.certificationDescription),
        sortOrder: sortedInfo.columnKey === 'certificationDescription' && sortedInfo.order
      },
      {
        title: 'Actions', key: 'actions', width: '15%',
        render: (record) => (
          <>
            <Tooltip title="View">
              <Button icon="eye" onClick={() => this.handleOpenWorkHours(record)} />
            </Tooltip>
            {' '}
            <Tooltip title="Approve">
              <Button icon="check" onClick={() => this.handleApproveWaiveRequest(record)} />
            </Tooltip>
            {' '}
            <Tooltip title="Reject">
              <Button icon="close" onClick={() => this.handleRejectWaiveRequest(record)} />
            </Tooltip>
          </>
        ),
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      pagination: { pageSize: 100, hideOnSinglePage: true},
    };

    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Pending Waive Requests"
                      onBack={() => this.props.app.pushPage(config.ApplicationRoutes.homepage)}/>
          <Layout.Content>
            <Table className="adminSearchUsersTable" onRow={this._onRow}
                   columns={columns} dataSource={this.state.data} {...props}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /**
   * Private
   */

  // Api calls
  async _approve(userID, certID) {
    this.startLoading();

    const resp = await this.props.app.api.userCertification.approveWaiveRequest(userID, certID);

    if (resp.statusCode == 200) {
      await this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _reject(userID, certID) {
    this.startLoading();

    const resp = await this.props.app.api.userCertification.rejectWaiveRequest(userID, certID);

    if (resp.statusCode == 200) {
      await this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
