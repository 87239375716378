import React from 'react';
import autoBind from 'react-autobind';
import { Button, Col, Row, Avatar, Typography, List, Icon, Drawer } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonAuditFilter from './CommonAuditFilter';
//
import Utils from '../../components/Utils';
//
import Globals from '../../config/Globals';
import config from '../../config/config';
//
import '../../assets/stylesheets/AdminAuditingDrawer.scss';
//
export default class AdminAuditingDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, rawEvents: null, events: null, isVisible: false };
  }
  //Public
  lazyLoad() {
    if (!this.state.rawEvents && !this.state.isLoading) { this.fetchData(); }
  }
  //API
  async fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //Get personal part
    const auditEvents = await this.props.app.api.audit.getEventsByCertID(this.props.certProcID);
    if (!this._isMounted) return;
    if (auditEvents.statusCode == 200 && auditEvents.body && auditEvents.body.events) {
      //sort events by date
      auditEvents.body.events.sort( (a,b) => a.createdOn - b.createdOn);
      this.setState({ isLoading: false, rawEvents: auditEvents.body.events, events: auditEvents.body.events}, () => {
        this.handleFilterChange(1);
      });
    } else {
      this.props.app.alertController.showErrorAlert(null, auditEvents);
      this.stopLoading();//avoid double render and set stoploading only on error
    }
  }
  //Actions
  handleFilterChange(eventLevel) {
    let newEvents = [];
    for (let event of this.state.rawEvents) if (!eventLevel || event.eventLevel == eventLevel) newEvents.push(event);
    this.setState({events: newEvents});
  }
  handleToogleAuditDrawer() {
    if (!this.state.visible) { this.lazyLoad(); }
    this.setState({visible: !this.state.visible});
  }
  //UI
  render() {
    const safeEvents = (this.state.events ? this.state.events : []);
    return (
      <Drawer title="Auditing Events" placement="right" closable={true} mask={false} onClose={this.handleToogleAuditDrawer}
              visible={this.state.visible} getContainer={false} className='auditDrawer' width={400} keyboard={true}
              maskClosable={true}>
        <div className='auditButtonContainer'>
          <Button onClick={this.handleToogleAuditDrawer} className='auditButton -leave'>
            <Icon className='auditIconButton' type="security-scan" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.light_red}/>
          </Button>
        </div>
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Row type="flex" justify="space-between">
          <Col>
            <Button onClick={this.fetchData} className='auditRefreshButton -leave'>
              <Icon type="sync" spin={this.state.isLoading}/>
            </Button>
          </Col>
          <CommonAuditFilter filterChange={this.handleFilterChange}/>
        </Row>
        <List itemLayout="horizontal" loading={this.state.isLoading} dataSource={safeEvents} renderItem={item => (
            <List.Item>
              <Col className='iconCol'>
                <div className='icon'>
                  {(item.eventLevel == 1 ?
                    <Icon type="security-scan" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.light_red}/> :
                    (item.eventLevel == 2 ?
                      <Icon type="lock" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.orange}/> :
                      <Icon type="info-circle" theme="twoTone" twoToneColor={Globals.COLOR_PALETTE.grey}/>
                    )
                  )}
                </div>
              </Col><Col className='textCol'>
                <Row> <Typography.Text className='title'>{Utils.capitalizeString(item.eventType.replace('_', ' '))}</Typography.Text> </Row>
                <Row> <Typography.Text className='subtitle'> Event was created on: {Utils.getDateAndTimeOnUIFormatByTimestamp(item.createdOn)} </Typography.Text> </Row>
              </Col>
            </List.Item>
          )}/>
      </Drawer>
    );
  }
}
