import React from 'react';
import autoBind from 'react-autobind';
import { ResponsiveBar } from '@nivo/bar';
import { Table, Row, Col, Typography, Spin, Icon } from 'antd';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
export default class AdvisorDashboardScheduledSection extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { };
  }
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    return (
      <Col className='advDashboardSectionColumn'>
        <Row className='advDashboardSectionTitleRow' type="flex" justify="space-between">
          <Typography.Text className='advDashboardSectionTitle'>Exams Ready to be Scheduled</Typography.Text>
        </Row><Row type="flex" align="middle" align='middle'>
          <Col className='advDashboardSectionTable'>
            { this.props.isLoading ?
              <Spin className='advDashboardSectionLoad' indicator={ <Icon type="loading" style={{ fontSize: 40 }} spin />}/> :
              this._renderTable()
            }
          </Col>
        </Row>
      </Col>
    )
  }
  _renderTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [{
      title: 'Candidate Name', key: 'name',  width: '30%',
      sorter: (a, b) => (a.userObj.firstName + ' ' + a.userObj.lastName).localeCompare((b.userObj.firstName + ' ' + b.userObj.lastName)),
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      render: d => (d.userObj && d.userObj.firstName ? d.userObj.firstName + ' ' + d.userObj.lastName : '-'),
     },
     {
       title: 'Certification Type', key: 'cert', width: '50%', render: d => {
         return  this.props.app.sharedCache().getCertificationByID(d.certification.certificationID).description;
       },
       sortOrder: sortedInfo.columnKey === 'cert' && sortedInfo.order,
       sorter: (a, b) => {
         const aDesc = this.props.app.sharedCache().getCertificationByID(a.certification.certificationID).description;
         const bDesc = this.props.app.sharedCache().getCertificationByID(b.certification.certificationID).description;
         return aDesc.localeCompare(bDesc);
       },
     },
     {
       title: 'Payment Date', dataIndex: 'updatedOn', key: 'updatedOn', width: '20%',
       sorter: (a, b) => a.updatedOn - b.updatedOn,
       render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props),
       sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
       defaultSortOrder: 'descending', defaultSortColumn: true
     }];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: 'No scheduled exams!'},
                    pagination: { pageSize: 100, hideOnSinglePage: true},
                    scroll:{ y: 'calc(60vh - 155px)' }, size: 'small',
                    onRow: this._onRow
                  };
    return (
      <Table className="advisorReadyToScheduleTable" columns={columns} dataSource={this.props.exams} {...props}/>
    );
  }
  onRowSelection(val) { this.props.onSelection(val); }
  _onRow(record) {
    return {
      onClick: () => {
        this.onRowSelection(record);
      }, // click row
      onDoubleClick: () => {
        this.onRowSelection(record);
      }, // double click row
    };
  }
}
