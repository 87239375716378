import URLQuery from 'query-string-manipulator';
//
const Parser = require('fast-xml-parser');
const he = require('he');
//
export default class APIRequest {
  constructor(method) {
    this.baseURL = null;
    this.path = '';
    this.method = method;
    this.headers = {};
    this.body = {};
    this.queryParameters = {};
    this.bodyType = 'JSON';
  }

  async exec() {
    try {
      // Build URL
      let reqURL = null;
      if (Object.keys(this.queryParameters).length > 0) reqURL = URLQuery(this.baseURL + this.path, { set: this.queryParameters });
      else reqURL = this.baseURL + this.path;
      // inject IE polyfill for fetch
      await import('whatwg-fetch');
      // Make request
      const respObj = await fetch(reqURL, this._buildRequest());
      //Build and return response
      return await this._buildResponse(respObj);
    } catch (e) {
      return { error: e, statusCode: -1 };
    }
  }
  //
  appendHeader(key, value) {
    this.headers[key] = value;
  }
  appendQueryParam(key, value) {
    this.queryParameters[key] = value;
  }
  /* private */
  _buildRequest() {
    let request = {
      method: this.method,
      redirect: 'follow',
      ...(Object.keys(this.headers).length > 0 ? { headers: this.headers } : {}),
    };
    if (this.bodyType == 'JSON' || this.bodyType == 'JSON/BLOB') {
      if (Object.keys(this.body).length > 0) request.body = JSON.stringify(this.body);
      else if (['POST', 'PUT', 'PATCH'].includes(this.method.toUpperCase())) request.body = JSON.stringify({})
    } else if (this.bodyType == 'XML') {
      if (Object.keys(this.body).length > 0) request.body = this._encodeToXML(this.body);
    } else if (this.bodyType == 'BINARY') {
      if (['POST', 'PUT', 'PATCH'].includes(this.method.toUpperCase()) && this.body && this.body.length > 0) request.body = this.body;
    } else {
      if (['POST', 'PUT', 'PATCH'].includes(this.method.toUpperCase()) && this.body) request.body = this.body;
    }
    return request;
  }
  async _buildResponse(respObj) {
    const contentType = respObj.headers.get('Content-Type');
    if (respObj.status == 204) {
      return { statusCode: respObj.status };
    } else if (contentType && contentType.includes('json')) {
      return { body: await respObj.json(), statusCode: respObj.status };
    } else if (this.bodyType == 'BLOB' || this.bodyType == 'JSON/BLOB') {
      return { body: await respObj.blob(), statusCode: respObj.status };
    } else if (contentType && contentType.includes('xml')) {
      return { body: this._decodeXML(await respObj.text()), statusCode: respObj.status };
    } else if (contentType && contentType.includes('image') || this.bodyType == 'BINARY') {
      return { body: await respObj.text(), statusCode: respObj.status };
    } return { body: await respObj.json(), statusCode: respObj.status };
  }



  /* xml support*/
  _decodeXML(val) {
    let retVal = null;
    try {
      const options = {
        attributeNamePrefix : "@_",     textNodeName : "#text",
        ignoreAttributes : true,        ignoreNameSpace : false,
        allowBooleanAttributes : false, parseNodeValue : true,
        parseAttributeValue : false,    trimValues: true,
        cdataTagName: "__cdata",        attrNodeName: "attr",
        cdataPositionChar: "\\c",       parseTrueNumberOnly: false,
        localeRange: "",
        attrValueProcessor: a => he.decode(a, {isAttributeValue: true}),
        tagValueProcessor : a => he.decode(a)
      };
      // Intermediate obj
      let tObj = Parser.getTraversalObj(val, options);
      retVal = Parser.convertToJson(tObj, options);
    } catch (e) { console.error('Error while decoding XML', e, val); }
    return retVal;
  }
  _encodeToXML(val) {
    let retValue = null;
    try {
      const defaultOptions = {
          attributeNamePrefix : "@_",  attrNodeName: "@",
          textNodeName : "#text",      ignoreAttributes : true,
          cdataPositionChar: "\\c",    format: false,
          indentBy: "  ",              supressEmptyNode: false,
          cdataTagName: "__cdata",
          tagValueProcessor: a => {
            return he.encode(a + "", { useNamedReferences: true});
          },// default is a=>a
          attrValueProcessor: a => {
            return (he.encode(a, {useNamedReferences: true}))
          }
      };
      let parser = new Parser.j2xParser(defaultOptions);
      retValue = parser.parse(val);
    } catch (e) { console.error('Error while encoding to XML', e, val, e.stack); }
    //append XML header
    if (retValue) retValue = '<?xml version="1.0" encoding="UTF-8"?>' + retValue;
    return retValue;
  }
}
