import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Button, message, Modal } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
// import Utils from '../../components/Utils';
// import Globals from '../../config/Globals';
//
//import config from '../../config/config';
import '../../assets/stylesheets/CommonContactModal.scss';
//
const { TextArea } = Input;
//props handleSubmissionComplete, handleCancel
class CommonContactModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false };
  }
  loadAuthorizedInfo() {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    if (ccpoUser) {
      this.props.form.setFieldsValue({
        name: ccpoUser.firstName + '' + ccpoUser.lastName,
        email: ccpoUser.email,
      });
    }
  }
  //Actions
  handleCancel() {
    this.props.handleCancel();
  }
  async handleSubmit() {
    const resp = await this.props.form.validateFields();
    if (resp) this._submitContactForm(resp);
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        closable={false}
        title="Contact Us"
        footer={null}
        className="contactUsModal"
        visible={this.props.isVisible}
        confirmLoading={this.state.isLoading}
      >
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subcomponents */
  _renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key="contactUs" className="contactUsForm">
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label="Full name">
              {this.props.form.getFieldDecorator(`name`, {
                rules: [{ required: true, message: 'Please, type your full name!' }],
              })(<Input id="name" name="name" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label="Contact Email">
              {this.props.form.getFieldDecorator(`email`, {
                rules: [{ required: true, message: 'Please, type your email!' }],
              })(<Input id="email" name="email" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label="Contact Text">
              {getFieldDecorator('comments', {
                rules: [{ required: true, message: 'Please, type your contact text' }],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary"
          loading={this.state.isLoading} onClick={this.handleSubmit} disabled={this.state.isLoading} className="contactUsSubmitButton"> Submit </Button>
      </Row>
    );
  }
  /* private methods */
  async _submitContactForm(data) {
    this.setState({ isLoading: true });
    const contactResp = await this.props.app.api.tenant.sendContactForm({
      name: data.name,
      email: data.email,
      comments: data.comments,
    });
    if (!this._isMounted) return;
    console.debug('Contact form resp:', contactResp, data);
    if (contactResp.statusCode == 200) {
      this.setState({ isLoading: false });
      message.success('Contact form submitted with success!');
      this.props.form.resetFields();
      this.props.handleSubmissionComplete();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, contactResp);
    }
  }
}

export default Form.create({})(CommonContactModal);
