import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout, PageHeader, Table, Row, Col, Tooltip, Button, Card, Skeleton, Divider, Tag, Popconfirm, Icon } from 'antd';
import moment from 'moment';
import { format } from 'date-fns';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonWorkHoursForm from '../commonSubviews/CommonWorkHoursForm';
//
// import config from '../../config/config';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonWorkHoursView.scss';
//
export default class CommonWorkHoursView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.customUserID = null;
    this.state = {
      isLoading: false,
      firstLoad: true,
      workhours: [],
      editingWorkHour: null, isEditing: false,
      isAdding: false
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    //Check if custom user parameter set (admin function)
    this.customUserID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    console.log('ID',this.customUserID)
    if (this.state.firstLoad) this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return; //Important, check if is mounted
    let id = (this.customUserID ? this.customUserID : this.props.app.sharedCache().getCCPOUser().id);
    const userWorkHours = (await this.props.app.api.userWorkHours.getByUserID(id));
    this.loadResponse(userWorkHours);
  }
  loadResponse(userWorkHours) {
    console.debug(userWorkHours)
    if (!this._isMounted) return; //check if is mounter before processing response and setting state!
    if (userWorkHours.statusCode == 200 && userWorkHours.body && userWorkHours.body.workHours) { //valid data state
      this.state.workHours = userWorkHours.body.workHours.map( item => {
        return {
          key: item.id,
          hours: item.hours,
          initDate: item.initDate != -1 ? format(new Date(item.initDate), 'yyyy-MM-dd') : '',
          endDate: item.endDate != -1 ? format(new Date(item.endDate), 'yyyy-MM-dd') : '',
          pumpCategories: item.pumpCategories ? item.pumpCategories : [],
          attachments: item.attachments ? item.attachments : [],
        }
      });
      this.state.editingWorkHour = null;
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, userWorkHours);
      this.state.workHours = [];
      this.state.editingWorkHour = null;
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  //Actions
  handleEdit(record) {
    this.setState({
      isEditing: true,
      isAdding: false,
      editingWorkHour: record,
    });
  };
  handleCancel() {
    this.workHoursForm.clearValues();
    this.setState({ isEditing: false, editingWorkHour: null, isAdding: false})
  }
  handleSave(data, files) {
    let initDate = '';
    let endDate = '';
    const { numberOfHours, pumpCategories } = data;
    //convert required values!
    initDate = moment(data.period[0]).toDate().getTime();
    endDate = moment(data.period[1]).toDate().getTime();
    const workHours = { initDate, endDate, hours: numberOfHours, pumpCategories, attachments: files,
                        id: (this.state.editingWorkHour ? this.state.editingWorkHour.key : '') };
    //Call API
    if (this.state.isAdding) this._createWorkHours(workHours);
    else this._updateWorkHours(workHours);
  };
  handleDelete = async record => { this._deleteWorkHours(record) };
  async openFile(file) {
    this.setState({isLoading: true});
    let id = (this.customUserID ? this.customUserID : this.props.app.sharedCache().getCCPOUser().id);
    const response = await this.props.app.api.userFile.signedGetURL(id, file.uid);
    if (!this._isMounted) return;
    if (response.statusCode != 200 || !response.body.fileURL) {
      console.log('ERROR while getting user work hours file');
      this.props.app.alertController.showErrorAlert('Error', Globals.ERROR_MESSAGE_GET_WORK_HOURS_FILES);
    } else this.props.app.openExternalPage(response.body.fileURL, false);
    this.setState({isLoading: false});
  };
  //UI
  render() {
    let id = (this.customUserID ? this.customUserID : (
      this.props.app.sharedCache().getCCPOUser() ? this.props.app.sharedCache().getCCPOUser().id : null
    ));
    return (
      <Layout.Content className="dashboard userDashboard">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" onBack={() => window.history.back()} title="Work Hours" />
          <Layout.Content>
            <Row justify="end" align="middle">
              <Col style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <Tooltip title={this.state.isAdding ? 'Cancel' : 'Add new Work Hours'} placement="left">
                  <Button type='primary'
                          icon={this.state.isAdding ? 'minus' : 'plus'} size="large"
                          onClick={() => this.setState({ isAdding: !this.state.isAdding })}
                          disabled={this.state.isEditing}/>
                </Tooltip>
              </Col>
            </Row>
            {(this.state.isAdding || this.state.editingWorkHour) && (
              <Card id={`isEditing-${this.state.isEditing}`} style={{ marginBottom: 10 }}>
                {!this.state.isLoading ? (
                  <CommonWorkHoursForm
                    initialData={this.state.isEditing ? this.state.editingWorkHour : null}
                    userID={id} app={this.props.app} handleSave={this.handleSave}
                    handleCancel={this.handleCancel}
                    wrappedComponentRef={ref => { this.workHoursForm = ref }}/>
                ) : (
                  <Skeleton paragraph={{ rows: 3 }} active />
                )}
              </Card>
            )}
            <Card className="workHoursCard">
              <Divider orientation="left">History of Worked Hours</Divider>
              <Table dataSource={this.state.workHours}>
                <Table.Column title="Hours" dataIndex="hours" key="hours" />
                <Table.Column title="From" dataIndex="initDate" key="initDate" />
                <Table.Column title="To" dataIndex="endDate" key="endDate" />
                <Table.Column title="Pump Categories" dataIndex="pumpCategories"
                        key="pumpCategories" render={pump => (
                          <span>
                            {pump.map(tag => (
                              <Tag key={tag}> {tag} </Tag>
                            ))}
                          </span>
                        )}/>
          <Table.Column title="Attachments" dataIndex="attachments"
                        key="attachments" render={file => (
                          <span>
                            {file.map(tag => (
                              <Tag key={tag.uid}>
                                <Button type="link" onClick={() => this.openFile(tag)}>
                                  <Icon type="file" /> {tag.name}
                                  </Button>
                                </Tag>
                              ))}
                            </span>
                          )}/>
          <Table.Column title="Action" key="action"
                        render={(text, record) => (
                          <span>
                            <Button type="link" onClick={() => this.handleEdit(record)}> Edit </Button>
                            <Divider type="vertical" />
                            <Popconfirm title="Are you sure delete this reported Work Hour?"
                                        onConfirm={() => this.handleDelete(record)}
                                        okText="Yes"
                                        cancelText="No"> <Button type="link">Delete</Button> </Popconfirm>
                          </span>
                  )}/>
              </Table>
            </Card>
          </Layout.Content>
      </Layout.Content>
    );
  }


  /* private */
  async _createWorkHours(workHoursObj) {
    this.startLoading();
    let id = (this.customUserID ? this.customUserID : this.props.app.sharedCache().getCCPOUser().id);
    const whCreateResp = await this.props.app.api.userWorkHours.create(workHoursObj, id);
    if (!this._isMounted) return;
    if (whCreateResp.statusCode == 200 && whCreateResp.body) {
      message.success('Work hours created!');
      //Update UI and ask to update work hours
      this.setState({ isAdding: false }, () => { this.fetchData() });
    } else {
      console.log('Error while creating work hours: ', whCreateResp);
      this.props.app.alertController.showAPIErrorAlert(null, whCreateResp);
      this.stopLoading();
    }
  }
  async _updateWorkHours(workHoursObj) {
    console.log(workHoursObj);
    this.startLoading();
    let id = (this.customUserID ? this.customUserID : this.props.app.sharedCache().getCCPOUser().id);
    const whUpdateResp = await this.props.app.api.userWorkHours.update({
      ...workHoursObj, userID: id
    });
    if (!this._isMounted) return;
    if (whUpdateResp.statusCode == 200 && whUpdateResp.body) {
      message.success('Work hours updated!');
      //Update UI and ask to update work hours
      this.setState({
        isEditing: false,
        editingWorkHour: null
      }, () => { this.fetchData() });
      //data synced
    } else {
      console.log('Error while updating work hours: ', whUpdateResp);
      this.props.app.alertController.showAPIErrorAlert(null, whUpdateResp);
      this.stopLoading();
    }
  }
  async _deleteWorkHours(workHours) {
    this.startLoading();
    let id = (this.customUserID ? this.customUserID : this.props.app.sharedCache().getCCPOUser().id);
    const whDeleteResp = await this.props.app.api.userWorkHours.delete(id, workHours.key);
    if (!this._isMounted) return;
    if (whDeleteResp.statusCode == 200 && whDeleteResp.body) {
      message.success('Work hours deleted!');
      this._deleteImages(workHours.attachments, id); //delete without waiting
      this.fetchData()
    } else {
      console.log('Error while deleting work hours: ', whDeleteResp);
      this.props.app.alertController.showAPIErrorAlert(null, whDeleteResp);
      this.stopLoading();
    }
  }
  async _deleteImages(images, userID) {
    for (let file of images) {
      await this.props.app.api.userFile.deleteImage(file.uid, userID);
      console.debug('deleting file', file);
    }
  }
}
