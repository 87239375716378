import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, Select, InputNumber, Button, message,
         Steps, Spin, Modal, Result, Popover, Icon } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import config from '../../config/config';
import '../../assets/stylesheets/CommonPaymentModal.css'; //inherits
import '../../assets/stylesheets/CommonExamCancellationModal.scss';
//
const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
const styleHidden = {style: { display: 'none' }};
const STEPS = {
  INPUT: 0,
  PROCESSING: 1,
  SUCCESS: 2
};
//
class CommonExamCancellationModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, stepper: { current: STEPS.INPUT, status: 'waiting', error: null },
                   currentExamSpecs: null, currentExam: null, rule: null, refundValue: 0, originalValue: 0};
  }
  //
  loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    //Calculate transaction values
    const rule = this._calculateProposedCancellationRule(examSpecs, examObj);
    const refundValue = this._calculateProposedCancellationValue(rule, examObj);
    //Calculate Values
    const taxAmount = refundValue * (examSpecs.taxPercentage / 100.0); //calculate tax amount
    const totalAmount = Utils.safelyFixCurrency(refundValue + taxAmount); //Calculate total amount (amount + taxes)
    this.setState({currentExamSpecs: examSpecs, refundValue, rule: rule, originalValue: refundValue, currentExam: examObj }, () => {
      // set dynamic initial values
      this.props.form.setFieldsValue({
        certificationDescription: currentCert.certificationDescription,
        examDescription: examSpecs.description, taxAmount, totalAmount,
        paymentValue: examObj.transaction.totalValue, amount: refundValue,
        paymentDate: Utils.getDateAndTimeOnUIFormatByTimestamp(examObj.transaction.updatedOn)
      });
    });
  }

  //Actions
  handleCancel() { this.props.onCancel(); }
  handleAfterClose() {
    this.setState({ isLoading: false, stepper: { current: STEPS.INPUT, status: 'waiting' }, payload: {},
                    currentExamSpecs: null, rule:null, currentExam: null, refundValue: 0, originalValue: 0});
  }
  async handleSubmit() {
    if (this.state.stepper.current == STEPS.INPUT) {
      const resp = await this.props.form.validateFields(['amount', 'comments', 'taxAmount', 'totalAmount']);
      if (resp) this._refundPayment(resp)
    } else if (this.state.stepper.current == STEPS.PROCESSING) { //error! - try again
      this.props.form.resetFields();
      this.setState({ ...this.state, isLoading: false, stepper: { current: STEPS.INPUT, status: 'waiting' }, payload: {} }, () => {
        this.loadModalInfo();
      });
    } else if (this.state.stepper.current == STEPS.SUCCESS) { //completed! - done
      this.props.onChange();
    }
  };
    //form change
  handleChange(value, id) {
    let amount = this.state.refundValue;
    //Check for the amount to be set
    if (id == 'amount') amount = value;
    else return;
    //Recalculate transaction value
    let refundValue = Utils.safelyFixCurrency(amount); //Calculate total amount (amount + taxes)
    //Calculate Values
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const taxAmount = refundValue * (this.state.currentExamSpecs.taxPercentage / 100.0); //calculate tax amount
    const totalAmount = Utils.safelyFixCurrency(refundValue + taxAmount); //Calculate total amount (amount + taxes)
    //
    if (isNaN(totalAmount)) {
      this.props.form.setFieldsValue({ amount: 0, taxAmount: 0, totalAmount: 0 });
    } else this.props.form.setFieldsValue({ taxAmount, totalAmount });
    //keep it reflected on state for security purposes (element values are easier to change)
    this.setState({refundValue: refundValue});
  };
  render() {
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = examType + ' Exam Payment ' + (this.state.refundValue == 0 ? 'Cancellation' : 'Refund');
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null}>
         <Steps {... this.state.stepper} size='small' className='paymentSteps'>
           <Step title={`${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'} Info`}/>
           <Step title={`Processing ${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'}`}/>
           <Step title="Completed"/>
         </Steps>
        {this._renderInputForm()}
        {this._renderFinalStepsView()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderInputForm() {
    const { getFieldDecorator } = this.props.form;
    const visible = (this.state.stepper.current == STEPS.INPUT);
    return (
      <Form {... (visible ? {} : styleHidden)}>
        <Divider orientation="left">{Globals.LABEL_PAYMENT_INFO}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`)(<Input disabled id="certificationDescription" name="certificationDescription" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={Globals.LABEL_PAYMENT_DATE}>
              {this.props.form.getFieldDecorator(`paymentDate`)(<Input disabled id="paymentDate" name="paymentDate" />)}
            </Form.Item>
          </Col><Col span={4} offset={1}>
            <Form.Item label={Globals.LABEL_PAYMENT_VALUE}>
              {this.props.form.getFieldDecorator(`paymentValue`)
                (<InputNumber precision={2} decimalSeparator="." formatter={Utils.defaultCurrenyInputFormatter}
                              disabled parser={Utils.defaultCurrentInputParser}/>)}
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">{Globals.LABEL_REFUND_INFO}</Divider>
        <Row type="flex" justify="center">
          <Col span={6}>
            <Form.Item label={`${Globals.LABEL_REFUND_VALUE}`}>
              {this.props.form.getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Please, inform the refund amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." formatter={Utils.defaultCurrenyInputFormatter}
                             disabled={!this.props.app.isAdmin()} min={0} max={(this.state.currentExam ? this.state.currentExam.transaction.transactionValue : 0)}
                             parser={Utils.defaultCurrentInputParser} onChange={ (val) => this.handleChange(val, 'amount') }/>
              )}
            </Form.Item>
          </Col>
          <Col span={1} offset={1}> {this._renderCancellationRulePopover()}</Col>
          <Col span={7} offset={1}>
            <Form.Item label={`Refund Tax`}>
              {this.props.form.getFieldDecorator('taxAmount', {
                rules: [{ required: true, message: 'Please, inform the tax amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={`Total Refund`}>
              {this.props.form.getFieldDecorator('totalAmount', {
                rules: [{ required: true, message: 'Please, inform the total amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
        </Row>
        {this.state.originalValue != this.state.refundValue && (
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: true, message: 'Please, justify why the refund amount has changed!' }],
                })(<TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
  _renderFinalStepsView() {
    const visible = (this.state.stepper.current != STEPS.INPUT);
    //if error on state, show error, if last step, success!
    let result = {};
    if (this.state.stepper.error) {
      result = { status: 'error', title: `Error on ${this.state.refundValue == 0 ? 'cancellation' : 'refund'}.`, subTitle: this.state.stepper.error };
    } else if (this.state.stepper.current == STEPS.SUCCESS) {
      result = { status: 'success', title: `${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'} completed.`,
                 subTitle: `${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'} completed.
                 ${this.state.refundValue == 0 ? 'Check your inbox for the cancellation confirmation.' :
                 'Check your inbox for the refund receipt.'}`};
    } else {
      result = { status: 'info', title: 'Processing.', subTitle:
                `Processing ${this.state.refundValue == 0 ? 'cancellation' : 'refund'}.`,
                extra: <Spin spinning>{' '}</Spin>};
    } return ( <Result {... (visible ? {} : styleHidden)} {...result}/> );
  }
  _renderButtonsFooter() {
    let title = 'Done';
    if (this.state.stepper.current == STEPS.INPUT) title = `Submit ${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'}`;
    else if (this.state.stepper.current == STEPS.PROCESSING) {
      if (this.state.stepper.error) title = 'Try again!';
      else title = '...'; //probably it's loading
    }
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading || this.state.stepper.current == STEPS.SUCCESS}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading} className='paymentConfirmButton'>
                {title} </Button>
      </Row>
    )
  }
  _renderCancellationRulePopover() {
    if (!this.state.currentExamSpecs) return (<></>);
    return (
        <Popover placement="right" title="Cancellation Rules" content={
            <ul style={{ width: 250 }}>
              {this.state.currentExamSpecs.policy.cancelPolicy.rules.sort((a,b) => a.daysAbove < b.daysAbove)
                .map( (rule, index) => (
                  <li {...(this.state.rule == rule ? {className:'selectedCancellationRule'} : {})}
                      key={index} style={{ textAlign: 'left', fontSize: 13 }}>
                    If <b>{rule.daysAbove}</b> days before scheduled date
                  {(()=>{
                      if (rule.percentageRefunded != 0 || rule.amountCharged == 0) return (
                        <> refund <b>{rule.percentageRefunded} percent</b> of the transaction.</>
                      );
                      else return (
                        <> refund the <b>total amount less ${Utils.toCurrencyFormat(rule.amountCharged)}</b></>
                      );
                    })()}
                  </li>
                ))}
            </ul>
          }>
          <Icon className='cancellationRulesIcon' type="info-circle"/>
        </Popover>
    )
  }

  /* private methods */
  async _refundPayment(data) {
    this.setState({stepper: { current: STEPS.PROCESSING, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExam = this.state.currentExam;
    const refundResp = await this.props.app.api.userCertification.refundPayment(this.props.ccpoUser.id, currentCert.id, {
      'refundAmount': this.state.refundValue,
      'originalRefundAmount': this.state.originalValue,
      'comments': data.comments,
      'financialTransactionID': currentExam.transaction.id,
      'taxValue': data.taxAmount,
      'totalValue': data.totalAmount
    });
    if (!this._isMounted) return;
    console.debug('Payment refund resp:', refundResp, data);
    if (refundResp.statusCode == 200 && refundResp.body) {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.SUCCESS, status: 'finish', error: null}, isLoading: false});
      message.success(`${this.state.refundValue == 0 ? 'Cancellation' : 'Refund'} Completed!`);


    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.PROCESSING, status: 'error', error: refundResp.body.err}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, refundResp);
    }
  }
  _calculateProposedCancellationValue(rule, examObj) {
    //Check if is using percentage or amount
    if (rule && rule.percentageRefunded != 0) return (examObj.transaction.transactionValue * (rule.percentageRefunded/100.0));
    if (rule && rule.amountCharged != 0) return rule.amountCharged;
    return 0; //no refund :/
  }
  _calculateProposedCancellationRule(examSpecs, examObj) {
    const cancelPolicy = examSpecs.policy.cancelPolicy;
    let rule = null;
    //Find rule
    for (let ruleObj of cancelPolicy.rules) {
      const initialDate = (examObj.examDate && examObj.examDate != -1 ? examObj.examDate : examObj.updatedOn);
      let time = new Date(initialDate);
      time.setDate(time.getDate() - ruleObj.daysAbove);
      if (cancelPolicy.isBusinessDays) time = Utils.businessDaysFromDate(new Date(initialDate), ruleObj.daysAbove);
      if (Date.now() <= time.getTime() && (!rule || rule.daysAbove < ruleObj.daysAbove)) rule = ruleObj;
    }
    return rule;
  }
}

export default Form.create({})(CommonExamCancellationModal);
