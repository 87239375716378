import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, DatePicker, Divider, Col, Row, InputNumber, Button, message, Steps, Spin, Modal, Result, Popover, Icon } from 'antd';
import { Beforeunload } from 'react-beforeunload';
import moment from 'moment';
//
import CustomComponent from '../../components/CustomComponent';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonPaymentModal.css'; //inherits
import '../../assets/stylesheets/CommonWrittenExamRescheduleModal.scss';
//
const { TextArea } = Input;
const { Step } = Steps;
const styleHidden = {style: { display: 'none' }};
const STEPS = { PERSONAL: 0, SENSITIVE: 1, PROCESSING: 2, RESULT: 3};
//props are: app, onCancel, certification, ccpoUser, isVisible
class CommonExamReschedulePaymentModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, loadingBraintree: false, stepper: {
                   current: STEPS.PERSONAL, status: 'waiting', error: null},
                   payload: { /* here we store sensitive information*/ },
                   currentExam: null, rule: null, currentExamSpecs: null,
                   transactionValue: 0, originalValue: 0, taxAmount: 0, certSpecs: null};
  }
  //Life cycle
  componentWillUnmount() { this._unloadBraintreeSDK(); }
  componentDidMount() {}
  //Public
  loadModalInfo(event) {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    let examObj = (event ? event.metadata.object : this.state.currentExam); //if resetting keep same obejct
    const examSpecs = this.props.app.sharedCache().getExamByID(examObj.examID);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    //Get proper reschedule rule
    const rule = this._calculateProposedRescheduleRule(examSpecs, examObj);
    const feeValue = (examObj.pendingPaymentValue || this._calculateProposedRescheduleValue(rule, examObj));
    //Calculate transaction values
    const taxAmount = feeValue * (examSpecs.taxPercentage / 100.0); //calculate tax amount
    const totalAmount = Utils.safelyFixCurrency(feeValue + taxAmount); //Calculate total amount (amount + taxes)
    //
    this.setState({currentExamSpecs: examSpecs, currentExam:examObj, originalValue: feeValue, certSpecs: certSpecs, taxAmount: taxAmount, transactionValue: feeValue, rule: rule }, () => {
      // set dynamic initial values
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        examDescription: examSpecs.displayName, amount: feeValue, taxAmount, totalAmount,
        examDate: moment(new Date(examObj.examDate || Date.now()), Globals.DefaultUIDateTimeFormat),
      });
    });
  }

  //Actions
  handleCancel() {
    //Make cancellation request if on second step and fID is set!
    if (this.state.stepper.current == STEPS.SENSITIVE && this.state.payload.fID) this._cancelPayment();
    else this.props.onCancel();
  };
  handleAfterClose() {
    this.setState({ isLoading: false, stepper: { current: STEPS.PERSONAL, status: 'waiting' }, payload: {}, loadingBraintree: false,
                    currentExamSpecs: null, transactionValue: 0, originalValue: 0, taxAmount: 0, rule: null, certSpecs: null });
  }
  async handleSubmit() {
    if (this.state.stepper.current == STEPS.PERSONAL) {
      const resp = await this.props.form.validateFields(['amount', 'comments', 'examDate']);
      if (resp) this._beginPayment(resp)
    } else if (this.state.stepper.current == STEPS.SENSITIVE) {
      //save current changes com bt component
      const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
      if (!nonce) return;
      //Set loading state, and process purchase
      this.setState({stepper: { current: STEPS.PROCESSING, status: 'process', error: null }}, () => {
        //unload sdk
        this._unloadBraintreeSDK();
        //complete payment
        this._completePayment();
      });
    } else if (this.state.stepper.current == STEPS.RESULT) { //error! - try again
      if (this.state.stepper.error) { //error, try again hit!
        this.props.form.resetFields();
        this.setState({ ...this.state, applingVoucher: false, validatedVoucher: null, isLoading: false, stepper: { current: STEPS.PERSONAL, status: 'waiting' }, payload: {} }, () => {
          this.loadModalInfo();
        });
      } else { //success completion
        this.props.onCancel();
      }
    }
  };
    //form change
  handleChange(value, id) {
    let amount = this.state.transactionValue;
    let originalValue = this.state.originalValue;
    //Check for the amount to be set
    if (id == 'amount') amount = value;
    //Recalculate transaction value
    let taxAmount = amount * (this.state.currentExamSpecs.taxPercentage / 100.0); //calculate tax amount
    if (isNaN(taxAmount)) taxAmount = 0;
    let totalAmount = Utils.safelyFixCurrency(amount + taxAmount); //Calculate total amount (amount + taxes)
    if (isNaN(totalAmount)) totalAmount = 0;
    //Update inputs
    if (id == 'amount') this.props.form.setFieldsValue({ taxAmount, totalAmount });
    else this.props.form.setFieldsValue({ amount, taxAmount, totalAmount });
    //keep it reflected on state for security purposes (element values are easier to change)
    this.setState({originalValue, transactionValue: amount, taxAmount: taxAmount});
  };
  render() {
    const sensitiveStep = (this.state.stepper.current == STEPS.SENSITIVE);
    const finalSteps = (this.state.stepper.current > STEPS.SENSITIVE && this.state.stepper.current < STEPS.RESULT + 1);
    const examType = (this.state.currentExamSpecs ? this.state.currentExamSpecs.type : '');
    const title = examType + ' Exam Reschedule';
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null}>
         {this.props.isVisible && <Beforeunload onBeforeunload={() => {
            if (this.state.stepper.current == STEPS.PERSONAL || this.state.stepper.current == STEPS.RESULT) return false;
            return "Changes you made may not be saved.";
         }} />}
         <Steps {... this.state.stepper} size='small' className='paymentSteps'>
           <Step title="Cart"/>
           <Step title="Payment Info."/>
           <Step title="Processing Payment"/>
           <Step title={this.state.stepper.error ? "Failed" : "Completed"}/>
         </Steps>
        {this._renderSensitiveForm(sensitiveStep)}
        {this._renderFinalStepsView(finalSteps)}
        {this._renderProductForm((!sensitiveStep && !finalSteps))}
        {this._renderButtonsFooter(sensitiveStep, finalSteps)}
      </Modal>
    );
  }

  /* subforms */
  _renderProductForm(visible) {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key='productReschedule' {... (visible ? {} : styleHidden)}>
        <Divider orientation="left">{Globals.LABEL_PAYMENT}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`, {
                rules: [{ required: true, message: 'Please, type the certification description!' }],
              })(<Input disabled id="certificationDescription" name="certificationDescription" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={Globals.LABEL_EXAM_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`examDescription`)(<Input disabled id="examDescription" name="examDescription" />)}
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Divider orientation="left">{'Reschedule Details'}</Divider>
        <Row type="flex" justify="center">
          <Col span={6}>
            <Form.Item label={'Scheduled date'}>
              {this.props.form.getFieldDecorator(`examDate`, { rules:[{required: true, message: 'Please, inform exam scheduled date!'}] })(
                <DatePicker allowClear={false} showTime={{format:'HH:mm:'}} format={Globals.DefaultUIDateTimeFormat} disabled={!this.props.app.isAdmin()}/>
              )}
            </Form.Item>
          </Col><Col span={16}></Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={7}>
            <Form.Item label={`${Globals.LABEL_AMOUNT}`}>
              {this.props.form.getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Please, inform the amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." formatter={Utils.defaultCurrenyInputFormatter}
                             disabled={!this.props.app.isAdmin()} min={0}
                             parser={Utils.defaultCurrentInputParser} onChange={ (val) => this.handleChange(val, 'amount') }/>
              )}
            </Form.Item>
          </Col>
          <Col span={1} offset={1}> {this._renderRescheduleRulePopover()} </Col>
          <Col span={5} offset={1}>
            <Form.Item label={`${Globals.LABEL_TAX_AMOUNT}`}>
              {this.props.form.getFieldDecorator('taxAmount', {
                rules: [{ required: true, message: 'Please, inform the tax amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={`${Globals.LABEL_TOTAL_AMOUNT}`}>
              {this.props.form.getFieldDecorator('totalAmount', {
                rules: [{ required: true, message: 'Please, inform the total amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
        </Row>
        {this.state.originalValue != this.state.transactionValue && (
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: true, message: 'Please, justify why the amount has changed!' }],
                })(<TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
  _renderSensitiveForm(visible) {
    return (
      <Form key='sensitiveReschedule' data-private id='sensitiveReschedule' {... (visible ? {} : styleHidden)}>
        <Divider orientation="left">{Globals.LABEL_PAYMENT}</Divider>
          <Row type='flex' align='center'>
            <div style={{ width: '90%', height: (this.state.loadingBraintree ? 450 : 450), marginTop: 20 }}>
              {this.state.loadingBraintree && <Row type='flex' justify='center' style={{ marginTop: '250px' }} >
                <Col> <Spin spinning size="large"></Spin> </Col>
              </Row>}
              <div {...(this.state.loadingBraintree ? styleHidden : { style: { opacity: 1 } })} id="paymentProcessorContainer"></div>
            </div >
          </Row>
        </Form>
    );
  }
  _renderFinalStepsView(visible) {
    //if error on state, show error, if last step, success!
    let result = {};
    if (this.state.stepper.error) {
      result = { status: 'error', title: 'Error on payment.', subTitle: this.state.stepper.error };
    } else if (this.state.stepper.current == STEPS.RESULT) {
      result = { status: 'success', title: 'Payment completed.', subTitle: 'Your payment was completed and exam rescheduled. Check your inbox for the payment receipt.' };
    } else {
      result = { status: 'info', title: 'Processing.', subTitle: 'Processing payment.', extra: <Spin spinning>{' '}</Spin>};
    } return ( <Result {... (visible ? {} : styleHidden)} {...result}/> );
  }
  _renderRescheduleRulePopover() {
    if (!this.state.currentExamSpecs) return (<></>);
    return (
        <Popover placement="right" title="Reschedule Rules" content={
            <ul style={{ width: 250 }}>
              {this.state.currentExamSpecs.policy.reschedulePolicy.rules.sort((a,b) => a.daysAbove < b.daysAbove)
                .map( (rule, index) => (
                  <li {...(this.state.rule == rule ? {className:'selectedRescheduleRule'} : {})}
                      key={index} style={{ textAlign: 'left', fontSize: 13 }}>
                    If <b>{rule.daysAbove}</b> days or more before scheduled date
                  {(()=>{
                      if (rule.percentageCharged != 0 || rule.amountCharged == 0) return (
                        <> <b>{rule.percentageCharged} percent</b> of the transaction will be charged.</>
                      );
                      else return (
                        <> <b>${Utils.toCurrencyFormat(rule.amountCharged)}</b> will be charged.</>
                      );
                    })()}
                  </li>
                ))}
            </ul>
          }>
          <Icon className='rescheduleRulesIcon' type="info-circle"/>
        </Popover>
    )
  }
  _renderButtonsFooter(sensitiveStep, finalSteps) {
    let title = 'Next';
    let opts = {};
    if (sensitiveStep) {
      title = 'Submit Payment';
      opts = {form:"sensitiveReschedule", htmlType:"submit"};
    } else if (finalSteps) {
      if (this.state.stepper.error) title = 'Try again!';
      else title = 'Done';
    }
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading || this.state.stepper.current == STEPS.RESULT}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading || this.state.loadingBraintree} {...opts} className='paymentConfirmButton'>
                {title} </Button>
      </Row>
    )
  }

  /* private methods */
  async _beginPayment(data) {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExam.id;
    const paymentResp = await this.props.app.api.userExam.beginReschedule(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'amount': this.state.transactionValue,
      'taxValue': this.state.taxAmount,
      'totalValue': Utils.safelyFixCurrency(this.state.transactionValue + this.state.taxAmount),
      'originalValue': this.state.originalValue,
      'comments': data.comments,
      'examDate': moment(data.examDate).toDate().getTime()
    });
    if (!this._isMounted) return;
    console.debug('Reschedule payment begin resp:', paymentResp, data);
    if (paymentResp.statusCode == 200 && paymentResp.body && paymentResp.body.fID) {
      /* Skip payment support */
      if (this.state.transactionValue === 0) {
        this.setState({stepper: { ...this.state.stepper, examDate: data.examDate, status: 'process', error: null},
                       payload: { ...this.state.payload, examDate: data.examDate, fID: paymentResp.body.fID },
                        isLoading: true});
        this._completePayment();
      } else {
        this.setState({stepper: { ...this.state.stepper, current: STEPS.SENSITIVE, status: 'waiting', error: null},
                       payload: { ...this.state.payload, examDate: data.examDate, fID: paymentResp.body.fID},
                       isLoading: false, loadingBraintree: true }, () => {
                         this._loadBraintreeSDK(); //load sdk
                       });
      }
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.PERSONAL, status: 'error', error: null}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _completePayment() {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExam.id;
    const paymentResp = await this.props.app.api.userExam.completeReschedule(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'financialTransactionID': this.state.payload.fID,
      'examDate': moment(this.state.payload.examDate).toDate().getTime()
    });
    if (!this._isMounted) return;
    console.debug('Reschedule payment completion resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null}, isLoading: false});
      message.success('Reschedule Completed!');
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error', error: paymentResp.body.err}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _cancelPayment() {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExam.id;
    const paymentResp = await this.props.app.api.userExam.cancelReschedule(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'financialTransactionID': this.state.payload.fID,
    });
    if (!this._isMounted) return;
    console.log('Reschedule payment cancellation resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.warning('Reschedule Cancelled!');
      this.props.onCancel(); //ask to hide modal
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error', error: paymentResp.body.err}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  /* Private helpers */
  _calculateProposedRescheduleValue(rule, examObj) {
    //Check if is using percentage or amount
    if (rule && rule.percentageCharged != 0) return examObj.transaction.transactionValue * (rule.percentageCharged/100.0);
    if (rule && rule.amountCharged != 0) return rule.amountCharged;
    return 0; //no feed :/
  }
  _calculateProposedRescheduleRule(examSpecs, examObj) {
    const reschedulePolicy = examSpecs.policy.reschedulePolicy;
    let rule = null;
    //Find rule
    for (let ruleObj of reschedulePolicy.rules) {
      const initialDate = (examObj.examDate && examObj.examDate != -1 ? examObj.examDate : examObj.updatedOn);
      let time = new Date(initialDate);
      time.setDate(time.getDate() - ruleObj.daysAbove);
      if (reschedulePolicy.isBusinessDays) time = Utils.businessDaysFromDate(new Date(initialDate), ruleObj.daysAbove);
      if (Date.now() <= time.getTime() && (!rule || rule.daysAbove < ruleObj.daysAbove)) rule = ruleObj;
    }
    return rule;
  }
  /* Private Braintree */
  async _loadBraintreeSDK() {
    console.log('load', this.props.ccpoUser)
    const { email, firstName, lastName } = this.props.ccpoUser;
    const nonce = await this.props.app.sharedCache().getVaultPaymentNonceByUserID(this.props.ccpoUser.id, { email, firstName, lastName });
    this.paymentProcessorInstance = await this.props.app.paymentManager.initializePaymentFlowSDKOnContainer('#paymentProcessorContainer', nonce);
    this._setupDropInListeners();
    this.setState({ loadingBraintree: false });
  }
  _unloadBraintreeSDK() {
    if (!this.paymentProcessorInstance) return;
    this._releaseDropInListeners();
    this.paymentProcessorInstance.teardown();
    this.paymentProcessorInstance = null;
  }
    //Listeners
  _setupDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.on('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.on('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _releaseDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.off('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.off('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _onPaymentMethodRequestable(event) { this.forceUpdate(); }
  _onNoPaymentMethodRequestable() { this.forceUpdate(); }
}

export default Form.create({})(CommonExamReschedulePaymentModal);
