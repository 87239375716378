import React from 'react';
import autoBind from 'react-autobind';
import { Card, Badge, Button, Icon } from 'antd';
import CustomComponent from '../../components/CustomComponent';
import '../../assets/stylesheets/CommonCertificationProcess.scss';

import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
export default class CommonCertificationCard extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    let id = props.certification.certificationID;
    if (!id) id = props.certification.id; //check for placeholder
    //
    const certSpecs = this.props.app.sharedCache().getCertificationByID(id);
    let isSuperseded = false;
    if (props.supersedeIDs && props.supersedeIDs.length > 0) isSuperseded = true;
    this.state = { certification: props.certification, certSpecs: certSpecs, loadingPrintFile: false, isSuperseded };
  }
  //Lifecycle
  componentDidUpdate(prevProps) {
    if (prevProps.certification) {
      if (prevProps.certification.id !== this.props.certification.id) {
        this.updateCard();
      }
    }
  }
  updateCard = () => {
    let id = this.props.certification.certificationID;
    if (!id) id = this.props.certification.id; //check for placeholder
    const certSpecs = this.props.app.sharedCache().getCertificationByID(id);
    this.setState({ certification: this.props.certification, certSpecs: certSpecs });
  };
  //Actions
  async handlePrintCert(certProcId) {
    if (!this.props.app.isAdmin()) return;
    //start print button loading
    this.setState({loadingPrintFile: true});
    //Get file
    const resp = await this.props.app.api.userCertification.getPrintingCard(this.props.customUser.id);
    if (resp.statusCode == 200) { //ask to download
      Utils.downloadArrayBuffer(resp.body.data, 'file', 'zip');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
    }
    //start print button loading
    this.setState({loadingPrintFile: false});
  }
  handleShowTimeline(certProcId) {
    if (this.props.app.isAdmin()) {
      this.props.app.pushPage(config.ApplicationRoutes.userCertification, {}, certProcId, this.props.customUser.id);
    } else {
      this.props.app.pushPage(config.ApplicationRoutes.certification, {}, certProcId);
    }
  }
  async handleStartCertification(certProcID) {
    if (this.props.supersedeIDs && this.props.supersedeIDs.length > 0) {
      //Format alert text
      const supersedeNames = this.props.supersedeIDs.map((id, i) => {
        const certSpecs = this.props.app.sharedCache().getCertificationByID(id);
        return certSpecs.shortDescription;
      }).join(' and ');
      const certName = this.state.certSpecs.shortDescription;
      const alertTest = `Please note, you do not require this certification. Your ${supersedeNames} certification qualifies you to operate ${certName}. This is reflected on your CCPO certification card.  Your ${certName} will be renewed automatically when your ${supersedeNames} is renewed. Press Cancel to return or OK to continue and start a new certification process.`
      //Prompt
      if (await this.props.app.alertController.showQuestionAlert("Attention!", alertTest)) this.props.handleStartCertification(certProcID);
    } else this.props.handleStartCertification(certProcID);
  }
  //UI
  render() {
    return (
      <div className="container-card">
        <Badge className={`badge-card-action ${
            Globals.getCertificationAction(this.state.certification.state) != '' && !this.state.isSuperseded ? 'visible' : 'invisible'
          }`} count={`${Globals.getCertificationAction(this.state.certification.state)}`}>
          <Card className={`certification-card started-process-${!!this.state.certification.certificationID}`} bordered={false}>
            {this.props.app.isAdmin() && this.state.certification.certNumber &&
              <Button onClick={() => this.handlePrintCert(this.state.certification.id)} className='printCertButton -leave'>
                <Icon className='printCertButtonIcon' type={this.state.loadingPrintFile ? "sync" : "printer"}
                  spin={this.state.loadingPrintFile} theme={this.state.loadingPrintFile ? "outlined" : "filled"}/>
              </Button>
            }
            {this.state.isSuperseded ? <></> :
            <strong>
              <small className="certification-number">
                Certification number:{' '}
                {this.state.certification.certNumber ? this.state.certification.certNumber : 'N/A'}
              </small>
            </strong>}
            <div className="container-body-card">
              <p>{this.state.certification.createdOn ? 'Certification:' : 'Certification not started:'}</p>
              <p className='container-certification-description'>
                {this.state.certSpecs ? this.state.certSpecs.description : ''}
              </p>
              {this.state.isSuperseded ? <><div className="dummy-button"></div></> : (!this.state.certification.certificationID ? (
                <button className="card-button" type="button" disabled={this.state.isSuperseded}
                  onClick={() => this.handleStartCertification(this.state.certification.id)}>
                  Start Certification
                </button>
              ) : (
                <button className="card-button timeline" type="button" disabled={this.state.isSuperseded}
                  onClick={() => this.handleShowTimeline(this.state.certification.id)}>
                  See timeline
                </button>
              ))}
            </div>
            <small className="current-status">{Globals.getCertificationDisplayState(this.state.certification.state, this.props.supersedeIDs)}</small>

            {this.state.isSuperseded ? <></> :
            <small className="expiry-date">
              {(this.state.certification.expiryDate > Date.now() ? 'Expiration Date: ' : 'Expired on: ')}
              {this.state.certification.expiryDate && this.state.certification.expiryDate != -1
                ? Utils.getDateOnUIFormatByTimestamp(this.state.certification.expiryDate)
                : 'N/A'}
            </small>}

          </Card>
        </Badge>
      </div>
    );
  }
}
