import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Row, Col, message } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonUserHeader from  '../commonComponents/CommonUserHeader';
import CommonExamScheduleModal from '../commonSubviews/CommonExamScheduleModal';
import CommonExamRescheduleRequestModal from '../commonSubviews/CommonExamRescheduleRequestModal';
//
import AdvisorDashboardScheduledSection from './AdvisorDashboardScheduledSection';
import AdvisorDashboardReadyToScheduleSection from './AdvisorDashboardReadyToScheduleSection';
//
import Globals from '../../config/Globals';
import config from '../../config/config';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/AdvisorDashboardView.scss';
//
export default class AdvisorDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: null, isLoading: false,
      firstLoad: true, isVisibleExamSchedule: false,
      selectedExam: null, isVisibleExamRescheduleRequest: false
    };
  }
  //API
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
    //
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObj });
  }
  async fetchData() {
    this.startLoading();
    this.loadData(await this.props.app.api.advisor.getDashboard())
  }
  async loadData(data) {
    if (!this._isMounted) return;
    console.debug('Advisor dashboard resp',data);
    if (data.statusCode == 200 && data.body.scheduled && data.body.toSchedule) {
      this.setState({ data: data.body, isLoading: false, firstLoad: false});
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, data);
      this.setState({ data: null, isLoading: false, firstLoad: false});
    }
  }
  //Actions
  handleScheduledExamSelect(exam) {
    //Check for exams on-hold
    if (exam && exam.certification.state != Globals.CertificationProcess_State.SCHEDULED) {
      message.warning('Exam can\'t be rescheduled, payment is required!');
      return;
    }
    //
    this.setState({ isVisibleExamRescheduleRequest: !this.state.isVisibleExamRescheduleRequest, selectedExam: exam}, () => {
      if (this.state.isVisibleExamRescheduleRequest) this.examRescheduleModal.loadModalInfo({metadata: { object: exam }});
      else this.fetchData();
    });
  }
  handleHideExamRescheduleRequest() { this.setState({ isVisibleExamRescheduleRequest: false }); }
  handleToScheduleExamSelect(exam) {
    this.setState({ isVisibleExamSchedule: !this.state.isVisibleExamSchedule, selectedExam: exam}, () => {
      if (this.state.isVisibleExamSchedule) this.scheduleModal.loadModalInfo({metadata: { object: exam }});
      else this.fetchData();
    });
  }
  handleHideExamSchedule() { this.setState({ isVisibleExamSchedule: false, selectedExam: null }); }
  //UI
  render() {
    return (
      <div className="dashboardNoNav">
        {this._renderExamScheduleModal()}
        {this._renderExamRescheduleRequestModal()}
        <Row type="flex" align="middle" justify='space-between' className='advisorDashboardRow'>
          <CommonUserHeader app={this.props.app} ccpoUser={this.state.userObj}
                            idmUser={{}} personalPart={{}} isAdminView
                            isLoading={this.state.isLoading} datasource={this}/>
        </Row>
        <Row type="flex" align="middle" justify='space-around'  className='advisorDashboardRow'>
          <AdvisorDashboardScheduledSection app={this.props.app} exams={(this.state.data ? this.state.data.scheduled : [])} isLoading={this.state.isLoading} onSelection={this.handleScheduledExamSelect}/>
          <AdvisorDashboardReadyToScheduleSection app={this.props.app} exams={(this.state.data ? this.state.data.toSchedule : [])} isLoading={this.state.isLoading} onSelection={this.handleToScheduleExamSelect}/>
        </Row>
      </div>
    );
  }
  _renderExamScheduleModal() {
    const safeCert = (this.state.selectedExam ? this.state.selectedExam.certification : null);
    const safeUserID = (this.state.selectedExam ? this.state.selectedExam.userID : null);
    return (
      <CommonExamScheduleModal certification={safeCert} ccpoUser={{id: safeUserID}}
                               isVisible={this.state.isVisibleExamSchedule}
                               wrappedComponentRef={ref => { this.scheduleModal = ref; }}
                               onChange={this.handleToScheduleExamSelect} app={this.props.app}
                               onCancel={this.handleHideExamSchedule}/>
    );
  }
  _renderExamRescheduleRequestModal() {
    const safeCert = (this.state.selectedExam ? this.state.selectedExam.certification : null);
    const safeUserID = (this.state.selectedExam ? this.state.selectedExam.userID : null);
    return (
      <CommonExamRescheduleRequestModal ccpoUser={{id: safeUserID}}
          certification={safeCert} isVisible={this.state.isVisibleExamRescheduleRequest}
          wrappedComponentRef={ref => { this.examRescheduleModal = ref; }}
          onChange={this.handleScheduledExamSelect} app={this.props.app}
          onCancel={this.handleHideExamRescheduleRequest}/>
    );
  }
}
