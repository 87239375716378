import React from 'react';
import LogoBW from '../../assets/images/logoBW.png';
import LogoImage from '../../assets/images/logo_ccpo.png';
import LogoRightImage from '../../assets/images/bccsa_header_white.png';
import LogoTitleImage from '../../assets/images/logo_ccpo_title_white_all.png';
import config from '../../config/config';

export default function Logo({ isHeading, isFixed, isRightLogo, isTitle, isCCPOLogo, isFooter, authorized}) {
  //

  let className = isHeading ? 'headingLogo' : 'bwLogo';
  if (authorized) className += ' authorized'
  if (isFooter) className = 'appFooterLogo';
  let file = isHeading ? LogoImage : LogoBW;
  if (isRightLogo) {
    className = 'idmBadgeLogo';
    file = LogoRightImage;
  } else if (isTitle) {
    className = isFixed ? 'headingTitleLogoFixed' : 'headingTitleLogo';
    file = LogoTitleImage;
  } else if (isCCPOLogo) {
    className = 'logoCCPO';
    file = LogoImage;
  }

  //Check for non links logo
  if (isRightLogo || isTitle) return <img className={className} alt="logo" src={file} />;
  //
  return (
    <a href={config.ApplicationRoutes.login}>
      <img className={className} alt="logo" src={file} />
    </a>
  );
}
