import React from "react";
import { Row, Col, Menu, Modal, Divider, Button, Dropdown, Icon, DatePicker } from 'antd';
import autoBind from 'react-autobind';
import moment from 'moment';
//
import '../../assets/stylesheets/CommonPeriodFilter.scss';
import Utils from '../../components/Utils';
//props are: onYearSelect, onQuarterSelect, onMonthSelect, onAllSelect, onRangeSelect
export default class CommonPeriodFilter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.buildFilter();
  }
  //helper
  buildFilter() {
    let yms = {};
    Utils.setReportDateRange(yms);
    this.state = { selectedFilter: 'c1', reportRange: yms };
  }
  handleChange(value, id) {
    //handle special cases
    if (value.key == 'c1') this.props.onYearSelect(this.state.reportRange.currentYear);
    else if (value.key == 'c2') this.props.onMonthSelect(this.state.reportRange.currentMonth, this.state.reportRange.currentYear);
    else if (value.key == 'c3') this.props.onQuarterSelect(this.state.reportRange.currentQuarter, this.state.reportRange.currentYear);
    else if (value.key == 'c4') this.props.onAllSelect();
    else if (value.key == 'c5') this.setState({ showRangeModal: true, rangeModalDates: [moment(moment().startOf('year'), 'YYYY/MM'), moment(moment(), 'YYYY/MM')] });
    else if (value.key.slice(0,1) == 'm') { this.props.onMonthSelect(value.key.slice(1), this.state.reportRange.currentYear); }
    else if (value.key.slice(0,1) == 'y') {
      const currentYear = value.key.slice(1);
      const yms = { selectedYear: currentYear };
      Utils.setReportDateRange(yms);
      this.setState({ reportRange: yms }, () => this.props.onYearSelect(this.state.reportRange.currentYear));
    }
    else if (value.key.slice(0,1) == 'q') { this.props.onQuarterSelect(value.key.slice(1), this.state.reportRange.currentYear); }
    this.setState({selectedFilter: value.key});
  }
  //
  handleCloseModal() { this.setState({ showRangeModal: false }); }
  handleChangeDateRange(dates) { this.setState({ rangeModalDates: dates }); }
  handleSubmitRangeModal() {
    this.props.onRangeSelect(this.state.rangeModalDates[0].format('YYYYMM'), this.state.rangeModalDates[1].format('YYYYMM'));
    this.setState({ showRangeModal: false });
  }
  //UI
  render() {
    //render
    return (
      <Row type="flex" justify="end">
        {this._renderDateRangeModal()}
        <Col>
          <Dropdown overlay={this._renderMenu()} trigger={['click']}>
              <a className="filterLabel">
                Period: {this._getTitleByFilterType(this.state.selectedFilter)} <Icon type="calendar" />
              </a>
            </Dropdown>
        </Col>
      </Row>
     );
  }
_renderMenu() {
  return (
     <Menu className='filterMenu' placement="bottomCenter" onClick={this.handleChange}>
       <Menu.Item key={`c1`}>Current Year - {this.state.reportRange.currentYear}</Menu.Item>
       <Menu.Item key={`c2`}>Current Month - {Utils.getMonthName(this.state.reportRange.currentMonth)}</Menu.Item>
       <Menu.Item key={`c3`}>Current Quarter - Q{this.state.reportRange.currentQuarter}</Menu.Item>
       <Menu.SubMenu popupClassName='filterSubmenuMenu' title="By Month">
         {this.state.reportRange.validMonths.map( month => <Menu.Item key={`m${month}`}>{Utils.getMonthName(month)}</Menu.Item>)}
       </Menu.SubMenu>
       <Menu.SubMenu popupClassName='filterSubmenuMenu' title="By Quarter">
         {this.state.reportRange.validQuarters.map( quarter => <Menu.Item key={`q${quarter}`}>Q{quarter}</Menu.Item>)}
       </Menu.SubMenu>
       <Menu.SubMenu popupClassName='filterSubmenuMenu' title="By Year">
         {this.state.reportRange.years.map( year => <Menu.Item key={`y${year}`}>{year}</Menu.Item>)}
       </Menu.SubMenu>
       <Menu.Item key={`c4`}>All Time</Menu.Item>
       <Menu.Item key={`c5`}>Custom Range</Menu.Item>
     </Menu>
    );
  }
  _renderDateRangeModal() {
    return (
        <Modal maskClosable  title={'Custom date selection'} onCancel={this.handleCloseModal}
          visible={this.state.showRangeModal} closable footer={null}>
          <DatePicker.RangePicker defaultValue={[moment(moment().startOf('year'), 'YYYY/MM'), moment(moment(), 'YYYY/MM')]} 
            format={'YYYY/MM'} onChange={this.handleChangeDateRange}/>
          <Divider/>
          <Button className="primary solid" type="primary" onClick={this.handleSubmitRangeModal}> Submit </Button>
        </Modal>
    )
  }

  _getTitleByFilterType(value) {
    //handle special cases
    if (value == 'c1') return 'Current Year - ' + this.state.reportRange.currentYear;
    if (value == 'c2') return 'Current Month - ' + Utils.getMonthDescription(this.state.reportRange.currentMonth);
    if (value == 'c3') return 'Current Quarter - Q' + this.state.reportRange.currentQuarter;
    if (value == 'c4') return 'All Time';
    if (value == 'c5') return `Custom Range - ${this.state.rangeModalDates[0].format('YYYY/MM')} - ${this.state.rangeModalDates[1].format('YYYY/MM')}`;
    if (value.slice(0,1) == 'm') return '' + Utils.getMonthDescription(value.slice(1)) + ' of ' + this.state.reportRange.currentYear;
    if (value.slice(0,1) == 'y') return '' + value.slice(1);
    if (value.slice(0,1) == 'q') return 'Q' + value.slice(1) + ' of ' + this.state.reportRange.currentYear;
  }
}
