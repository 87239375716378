import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Typography } from 'antd';
//
import AppRoutes from './AppRoutes';
import Logo from './views/commonComponents/Logo';
import CommonContactModal from './views/commonSubviews/CommonContactModal';
//
import Globals from './config/Globals';
//
import './assets/stylesheets/AppViewController.scss';
//
export default class UnauthorizedViewController extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {contactFormVisible: false};
    this.props.app.props.history.location.state = undefined; // reset history state if restarting app
  }
  // UI
  render() {
    return this._renderPage();
  }
  /* actions */
  handleContactShow() { this.setState({contactFormVisible: true}); }
  handleContactClose() { this.setState({contactFormVisible: false}); }
  /* subviews */
  _renderPage() {
    return (
      <Layout style={{ height: '100vh' }}>
        {this._renderHeader()}
        <Layout.Content style={{ height: '100%' }}>
          <Layout style={{ height: '100%' }}>
            <Layout.Content>
              <AppRoutes appRef={this} />
            </Layout.Content>
          </Layout>
        </Layout.Content>
        {this._renderFooter()}
        {this._renderContactModal()}
      </Layout>

    );
  }
  _renderHeader() {
    return (
      <Layout.Content className="appHeader">
        <Row type="flex" align="middle" justify="center">
          <Col> <Logo isHeading /> </Col>
        </Row>
      </Layout.Content>
    );
  }
  _renderFooter() {
    return (
      <Row type="flex" justify="end" align="middle" className="appFooter">
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" href={Globals.WEBSITE_LINK} target="_blank"> {Globals.WEBSITE_LABEL} </a>
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" key="contactUs" onClick={this.handleContactShow}> Contact Us </a>
          </Typography.Text>
        </Col>
        <Col> <Logo isFooter/> </Col>
      </Row>
    );
  }
  _renderContactModal() {
    return (
      <CommonContactModal isVisible={this.state.contactFormVisible}
                          app={this.props.app}
                          handleCancel={this.handleContactClose}
                          handleSubmissionComplete={this.handleContactClose}/>
                      );
  }
}
