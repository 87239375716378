import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Row, Button, Icon, Modal } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import '../../assets/stylesheets/CommonCertificationProcess.scss';
//
export default class CommonCertificationContactModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Actions
  handleCancel() {
    this.props.handleCancel();
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} closable={false} title="Book Exam"
             footer={null} visible={this.props.isVisible} confirmLoading={this.state.isLoading}>
        {this._renderContent()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subcomponents */
  _renderContent() {
    const tenantSpecs = this.props.app.sharedCache().getTenantConfig();
    return (
      <div className="commonStyleDivContainer">
        <p style={{ fontSize: 16, fontWeight: 500 }}>
          {tenantSpecs ? tenantSpecs.organizationRegText : ''}
        </p>
        <Divider />
        <div>
          <strong style={{ paddingRight: 10 }}>
            {tenantSpecs ? `${tenantSpecs.organizationName} Help Desk` : ''}
          </strong>
          <br />
          <Icon type="phone" />

          <span style={{ marginLeft: 15 }}>
            {tenantSpecs && tenantSpecs.organizationContactDisplay
              ? `${tenantSpecs.organizationContactDisplay.phoneNumber}`
              : ''}
          </span>
          <br />
          <Icon type="mail" />
          <span style={{ marginLeft: 15 }}>
            {tenantSpecs && tenantSpecs.organizationContactDisplay
              ? `${tenantSpecs.organizationContactDisplay.email}`
              : ''}
          </span>
        </div>
      </div>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}>
          {' '}
          Close{' '}
        </Button>
      </Row>
    );
  }
}
