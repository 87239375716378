import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Select, InputNumber, Button, message, Steps, Spin, Modal, Result, Alert, Typography } from 'antd';
import { Beforeunload } from 'react-beforeunload';
//
import CustomComponent from '../../components/CustomComponent';
//
import APIUtils from '../../components/API/APIUtils';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonPaymentModal.css';
//
const styleHidden = {style: { display: 'none' }};
const STEPS = { PERSONAL: 0, SENSITIVE: 1, PROCESSING: 2, RESULT: 3};
//props are: certification, app, onCancel, certification, ccpoUser, isVisible
class CommonPaymentView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, loadingBraintree: false, stepper: {
                   current: STEPS.PERSONAL, status: 'waiting', error: null},
                   payload: { /* here we store sensitive information*/ },
                   applingVoucher: false, validatedVoucher: null,
                   selectedExamIndex: 0, certSpecs: null,
                   currentExamsSpecs: null, transactionValue: 0, originalValue: 0, taxAmount: 0};
  }
  //Life cycle
  componentWillUnmount() { this._unloadBraintreeSDK(); }
  componentDidMount() { }
  //Public
  loadModalInfo() {
    //Get all interesting ivars
    const currentCert = this.props.certification;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const examsSpecs = APIUtils.findNextRequirementsForCertification(currentCert, this.props.app.sharedCache(), tenantConfig);
    const certSpecs = this.props.app.sharedCache().getCertificationByID(currentCert.certificationID);
    const selectedExamIndex = 0;
    const examFee = examsSpecs[selectedExamIndex].policy.fee;
    //Calculate transaction values
    const taxAmount = examFee * (examsSpecs[selectedExamIndex].taxPercentage / 100.0); //calculate tax amount
    const totalAmount = Utils.safelyFixCurrency(examFee + taxAmount); //Calculate total amount (amount + taxes)
    this.setState({currentExamsSpecs: examsSpecs, originalValue: examFee, taxAmount: taxAmount, certSpecs: certSpecs, transactionValue: examFee, selectedExamIndex: selectedExamIndex, validatedVoucher: null, applingVoucher: false }, () => {
      // set dynamic initial values
      this.props.form.setFieldsValue({
        certificationDescription: certSpecs.description,
        exam: examsSpecs[selectedExamIndex].displayName, amount: examFee, taxAmount, totalAmount,
      });
    });
  }

  //Actions
  handleCancel() {
    //Make cancellation request if on second step and fID is set!
    if (this.state.stepper.current == STEPS.SENSITIVE && this.state.payload.fID) this._cancelPayment();
    else this.props.onCancel();
  };
  handleAfterClose() {
    this.setState({ isLoading: false, loadingBraintree: false, stepper: { current: STEPS.PERSONAL, status: 'waiting' }, payload: {}, applingVoucher: false, validatedVoucher: null, currentExamsSpecs: null, transactionValue: 0, originalValue: 0, taxAmount: 0, selectedExamIndex: 0, certSpecs: null});
  }
  async handleSubmit() {
    if (this.state.stepper.current == STEPS.PERSONAL) {
      const resp = await this.props.form.validateFields(['exam', 'amount', 'comments', 'voucherCode']);
      if (resp) this._beginPayment(resp)
    } else if (this.state.stepper.current == STEPS.SENSITIVE) {
      //save current changes com bt component
      const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
      if (!nonce) return;
      //Set loading state, and process purchase
      this.setState({stepper: { current: STEPS.PROCESSING, status: 'process', error: null }}, () => {
        //unload sdk
        this._unloadBraintreeSDK();
        //complete payment
        this._completePayment();
      });
    } else if (this.state.stepper.current == STEPS.RESULT) { //error! - try again
      if (this.state.stepper.error) { //error, try again hit!
        this.props.form.resetFields();
        this.setState({ ...this.state, applingVoucher: false, validatedVoucher: null, isLoading: false, stepper: { current: STEPS.PERSONAL, status: 'waiting' }, payload: {} }, () => {
          this.loadModalInfo();
        });
      } else { //success completion
        this.props.onCancel();
      }
    }
  };
  async handleVoucherApply() {
    const resp = await this.props.form.validateFields(['voucherCode']);
    if (resp) this._validateVoucher(resp)
  }
  handleChange(value, id, force) {
    let amount = this.state.transactionValue;
    let originalValue = this.state.originalValue;
    let selectedExamIndex = this.state.selectedExamIndex;
    //Check for the amount to be set
    if (id == 'exam' || (id == 'voucherCode' && value == '')) {
      if (id == 'exam') selectedExamIndex = value;
      amount = originalValue = this.state.currentExamsSpecs[selectedExamIndex].policy.fee;
    } else if (id == 'amount') amount = value;

    //Recalculate transaction value
    let taxAmount = amount * (this.state.currentExamsSpecs[selectedExamIndex].taxPercentage / 100.0); //calculate tax amount
    if (isNaN(taxAmount)) taxAmount = 0;
    let totalAmount = Utils.safelyFixCurrency(amount + taxAmount); //Calculate total amount (amount + taxes)
    if (isNaN(totalAmount)) totalAmount = 0;
    //Update inputs
    if (id == 'amount' && !force) this.props.form.setFieldsValue({ taxAmount, totalAmount });
    else this.props.form.setFieldsValue({ amount, taxAmount, totalAmount });
    //keep it reflected on state for security purposes (element values are easier to change)
    this.setState({originalValue, transactionValue: amount, taxAmount: taxAmount, selectedExamIndex: selectedExamIndex, validatedVoucher: (id == 'voucherCode' ? null : this.state.validatedVoucher)});
  };

  //UI
  render() {
    const sensitiveStep = (this.state.stepper.current == STEPS.SENSITIVE);
    const finalSteps = (this.state.stepper.current > STEPS.SENSITIVE && this.state.stepper.current < STEPS.RESULT + 1);
    const examType = (this.state.currentExamsSpecs ? this.state.currentExamsSpecs[this.state.selectedExamIndex].type : '');
    const title = (this.state.certSpecs ? this.state.certSpecs.description + ' - ' + examType + ' Exam Payment' : '');
    return (
      <Modal maskClosable={false} title={title} afterClose={this.handleAfterClose}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading} closable={false}
             footer={null}>
         {this.props.isVisible && <Beforeunload onBeforeunload={() => {
           if (this.state.stepper.current == STEPS.PERSONAL || this.state.stepper.current == STEPS.RESULT) return false;
           return "Changes you made may not be saved.";
         }} />}
         <Steps {... this.state.stepper} size='small' className='paymentSteps'>
           <Steps.Step title="Cart"/>
           <Steps.Step title="Payment Info."/>
           <Steps.Step title="Processing Payment"/>
           <Steps.Step title={this.state.stepper.error ? "Failed" : "Completed"}/>
         </Steps>

        {this._renderSensitiveForm(sensitiveStep)}
        {this._renderFinalStepsView(finalSteps)}
        {this._renderProductForm((!sensitiveStep && !finalSteps))}
        {this._renderButtonsFooter(sensitiveStep, finalSteps)}
      </Modal>
    );
  }

  /* subforms */
  _renderProductForm(visible) {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form key='product' {... (visible ? {} : styleHidden)}>
        {this._renderPaymentAlert()}
        <Divider orientation="left">{Globals.LABEL_PAYMENT}</Divider>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item label={Globals.LABEL_CERTIFICATION_DESCRIPTION}>
              {this.props.form.getFieldDecorator(`certificationDescription`, {
                rules: [{ required: true, message: 'Please, type the certification description!' }],
              })(<Input disabled id="certificationDescription" name="certificationDescription" allowClear />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={10}>
            <Form.Item label={`${Globals.LABEL_EXAM}`}>
              {this.props.form.getFieldDecorator('exam', {
                rules: [{ required: true, message: 'Please, type the exam type!' }],
              })(
                <Select onChange={ (val) => this.handleChange(val, 'exam') }>
                  {this.state.currentExamsSpecs &&
                   this.state.currentExamsSpecs.map((item, index) => (
                    <Select.Option value={index} key={index}>{item.displayName}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>{' '}</Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={7}>
            <Form.Item label={`${Globals.LABEL_AMOUNT}`}>
              {this.props.form.getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Please, inform the amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." formatter={Utils.defaultCurrenyInputFormatter}
                             disabled={!this.props.app.isAdmin()} min={0}
                             parser={Utils.defaultCurrentInputParser} onChange={ (val) => this.handleChange(val, 'amount') }/>
              )}
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={`${Globals.LABEL_TAX_AMOUNT}`}>
              {this.props.form.getFieldDecorator('taxAmount', {
                rules: [{ required: true, message: 'Please, inform the tax amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={`${Globals.LABEL_TOTAL_AMOUNT}`}>
              {this.props.form.getFieldDecorator('totalAmount', {
                rules: [{ required: true, message: 'Please, inform the total amount!' }],
              })(
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                             parser={Utils.defaultCurrentInputParser}/>
              )}
            </Form.Item>
          </Col>
        </Row>
        {this._renderVoucherRow()}
        {this.state.originalValue != this.state.transactionValue &&
         (!this.state.validatedVoucher || !this.state.validatedVoucher.id) && (
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: true, message: 'Please, justify why the amount has changed!' }],
                })(<Input.TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
  _renderSensitiveForm(visible) {
    return (
      <Form key='sensitive' data-private id='sensitive' {... (visible ? {} : styleHidden)}>
        <Divider orientation="left">{Globals.LABEL_PAYMENT}</Divider>
          <Row type='flex' align='middle'>
            <div style={{ width: '90%', height: (this.state.loadingBraintree ? 450 : 450), marginTop: 20 }}>
              {this.state.loadingBraintree && <Row type='flex' justify='center' style={{ marginTop: '250px' }} >
                <Col> <Spin spinning size="large"></Spin> </Col>
              </Row>}
              <div {...(this.state.loadingBraintree ? styleHidden : { style: { opacity: 1 } })} id="paymentProcessorContainer"></div>
            </div >
          </Row>
        </Form>
    );
  }
  _renderFinalStepsView(visible) {
    //if error on state, show error, if last step, success!
    let result = {};
    if (this.state.stepper.error) {
      result = { status: 'error', title: 'Error on payment.', subTitle: this.state.stepper.error };
    } else if (this.state.stepper.current == STEPS.RESULT) {
      result = { status: 'success', title: 'Payment completed.', subTitle: 'Your payment was completed. Check your inbox for the payment receipt.' };
    } else {
      result = { status: 'info', title: 'Processing.', subTitle: 'Processing payment.', extra: <Spin spinning>{' '}</Spin>};
    } return ( <Result {... (visible ? {} : styleHidden)} {...result}/> );
  }
  _renderButtonsFooter(sensitiveStep, finalSteps) {
    let title = 'Next';
    let opts = {};
    if (sensitiveStep) {
      title = 'Submit Payment';
      opts = {form:"sensitive", htmlType:"submit"};
    } else if (finalSteps) {
      if (this.state.stepper.error) title = 'Try again!';
      else title = 'Done';
    }
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider/>
        <Button disabled={this.state.isLoading || this.state.stepper.current == STEPS.RESULT || this.state.applingVoucher}
                key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ background: '#af3947', border: 0 }} key="submit" type="primary" loading={this.state.isLoading}
                onClick={this.handleSubmit} disabled={this.state.isLoading || this.state.applingVoucher || this.state.loadingBraintree} {...opts} className='paymentConfirmButton'>
                {title} </Button>
      </Row>
    )
  }
  /* subcomponents */
  _renderVoucherRow() {
    let validationStatus = null;
    if (this.state.applingVoucher) validationStatus = 'validating';
    if (this.state.validatedVoucher) {
      if (this.state.validatedVoucher.id) validationStatus = 'success';
      else validationStatus = 'error';
    }
    //
    return (
      <Row type="flex" justify="center">
        <Col span={4} offset={17}>
          <Form.Item formLayout='inline' label='Coupon Code' {...(validationStatus ? {validateStatus: validationStatus, hasFeedback:true} : {})}>
            {this.props.form.getFieldDecorator('voucherCode')(
              <Input id="voucherCode" name="voucherCode" allowClear onChange={ (val) => this.handleChange(val.target.value, 'voucherCode') }/>
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button className='fixedInputButton_Voucher' loading={this.state.applingVoucher} onClick={this.handleVoucherApply} disabled={this.state.applingVoucher} type="primary">Apply</Button>
        </Col>
      </Row>
    );
  }
  _renderPaymentAlert() {
    if (this.state.currentExamsSpecs && this.state.currentExamsSpecs[this.state.selectedExamIndex] &&  this.state.currentExamsSpecs[this.state.selectedExamIndex].isOnline) return (
      <Alert message="PLEASE READ THE FOLLOWING CAREFULLY" type="warning" showIcon description={
        <>
          <Typography.Paragraph>To complete the CCPO written exam a computer is required.</Typography.Paragraph>
          <Typography.Paragraph>Minimum computer requirements:</Typography.Paragraph>
          <Typography.Paragraph>- Connected to a stable internet connection</Typography.Paragraph>
          <Typography.Paragraph>- Have a functioning web-camera or internal camera</Typography.Paragraph>
          <Typography.Paragraph>BCCSA uses an online proctoring service which utilizes a web camera to monitor the student as they complete their exam.</Typography.Paragraph>
          <Typography.Paragraph>If your or your employer’s computer does not meet the criteria above, or you do not have access to a computer at all, please contact the BCCSA at ccpo@bccsa.ca or 604 636 3676.</Typography.Paragraph>
        </>
    } style={{maxWidth: 980, marginTop: 20}}/>);
    else return (<></>);
  }

  /* private methods */
  async _beginPayment(data) {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExamsSpecs[this.state.selectedExamIndex].id;
    const paymentResp = await this.props.app.api.userCertification.beginPayment(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'amount': this.state.transactionValue,
      'taxValue': this.state.taxAmount,
      'totalValue': Utils.safelyFixCurrency(this.state.transactionValue + this.state.taxAmount),
      'originalValue': this.state.originalValue,
      'comments': data.comments,
      ...(this.state.validatedVoucher && this.state.validatedVoucher.code && this.state.validatedVoucher.code == data.voucherCode ? {voucherCode: this.state.validatedVoucher.code} : {})
    });
    if (!this._isMounted) return;
    console.debug('Payment begin resp:', paymentResp, data);
    if (paymentResp.statusCode == 200 && paymentResp.body && paymentResp.body.fID) {
      /* Skip payment support */
      if (this.state.transactionValue === 0) {
        this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true,
                       payload: { ...this.state.payload, fID: paymentResp.body.fID}});
        message.info('Skipping Payment!');
        this._completePayment();
      } else { /* Normal payment flow */
        this.setState({stepper: { ...this.state.stepper, current: STEPS.SENSITIVE, status: 'waiting', error: null},
                       payload: { ...this.state.payload, fID: paymentResp.body.fID},
                       isLoading: false, loadingBraintree: true }, () => {
                         this._loadBraintreeSDK(); //load sdk
                       });
      }
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.PERSONAL, status: 'error', error: null}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _completePayment() {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExamsSpecs[this.state.selectedExamIndex].id;
    const paymentResp = await this.props.app.api.userCertification.completePayment(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'financialTransactionID': this.state.payload.fID
    });
    if (!this._isMounted) return;
    console.debug('Payment completion resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null}, isLoading: false});
      message.success('Payment Completed!');
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error', error: paymentResp.body.err}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _cancelPayment() {
    this.setState({stepper: { ...this.state.stepper, status: 'process', error: null}, isLoading: true});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExamsSpecs[this.state.selectedExamIndex].id;
    const paymentResp = await this.props.app.api.userCertification.cancelPayment(this.props.ccpoUser.id, currentCert.id, currentExamID, {
      'financialTransactionID': this.state.payload.fID
    });
    if (!this._isMounted) return;
    console.debug('Payment cancellation resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.warning('Payment Cancelled!');
      this.props.onCancel(); //ask to hide modal
    } else {
      this.setState({stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error', error: paymentResp.body.err}, isLoading: false});
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _validateVoucher(data) {
    this.setState({applingVoucher: true, validatedVoucher: null});
    const currentCert = this.props.certification;
    const currentExamID = this.state.currentExamsSpecs[this.state.selectedExamIndex].id;
    const voucherResp = await this.props.app.api.userCertification.applyVoucher(this.props.ccpoUser.id, currentCert.id, currentExamID, data.voucherCode);
    if (!this._isMounted) return;
    console.debug('Voucher validation resp: ', voucherResp, data);
    if (voucherResp.statusCode == 200 && voucherResp.body) {
      message.success('Voucher applied!');
      this.setState({applingVoucher: false, validatedVoucher: voucherResp.body});
      this.handleChange(voucherResp.body.discountValue, 'amount', true);
    } else {
      this.setState({applingVoucher: false, validatedVoucher: voucherResp});
      this.props.app.alertController.showAPIErrorAlert(null, voucherResp);
    }
  }
  /* Private Braintree */
  async _loadBraintreeSDK() {
    console.log('load', this.props.ccpoUser)
    const { email, firstName, lastName } = this.props.ccpoUser;
    const nonce = await this.props.app.sharedCache().getVaultPaymentNonceByUserID(this.props.ccpoUser.id, { email, firstName, lastName });
    this.paymentProcessorInstance = await this.props.app.paymentManager.initializePaymentFlowSDKOnContainer('#paymentProcessorContainer', nonce);
    this._setupDropInListeners();
    this.setState({ loadingBraintree: false });
  }
  _unloadBraintreeSDK() {
    if (!this.paymentProcessorInstance) return;
    this._releaseDropInListeners();
    this.paymentProcessorInstance.teardown();
    this.paymentProcessorInstance = null;
  }
    //Listeners
  _setupDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.on('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.on('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _releaseDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.off('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.off('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _onPaymentMethodRequestable(event) { this.forceUpdate(); }
  _onNoPaymentMethodRequestable() { this.forceUpdate(); }
}

export default Form.create({})(CommonPaymentView);
