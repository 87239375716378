import React, { Component } from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { Button, Form, TreeSelect, Row, Col, InputNumber, DatePicker, Upload, Icon, message } from 'antd';

import Globals from '../../config/Globals';
import '../../assets/stylesheets/CommonWorkHoursView.scss';

const { TreeNode } = TreeSelect;
const { RangePicker } = DatePicker;


class CommonWorkHoursForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      disabled: false,
      isLoading: false,
      isVisible: false,
      uploadSuccess: false,
      //files
      pendingDeletion: [], //images deleted locally, but still need to be delete if the user save
      pendingSave: [], //images that had been uploaded but not saved on the DB yet
      uploadingFile: null, //current uploading image
      currentFiles: [] //current wk images (previously saved)
    };
  }

  componentDidMount = () => {
    if (this.props.initialData) {
      console.log(this.props.initialData);
      this.showAnotherWorkHours();
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.initialData && this.props.initialData) {
      if (prevProps.initialData.key !== this.props.initialData.key) {
        this.showAnotherWorkHours();
      }
    } else if (this.props.initialData) this.showAnotherWorkHours();
  }
  clearValues() { this.props.form.resetFields(); }
  showAnotherWorkHours = () => {
    //this.getFiles(this.props.initialData.attachments);
    this.setState({currentFiles: (this.props.initialData.attachments ? this.props.initialData.attachments : [])});
    const period = [moment(this.props.initialData.initDate), moment(this.props.initialData.endDate)];
    this.props.form.setFieldsValue({
      numberOfHours: this.props.initialData.hours,
      pumpCategories: this.props.initialData.pumpCategories,
      period,
    });
  };

  //Main Actions
  async handleSave() {
    let files = this.state.currentFiles.concat(this.state.pendingSave);
    const resp = await this.props.form.validateFields();
    if (resp) this.props.handleSave(resp, files);
    this._removeFlaggedImages();
  };
  handleCancel = () => {
    this.props.handleCancel();
    this._discardUnsavedImages();
  };
  //Actions
  async handleFilePreview(file) {
    this.setState({isLoading: true});
    const response = await this.props.app.api.userFile.signedGetURL(this.props.userID, file.uid);
    if (response.statusCode != 200 || !response.body.fileURL) {
      console.log('ERROR while getting user work hours file');
      this.props.app.alertController.showErrorAlert('Error', Globals.ERROR_MESSAGE_GET_WORK_HOURS_FILES);
    } else this.props.app.openExternalPage(response.body.fileURL, false);
    this.setState({isLoading: false});
  };
  handleRemoveFile(file) {
    this._flagImageForDeletion(file);
  };
  handleUploadFile(event) {
    this.setState({ isLoading: true, disabled: true, uploadingFile: event.file });
    // Add class to new files
    const containerFiles = document.querySelectorAll('.ant-upload-list.ant-upload-list-picture')[0].childNodes;
    this._updateClasses(containerFiles[containerFiles.length - 1], 'add', 'loading-file');
    event.onProgress(1);
    //
    this._uploadImage(event.file);
  }

  //UI
  render() {
    const fileList = this.state.currentFiles.concat(this.state.pendingSave);
    if (this.state.uploadingFile) fileList.push(this.state.uploadingFile);
    console.log('Files are', fileList)
    //
    return (
      <>
        <Form>
          <Row align="middle" justify="space-between">
            <Col span={4}>
              <Form.Item label={Globals.LABEL_NUMBER_OF_HOURS}>
                {this.props.form.getFieldDecorator('numberOfHours', {
                  rules: [{ required: true, message: 'Please, inform the number of hours!' }],
                })(
                  <InputNumber min={0} precision={2} decimalSeparator="."
                               parser={value => value.replace(/\$\s?|(,*)/g, '')} formatter={value =>
                      `${value || parseFloat(0).toFixed(Globals.FLOAT_DECIMALS)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,'$1,')}/>
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item label={Globals.LABEL_PERIOD}>
                {this.props.form.getFieldDecorator('period', {
                  rules: [{ required: true, message: 'Please, inform the period!' }],
                })(
                  <RangePicker
                    disabledDate={this._disabledDate}
                    ranges={{
                      Today: [moment(), moment()],
                      'Last 15 days': [moment().subtract(15, 'days'), moment()],
                      'Last Month': [
                        moment()
                          .startOf('month')
                          .subtract(1, 'month'),
                        moment()
                          .endOf('month')
                          .subtract(1, 'month'),
                      ],
                    }}/>
                )}
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item label={Globals.LABEL_PUMP_CATEGORY}>
                {this.props.form.getFieldDecorator('pumpCategories', {
                  rules: [{ required: true, message: 'Please, inform at least one pump category!' }],
                })(
                  <TreeSelect showSearch style={{ width: '100%' }} dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                              placeholder="Please select" allowClear multiple treeDefaultExpandAll>
                    {Globals.LABEL_PUMPS_CATEGORIES.map(category => (
                      <TreeNode disabled={category.isDisabled} value={`${category.description}`}
                                title={`${category.description}`} key={`${category.pk}`} className='topTreeNode'>
                        {category.Options &&
                          category.Options.map(option => (
                            <TreeNode
                              value={`${option.description}`}
                              title={`${option.description}`}
                              key={`${option.pk}`}
                              onChange={this.handleValidation}
                            />
                          ))}
                      </TreeNode>
                    ))}
                  </TreeSelect>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Form.Item label={Globals.LABEL_ATTACHMENTS}>
                {this.props.form.getFieldDecorator('attachments', {
                  rules: [
                    { required: true, message: 'Please, upload at least one file to comprove the hours reported!' },
                  ],
                })(
                  <div>
                    <Upload className={`upload-list-inline isUploadSuccess-${this.state.uploadSuccess} wh-upload-list`}
                            listType="picture" customRequest={this.handleUploadFile} onRemove={this.handleRemoveFile}
                            fileList={fileList} onPreview={this.handleFilePreview} multiple>
                      <Button> <Icon type="upload" /> Upload </Button>
                    </Upload>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col offset={22}
                 style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', minHeight: 79, maxHeight: 79, }}>
              <Button style={{ border: 0, backgroundColor: '#af3947', color: '#fff', marginRight: 10 }}
                      onClick={this.handleSave} disabled={this.state.isLoading}> Save </Button>
              {this.props.initialData && (
                <Button onClick={this.handleCancel} disabled={this.state.disabled}>
                  Cancel
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </>
    );
  }
  //Helper
  _disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }
  _updateClasses = (element, action, className) => {
    if (action == 'add') element.classList.add(className);
    else element.classList.remove(className);
  };
  //Images
  _flagImageForDeletion(file) {
    //mark image for deletion
    let pendingDeletion = this.state.pendingDeletion;
    pendingDeletion.push(file);
    //remove from current files
    let currentFiles = this.state.currentFiles;
    currentFiles.splice(currentFiles.indexOf(file),1);
    //remove from pending save files
    let pendingSave = this.state.pendingSave;
    pendingSave.splice(pendingSave.indexOf(file),1);
    this.setState({pendingSave, currentFiles, pendingDeletion});
  }
  _uploadImage(file) {
    const containerFiles = document.querySelectorAll('.ant-upload-list.ant-upload-list-picture')[0].childNodes;
    //Read file
    const fileReader = new FileReader();
    fileReader.onloadend = async readerResp => {
      //Prepare to upload
      const userID = this.props.userID;
      const hashedFile = this.props.app.api.userFile.encodedFileName(userID, file.name);
      file.file = readerResp.currentTarget.result;
      //Upload
      const resp = await this.props.app.api.userFile.uploadImage(file, hashedFile, userID, Globals.API_FileUpload_WorkHoursDescription);
      if (resp.statusCode == 200) {
        let pendingSave = this.state.pendingSave;

        pendingSave.push({ uid: hashedFile, name: file.name, type: file.type, url: hashedFile, status: 'done' });
        this.setState({ isLoading: false, pendingSave: pendingSave, uploadSuccess: true, disabled: false, uploadingFile: null});
        // Remove loading class and Add class to new files
        this._updateClasses(containerFiles[containerFiles.length - 1], 'remove', 'loading-file');
        this._updateClasses(containerFiles[containerFiles.length - 1], 'add', 'loading-file-done');
        message.success(`${file.name} file uploaded successfully.`);
        // await this.loadImage(hashedFile);
      } else {
        // Remove loading class and Add class to new files
        this._updateClasses(containerFiles[containerFiles.length - 1], 'remove', 'loading-file');
        this._updateClasses(containerFiles[containerFiles.length - 1], 'add', 'loading-file-fail');
        this.props.app.alertController.showErrorAlert(
          'Error',
          'Could not upload work hours file - ' + JSON.stringify(resp.error.message)
        );
        this.setState({ isLoading: false, uploadSuccess: false, uploadingFile: null, disabled: false });
      }
    };
    fileReader.readAsDataURL(file);
  }
  _discardUnsavedImages() {
    this.state.pendingSave.forEach((file) => {
      console.debug('Discarding file', file)
      this.props.app.api.userFile.deleteImage(file.uid, this.props.userID);
    });
  }
  _removeFlaggedImages() {
    this.state.pendingDeletion.forEach((file) => {
      console.debug('Removing file', file)
      this.props.app.api.userFile.deleteImage(file.uid, this.props.userID);
    });
  }
}

export default Form.create()(CommonWorkHoursForm);
