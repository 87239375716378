import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import UserRegistrationModal from './UserRegistrationModal';
import CommonCertificationCollectionView from '../commonSubviews/CommonCertificationCollectionView';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonUserHeader from  '../commonComponents/CommonUserHeader';
//
import config from '../../config/config';
import DataUtils from '../../components/DataUtils';
// import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonDashboardStyles.scss';
//
export default class UserDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, firstLoad: true,
                   certifications: [], personalPart: null, selectingCertSpecsID: null};
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({ isLoading: true });
    //Try to get current logged CCPO user, if does not exists, handle enrollment
    if (!this.props.app.sharedCache().getCCPOUser()) {
      this.setState({ isLoading: false });
      this.registrationModal.handleShow();
    } else {
      this.fetchData();
    }
  }
  //Data
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return; //Important, check if is mounted
    const ccpoUser = this.props.app.sharedCache().getCCPOUser(); //get current logged user
    const userCertsResp = await this.props.app.api.userCertification.getByUserID(ccpoUser.id);
    this.loadResponse(userCertsResp);
  }
  async loadResponse(userCertsResp) {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    if (!this._isMounted) return; //check if is mounter before processing response and setting state!

    //Mount state
    let certifications = [];
    if (userCertsResp.statusCode == 200 && userCertsResp.body && userCertsResp.body.procs) {
      //valid data state
      certifications = DataUtils.processUserCertifications(userCertsResp.body.procs, tenantConfig.certifications);
      //Auto Select certification
      if (this.state.selectingCertSpecsID) {
        const cert = certifications.filter(item => item.certificationID == this.state.selectingCertSpecsID);
        if (cert) this.props.app.pushPage(config.ApplicationRoutes.certification, {}, cert[0].id);
      }
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, userCertsResp);
    }
    //should be available locally but we add on state j8ust in case
    const personalPart = await this.props.app.idm.session.getPartitionByID(config.IDMClientOptions.partitions.PERSONAL, true);
    this.stopLoading(false);
    this.setState({certifications: certifications, firstLoad: false, selectingCertSpecsID: null, personalPart});
  }
  //Actions
  handleUpdate = async () => {
    this.fetchData();
  };
  handleRegistrationCompletion() { this.fetchData(); }
  async handleStartCertification(certSpecsID) { this._startCertification(certSpecsID); }

  //UI
  render() {
    const ccpoUser = this.props.app.sharedCache().getCCPOUser();
    return (
      <Layout.Content className="dashboard dashboardNoNav">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <CommonUserHeader app={this.props.app} ccpoUser={ccpoUser}
                          personalPart={this.state.personalPart}
                          isLoading={this.state.isLoading} datasource={this}/>
        {this.state.certifications.length > 0 && (
          <CommonCertificationCollectionView
            certifications={this.state.certifications}
            handleStartCertification={this.handleStartCertification}
            app={this.props.app}
          />
        )}
        <UserRegistrationModal
          app={this.props.app}
          delegate={this}
          completionHandler={this.handleRegistrationCompletion}
        />
      </Layout.Content>
    );
  }

  //API
  async _startCertification(certSpecsID) {
    this.startLoading();
    const ccpoUser = this.props.app.sharedCache().getCCPOUser(); //get current logged user
    //Start cert
    const certStarted = await this.props.app.api.userCertification.createByUserIDAndCertSpecsID(ccpoUser.id, certSpecsID);
    if (!this._isMounted) return;
    //Check answer
    if (certStarted.statusCode == 200 && certStarted.body) {
      message.success('Certification initiated.');
      //Flag cert id to be selected
      this.setState({ selectingCertSpecsID: certSpecsID }, () => {
        this.fetchData();
      });
    } else {
      //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, certStarted);
      this.stopLoading();
    }
  }
}
