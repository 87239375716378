import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Row, Col } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import AdminDashboardGraph_Achieved from './AdminDashboardGraph_Achieved';
import AdminDashboardGraph_Certifications from './AdminDashboardGraph_Certifications';
import AdminDashboardGraph_ExamRevenue from './AdminDashboardGraph_ExamRevenue';
import AdminDashboardGraph_CertRevenue from './AdminDashboardGraph_CertRevenue';
//
import CommonUserHeader from  '../commonComponents/CommonUserHeader';
import CommonPeriodFilter from './../commonComponents/CommonPeriodFilter';
//
import Globals from '../../config/Globals';
import config from '../../config/config';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/AdminDashboardView.scss';
//
const ALL_MONTHS =  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
//
export default class AdminDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      stats: null,
      selectedStatsYear: (new Date()).getFullYear(), //current year
      selectedStatsMonths: ALL_MONTHS,
      selectedStatsTo: null,
      isLoading: false,
      firstLoad: true,
    };
  }
  //API
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
    //
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObj });
  }
  async fetchData() {
    this.startLoading();
    const stats = await (!this.state.selectedStatsTo ? 
      this.props.app.api.tenant.getStatsByYear(this.state.selectedStatsYear) : 
      this.props.app.api.tenant.getStatsByRange(this.state.selectedStatsYear, this.state.selectedStatsTo));
    this.loadData(stats)
  }
  async loadData(stats) {
    if (!this._isMounted) return;
    console.debug('Stats resp',stats);
    if (stats.statusCode == 200 && stats.body.stats) {
      this.graphAchieved.loadData(stats.body.stats);
      this.graphCertifications.loadData(stats.body.stats);
      this.graphExamRevenue.loadData(stats.body.stats);
      this.graphCertRevenue.loadData(stats.body.stats);
      this.setState({ stats: stats.body.stats, isLoading: false, firstLoad: false});
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, stats);
      this.setState({ stats: null, isLoading: false, firstLoad: false});
    }
  }
  //Filter
  handleAllFilter() { this._reloadDataByFilter(ALL_MONTHS, '2'); }
  handleYearFilter(year) { this._reloadDataByFilter(ALL_MONTHS, year); }
  handleMonthFilter(month, year) { this._reloadDataByFilter([parseInt(month)], year); }
  handleQuarterFilter(quarter, year) { this._reloadDataByFilter(Utils.getQuarterMonths(quarter), year); }
  handleRangeFilter(from, to) {
    this._reloadDataByFilter(ALL_MONTHS, from, to);
  }
  _reloadDataByFilter(months, year, optTo) {
    this.setState({ selectedStatsYear: year, selectedStatsMonths: months, selectedStatsTo: optTo }, () => {
      this.fetchData();
    });
  }
  //UI
  render() {
    return (
      <div className="dashboardNoNav">
        <Row type="flex" align="middle" justify='space-between' className='mainGraphRow'>
          <CommonUserHeader app={this.props.app} ccpoUser={this.state.userObj}
                            idmUser={{}} personalPart={{}} isAdminView
                            isLoading={this.state.isLoading} datasource={this}/>
          <Col className='filterAdminDashboardCol'>
            <CommonPeriodFilter onYearSelect={this.handleYearFilter} onQuarterSelect={this.handleQuarterFilter}
                                onMonthSelect={this.handleMonthFilter} onAllSelect={this.handleAllFilter}
                                onRangeSelect={this.handleRangeFilter}/>
          </Col>
        </Row>
        <Row type="flex" align="middle" justify='space-around' className='mainGraphRow'>
          <AdminDashboardGraph_Achieved {... Utils.propagateRef(this, 'graphAchieved')}
                                        isLoading={this.state.isLoading} months={this.state.selectedStatsMonths}/>
          <AdminDashboardGraph_Certifications {... Utils.propagateRef(this, 'graphCertifications')}
                                        isLoading={this.state.isLoading} months={this.state.selectedStatsMonths}/>
        </Row>
        <Row type="flex" align="middle" justify='space-around'  className='mainGraphRow'>
          <AdminDashboardGraph_ExamRevenue {... Utils.propagateRef(this, 'graphExamRevenue')}
                                        isLoading={this.state.isLoading} months={this.state.selectedStatsMonths}/>
          <AdminDashboardGraph_CertRevenue {... Utils.propagateRef(this, 'graphCertRevenue')}
                                        isLoading={this.state.isLoading} months={this.state.selectedStatsMonths}/>
        </Row>
      </div>
    );
  }
}
