import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { format } from 'date-fns';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Divider, Button, Badge, Descriptions, Dropdown, Menu, Icon, Popconfirm, message, Modal } from 'antd';

//
import IconsTimeline from './IconsTimeline';
import CommonCertificationTimelineEventPopover from './CommonCertificationTimelineEventPopover';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/CommonCertificationProcess.scss';

export default class CommonCertificationTimelineEvent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }
  // Actions
  async handleRequestWaive(event) {
    this.props.onWaive(event);
  }

  render() {
    const event = this.props.event;
    const index = this.props.index;
    return (
      <VerticalTimelineElement key={index} state={event.type}
        className="vertical-timeline-element--work" animate={false}
        date={event.eventDate != -1 ? format(new Date(event.eventDate), 'MMM dd, yyyy') : ''}
        iconStyle={{ background: '#f0f2f5', textAlign: 'center', }}
        icon={<IconsTimeline state={`${event.type}${event.state}`} eventState={event.state} />}>
        <CommonCertificationTimelineEventPopover event={event} app={this.props.app} index={index}
        onExamPracticalResult={this.props.onExamPracticalResult} onReceiptDownload={this.props.onReceiptDownload}>
          {this._renderEventContent(event, index)}
        </CommonCertificationTimelineEventPopover>
      </VerticalTimelineElement>
    );
  }
  _renderEventContent(event) {
    return (
      <div className="containterItem">
        {this._renderBadge(event)}
        <h3 className="vertical-timeline-element-title">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
              <IconsTimeline event={event.type} eventState={`${event.state}`} />
              <span style={{ marginLeft: 10, color: 'black' }}>{event.metadata.title}</span>
            </div>
            {this._renderButton(event)}
          </div>
        </h3>
        <Divider />
        <h4 className="vertical-timeline-element-subtitle">{event.id}</h4>
        <p style={{ marginBottom: 40 }}>{event.metadata.description}</p>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="badgeContainer">
            <div className="event" state={`${event.state}`}>
              <Badge>{event.metadata.badge.toUpperCase()}</Badge>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* private */
  _confirmWaiveRequest(event) {
    Modal.confirm({
      title: 'Confirm Waive Request',
      icon: <Icon type="exclamation-circle" />,
      content: 'Are you sure that you want to send a Waive Request?',
      onOk: () => {
        this.handleRequestWaive(event);
      },
      onCancel() {},
    });
  }
  _renderButton(event/*, isAdminView*/) {
    const eventType = event.type;
    const eventState = event.state;
    const hasCertNumber = !!(
      event.metadata && event.metadata.object && event.metadata.object.cert && event.metadata.object.cert.certNumber
    );

    let isPractical = false;
    if (event.metadata && event.metadata.object && event.metadata.object.examSpecs && event.metadata.object.examSpecs.id) {
      isPractical = event.metadata.object.examSpecs.id.includes('Practical');
    } else if (event.metadata && event.metadata.object && event.metadata.object.id) {
      isPractical = event.metadata.object.id.includes('Practical');
    }

    //Payment ready to be done
    if (eventType == Globals.Event_Type.PAYMENT &&
        eventState == Globals.Event_State.READY) {

      if (hasCertNumber && isPractical) {
        return (
          <Popconfirm
            title={(
              <>
                <p>
                  <strong>Your practical assessment can be waived if you can provide confirmation</strong>
                  <br />
                  <strong>of enough work hours.</strong>
                </p>
                <p>
                  If you have enough hours already added to your account, select <strong>"Request Waive"</strong>
                  <br />
                  to request your assessment to be waived. <br />
                  If you would like to add your work hours into your account select <strong>"Work Hours"</strong>
                  <br />
                  option in left sidebar and come back here.
                </p>
                <p>
                  But if you do not have enough hours as a pump operator, click in <strong>"Proceed to Payment"</strong>
                </p>
              </>
            )}
            okText="Request Waive"
            onConfirm={() => this._confirmWaiveRequest(event)}
            cancelText="Proceed to Payment"
            onCancel={this.props.onPayment.bind(this, event.metadata.object)}
          >
            <Button>
              {Globals.Timeline_label.RENEWAL.toUpperCase()}
            </Button>
          </Popconfirm>
        );
      }

      return (
        <Button onClick={this.props.onPayment.bind(this, event.metadata.object)}>
          {Globals.Timeline_label.PAY_NOW.toUpperCase()}
        </Button>
      );
    }
    //Reschedule payment
    if (eventType == Globals.Event_Type.RES_PAYMENT &&
        eventState == Globals.Event_State.READY) {
      return (
        <Button onClick={this.props.onExamReschedulePayment.bind(this, event)}>
          {Globals.Timeline_label.PAY_NOW.toUpperCase()}
        </Button>
      );
    }
    //Payment WIP
    if (eventType == Globals.Event_Type.PAYMENT &&
        eventState == Globals.Event_State.IN_PROGRESS) {
      return (
        <Button onClick={this.props.onPaymentCancel.bind(this, event)}>
          {Globals.Timeline_label.CANCEL.toUpperCase()}
        </Button>
      );
    }
    //Exam inprogress or ready
    if (eventType === Globals.Event_Type.EXAM && (
        eventState === Globals.Event_State.IN_PROGRESS ||
        eventState === Globals.Event_State.READY ||
        eventState === Globals.Event_State.LOCKED )) {
      const isScheduled = (event.metadata.object.examDate && event.metadata.object.examDate != -1);
      const isPractical = (event.metadata.object.examSpecs.id.includes('Practical'));
      const isLocked = (eventState === Globals.Event_State.LOCKED);
      const menu = (
        <Menu>
          {isLocked && !isPractical && this.props.app.isAdmin() &&
            <Menu.Item onClick={this.props.onExamSchedule.bind(this, event)}> <Icon type="unlock" /> {Globals.Timeline_label.RELEASE_EXAM} </Menu.Item>
          }
          {isLocked && !isPractical && !this.props.app.isAdmin() &&
            <Menu.Item onClick={this.props.onNextSteps.bind(this, event)}> <Icon type="unlock" /> {Globals.Timeline_label.RELEASE_EXAM} </Menu.Item>
          }
          {!isLocked && !isPractical &&
            <Menu.Item onClick={this.props.onExamLaunch.bind(this, event)}> <Icon type="export" /> {Globals.Timeline_label.LAUNCH_EXAM} </Menu.Item>
          }
          {this.props.app.isAdmin() && !isScheduled && isPractical &&
            <Menu.Item onClick={this.props.onExamSchedule.bind(this, event)}> <Icon type="calendar"/> {Globals.Timeline_label.SCHEDULE_EXAM} </Menu.Item>
          }
          {isScheduled && (this.props.app.isAdmin() ?
            <Menu.Item onClick={(isPractical ?
                this.props.onExamRescheduleRequest.bind(this, event) :
                this.props.onExamReschedulePayment.bind(this, event)
              )}> <Icon type="issues-close" /> {Globals.Timeline_label.RESCHEDULE_EXAM} </Menu.Item> :
            <Menu.Item onClick={this.props.onNextSteps.bind(this, event)}> <Icon type="issues-close" /> {Globals.Timeline_label.RESCHEDULE_EXAM} </Menu.Item>
          )}
          {this.props.app.isAdmin() && !isPractical &&
            <Menu.Item onClick={this.props.onExamWrittenResult.bind(this, event)}> <Icon type="form" /> {Globals.Timeline_label.ADD_EXAM_RESULT} </Menu.Item>}
          {!isLocked && !isPractical &&
            <Menu.Item onClick={this.props.onCheckResults.bind(this, event)}> <Icon type="sync" /> {Globals.Timeline_label.CHECK_RESULTS} </Menu.Item>}
          {this.props.app.isAdmin() ?
            <Menu.Item onClick={this.props.onExamCancel.bind(this, event)}> <Icon type="close-circle" /> {Globals.Timeline_label.CANCEL_EXAM} </Menu.Item> :
            <Menu.Item onClick={this.props.onNextSteps.bind(this, event)}> <Icon type="close-circle" /> {Globals.Timeline_label.CANCEL_EXAM} </Menu.Item>}
        </Menu>
      );
      return (
        <Dropdown overlay={menu} placement="bottomLeft">
          <Button>{Globals.Timeline_label.OPTIONS.toUpperCase()}</Button>
        </Dropdown>
      );
    }
    //Cooldown
    if (eventType == Globals.Event_Type.EXAM &&
        eventState == Globals.Event_State.WAITING &&
        this.props.app.isAdmin()) {
      return (
        <Button onClick={this.props.onExamPracticalResult.bind(this, event)}>
          {Globals.Timeline_label.APPROVE_EXAM.toUpperCase()}
        </Button>
      );
    }
    //Cooldown
    if (eventType == Globals.Event_Type.COOLDOWN && eventState == Globals.Event_State.IN_PROGRESS && this.props.app.isAdmin()) {
      return (
        <Button onClick={this.props.onCooldownEnd.bind(this, event)}>
          {Globals.Timeline_label.CANCEL_COOLDOWN.toUpperCase()}
        </Button>
      );
    }
    //Card Print
    if (eventType == Globals.Event_Type.PRINTCARD && eventState == Globals.Event_State.IN_PROGRESS && this.props.app.isAdmin()) {
      return (
        <Button onClick={this.props.onCardPrint.bind(this, event)}>
          {Globals.Timeline_label.PRINT.toUpperCase()}
        </Button>
      );
    }
    //Certification completed
    if (eventType == Globals.Event_Type.CERTIFICATION &&
        (eventState == Globals.Event_State.COMPLETED || eventState == Globals.Event_State.EXPIRED) &&
        !event.metadata.object.noAction) {
      const isExpired = (eventState == Globals.Event_State.EXPIRED);
      //Check here if is in the renewal range but not expired yet
      if (!isExpired) {
        const time = Utils.timestampBeforeYearsFromDate(event.metadata.object.certSpecs.allowRenewalBefore, new Date(event.metadata.object.expiryDate));
        //check is in the renewal range
        if (Date.now() < time) return ''; //deny action
      }
      return (
        <Button className={(isExpired ? 'red' : 'green')}
                onClick={this.props.onRenewal.bind(this, event)}>
          {Globals.Timeline_label.RENEWAL.toUpperCase()}
        </Button>
      );
    }
    //
    return '';
  };
  _renderBadge(event) {
    const eventType = event.type;
    const eventState = event.state;
    if (eventType === Globals.Event_Type.REQUIREMENT && eventState === Globals.Event_State.COMPLETED &&
        event.metadata.object && event.metadata.object.examSpecs && !event.metadata.object.invalidated &&
        event.metadata.object.state == Globals.Exam_State.PASS &&
        this.props.certification.state != Globals.CertificationProcess_State.COMPLETED) {
          const isPractical = (event.metadata.object.examSpecs.id.includes('Practical'));
          const time = new Date(event.metadata.object.reqExpiryDate);
          let daysDiff = (time.getTime() - Date.now())/ (1000 * 3600 * 24);
          if (!isPractical && parseInt(daysDiff) >= 0) {
              return (<Badge className='timelineEventBadge' count={`Expires in ${parseInt(daysDiff)} ${daysDiff == 1 ? 'day' : 'days'}`}/>);
          } else if (!isPractical && parseInt(daysDiff) < 0) {
            daysDiff *= -1;//invert
            return (<Badge className='timelineEventBadge' count={`Expired ${parseInt(daysDiff)} ${daysDiff == 1 ? 'day' : 'days'} ago`}/>);
          }
    } return (<></>);
  }
}
