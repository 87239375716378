import React from 'react';
import { Modal, Form, Steps, Button, Icon, Layout } from 'antd';
// import { Spring } from 'react-spring/renderprops';

import CustomComponent from '../../components/CustomComponent';

const { Step } = Steps;

require('./../../assets/stylesheets/CommonModal.scss');

class CommonModal extends CustomComponent {
  constructor(props) {
    super(props);

    this.state = {
      noSteps: props.noSteps ? props.noSteps : false,
      isLoading: props.isLoading,
      isVisible: props.isVisible,
      current: 0,
    };
  }

  handleOk = e => {
    console.log(e);
    this.setState({
      isVisible: false,
      current: 0,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      isVisible: false,
    });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    return (
      <Layout>
        <Modal
          maskClosable={false}
          title={this.props.title}
          visible={this.state.isVisible}
          confirmLoading={this.state.isLoading}
          closable={false}
          footer={this.props.modalButtons}
        >
          <>
            {this.state.noSteps ? (
              <>{this.props.children}</>
            ) : (
              <>
                <Steps id="StartCertificationSteps" current={this.state.current} type="navigation">
                  {this.props.steps.map((item, key) => (
                    <Step
                      icon={key !== 1 && item.key === this.state.current + 1 ? <Icon type="loading" /> : ''}
                      key={item.title}
                      title={item.title}
                    />
                  ))}
                </Steps>

                {this.props.steps.map((step, index) => {
                  return (
                    <div key={index} className="steps-content" hidden={this.state.current != index}>
                      {this.props.steps[index].content}
                    </div>
                  );
                })}
                <div className="steps-action">
                  {this.state.current > 0 && (
                    <Button style={{ marginLeft: 0 }} className="secondary bordered" onClick={() => this.prev()}>
                      Previous
                    </Button>
                  )}
                  {this.state.current < this.props.steps.length - 1 ? (
                    <Button className="primary solid" type="primary" onClick={() => this.next()}>
                      Next
                    </Button>
                  ) : (
                    <>{this.props.submitButton && <>{this.props.submitButton}</>}</>
                  )}
                </div>
              </>
            )}
          </>
        </Modal>
      </Layout>
    );
  }
}

export default Form.create()(CommonModal);
